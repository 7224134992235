<template>
    <Modal class="modal modal--location" :size="'medium'" :closable="false" :modalStyle="'padding:80px 105px 80px 105px'">
        <template #header>
            <div class="u-text-center">
                <h1>{{$t('profile.select_address.title')}}</h1>
                <p>{{$t('profile.select_address.description')}}</p>
            </div>
        </template>
        <template #body>
            <div class="input-box input-box--search">
                <p>{{$t('profile.your_location')}}</p>
                <SearchLocation @focus="setFocus" @location-updated="setLocation" :placeholder="$t('form_fields.enter_your_city')" />
            </div>
        </template>
        <template #footer>
            <button :disabled="!location || focus" @click="work($event)" class="button button--primary w-100">{{$t('settings.save')}}</button>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal";
import {updateProfile} from "@/service/user";
import toastr from "toastr";
import SearchLocation from "@/components/location/SearchLocation";
import { mapActions } from "vuex";

export default {
    name: "PreActionLocation",
    components: {Modal, SearchLocation},
    methods: {
        ...mapActions({
            updateLocation: 'search/setLocation'
        }),
        setFocus(value) {
            this.focus = !! value
        },
        setLocation(location) {
            this.location = location
        },
        async work($event) {
            if( ! this.location)
                return
            $event.target.disabled = true
            const payload = {
                ...this.location,
                address: this.location.location
            }
            try {
                await updateProfile(payload)
                this.updateLocation(this.location)
                this.$emit('success')
            } catch (e) {
                console.log(e)
                toastr.error(this.$i18n.t('credits.error_processing'))
            }
            $event.target.disabled = false

        }
    },
    data() {
        return {
            location: null,
            focus: false
        }
    }
}
</script>

<style scoped lang="scss">
    h1 {
        font-size: 26px;
    }

    .close {
        position: absolute;
        right: -65px;
        top: -45px;
    }

    .text {
        color: $color-type12;
        margin: 20px auto;
        max-width: 374px;
        line-height: 1.1;
        font-size: 15px;
    }

    .button--fourth {
        margin-bottom: 30px;
        height: 45px;
        margin-top: 10px;
        text-transform: uppercase;
    }
</style>

<style lang="scss" >
    .modal--location {

        .autocomplete-dropdown {
            position: relative;
            background-color: #F5F5F5;
        }
    }
</style>
