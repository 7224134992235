<template>
    <main>
        <div class="o-container">
            <section class="settings">
                <div>
                    <div class="header-mob">
                        <router-link :to="{name: 'search'}" class="back-mob"><svg xmlns="http://www.w3.org/2000/svg" width="14.004" height="14.001"><path data-name="Union 6" d="M6.289 13.708.262 7.669a1 1 0 0 1 .03-1.379l6-6a1 1 0 0 1 1.417 0 1 1 0 0 1 0 1.413l-4.3 4.295H13a1 1 0 0 1 1 1 1 1 0 0 1-1 1H3.41l4.292 4.3a1 1 0 0 1 0 1.412A1 1 0 0 1 7 14a1 1 0 0 1-.711-.292Z"/></svg></router-link>
                        <h1>{{$t('navigation.user_menu.settings')}}</h1>
                    </div>

                    <ul>
                        <li>
                            <router-link active-class="active" :to="{name: 'settings-email'}" class="settings-item">
                                {{$t('settings.email_settings')}}
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 115"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link active-class="active" :to="{name: 'settings-password'}" class="settings-item">
                                {{$t('settings.password')}}
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 115"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </router-link>
                        </li>
                        <li v-if="auth.user.gender === TYPE_GENDER_MALE">
                            <router-link active-class="active" :to="{name: 'settings-secret-browsing'}" class="settings-item">
                                {{$t('settings.secret_browsing')}}
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 115"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link active-class="active" :to="{name: 'settings-email-notifications'}" class="settings-item">
                                {{$t('settings.email_notifications')}}
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 115"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link active-class="active" :to="{name: 'settings-blocked-users'}" class="settings-item">
                                {{$t('users.blocked')}}
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 115"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link active-class="active" :to="{name: 'settings-delete-profile'}" class="settings-item">
                                {{$t('profile.delete.profile')}}
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 115"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <router-view></router-view>
            </section>
        </div>
    </main>
</template>

<script>
import { mapState } from "vuex";
import { TYPE_GENDER_MALE } from "@/types/user";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";

export default {
    name: "SettingsPage",
    mixins: [AuthenticatedMixin],
    computed: {
        ...mapState({
            mobile: state => state.application.mobile
        })
    },
    created() {
        this.TYPE_GENDER_MALE = TYPE_GENDER_MALE
    },
    mounted() {
        if( ! this.mobile && this.$route.name === 'settings') {
            this.$router.push({name: 'settings-email'})
        }
    }
}
</script>

<style scoped lang="scss">

    @import "../../assets/styles/components/_components.settings.scss";

    .header-mob {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .back-mob {
            display: none;
        }

        h1 {
            margin-bottom: 0;
            font-size: 22px;
            font-weight: 700;
        }

        @media(max-width: 500px) {



            .back-mob {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                margin-right: 10px;
            }
        }
    }

    ul {
        margin-bottom: 0;

        li {
            margin-bottom: 20px;
        }
    }

    .settings-item {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: -0.74px;
        text-align: left;
        transition: all 0.3s ease 0s;
        color: #000000;

        svg {
            display: none;
        }

        &:hover,
        &.active  {
            color: $color-type19;
            font-weight: 600;
        }

        @media(max-width: 991px) {
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            svg {
                display: flex;
            }
        }
    }

</style>
