import { ANIMATION_PERIOD, ANIMATION_KEY } from "@/utilities/animationConfig";

export const setUserAsAnimated = user => {

    let data = JSON.parse(localStorage.getItem(ANIMATION_KEY)) || []

    data = data.filter(e => e.id !== user.id)

    data.push({
        id: user.id,
        valid_until: Date.now() + (ANIMATION_PERIOD * 1000)
    })

    localStorage.setItem(ANIMATION_KEY, JSON.stringify(data))
}

export const getValidAnimatedUsersIDs = () => {

    return getAllAnimated().map(e => e.id)

}

export const getAllAnimated = () => {
    return JSON.parse(localStorage.getItem(ANIMATION_KEY)) || []
}

export const clearExpiredAnimatedUsers = () => {
    const data = getAllAnimated().filter(e => e.valid_until >= Date.now())
    if( ! data.length) {
        return localStorage.removeItem(ANIMATION_KEY)
    }
    localStorage.setItem(ANIMATION_KEY, JSON.stringify(data))
}