<template>
    <Modal class="modal modal--mob modal--registration" :closable="false">
        <template #body>

            <h1>{{$t('profile.edit.info')}}</h1>

            <Form ref="descriptionForm" :validation-schema="validationSchema" @keydown.enter="$event.preventDefault()" :submit="work">
                <div class="input-box">
                    <p>{{$t('form_fields.needs_label')}}</p>
                    <Field as="textarea" :validateOnInput="true" class="input input--primary input--textarea" v-model="needs" name="needs" :placeholder="$t('form_fields.needs_placeholder')" />
                    <ErrorMessage class="text-error text-left form-text" name="needs" />
                </div>
                <div v-if="auth.user.gender === TYPE_GENDER_MALE" class="input-box">
                    <p>{{$t('form_fields.description_label')}}</p>
                    <Field as="textarea" :validateOnInput="true" class="input input--primary input--textarea" v-model="description" name="description" :placeholder="$t('form_fields.description_placeholder')"></Field>
                    <ErrorMessage class="text-error text-left form-text" name="description" />
                </div>
                <div class="input-box" v-else>
                    <p>{{$t('form_fields.greeting_label')}}</p>
                    <Field as="textarea" :validateOnInput="true" class="input input--primary input--textarea" v-model="greeting" name="greeting" :placeholder="$t('form_fields.greeting_placeholder')"></Field>
                    <ErrorMessage class="text-error text-left form-text" name="greeting" />
                </div>
                <div class="u-text-center">
                    <button ref="submit" class="button button--primary w-100">{{ucFirst($t('profile.edit.save'))}}</button>
                </div>
            </Form>

        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal";
import toastr from "toastr";
import {updateProfile} from "@/service/user";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { Form, Field, ErrorMessage} from "vee-validate";
import { defineRule } from "vee-validate";
import { required } from "@vee-validate/rules";
import { TYPE_GENDER_MALE } from "@/types/user";
import { ucFirst } from "@/utilities/general";
import { pushDataLayer } from "@/service/gtm";
import { GTM_EVENT_ADD_PROFILE_INFO_MODAL, GTM_EVENT_ADD_PROFILE_INFO_UPDATE } from "@/service/gtm/types";

export default {
    name: "PreActionDescription",
    mixins: [AuthenticatedMixin],
    components: {Modal, Form, Field, ErrorMessage},
    data() {
        return {
            needs: '',
            description: '',
            greeting: '',
            validationSchema: null
        }
    },

    methods: {
        ucFirst,
        async work() {

            this.$refs.submit.disabled = true

            try {

                const payload = {
                    needs: this.needs
                }

                if(this.auth.user.gender === this.TYPE_GENDER_MALE) {
                    payload.description = this.description
                } else {
                    payload.greeting = this.greeting
                }

                await updateProfile(payload)

                pushDataLayer(GTM_EVENT_ADD_PROFILE_INFO_UPDATE, this.auth.user)

                this.$emit('success')

            } catch {
                toastr.error(this.$i18n.t('credits.error_processing'))
            }

            this.$refs.submit.disabled = false

        }
    },
    created() {
        defineRule('required', required)
        defineRule('min', (value, [min], ctx) => [...value.trim()].length < parseInt(min) ? this.$i18n.t('validation.min.string').replace(':attribute', ctx.field).replace(':min', min) : true)
        this.TYPE_GENDER_MALE = TYPE_GENDER_MALE


        this.validationSchema = {
            needs: 'required|min:10'
        }

        if(this.auth.user.gender === TYPE_GENDER_MALE) {
            this.validationSchema.description = 'required|min:10'
        } else {
            this.validationSchema.greeting = 'required|min:10'
        }


    },
    mounted() {
        this.needs          = this.auth.user.needs
        this.description    = this.auth.user.description
        this.greeting       = this.auth.user.greeting
        pushDataLayer(GTM_EVENT_ADD_PROFILE_INFO_MODAL, this.auth.user)
    }
}
</script>

<style scoped lang="scss">
    h1 {
        font-size: 22px;
        margin-bottom: 30px;
        font-weight: 700;
        letter-spacing: -0.9px;
        text-align: center;
    }

    .button--primary {
        margin-top: 20px;
    }
</style>