<template>
    <div class="img-wrap" :key="s.id" v-show="k === displaying" v-for="(s, k) in slides">
        <Video preload="auto" controls  v-if="s.type === ITEM_TYPE_VIDEO" playsinline :autoplay="k === displaying" :src="s.source.original" />
        <Image v-if="s.type === ITEM_TYPE_PHOTO" :src="s.source.original" />
        <h2 style="color: white" v-if=" ! [ITEM_TYPE_VIDEO, ITEM_TYPE_PHOTO].includes(s.type)">Not supported format</h2>
        <slot name="content"></slot>
    </div>
</template>

<script>
import { ITEM_TYPE_VIDEO, ITEM_TYPE_PHOTO} from "@/types/item";
import Image from "@/components/generic/Image";
import Video from "@/components/generic/Video.vue";

export default {
    name: 'MediaSlider',
    components: {Video, Image },
    props: {
        displaying: {
            type: Number,
            default: 0
        },
        slides: {
            type: Array,
            required: true
        }
    },
    created() {
        this.ITEM_TYPE_VIDEO = ITEM_TYPE_VIDEO
        this.ITEM_TYPE_PHOTO = ITEM_TYPE_PHOTO
    }
}
</script>

<style scoped lang="scss">
.img-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 46vw;
    max-height: 100vh;
    display: block;
    width: 100%;

    img,
    video {
        max-width: 100%;
        max-height: 100vh;
        display: block;
        margin: 0 auto;
    }

    video {
        width: 100%;
    }

    @media(max-width: 768px) {
        max-width: 100vw;
        display: flex;
        width: 100%;
        height: 100vh;
        align-items: center;
    }

}
</style>