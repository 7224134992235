<template>
    <main>
        <router-view v-slot="{Component}">
            <transition name="fade" mode="out-in">
                <component :is="Component" />
            </transition>
        </router-view>
    </main>
</template>

<script>
export default {
    name: "ProfilePage",

    computed: {
        page() {
            return this.$route.path
        }
    },
}
</script>

<style scoped lang="scss">

    main {
        height: auto;
        overflow: hidden;
        @media(max-width: 991px) {
            background-color: #fff;
            height: auto;
        }
    }
    .subnav__header {
        display: none;
        align-items: center;
        margin-bottom: 20px;

        h1 {
            margin-bottom: 0;
        }

        // .back-mob {
        //     display: none;
        // }

        @media(max-width: 991px) {
            display: flex;
            .back-mob {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                margin-right: 10px;
                background-color: transparent;
            }
        }
    }

</style>