<template>
    <svg class="verified-icon" xmlns="http://www.w3.org/2000/svg" :width="width" height="16" viewBox="0 0 32 32"><g data-name="Group 121"><path data-name="Polygon 2" d="M14.51 1.665a2 2 0 0 1 2.98 0l1.268 1.417a2 2 0 0 0 2.018.6l1.821-.5a2 2 0 0 1 2.5 1.606l.315 1.924a2 2 0 0 0 1.366 1.578l1.834.587a2 2 0 0 1 1.23 2.69l-.776 1.819a2 2 0 0 0 .294 2.055l1.251 1.523a2 2 0 0 1-.421 2.923l-1.614 1.1a2 2 0 0 0-.861 1.894l.236 1.956a2 2 0 0 1-1.943 2.24l-1.9.041a2 2 0 0 0-1.762 1.137l-.83 1.737a2 2 0 0 1-2.858.838l-1.6-.994a2 2 0 0 0-2.106 0l-1.6.994a2 2 0 0 1-2.858-.838l-.83-1.737a2 2 0 0 0-1.762-1.137l-1.9-.041a2 2 0 0 1-1.943-2.24l.236-1.956a2 2 0 0 0-.861-1.894l-1.614-1.1a2 2 0 0 1-.421-2.923l1.242-1.524a2 2 0 0 0 .294-2.055l-.776-1.819a2 2 0 0 1 1.23-2.69l1.833-.586a2 2 0 0 0 1.364-1.582L6.9 4.784a2 2 0 0 1 2.5-1.606l1.821.5a2 2 0 0 0 2.018-.6Z" fill="#2c7ddb"/><path data-name="Icon metro-checkmark" d="m21.668 9.461-8.165 8.163-3.81-3.81-2.719 2.722 6.529 6.531 10.886-10.885Z" fill="#fff"/></g></svg>
</template>

<script>
export default {
    name: "Verified",
    props: {
        width: {
            type: Number,
            default: 12
        }
    }
}
</script>

<style scoped>

</style>