<template>
    <div class="bubble-notification-modal">

        <div class="bubble-notification-modal__header">
            <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"><g data-name="Group 257" transform="translate(-150 -390)"><circle data-name="Ellipse 59" cx="30" cy="30" r="30" transform="translate(150 390)" fill="#f5f5f5"/><path data-name="Shape 2" d="M179.777 438a1.6 1.6 0 0 1-.871-.258c-1.5-.969-14.709-9.694-16.411-17.448a9.618 9.618 0 0 1-.5-3.2 11.068 11.068 0 0 1 5.579-9.719 10.055 10.055 0 0 1 5.193-1.38 10.583 10.583 0 0 1 7.005 2.675 10.786 10.786 0 0 1 12.225-1.281 11.069 11.069 0 0 1 5.552 9.7 10.156 10.156 0 0 1-.559 3.4c-1.639 7.547-14.848 16.271-16.348 17.24a1.6 1.6 0 0 1-.865.271Z" fill="#c59264"/></g></svg>
            </div>

            <div class="img-wrapper">
                <img :src="getUserPhotoURL(bubble.payload.user)" alt="User Photo">
            </div>
        </div>
        <div class="text">
            {{$t('notifications.suggested.title')}}
        </div>
        <h2>
            {{$t('notifications.suggested.subtitle')}}
        </h2>
        <button @click="favoriteUser" :disabled="working" class="button button--primary">{{$t('notifications.suggested.like')}}</button>
        <router-link class="button button--fourth" @click="$emit('close'); pushDataLayer(GTM_EVENT_SUGGESTED_PROFILE_CLICK_2, auth.user)" :to="{name: 'user', params: {id: bubble.payload.user.id}}">{{$t(`notifications.actions.check.${bubble.payload.user.gender === TYPE_GENDER_FEMALE ? 'female' : 'male'}`)}}</router-link>
    </div>
</template>

<script>
import { getUserPhotoURL } from "@/service/user/profile";
import { TYPE_GENDER_FEMALE } from "@/types/user";
import { addToFavoriteService } from "@/service/favorite";
import toastr from "toastr";
import { GTM_EVENT_SUGGESTED_PROFILE_OPEN, GTM_EVENT_SUGGESTED_PROFILE_CLICK_1, GTM_EVENT_SUGGESTED_PROFILE_CLICK_2 } from "@/service/gtm/types";
import { pushDataLayer } from "@/service/gtm";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";

export default {
    name: "SuggestedSugarDaddy",
    mixins: [AuthenticatedMixin],
    props: {
        bubble: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            working: false
        }
    },
    methods: {
        pushDataLayer,
        getUserPhotoURL,
        async favoriteUser() {
            this.working = true
            try {
                await addToFavoriteService(this.bubble.payload.user.id)
                this.working = false
                toastr.success(this.$i18n.t('search.favorite_added'))
                pushDataLayer(GTM_EVENT_SUGGESTED_PROFILE_CLICK_1, this.auth.user)
            } catch (e) {
                toastr.error(this.$i18n.t('credits.error_processing'))
            }

            this.$emit('close')
        }
    },
    created() {
        this.TYPE_GENDER_FEMALE = TYPE_GENDER_FEMALE
        this.GTM_EVENT_SUGGESTED_PROFILE_CLICK_2 = GTM_EVENT_SUGGESTED_PROFILE_CLICK_2
    },
    mounted() {
        pushDataLayer(GTM_EVENT_SUGGESTED_PROFILE_OPEN, this.auth.user)
    }
}
</script>