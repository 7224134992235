// Users with 3 days of 30 credits limited promotion (quantity limited)
export const FLOW_1 = 'flow_1'

// Users with 3 days of 30 credits limited promotion (time limited)
export const FLOW_2 = 'flow_2'

// Users without payment history are locked to credits page until first purchase forever
export const SIGNUP_FLOW_1 = 'sf_1'

// Users without payment history are locked to credits page until first purchase forever
export const SIGNUP_FLOW_2 = 'sf_2'

export const SONI_FLOW_1 = 'sd1'