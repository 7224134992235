<template>
    <div class="o-flex o-flex--center">
        <svg viewBox="0 0 32 32" class="mr--" width="22" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><path d="M28 24.5H10a2 2 0 0 1-2-2v-13a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2Zm-18-15v13h18v-13Z"/><path d="M19 17.5a1 1 0 0 1-.53-.15l-8-5a1 1 0 1 1 1.06-1.7L19 15.32l7.47-4.67a1 1 0 1 1 1.06 1.7l-8 5a1 1 0 0 1-.53.15ZM6 17H3a1 1 0 0 1 0-2h3a1 1 0 1 1 0 2ZM6 11H3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2ZM6 23H3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Z"/></g></svg>
        Sending...
    </div>
</template>

<script>
export default {
    name: "SendingAnimation"
}
</script>