<template>
    <div class="status-box">
        <span></span>{{$t(`general.time_since_online`)}}
    </div>
</template>

<script>
export default {
    name: "OnlineBox"
}
</script>

<style scoped lang="scss">
    .status-box {
        padding: 0 10px 0 5px;
        height: 20px;
        border-radius: 10px;
        background-color: $color-type25;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 600;

        span {
            width: 8px;
            height: 8px;
            border-radius: 10px;
            background-color: #fff;
            margin-right: 5px;
        }
    }
</style>