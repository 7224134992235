<template>
    <div class="copy-wrapper o-flex o-flex--center">
        <div class="link-wrapper">
            {{ link }}
        </div>
        <a href="javascript:;" class="copy" @click="copyToClipboard">
            <svg width="18" viewBox="0 0 18 22">
                <g fill="none" stroke="#9ea4b2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" data-name="Icon akar-copy">
                    <path d="M5 3v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V6.24a2 2 0 0 0-.6-1.43l-3.32-3.24a2 2 0 0 0-1.4-.57H7a2 2 0 0 0-2 2Z" data-name="Path 12076"/>
                    <path d="M13 17v2a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2" data-name="Path 12077"/>
                </g>
            </svg>
        </a>
    </div>
</template>
<script>

export default {
    name:"CopyLink",
    emits: ['success', 'error'],
    props: {
        link: {
            type: String,
        }
    },
    methods: {
        async copyToClipboard() {
            try {
                await navigator.clipboard.writeText(this.link)
                this.$emit('success', this.link)
            } catch (e) {
                this.$emit('error', e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .copy-wrapper {
        gap: 20px;
        background-color: #F5F5F5 ;
        height: 40px;
        padding: 0 16px;
        width: 100%;
        border-radius: 20px;

    }

    .link-wrapper {
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        letter-spacing: -0.66px;
        color: #000000;
    }

    .copy {
        svg {
            pointer-events: none;
        }
        // path:last-child {
        //     fill: $color-type28;
        // }

        // &:hover {
        //     path:last-child {
        //         fill: darken($color-type28, 10%);
        //     }
        // }
    }
</style>