<template>
    <div class="tab-section">
        <ContentLoader v-if="loading"/>
        <div v-if="!loading">
            <article class="info-box">
                <h1>{{$t('access.declined.title')}}</h1>
                <p>
                    {{$t('access.declined.text')}}
                </p>
            </article>
            <article class="list">
                <div v-if="approved.length">
                    <transition-group appear name="fade-in" tag="ul">
                        <li v-for="(a, index) in approved" :key="a.id" :style="'--animate:' + index">
                            <router-link :to="{name: 'user', params: {id: a.user.id}}">
                                <div class="user-info">
                                    <div class="img-wrapper">
                                        <Image alt="Profile photo" :src="getUserPhotoURL(a.user, 'small')" :blurred="!! a.user.profile_photo?.private && ! access.includes(a.user.id)" />
                                    </div>
                                    <OnlineStatus class="active-user" v-if="isOnline(a.user)" />
                                    <p class="mb0">
                                        <span class="o-flex o-flex--center">
                                            <Premium v-if="a.user.premium" />
                                            <Verified v-if="a.user.verified" />
                                            <strong>{{a.user.username}}</strong>
                                        </span>
                                        <span>
                                            {{a.user.address}}
                                        </span>
                                    </p>
                                </div>
                            </router-link>

                            <button @click="update(a.id, TYPE_ACCESS_APPROVED, $event)" class="button button--primary">{{$t('access.accept')}}</button>
                        </li>
                    </transition-group>
                </div>
            </article>
            <Pagination @page-click="load" :info="pagination" />
        </div>
    </div>
</template>

<script>
import { getUserPhotoURL, isOnline } from "@/service/user/profile";
import { getDeclined, respond } from "@/api/access";
import Pagination from "@/components/generic/Pagination";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { getPaginationPayload } from "@/service/general";
import ContentLoader from "@/components/ContentLoader";
import OnlineStatus from "@/components/user/OnlineStatus";
import { TYPE_ACCESS_APPROVED } from "@/types/access";
import Premium from "@/components/generic/Premium.vue";
import Verified from "@/components/generic/Verified.vue";
import Image from "@/components/generic/Image.vue";
import {mapState} from "vuex";

export default {
    name: "Declined",
    components: {Image, Verified, Premium, Pagination, ContentLoader, OnlineStatus},
    mixins: [AuthenticatedMixin],
    data() {
        return {
            loading: false,
            approved: [],
            pagination: null
        }
    },
    computed: {
        ...mapState({
            access: state => state.access.available
        })
    },
    methods: {
        getUserPhotoURL,
        isOnline,
        async load(page = 1) {
            this.loading = true
            this.approved = []
            const { data } = await getDeclined({page: page})
            this.approved = data.data
            this.pagination = getPaginationPayload(data)
            this.loading = false
        },
        async update(id, status, $event) {
            $event.target.disabled = true
            try {
                await respond(id, status)
            } catch (e) {
                console.log('Error updating access status from declined page', e)
            }
            await this.load(this.pagination?.current_page || 1)
        }
    },
    created() {
        this.TYPE_ACCESS_APPROVED = TYPE_ACCESS_APPROVED
    },
    mounted() {
        this.load()
    }
}
</script>
<style scoped lang="scss">
    main {
        height: auto;
        min-height: 100%!important;

    }
    // @import "../../assets/styles/components/_components.page-tab-content.scss";
</style>