<template>
    <div>
        <div class="user-panel" v-if="user">
            <div class="user-panel__header">
                <router-link @click="storePosition" class="img-wrapper" :to="{name: 'user', params: {id: user.id}}">
                    <Image :src="getUserPhotoURL(user, 'medium')" :blurred="secret && ! unlocked"/>
                    <transition name="fade" mode="out-in">
                        <svg v-if="secret && ! unlocked" class="private-photo-icon" xmlns="http://www.w3.org/2000/svg" width="22.919" height="22" data-name="Component 7 – 24"><g data-name="Group 35"><path fill="#fff" d="M6.751 22a6.8 6.8 0 0 1-4.774-11.59 6.725 6.725 0 0 1 8.794-.656L20.176.293a.989.989 0 0 1 1.406 0 1 1 0 0 1 0 1.414L20.193 3.1l2.436 2.452a1 1 0 0 1 0 1.414l-3.663 3.684a1.019 1.019 0 0 1-1.406 0L15.123 8.2l-2.947 2.964A6.786 6.786 0 0 1 6.751 22m0-11.578a4.717 4.717 0 0 0-3.367 1.4 4.762 4.762 0 1 0 6.735 0 4.717 4.717 0 0 0-3.367-1.4m9.777-3.632 1.734 1.744 2.257-2.27-1.733-1.745Z" data-name="Path 26"/></g></svg>
                    </transition>
                    <div class="status-box-top">
                        <span v-if="user.contacted">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" data-name="Component 6 – 10"><path fill="#fff" d="M2.964 0h10.07A2.969 2.969 0 0 1 16 2.965v6.714a2.968 2.968 0 0 1-2.965 2.964H7.916l-3.555 3.048a1.293 1.293 0 0 1-.833.306 1.289 1.289 0 0 1-1.287-1.287v-2.16A2.969 2.969 0 0 1 0 9.679V2.965A2.968 2.968 0 0 1 2.964 0Zm10.07 11.188a1.512 1.512 0 0 0 1.511-1.51V2.965a1.513 1.513 0 0 0-1.51-1.51H2.964a1.512 1.512 0 0 0-1.509 1.51v6.714a1.511 1.511 0 0 0 1.51 1.51.727.727 0 0 1 .727.727v2.434l3.482-2.986a.727.727 0 0 1 .473-.175Z" data-name="Path 4"/><path fill="#fff" d="M11.224 5.439H4.775a.727.727 0 0 1-.727-.728.727.727 0 0 1 .727-.727h6.449a.727.727 0 0 1 .724.727.727.727 0 0 1-.724.728Z" data-name="Line 4"/><path fill="#fff" d="M11.273 8.364H4.727A.727.727 0 0 1 4 7.636a.727.727 0 0 1 .727-.727h6.546a.727.727 0 0 1 .727.727.727.727 0 0 1-.727.728Z" data-name="Line 5"/></svg>
                        </span>
                        <span v-if="unlocked">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18"  viewBox="0 0 13.985 17.649"><g fill="#fff" data-name="Group 422"><g data-name="Icon feather-image"><path d="M2.083 5.763H11.9a2 2 0 0 1 2.083 1.9v8.077a2 2 0 0 1-2.083 1.9H2.083A2 2 0 0 1 0 15.74V7.667a2 2 0 0 1 2.083-1.904ZM11.9 16.148c.344 0 .583-.213.583-.4V7.667c0-.191-.24-.4-.583-.4H2.083c-.344 0-.583.213-.583.4v8.077c0 .191.24.4.583.4Z" data-name="Path 108"/><path d="M4.604 8.071a1.615 1.615 0 1 1-1.615 1.615 1.617 1.617 0 0 1 1.615-1.615Z" data-name="Path 109"/><path d="M1.411 16.764a.75.75 0 0 1-.465-1.339L8.94 9.577a.75.75 0 0 1 .951.018l3.711 2.969a.75.75 0 1 1-.972 1.142l-3.244-2.568-7.511 5.463a.747.747 0 0 1-.464.163Z" data-name="Path 110"/></g><path d="M3.832 7.263a.75.75 0 0 1-.75-.75V3.958a3.95 3.95 0 0 1 7.821-.789.75.75 0 1 1-1.47.3 2.45 2.45 0 0 0-4.851.49v2.554a.75.75 0 0 1-.75.75Z" data-name="Path 111"/></g></svg>
                        </span>
                    </div>
                    <div class="status-box-wrapper">
                        <OnlineBox v-if="isOnline(user)" />
                        <NewBox v-if="isNew(user)" />
                    </div>
                </router-link>
            </div>
            <div class="user-panel__body">
                <Favorite class="favorite" :profile="user" />
                <router-link class="name-link" @click="storePosition" :to="{name: 'user', params: {id: user.id}}">
                    <div class="o-flex o-flex--center">
                        <Premium v-if="user.premium" :width="15" class="mr--"/>
                        <Verified v-if="user.profile_verified_at" :width="15" />
                        <span class="name-age">
                            {{user.username}}
                        </span></div>
                    <div class="place-box">
                        {{user.age}} · {{user.address}}
                    </div>
                </router-link>
            </div>
        </div>
        <div v-else class="user-panel">
            <div class="user-panel__header">
                <div class="animated-background"></div>
            </div>
            <div class="user-panel__body">

                <span class="vertical"></span>

            </div>
        </div>
    </div>
</template>

<script>

import { getUserPhotoURL } from "@/service/user/profile";
import Favorite from "@/components/search/Favorite";
import { isOnline, isNew } from "@/service/user/profile";
import OnlineBox from "@/components/user/OnlineBox.vue";
import NewBox from "@/components/user/NewBox.vue";
import { mapActions } from "vuex";
import Image from "@/components/generic/Image";
import Premium from "@/components/generic/Premium.vue";
import Verified from "@/components/generic/Verified.vue";

export default {
    name: "Placeholder",
    components: {Verified, Image, Favorite, OnlineBox, Premium, NewBox },
    props: {
        user: {
            type: Object,
            default: null
        },
        unlocked: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        secret() {
            return this.user.profile_photo?.private === 1
        }
    },
    methods: {
        getUserPhotoURL,
        isOnline,
        isNew,
        ...mapActions({
            setScrollPosition: "search/storeScrollPosition"
        }),
        async storePosition() {
            await this.setScrollPosition(document.documentElement.scrollTop || document.body.scrollTop)
        }
    },
}
</script>

<style scoped lang="scss">

    .status-box-wrapper {
        position: absolute;
        bottom: 20px;
        left: 10px;
        display: flex;
        align-items: center;
        gap: 2px;
    }

    .verified-icon {
        margin-left: -1px;
        margin-right: 5px;

        @media(max-width: 768px) {
            margin-right: 2px;
        }
    }

    .user-panel__header {

        .img-wrapper {
            width: 100%;
            padding-bottom: 135%;
            position: relative;
            display: block;
            background: #e6e6e6;
            border-radius: 10px 10px 0 0;
            overflow: hidden;

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                object-fit: cover;
                min-width: 100%;
                min-height: 100%;
            }
        }

        .private-photo-icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            pointer-events: none;
        }
    }

    .user-panel__body {
        background-color: $color-type1;
        min-height: 70px;
        display: flex;
        position: relative;
        border-radius: 16px;
        margin-top: -10px;
        padding: 12px 15px;

        .name-age {
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            position: relative;

            &:before {
                position: absolute;
                // left: 100%;
                right: 0;
                top: 0;
                height: 100%;
                width: 100%;
                content:"";
                background: linear-gradient(90deg, rgba(255,255,255,0) 70%, rgba(255,255,255,1) 98%);
            }
        }
        .name-link {
            width: 100%;
        }

        a {
            line-height: 1.4;
            color: $color-type18;
            font-size: 18px;

            >div:first-child {
                font-weight: 600;
            }

            >div:last-child {
                font-size: 14px;
                font-weight: 400;
            }
        }

        .favorite {
            position: absolute;
            right: 5%;
            bottom: 100%;
            transform: translate(0, 50%);
            padding: 10px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-type1;
        }

        @media(max-width: 768px) {
            border-radius: 10px;
            padding: 12px 10px;

            .name-link {
                line-height: 1.2;
            }
        }

        @media(max-width: 500px) {
            min-height: 60px;

            a {
                >div:first-child {
                    font-size: 14px;
                }

                >div:last-child {
                    font-size: 12px;
                }
            }
        }
    }

    .user-panel__footer {
        padding: 8px 0;

        a {
            font-size: 16px;
            line-height: 1.5;
            color: $color-type12;
        }

        span {
            font-weight: 600;
        }
    }

    .place-box {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        display:inline-block;
        line-height: 1.2;
    }

    @keyframes placeHolderShimmer{
        0%{
            background-position: -468px 0
        }
        100%{
            background-position: 468px 0
        }
    }

    .animated-background {
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #f6f7f8;
        background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
        background-size: 800px 104px;
        width: 100%;
        padding-bottom: 100%;
        position: relative;
    }

    .status-box-top {
        position: absolute;
        display: flex;
        gap: 4px;
        top: 0;
        left: 10px;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 22px;
            background-image: url("~@/assets/img/flag-bgr.svg");
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
    }

</style>
