import store from "@/store";
import {Android, Chrome, iOS, Safari} from "@/types/device";

export const getPaginationPayload = data => {
    const {current_page, next_page_url, prev_page_url} = data

    return {
        current_page,
        next_page_url,
        prev_page_url
    }
}
// Web-Push
// Public base64 to Uint
export const urlBase64ToUint8Array = base64String => {
    let padding = '='.repeat((4 - base64String.length % 4) % 4);
    let base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    let rawData = window.atob(base64);
    let outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export const isOS = os => store.state.application.os.name.toLowerCase() === os.toLowerCase();

export const isBrowser = browser => store.state.application.browser.name.toLowerCase().includes(browser.toLowerCase());

export const isDevice = (os, browser) => isOS(os) && isBrowser(browser)

export const isPWASupported = () => {

    if(isDevice(iOS, Safari))
        return true

    if(isDevice(iOS, Chrome))
        return true

    return isDevice(Android, Chrome)

}

export const canPWABeInstalled = () => ! store.state.application.installed && isPWASupported()