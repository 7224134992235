import {
    TYPE_ACCESS_APPROVED,
    TYPE_ACCESS_REQUESTED,
    TYPE_ANIMATION_NEW_VIDEO,
    TYPE_ANIMATION_ONLINE,
    TYPE_ANIMATION_PREMIUM,
    TYPE_ANIMATION_SEARCH_MATCH,
    TYPE_FAVORITE,
    TYPE_MESSAGE,
    TYPE_PROFILE_VISIT,
    TYPE_ANIMATION_OFFER,
} from "@/types/bubble";
import {pushDataLayer} from "@/service/gtm";
import {
    GTM_EVENT_ADDED_NEW_VIDEO_APPEAR,
    GTM_EVENT_FAVORITED_YOU_APPEAR,
    GTM_EVENT_MATCH_APPEAR,
    GTM_EVENT_NEW_MESSAGE_APPEAR,
    GTM_EVENT_ONLINE_PROFILE_APPEAR,
    GTM_EVENT_PERFECT_MATCH_APPEAR, GTM_EVENT_PREMIUM_SUGAR_DADDY_APPEAR,
    GTM_EVENT_PROFILE_VISIT_APPEAR,
    GTM_EVENT_REQUESTED_SECRET_ACCESS_APPEAR,
    GTM_EVENT_SECRET_ACCESS_GRANTED_APPEAR,
    GTM_EVENT_SUGGESTED_PROFILE_APPEAR,
    GTM_EVENT_DATING_OFFER_APPEAR

} from "@/service/gtm/types";
import { TYPE_GENDER_MALE } from "@/types/user";

export default {

    namespaced: true,

    state: {
        data: []
    },

    mutations: {
        set(state, payload) {
            state.data = payload
        }
    },

    actions: {
        add(context, payload) {

            if(context.state.data.find(e => e.id === payload.id))
                return

            context.commit('set', [
                ...context.state.data,
                payload
            ])

            switch (payload.type) {

                case TYPE_FAVORITE:
                    pushDataLayer(payload?.user?.mutual ? GTM_EVENT_MATCH_APPEAR : GTM_EVENT_FAVORITED_YOU_APPEAR, context.rootState.authenticate.auth.user)
                    break;

                case TYPE_MESSAGE:
                    pushDataLayer(GTM_EVENT_NEW_MESSAGE_APPEAR, context.rootState.authenticate.auth.user)
                    break;

                case TYPE_ACCESS_REQUESTED:
                    pushDataLayer(GTM_EVENT_REQUESTED_SECRET_ACCESS_APPEAR, context.rootState.authenticate.auth.user)
                    break;

                case TYPE_ACCESS_APPROVED:
                    pushDataLayer(GTM_EVENT_SECRET_ACCESS_GRANTED_APPEAR, context.rootState.authenticate.auth.user)
                    break;

                case TYPE_PROFILE_VISIT:
                    pushDataLayer(GTM_EVENT_PROFILE_VISIT_APPEAR, context.rootState.authenticate.auth.user)
                    break;

                case TYPE_ANIMATION_SEARCH_MATCH:
                    pushDataLayer(context.rootState.authenticate.auth.user.gender === TYPE_GENDER_MALE ? GTM_EVENT_PERFECT_MATCH_APPEAR : GTM_EVENT_SUGGESTED_PROFILE_APPEAR, context.rootState.authenticate.auth.user)
                    break;

                case TYPE_ANIMATION_ONLINE:
                    this.component = 'OnlineNow'
                    pushDataLayer(GTM_EVENT_ONLINE_PROFILE_APPEAR, context.rootState.authenticate.auth.user)
                    break;

                case TYPE_ANIMATION_NEW_VIDEO:
                    pushDataLayer(GTM_EVENT_ADDED_NEW_VIDEO_APPEAR, context.rootState.authenticate.auth.user)
                    break;

                case TYPE_ANIMATION_PREMIUM:
                    pushDataLayer(GTM_EVENT_PREMIUM_SUGAR_DADDY_APPEAR, context.rootState.authenticate.auth.user)
                    break;
                case TYPE_ANIMATION_OFFER:
                    pushDataLayer(GTM_EVENT_DATING_OFFER_APPEAR, context.rootState.authenticate.auth.user)
                    break;

            }

            setTimeout(() => {

                context.commit('set', [
                    ...context.state.data.filter(e => e.id !== payload.id)
                ])

            }, 10000)

        },
        remove(context, id) {
            context.commit('set', [
                ...context.state.data.filter(e => e.id !== id)
            ])
        }
    },

    getters: {

    }

}