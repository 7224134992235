<template>
    <ul class="navbar-nav mb0">
        <li class="nav-item dropdown" :class="dropdownAbsolute === 'type1'?'absolute':''" >
            <a @click="expand = ! expand" class="nav-link o-flex o-flex--center dropdown-toggle o-flex--justify-between" :class="{'active':expand}" href="javascript:void(0)">
                <span>
                    <img width="20" :src="require(`@/assets/img/flags/${lang}.svg`)"/>
                    {{$t(`profile.edit.language_${lang}`)}}
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
            </a>
            <div class="dropdown-menu" :class="{'active':expand}"  aria-labelledby="navbarDropdown">

                <ul class="mb0">
                    <li v-for="l in languages.filter(e => e.code !== lang)" :key="l.code">
                        <a @click="setLanguage(l.code); expand = false"  class="dropdown-item o-flex lang-selection">
                            <img width="20" :src="require(`@/assets/img/flags/${l.code}.svg`)"/>
                            {{l.name}}
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { updateProfile } from "@/service/user";

export default {
    name: "LanguageSelection",
    mixins: [AuthenticatedMixin],
    emits: ['change'],
    props:{
        dropdownAbsolute: {
            type: String
        }
    },
    data() {
        return {
            expand: false,
        }
    },
    computed: {
        ...mapGetters({
            languages: 'authenticate/languages',
            lang: 'authenticate/language'
        }),
    },
    methods: {
        ...mapActions({
            setLang: 'authenticate/setLang'
        }),
        async setLanguage(lang) {

            this.$emit('change', lang)

            if( ! this.logged) {
                await this.setLang(lang)
                return
            }

            if(lang === this.auth.user.lang)
                return

            await updateProfile({
                lang: lang
            })

        }
    }
}
</script>

<style scoped lang="scss">

    a {
        color: $color-type13;
        font-weight: 600;
        padding: 10px 0;

        img {
            margin-right: 5px;
        }
    }

    .dropdown-toggle {
        span {
            display: flex;
            margin-right: 10px;
        }

        &.active {

            svg {
                transform: rotate(90deg);
            }
        }

        svg {
            transition: all .3s ease;
        }
    }

    .dropdown {
    }

    .dropdown-menu {
        height: auto;
        transition: max-height 0.3s cubic-bezier(0,1,0,1);
        max-height: 0;
        overflow: hidden;
        &.active {
            max-height: 99999px;
            transition: max-height 0.5s cubic-bezier(1,0,1,0);
        }
    }

    .absolute {
        position: relative;

        .dropdown-menu {
            position: absolute;
            right: 0;
            top: 100%;
            overflow: hidden;
            border-radius: 8px;
            background-color: $color-type1;
            box-shadow: 0 2px 8px 0 rgba(0,0,0,0.16);
            min-width: 148px;
            padding: 0 30px;
            z-index: 9;
            margin-top: 10px;
        }

        ul {
            padding: 15px 0;
        }
    }

    .lang-selection {
        cursor: pointer;
    }
</style>