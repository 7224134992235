<template>
    <div class="panel-box " :class="'panel-box--'+type">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "PanelBox",
    props: {
        type: {
            type: String,
            default: 'green'
        }
    },
}
</script>
<style lang="scss" scoped>
    .panel-box {
        width: 100%;
        padding: 16px;
        letter-spacing: -0.66px;
        margin-bottom: 20px;
        border-radius: 10px;
        font-size: 16px;
        line-height: 1.3;
    }

    .panel-box--green {
        background-color: #E0FBEA;
        color: #000000;
    }

    .panel-box--red {
        background-color: #FFBCBC;
        color: #B10000;
    }
</style>