<script>
export default {
    name: "PrivacyAndTerms",
    emits: ['update'],
    data() {
        return {
            confirmed: true
        }
    },
    watch: {
        confirmed(value) {
            this.$emit('update', value)
        }
    }
}
</script>

<template>
    <div class="privacy-terms-box o-flex o-flex--center">
        <div class="checkbox">
            <input class="form-check-input" type="checkbox" id="privacy" v-model="confirmed">
            <label class="checkbox__label" for="privacy">
                <div class="checkbox__icon">
                    <svg width="16" viewBox="0 0 24 24" xml:space="preserve">
                        <path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"></path>
                    </svg>
                </div>
            </label>
        </div>
        <small v-html="$t('payment.terms', {terms_link: '/terms', privacy_link: '/privacy'})"></small>
    </div>
</template>

<style scoped lang="scss">

    small {
        color: $color-type16;
        font-size: 16px;

        @media (max-width: 991px) {
            font-size: 14px;
        }
    }

    
</style>

<style lang="scss">
    .privacy-terms-box {
        a {
            color: $color-type19;
            font-size: 16px;
        }

        .checkbox__icon {
            margin-right: 10px;
        }

        @media (max-width: 991px) {
            padding: 0 20px;

            a {
                font-size: 14px;
            }
        }
    }
</style>