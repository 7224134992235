<template>
    <main>
        <div class="o-container">
            <section class="tabs-pages">
                <article>
                    <h1>{{$t('general.matches')}}</h1>
                    <ul class="tabs-pages__list">
                        <li>
                            <router-link :class="`font-weight-${page === '/matches/admirers' ? 'bold' : 'normal'}`" :to="{name: 'admirers'}">
                                <div v-if="count('matches', 'admirers')" class="messages-numb">
                                    {{ count('matches', 'admirers') }}
                                </div>
                                {{ $t('navigation.sub_nav.admirers') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="`font-weight-${'/matches/favorites' === page ? 'bold' : 'normal'}`" :to="{name: 'favorites'}">
                                {{$t('navigation.sub_nav.favorites')}}
                            </router-link>
                        </li>
                        <li>

                            <router-link :class="`font-weight-${'/matches/visitors' === page ? 'bold' : 'normal'}`" :to="{name: 'visitors'}">
                                <div v-if="count('matches', 'visitors')" class="messages-numb">{{ count('matches', 'visitors') }}</div>
                                {{ $t('navigation.sub_nav.visitors') }}
                            </router-link>
                        </li>
                        <li>

                            <router-link :class="`font-weight-${'/matches/viewed' === page ? 'bold' : 'normal'}`" :to="{name: 'viewed'}">
                                {{$t('navigation.sub_nav.viewed')}}
                            </router-link>
                        </li>
                    </ul>
                </article>
                <router-view v-slot="{ Component }">
                    <transition name="fade" mode="out-in">
                        <component :is="Component" />
                    </transition>
                </router-view>
            </section>
        </div>
    </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Matches",
    computed: {
        page() {
            return this.$route.path
        },
        ...mapGetters({
            count: 'notifications/countSocial'
        })
    }
}
</script>

<style scoped lang="scss">
    main {
        display: flex;

        @media(max-width: 991px) {
            background-color: #fff;
            height: 100%!important;
        }
    }
</style>