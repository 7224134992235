import store from "@/store";
import { locationPayload } from "@/service/user";
import { autoLogin, user, logout as serverLogout } from "@/api/auth";
import { bootUser } from "@/service";
import { getReport } from "@/api/profile";
import { disconnect } from "@/connection/websocket/connection";
import { deleteAllCookies } from "@/utilities/general";
import * as Sentry from "@sentry/vue";

export const setAuthenticatedUserLocation = () => {

    if( ! localStorage.getItem('search_location') || ! validLocation(JSON.parse(localStorage.getItem('search_location')))) {

        localStorage.removeItem('search_location')

        if( ! locationPayload(store.state['authenticate'].auth.user))
            return

        return store.commit('search/setLocation', {
            ...locationPayload(store.state['authenticate'].auth.user)
        })

    }

    store.commit('search/setLocation', JSON.parse(localStorage.getItem('search_location')))

}

export const setAuthenticatedUserSearchLocationHistory = () => {

    let locations = localStorage.getItem('search_history_locations')

    if( ! locations)
        return

    locations = JSON.parse(locations)

    if( ! locations.length)
        return

    store.commit('search/setHistoryLocations', locations.filter(e => validLocation(e)))

}

export const setAuthenticatedUser = async(user) => {

    await store.dispatch('authenticate/setLoggedUser', {user: user, token: localStorage.getItem('auth_token')})

    Sentry.setUser({username: user.username, id: user.id})

}

export const validLocation = payload => {
    return payload.location && payload.latitude && payload.longitude && payload.city && payload.state && payload.country
}

export const logout = async() => {
    await serverLogout()
    await clientLogout();
}

export const clientLogout = async() => {
    disconnect()
    await store.dispatch('authenticate/logoutUser')
    store.commit('search/setLocation', null)
    const marketing = localStorage.getItem('marketing')
    localStorage.clear()
    if(marketing) {
        localStorage.setItem('marketing', marketing)
    }
    await store.dispatch('search/setData', {})
    await store.dispatch('search/storeScrollPosition', null)
    await store.dispatch('search/setQuery', null)
    await store.commit('messages/setSendingQueue', [])
    await store.commit('messages/setAttachmentsReady', [])
    await store.commit('credits/setAmount', 0)
    await store.commit('credits/setCard', null)
    deleteAllCookies()
}

/**
 *
 * @param url
 * @returns {Promise<Object|false>}
 */
export const autoLoginUser = async url => {

    try {

        const { data: { access_token } } = await autoLogin(url)
        localStorage.setItem('auth_token', access_token)
        return bootUser()

    } catch (e) {

        return Promise.reject(e)

    }

}

export const setAuthenticatedUserItemCount = async() => {

    try {
        const { data } = await getReport()
        await store.dispatch('items/setPublic', data.public)
        await store.dispatch('items/setSecret', data.secret)
    } catch(e) {
        console.log('Error loading item report', e)
    }

}

export const loadAuthenticatedUser = async () => {
    try {

        const { data } = await user()
        await setAuthenticatedUser(data)

    } catch (e) {
        console.log('Error loading authenticated user', e)
    }
}