<template>
    <div class="bubble-notification-modal">

        <div class="bubble-notification-modal__header">
            <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"><g data-name="Group 247" transform="translate(-150 -366)"><circle data-name="Ellipse 59" cx="30" cy="30" r="30" transform="translate(150 366)" fill="#f5f5f5"/><path data-name="Shape 7" d="M185.629 410.773a2.368 2.368 0 0 1 .945-3.261 13.032 13.032 0 0 0 0-23.023 2.367 2.367 0 0 1-.945-3.261 2.485 2.485 0 0 1 3.339-.922 17.771 17.771 0 0 1 0 31.39 2.491 2.491 0 0 1-1.195.3 2.463 2.463 0 0 1-2.144-1.223Zm-14.6.922a17.772 17.772 0 0 1 0-31.39 2.485 2.485 0 0 1 3.339.922 2.367 2.367 0 0 1-.944 3.261 13.032 13.032 0 0 0 0 23.023 2.367 2.367 0 0 1 .944 3.261 2.462 2.462 0 0 1-2.144 1.227 2.5 2.5 0 0 1-1.191-.305Zm1.384-15.7a7.587 7.587 0 1 1 7.585 7.769 7.679 7.679 0 0 1-7.582-7.77Z" fill="#50bf72"/></g></svg>
            </div>

            <div class="img-wrapper">
                <img :src="getUserPhotoURL(bubble.payload.user)" alt="User Photo">
            </div>
        </div>
        <div class="text">
            {{$t('notifications.online.title')}}
        </div>
        <h2>{{$t(`notifications.online.subtitle.${bubble.payload.user.gender === TYPE_GENDER_MALE ? 'male' : 'female'}`, [, bubble.payload.user.username])}}</h2>
        <button @click="messageUser" :disabled="working" class="button button--primary">
            {{$t(`notifications.actions.contact_now.${bubble.payload.user.gender === TYPE_GENDER_MALE ? 'male' : 'female'}`)}}
        </button>
        <router-link class="button button--fourth" @click="$emit('close'); pushDataLayer(GTM_EVENT_ONLINE_PROFILE_CLICK_2, auth.user)" :to="{name: 'user', params: {id: bubble.payload.user.id}}">
            {{$t(`notifications.actions.check.${bubble.payload.user.gender === TYPE_GENDER_MALE ? 'male' : 'female'}`)}}
        </router-link>
    </div>
</template>

<script>
import { TYPE_GENDER_MALE } from "@/types/user";
import { getUserPhotoURL } from "@/service/user/profile";
import { getOrCreate } from "@/api/messages";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { pushDataLayer } from "@/service/gtm";
import { GTM_EVENT_ONLINE_PROFILE_OPEN, GTM_EVENT_ONLINE_PROFILE_CLICK_1, GTM_EVENT_ONLINE_PROFILE_CLICK_2 } from "@/service/gtm/types";
import { mapActions } from "vuex";
import toastr from "toastr";

export default {
    name: "OnlineNow",
    mixins: [AuthenticatedMixin],
    props: {
        bubble: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            working: false
        }
    },
    methods: {
        pushDataLayer,
        getUserPhotoURL,
        ...mapActions({
            addPreAction: "profile/add"
        }),
        async messageUser() {
            this.working = true

            try {

                const { data } = await getOrCreate(this.bubble.payload.user.id)
                pushDataLayer(GTM_EVENT_ONLINE_PROFILE_CLICK_1, this.auth.user)
                await this.$router.push({name: 'messages', params: {conversation: data.id}})

            } catch(e) {

                if(e?.response?.status === 406) {
                    await this.addPreAction('daily_limit')
                } else {
                    toastr.error(this.$i18n.t('credits.error_processing'))
                }

            }

            this.working = false

            this.$emit('close')
        }
    },
    created() {
        this.TYPE_GENDER_MALE                   = TYPE_GENDER_MALE
        this.GTM_EVENT_ONLINE_PROFILE_CLICK_2   = GTM_EVENT_ONLINE_PROFILE_CLICK_2
    },
    mounted() {
        pushDataLayer(GTM_EVENT_ONLINE_PROFILE_OPEN, this.auth.user)
    }
}
</script>

<style lang="scss" scoped>

</style>