<template>
    <article class="password-box">

        <div class="header-mob">
            <BackButton />
            <h1>{{$t('form_fields.password_label')}}</h1>
        </div>
        <p>{{$t('settings.change_password.strong_password')}}</p>
        <Form :validation-schema="validationSchema" @keydown.enter="$event.preventDefault()" ref="password" @submit="update">
            <div class="input-box">
                <p>{{$t('form_fields.password_label')}}</p>
                <div class="relative">
                    <Field name="password" v-model="password" :type="showPassTrigger?'password':'text'" class="input input--primary" :placeholder="$i18n.t('form_fields.password_placeholder')" />
                    <a class="show-pass-trigger" @click="showPassTrigger = !showPassTrigger">
                        <svg v-if="showPassTrigger" class="show-pass" xmlns="http://www.w3.org/2000/svg" width="18" height="12"><path data-name="Icon awesome-eye" d="M17.891 5.544A10.023 10.023 0 0 0 9 0 10.025 10.025 0 0 0 .109 5.544a1.011 1.011 0 0 0 0 .912A10.023 10.023 0 0 0 9 12a10.025 10.025 0 0 0 8.891-5.544 1.011 1.011 0 0 0 0-.912ZM9 10.5A4.5 4.5 0 1 1 13.5 6 4.5 4.5 0 0 1 9 10.5ZM9 3a2.978 2.978 0 0 0-.791.118 1.5 1.5 0 0 1-2.091 2.091A2.993 2.993 0 1 0 9 3Z" fill="#4b4b4b"/></svg>
                        <svg v-else class="hidden-pass" xmlns="http://www.w3.org/2000/svg" width="20" height="16"><path data-name="Icon awesome-eye-slash" d="M10 12.5a4.483 4.483 0 0 1-4.466-4.16L2.256 5.807a10.415 10.415 0 0 0-1.147 1.737 1.011 1.011 0 0 0 0 .912A10.023 10.023 0 0 0 10 14a9.707 9.707 0 0 0 2.434-.327l-1.622-1.255A4.5 4.5 0 0 1 10 12.5Zm9.807 1.816-3.455-2.67a10.352 10.352 0 0 0 2.539-3.19 1.011 1.011 0 0 0 0-.912A10.023 10.023 0 0 0 10 2a9.63 9.63 0 0 0-4.6 1.178L1.421.105a.5.5 0 0 0-.7.088l-.616.79a.5.5 0 0 0 .088.7l18.386 14.21a.5.5 0 0 0 .7-.088l.614-.79a.5.5 0 0 0-.086-.699Zm-5.741-4.438-1.228-.949a2.961 2.961 0 0 0-3.628-3.81A1.489 1.489 0 0 1 9.5 6a1.458 1.458 0 0 1-.048.313l-2.3-1.778A4.447 4.447 0 0 1 10 3.5 4.5 4.5 0 0 1 14.5 8a4.393 4.393 0 0 1-.434 1.878Z" fill="#4b4b4b"/></svg>
                    </a>
                    <ErrorMessage class="text-error" name="password"/>
                </div>
            </div>
            <div class="btn-wrapper">
                <button :disabled="password.length < 1" ref="save" class="w-100 button button--primary">{{$t('settings.save')}}</button>
            </div>
        </Form>
    </article>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, min, max } from '@vee-validate/rules'
import { updateProfile } from "@/service/user";
import toastr from "toastr";
import BackButton from "@/components/generic/BackButton";

export default {
    name: "Password",
    components: {BackButton, Form, Field, ErrorMessage},
    data() {
        return {
            password: '',
            showPassTrigger: true,
            validationSchema: null
        }
    },
    created() {
        defineRule('required', required)
        defineRule('min', min)
        defineRule('max', max)
        this.validationSchema = {
            password: 'required|min:6|max:45'
        }
    },
    methods: {
        async update() {

            this.$refs.save.disabled = true

            try {
                await updateProfile({password: this.password})
                toastr.success(this.$i18n.t('settings.change_password.changed_successfully'))
                this.$refs.save.disabled = false
                await this.$router.push({name: 'settings'})
            } catch (e) {
                console.log(e.response.data && e.response.data.errors)

                if(e.response.data && e.response.data.errors) {
                    this.$refs.passowrd.setErrors(e.response.data.errors)
                } else {
                    toastr.error(this.$i18n.t('credits.error_processing'))
                }

                this.$refs.save.disabled = false

            }

        }
    }
}
</script>

<style scoped lang="scss">

@import "@/assets/styles/components/_components.settings.scss";

.show-pass-trigger {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0,-50%);
}

.hidden-pass {
    transform: translate(1px,1px);
}

.password-box {
    p {
        margin-top: 30px;
        font-size: 16px;
        line-height: 20px;
        color: $color-type10;
    }

    h1 {
        font-size: 22px;
        line-height: 1.3;
        font-weight: 700;
    }

    form {
        margin-top: 50px;
    }

    .btn-wrapper {
        margin-top: 20px;

        @media(max-width: 500px) {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: $color-type3;
            padding: 10px 20px;
            border-radius: 10px 10px 0 0;
            .button {
                width: 100%;
                margin-left: 0!important;
            }
        }
    }

    @media(max-width: 991px) {
        position: fixed;
        left: 0;
        top: 0;
        padding: 20px 20px 70px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        overflow: auto;
    }
}

</style>