import { createI18n } from "vue-i18n/index";
import translations from '@/translations/ui.json'

const messages = Object.assign(translations)


const i18n = createI18n({
    locale: 'en',
    messages,
    warnHtmlInMessage: 'off'
})

export default i18n