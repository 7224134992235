<template>
    <div class="aside__article" :class="bigAccordion === 'big-accordion'?'big-accordion':''">
        <div class="aside-title" :class="isActive ? 'is-active' : ''" @click="accordion = !accordion; $emit('accordion', accordion)">
            <slot name="asideTitle"></slot>
        </div>
        <div class="aside-options" :class="isActive ? 'is-active' : ''">
            <div class="aside-option__wrapper">
                <slot name="asideOptions"></slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Accordion",
    props: {
        bigAccordion: {
            type: String,
            default: ''
        },
        opened: {
            type:Boolean,
            default: false
        }
    },

    emits: ['accordion'],

    computed: {
        isActive() {
            return this.accordion;
        }
    },

    watch: {
        opened(newVal) {
            this.accordion = newVal;
        }
    },

    data() {
        return {
            accordion: this.opened
        }
    },
}
</script>

<style scoped lang="scss">
    .aside__article {
        border-bottom: 1px solid $color-type3;
    }

    .aside-title {
        padding: 15px 0;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media(max-width: 991px) {
            font-size: 16px;
            text-transform: capitalize;
        }
    }

    .aside-options {
        height: auto;
        overflow: hidden;
        transition: max-height 0.3s cubic-bezier(0,1,0,1);
        max-height: 0;

        .aside-option__wrapper {
            padding:5px 10px 20px;
        }

        &.is-active {
            transition: max-height 0.3s cubic-bezier(1,0,1,0);
            max-height: 99999px;
        }
    }

    .big-accordion {

        .aside-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;
            color: $color-type16;
            font-weight: 700;
            padding: 20px 0;
            text-transform: initial;
        }

        @media(max-width: 991px) {
            .aside-title {
                font-size: 16px;
                font-weight: 700;
            }
        }
    }
</style>

<style lang="scss">
    .aside-title {
        svg {
            transform: rotate(180deg);
        }
        &.is-active {
            svg {
                transform: rotate(180deg);
            }
        }

        @media(max-width: 991px) {

            svg {
                width: 9px;
            }
        }
    }
</style>