<template>
    <div class="login-page">
        <Login @request-register="$emit('request-register')" />
    </div>
</template>

<script>
import Login from "@/components/auth/Login";

export default {
    name: "LoginPage",
    emits: ['request-register'],
    data() {
        return {
            showGenderOptions: false
        }
    },
    components: {
        Login
    }
}
</script>

<style scoped lang="scss">

</style>