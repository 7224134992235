<template>
    <main>
        <section class="messages">
            <div class="o-container">
                <ContentLoader v-if="loading" />
                <template v-else>
                   <article v-if="list?.data?.length">
                       <div class="messages__header">
                            <BackButton />
                            <h2>{{$t('messages.inbox.my_chat')}}</h2>
                        </div>
                        <Messages @error="errorHandler" :mobile="isMobile" @new="append" @update="updated" :conversations="list">
                            <div class="load-more-button" v-if="list && list.last_page > list.current_page">
                                <button class="button button--third" :disabled="working" @click="loadMoreButtonHandler">{{working ? $t('general.loading') : $t('general.load_more')}}</button>
                            </div>
                        </Messages>
                   </article>
                   <div v-else class="no-messages u-text-center">
                       <!-- <img width="100" src="@/assets/img/no_messages.svg"> -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 73.707 74.294"><g data-name="Group 217"><path data-name="Union 10" d="M11.95 73.821a5.155 5.155 0 0 1-3-4.678v-8.64a11.877 11.877 0 0 1-8.948-11.5V22.15a11.873 11.873 0 0 1 11.855-11.857h40.282A11.875 11.875 0 0 1 64 22.154v26.853a11.873 11.873 0 0 1-11.861 11.857H31.664L17.445 73.056l-.015.013a5.173 5.173 0 0 1-3.33 1.224 5.107 5.107 0 0 1-2.15-.472ZM5.818 22.154v26.853a6.045 6.045 0 0 0 6.038 6.039 2.909 2.909 0 0 1 2.909 2.909v9.735l13.928-11.943a2.908 2.908 0 0 1 1.894-.7h21.551a6.048 6.048 0 0 0 6.043-6.039V22.155a6.051 6.051 0 0 0-6.043-6.043H11.856a6.048 6.048 0 0 0-6.037 6.042Zm13.091 21.593A2.909 2.909 0 0 1 16 40.839a2.91 2.91 0 0 1 2.909-2.909h26.182A2.909 2.909 0 0 1 48 40.839a2.909 2.909 0 0 1-2.909 2.908Zm.192-11.7a2.91 2.91 0 0 1-2.909-2.909 2.909 2.909 0 0 1 2.909-2.909h25.8a2.909 2.909 0 0 1 2.908 2.909 2.909 2.909 0 0 1-2.908 2.909Z" fill="#c7c7c7"/><g data-name="Group 216" transform="translate(-923 -340)"><circle data-name="Ellipse 23" cx="13" cy="13" r="13" transform="translate(970.707 340)" fill="#e93d60"/><path data-name="Path 89" d="M988.34 353.575a8.956 8.956 0 0 1-1.1 4.983 3.827 3.827 0 0 1-3.4 1.618 3.783 3.783 0 0 1-3.353-1.67 8.83 8.83 0 0 1-1.13-4.931 9.042 9.042 0 0 1 1.1-5.014 3.811 3.811 0 0 1 3.384-1.613 3.793 3.793 0 0 1 3.362 1.688 8.847 8.847 0 0 1 1.137 4.939Zm-6.284 0a10 10 0 0 0 .409 3.388 1.415 1.415 0 0 0 1.375 1.024 1.43 1.43 0 0 0 1.367-1.037 9.721 9.721 0 0 0 .422-3.375 9.692 9.692 0 0 0-.426-3.4 1.432 1.432 0 0 0-1.367-1.033 1.419 1.419 0 0 0-1.367 1.036 9.98 9.98 0 0 0-.413 3.397Z" fill="#fff"/></g></g></svg>
                        <h1><strong>{{$t('messages.title')}}</strong></h1>
                        <p>
                           {{$t('messages.text_1')}} <br>
                           {{$t('messages.text_2')}}
                        </p>
                        <router-link class="button button--primary" :to="{name: 'search'}">{{$t('messages.search')}}</router-link>
                   </div>
                </template>
            </div>
        </section>
    </main>
</template>

<script>
import Messages from "@/components/messages/Messages"
import { conversations, conversation } from "@/api/messages";
import ContentLoader from "@/components/ContentLoader";
import { mapState } from "vuex";
import BackButton from "@/components/generic/BackButton";

export default {
    name: "MessagesPage",
    components: {ContentLoader, Messages, BackButton },
    data() {
        return {
            list: null,
            loading: false,
            working: false
        }
    },
    computed: {
        ...mapState({
            isMobile: state => state.application.mobile
        })
    },
    methods: {
        async loadMoreButtonHandler() {
            const data = await this.load(this.list.current_page + 1)
            this.merge(data)
            await this.$nextTick(() => document.querySelector('.conversation-items').scrollIntoView({behavior: "smooth", block: 'end'}))
        },
        merge(conversations) {
            if( ! this.list) {
                return this.list = {
                    ...conversations,
                    data: [
                        ...conversations.data.filter(e => e.members.length > 1)
                    ]
                }
            }

            this.list = {
                ...conversations,
                data: [
                    ...this.list.data,
                    ...conversations.data.filter(e => e.members.length > 1).filter(e => ! this.list.data.find(i => i.id === e.id))
                ]
            }
        },
        append(conversation) {

            if( ! this.list) {
                return this.list.data = [conversation]
            }

            const [first, ...rest] = this.list.data

            if(conversation.id === first.id || this.list.data.find(e => e.id === conversation.id))
                return

            this.list = {
                ...this.list,
                data: [
                    first, conversation, ...rest
                ]
            }
        },
        async load(page = 1) {

            this.working = true

            const { data } = await conversations({perPage: 10, page: page ?? 1})

            this.working = false

            return {
                ...data,
                data: data.data.filter(e => e.members.length > 1)
            }
        },
        errorHandler({conversation}) {

            if( ! this.list || ! this.list?.data.length)
                return this.$router.push({name: 'messages'})


            this.list = {
                ...this.list,
                data: this.list.data.filter(e => e.id !== conversation)
            }

            let params = null

            if(!this.isMobile) {
                params = {
                    conversation: this.list.data[0].id
                }
            }

            return this.$router.push({name: 'messages', params: params})

        },

        updated(conversation) {
            this.list = {
                ...this.list,
                data: [
                    ...this.list.data.filter(e => e.id !== conversation.id),
                    conversation
                ]
            }
        }
    },
    created() {

        this.$store.watch(
            (state) => state.messages.messages,
            async () => {

                if( ! this.list?.total) {

                    const {data} = await conversations()
                    this.list = {
                        ...data,
                        data: data.data.filter(e => e.members.length > 1)
                    }

                }

            }
        )

    },
    async mounted() {
        this.loading = true
        const data = await this.load()

        if(this.$route.params.conversation) {

            let selected = data.data.find(e => e.id === parseInt(this.$route.params.conversation))

            if( ! selected) {

                try {
                    selected = await conversation(this.$route.params.conversation)
                    selected = selected.data.members.length < 2 ? null : selected.data
                } catch {
                    selected = null
                }

            }

            if(selected) {
                this.loading = false
                return this.list = {
                    ...data,
                    data: [
                        selected,
                        ...data.data.filter(e => e.id !== selected.id)
                    ]
                }

            } else {
                await this.$router.push({name: 'messages'})
            }

        } else if(data?.data?.length && ! this.isMobile) {

            await this.$router.push({
                name: 'messages',
                params: {
                    conversation: data.data[0].id
                }
            })

        }

        this.loading = false
        this.list = data
    }
}
</script>

<style scoped lang="scss">
main {
    display: flex;
    min-height: calc(100% - 76px);
    // height: auto;

    @media(max-width: 991px) {
        z-index: 999999;
        position: relative;
        background-color: #fff;
        height: 100%;

    }
}

article {
    display: grid;
    grid-template-columns: 328px 1fr;
    padding-bottom: 20px;
    overflow: hidden;


    @media(max-width: 991px) {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 100%;
        padding-bottom: 0;
    }
}

.load-more-button {
    display: flex;
    // grid-column: 1 / span 2;
    margin-top: 10px;

    .button {
        width: 390px;
    }

    @media(max-width: 991px) {
        background-color: #F5F5F5;
        padding: 10px 20px;
        border-radius: 10px 10px 0 0;

        .button {
            width: 400px;
        }

    }

    @media(max-width: 500px) {
        .button {
            width: 100%;
        }
    }
}


.messages {
    min-height: 100%;
    width: 100%;

    .o-container {
        height: 100%;

        @media(max-width: 991px) {
            padding: 0;
        }
    }
}

.messages__header {
    display: flex;
    align-items: center;
    grid-column: 1 / span 3;
    padding: 20px 10px;

    .back-mob {
        display: none;
    }

    h2 {
        margin-bottom: 0;
        font-size: 22px;
        font-weight: 700;
        letter-spacing: -0.9px;
    }


    @media(max-width: 991px) {

        .back-mob {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            margin-right: 10px;
        }

    }
}
.no-messages {
    padding: 60px 100px;
    border-radius: 10px;
    background-color: #fff;
    min-height: calc(100% - 40px);
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        padding: 25px 0;
        margin-bottom: 0;
    }

    p {
        font-size: 18px;
        line-height: 1.6;
        padding: 0;
        margin-bottom: 30px;
    }

    @media(max-width: 991px) {
        box-shadow: none;
        padding: 30px 20px;
        margin-top: 0;
    }
}


</style>
