<template>
    <teleport to="#modals">
        <transition name="fade">
            <Modal v-if="confirmStep" @close="confirmStep = false; password = ''" :size="'small'" class="modal modal--mob u-text-center modal-confirm">
                <template #body>
                    <h1><strong>{{$t('profile.delete.sure')}}</strong></h1>
                    <p>{{$t('profile.delete.action')}}</p>
                    <div class="btn-wrapper">
                        <button :disabled="working" @click="deleteProfile" class="w-100 button button--primary mb-">{{$t('profile.delete.yes')}}</button>
                        <button @click="confirmStep = false; password = ''" class="button button--gray w-100">{{$t('profile.delete.no')}}</button>
                    </div>
                </template>
            </Modal>
        </transition>
    </teleport>
    <article class="secret-browsing-box">
        <div class="header-mob">
            <BackButton />
            <h1>{{$t('profile.delete.profile')}}</h1>
        </div>
        <p class="clr-black mb">{{$t('profile.delete.deleting')}}</p>
        <p class="clr-black mt0">{{$t('profile.delete.feedback')}}</p>
        <h2 class="mb-"><strong>{{$t('profile.delete.reason')}}</strong></h2>

        <div :key="v" v-for="v in REASONS" class="checkbox">
            <input class="form-check-input" type="radio" :id="`checkbox${v}`" v-model="reason" :value="v">
            <label class="checkbox__label" :for="`checkbox${v}`">
                <div class="checkbox__icon">
                    <svg width="12" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                </div>
                {{$t(`profile.delete.reasons.${v}`)}}
            </label>
        </div>
        <br>
        <div class="input-box mb-">
            <textarea class="input input--primary input--textarea" v-model="comment" rows="2" :placeholder="$t('profile.delete.add_feedback')"></textarea>
        </div>
        <div class="input-box mb-">
            <p class="mt0"><small class="clr-black">{{$t('profile.delete.password')}} *</small> </p>
            <input v-model="password" type="password" class="input input--primary" :placeholder="$t('profile.delete.type_password')">
        </div>
        <div class="btn-wrapper">
            <button :disabled="password.trim().length < 6 || working" @click="confirmStep = true" class="w-100 button button--primary">{{$t('profile.delete.delete_my')}}</button>
        </div>
    </article>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import BackButton from "@/components/generic/BackButton";
import Modal from "@/components/generic/Modal.vue";
import { deleteProfile } from "@/api/profile";
import { logout } from "@/service/auth";
import toastr from "toastr";
import { deleteProfileReasons } from "@/utilities/user";

export default {
    name: "DeleteProfile",
    components: {BackButton, Modal},
    mixins: [AuthenticatedMixin],
    emits: ['requestRegister'],
    data() {
        return {
            reason: 'break',
            comment: '',
            confirmStep: false,
            password: '',
            working: false
        }
    },
    methods: {
        async deleteProfile() {
            try {
                this.working = true
                await deleteProfile({password: this.password, reason: this.reason, comment: this.comment})
                await logout()
                await this.$router.push({name: 'goodbye'})
            } catch {
                this.working = false
                toastr.error('Error deleting profile')
            }
        },
    },
    created() {
        this.REASONS = deleteProfileReasons
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/components/_components.settings.scss";

.secret-browsing-box {

    p {
        margin-top: 30px;
        font-size: 16px;
        line-height: 20px;
        color: rgb(119, 119, 119);
    }

    form {
        margin-top: 40px;
    }

    .btn-wrapper {
        margin-top: 20px;

        @media(max-width: 500px) {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: $color-type3;
            padding: 10px 20px;
            border-radius: 10px 10px 0 0;
            .button {
                width: 100%;
                margin-left: 0!important;
            }
        }
    }

    @media(max-width: 991px) {
        position: fixed;
        left: 0;
        top: 0;
        padding: 20px 20px 70px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        overflow: auto;
    }
}

.modal-confirm {
    p {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 50px;
    }

    .button {
        font-size: 18px;
        letter-spacing: -0.74px;
        font-weight: 600;
    }
}
</style>