<template>
    <Header @request-register="$emit('request-register')" />
    <main class="main-small-header">
        <section class="login">
            <div class="o-container">
                <article>
                    <div>
                        <h1><strong>{{$t('reset_password.title')}}</strong></h1>
                        <Form ref="loginForm" @submit="onSubmit" :validation-schema="schema">
                            <div class="input-box">
                                <p>{{$t('form_fields.password_label')}}</p>
                                <div class="relative">
                                    <Field ref="input" v-model="password" :class="`input input--primary`" :type="passTrigger?'password':'text'" :placeholder="$t('form_fields.password_placeholder')" :disabled="working" name="password" />
                                    <ErrorMessage class="text-error text-left form-text" name="password"  />
                                    <a class="show-pass-trigger" @click="passTrigger = !passTrigger">
                                        <svg v-if="passTrigger" class="show-pass" xmlns="http://www.w3.org/2000/svg" width="18" height="12"><path data-name="Icon awesome-eye" d="M17.891 5.544A10.023 10.023 0 0 0 9 0 10.025 10.025 0 0 0 .109 5.544a1.011 1.011 0 0 0 0 .912A10.023 10.023 0 0 0 9 12a10.025 10.025 0 0 0 8.891-5.544 1.011 1.011 0 0 0 0-.912ZM9 10.5A4.5 4.5 0 1 1 13.5 6 4.5 4.5 0 0 1 9 10.5ZM9 3a2.978 2.978 0 0 0-.791.118 1.5 1.5 0 0 1-2.091 2.091A2.993 2.993 0 1 0 9 3Z" fill="#4b4b4b"/></svg>
                                        <svg v-else class="hidden-pass" xmlns="http://www.w3.org/2000/svg" width="20" height="16"><path data-name="Icon awesome-eye-slash" d="M10 12.5a4.483 4.483 0 0 1-4.466-4.16L2.256 5.807a10.415 10.415 0 0 0-1.147 1.737 1.011 1.011 0 0 0 0 .912A10.023 10.023 0 0 0 10 14a9.707 9.707 0 0 0 2.434-.327l-1.622-1.255A4.5 4.5 0 0 1 10 12.5Zm9.807 1.816-3.455-2.67a10.352 10.352 0 0 0 2.539-3.19 1.011 1.011 0 0 0 0-.912A10.023 10.023 0 0 0 10 2a9.63 9.63 0 0 0-4.6 1.178L1.421.105a.5.5 0 0 0-.7.088l-.616.79a.5.5 0 0 0 .088.7l18.386 14.21a.5.5 0 0 0 .7-.088l.614-.79a.5.5 0 0 0-.086-.699Zm-5.741-4.438-1.228-.949a2.961 2.961 0 0 0-3.628-3.81A1.489 1.489 0 0 1 9.5 6a1.458 1.458 0 0 1-.048.313l-2.3-1.778A4.447 4.447 0 0 1 10 3.5 4.5 4.5 0 0 1 14.5 8a4.393 4.393 0 0 1-.434 1.878Z" fill="#4b4b4b"/></svg>
                                    </a>
                                </div>
                            </div>
                            <div class="input-box">
                                <p>{{$t('form_fields.password_confirmation_label')}}</p>
                                <div class="relative">
                                    <Field v-model="password_confirm" :class="`input input--primary`" :label="$t('form_fields.password_label').toLowerCase()" :type="confirmPassTrigger?'password':'text'" :placeholder="$t('form_fields.password_confirmation_placeholder')" :disabled="working" name="password_confirm" />
                                    <ErrorMessage class="text-error text-left form-text" name="password_confirm"  />
                                    <a class="show-pass-trigger" @click="confirmPassTrigger = !confirmPassTrigger">
                                        <svg v-if="confirmPassTrigger" class="show-pass" xmlns="http://www.w3.org/2000/svg" width="18" height="12"><path data-name="Icon awesome-eye" d="M17.891 5.544A10.023 10.023 0 0 0 9 0 10.025 10.025 0 0 0 .109 5.544a1.011 1.011 0 0 0 0 .912A10.023 10.023 0 0 0 9 12a10.025 10.025 0 0 0 8.891-5.544 1.011 1.011 0 0 0 0-.912ZM9 10.5A4.5 4.5 0 1 1 13.5 6 4.5 4.5 0 0 1 9 10.5ZM9 3a2.978 2.978 0 0 0-.791.118 1.5 1.5 0 0 1-2.091 2.091A2.993 2.993 0 1 0 9 3Z" fill="#4b4b4b"/></svg>
                                        <svg v-else class="hidden-pass" xmlns="http://www.w3.org/2000/svg" width="20" height="16"><path data-name="Icon awesome-eye-slash" d="M10 12.5a4.483 4.483 0 0 1-4.466-4.16L2.256 5.807a10.415 10.415 0 0 0-1.147 1.737 1.011 1.011 0 0 0 0 .912A10.023 10.023 0 0 0 10 14a9.707 9.707 0 0 0 2.434-.327l-1.622-1.255A4.5 4.5 0 0 1 10 12.5Zm9.807 1.816-3.455-2.67a10.352 10.352 0 0 0 2.539-3.19 1.011 1.011 0 0 0 0-.912A10.023 10.023 0 0 0 10 2a9.63 9.63 0 0 0-4.6 1.178L1.421.105a.5.5 0 0 0-.7.088l-.616.79a.5.5 0 0 0 .088.7l18.386 14.21a.5.5 0 0 0 .7-.088l.614-.79a.5.5 0 0 0-.086-.699Zm-5.741-4.438-1.228-.949a2.961 2.961 0 0 0-3.628-3.81A1.489 1.489 0 0 1 9.5 6a1.458 1.458 0 0 1-.048.313l-2.3-1.778A4.447 4.447 0 0 1 10 3.5 4.5 4.5 0 0 1 14.5 8a4.393 4.393 0 0 1-.434 1.878Z" fill="#4b4b4b"/></svg>
                                    </a>
                                </div>
                            </div>
                            <div class="input-box">
                                <Button :disabled="working" :class-name="['button', 'button--primary', 'w-100']">
                                    <template v-slot:content>
                                        {{ $t('reset_password.submit') }}
                                    </template>
                                </Button>
                            </div>
                        </Form>
                    </div>
                    <p><router-link :to="{name: 'login'}" class="link">{{$t('forgot_password.return')}}</router-link></p>
                </article>
            </div>
        </section>
    </main>
</template>

<script>
import { defineRule } from 'vee-validate'
import { required, max, min, confirmed } from '@vee-validate/rules'
import { Form, Field, ErrorMessage } from 'vee-validate'
import Button from "@/components/generic/Button";
import Header from "@/components/Header";
import { resetPassword } from "@/api/auth";
import toastr from "toastr";
import { login } from "@/api/auth";
import { mapActions } from "vuex";

export default {
    name: "ResetPasswordPage",
    components: {Form, Field, ErrorMessage, Button, Header},
    data() {
        return {
            working: false,
            password: '',
            password_confirm: '',
            passTrigger: true,
            confirmPassTrigger: true
        }
    },
    methods: {
        ...mapActions({
            bootstrapping: 'application/setBootstrapping'
        }),
        async onSubmit() {

            this.working = true

            try {

                await resetPassword(this.$route.query.email, this.password, this.password_confirm, this.$route.params.token)

                toastr.success(this.$i18n.t('settings.change_password.changed_successfully'))

            } catch {

                toastr.error(this.$i18n.t('credits.error_processing'))

                return this.working = false

            }

            try {

                this.bootstrapping(true)

                const { data } = await login(this.$route.query.email, this.password)

                localStorage.setItem('auth_token', data.access_token)

                return await this.$router.push({name: 'search'})

            } catch {

                this.bootstrapping(false)

                await this.$router.push({name: 'login'})

            }

        }
    },
    created() {
        defineRule('required', required)
        defineRule('max', max)
        defineRule('min', min)
        defineRule('confirmed', confirmed)

        this.schema = {
            password: 'required|min:6|max:45',
            password_confirm: 'required|confirmed:@password',

        }
    },
    mounted() {
        if( ! this.$route.query.email) {
            this.working = true
            toastr.error(this.$i18n.t('credits.error_processing'))
        }

        this.$nextTick(() => this.$refs.input.$el.focus())
    }
}
</script>

<style scoped lang="scss">
    .login {
        padding: 90px 0;

        article {
            padding: 60px 90px 80px;
            max-width: 520px;
            margin: 0 auto;
            background-color: #fff;
            border-radius: 10px;
        }

        h1 {
            margin-bottom: 30px;
            font-size: 30px;
            color: $color-type19;
        }

        .link {
            font-weight: 600;
            margin-left: 3px;
        }

        @media(max-width: 991px) {
            padding: 30px 0;

            article {
                padding: 30px 20px;
                background-color: #fff;
                border-radius: 0;
            }
        }
    }

    main.main-small-header {
        min-height: calc(100vh - 212px);
        height: auto;

        @media(max-width: 991px) {
            background-color: #fff;
        }
    }

    .show-pass-trigger {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0,-50%);
    }

    .hidden-pass {
        transform: translate(1px,1px);
    }


</style>