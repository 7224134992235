<template>
    <div class="o-container">

        <teleport to="#modals">

            <transition name="fade" mode="out-in">
                <Modal :closable="false" v-if="working">
                    <template #body>
                        <p>{{$t('general.wait')}}</p>
                        <ProgressBar :completed="uploaded" />
                    </template>
                </Modal>
            </transition>

            <transition name="fade" mode="out-in">
                <Modal v-if="showUploadVideo" v-show="video && ! working" class="modal modal--mob">
                    <template #header>
                        <h2 class="h1">{{$t('promotions.verification.profile')}}</h2>
                    </template>
                    <template #body>
                        <UploadItem :verification="true" v-if="video" @progress="track" @finished="finish" @error="handleUploadError" :file="video" @close="reset">
                            <template #switch>
                                {{ucFirst($t(`profile.secret_video`))}}
                            </template>
                            <template #description>
                                <small>{{$t('profile.verification.secret_mode')}}</small>
                            </template>
                        </UploadItem>
                    </template>
                </Modal>
            </transition>
        </teleport>

        <section class="verification">
            <article  v-if="loaded && auth.user.profile_verified_at === null">

                <div  class="verified-box">
                    {{$t('profile.verification.get_verified')}}
                    <svg class="verified-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g data-name="Group 121"><path data-name="Polygon 2" d="M14.51 1.665a2 2 0 0 1 2.98 0l1.268 1.417a2 2 0 0 0 2.018.6l1.821-.5a2 2 0 0 1 2.5 1.606l.315 1.924a2 2 0 0 0 1.366 1.578l1.834.587a2 2 0 0 1 1.23 2.69l-.776 1.819a2 2 0 0 0 .294 2.055l1.251 1.523a2 2 0 0 1-.421 2.923l-1.614 1.1a2 2 0 0 0-.861 1.894l.236 1.956a2 2 0 0 1-1.943 2.24l-1.9.041a2 2 0 0 0-1.762 1.137l-.83 1.737a2 2 0 0 1-2.858.838l-1.6-.994a2 2 0 0 0-2.106 0l-1.6.994a2 2 0 0 1-2.858-.838l-.83-1.737a2 2 0 0 0-1.762-1.137l-1.9-.041a2 2 0 0 1-1.943-2.24l.236-1.956a2 2 0 0 0-.861-1.894l-1.614-1.1a2 2 0 0 1-.421-2.923l1.242-1.524a2 2 0 0 0 .294-2.055l-.776-1.819a2 2 0 0 1 1.23-2.69l1.833-.586a2 2 0 0 0 1.364-1.582L6.9 4.784a2 2 0 0 1 2.5-1.606l1.821.5a2 2 0 0 0 2.018-.6Z" fill="#2c7ddb"/><path data-name="Icon metro-checkmark" d="m21.668 9.461-8.165 8.163-3.81-3.81-2.719 2.722 6.529 6.531 10.886-10.885Z" fill="#fff"/></g></svg>
                </div><br>
                <input @change="upload" accept="video/*" ref="upload" type="file" style="display: none">
                <p>
                    <strong>{{$t('profile.verification.prove_yourself')}}</strong><br>
                    {{$t('profile.verification.be_trusted')}}<br>
                    {{$t('profile.verification.a_minute')}}
                </p>
                <div v-show="!verification_video_sent">
                    <h2 class="h1"> {{$t('profile.verification.title')}} </h2><br>
                    <p>
                        {{$t('profile.verification.video_yourself')}}:<br>
                        <strong>"{{$t(`profile.verification.your_sugar${this.auth.user.gender ?  'babby' : 'daddy'}.${theme}`)}}"</strong><br><br>
                        {{$t('profile.verification.privacy')}}
                    </p>
                    <div class="btn-wrapper">
                        <button :disabled="working" @click="$refs.upload.click()" class="button button--primary">{{$t('profile.verification.get_verified')}}</button>
                    </div>
                </div>
                <div v-show="verification_video_sent">
                    <h2>{{$t('profile.verification.video_sent')}}</h2>
                </div>
            </article>

            <article v-if="loaded && auth.user.profile_verified_at !== null" class="verified">

                <img src="@/assets/img/verified-icon.svg" alt="verified icon"/>

                <h1>
                    {{$t('profile.verification.great_news')}}<br/>
                    {{$t('profile.verification.is_verified')}}
                </h1>
                <p>{{$t('profile.verification.description')}}</p>

            </article>
        </section>
    </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { videoGallery } from "@/api/profile";
import ThemeMixin from "@/mixins/ThemeMixin";
import {ALLOWED_VIDEO_FORMATS, MAX_VIDEO_FILE_SIZE} from "@/utilities/files";
import { createSelectedFile, getExtensionFromFilename } from "@/service/files";
import toastr from "toastr";
import Modal from "@/components/generic/Modal";
import ProgressBar from "@/components/ProgressBar";
import UploadItem from "@/components/photos/UploadItem.vue";
import { ucFirst } from "@/utilities/general";

export default {
    name: "VerificationPage",
    mixins: [AuthenticatedMixin, ThemeMixin],
    components: {UploadItem, Modal, ProgressBar},
    data() {
        return {
            loaded: false,
            working: false,
            verification_video_sent: false,
            uploaded: 0,
            video: null,
            showUploadVideo: false
        }
    },
    methods: {
        ucFirst,
        async upload(e) {

            const [file] = e.target.files || e.dataTransfer.files

            if( ! file)
                return

            const extension = getExtensionFromFilename(file.name)

            if( ! ALLOWED_VIDEO_FORMATS.includes(extension)) {
                this.$refs.upload.value = ''
                return toastr.error(this.$i18n.t('form_fields.validation.file_type', [null, extension]))
            }

            if(file.size > MAX_VIDEO_FILE_SIZE) {
                this.$refs.upload.value = ''
                return toastr.error(this.$i18n.t('form_fields.validation.file_size', [null, 'Video']))
            }

            this.showUploadVideo = true
            this.working = true
            this.video = await createSelectedFile(file)
            this.working = false
        },


        track(e) {
            this.working = true
            this.uploaded = Math.round((e.loaded * 100) / e.total)
        },

        finish() {
            this.reset()
            this.verification_video_sent = true
        },

        handleUploadError() {
            this.reset()
            toastr.error(this.$i18n.t('credits.error_processing'))
        },

        reset() {
            this.working = false
            this.$refs.upload.value = ''
            this.video = null
            this.uploaded = 0
            this.showUploadVideo = false
        },


    },

    async mounted() {

        if( ! this.auth.user.profile_verified_at) {

            try {
                const { data } = await videoGallery({verification: 1})
                if(data.data.length > 0) {
                    this.verification_video_sent = true
                }
            } catch (e) {
                console.log(e)
                console.log('Error fetching verification video')
            }

        }

        this.loaded = true

    }
}
</script>

<style scoped lang="scss">
    .verification {
        padding: 60px 100px;
        border-radius: 10px;
        background-color: #fff;
        min-height: calc(100% - 40px);
        height: auto;
        overflow: hidden;

        .verified-box {
            background-color: rgba(#2C7DDB, .5);
            height: 44px;
            border-radius: 22px;
            padding: 0 8px 0 14px;
            color: #fff;
            font-weight: 700;
            font-size: 18px;
            width: auto;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            .verified-icon {
                margin-left: 8px;
            }
        }

        h2 {
            display: inline-flex;
            align-items: center;

            img {
                margin-right: 6px;
            }
        }

        article {
            width: 100%;
            max-width: 680px;
            margin: 0 auto 50px;
            text-align: center;
        }

        p {
            font-size: 18px;
            line-height: 1.6;
            padding: 20px 0;
            margin-bottom: 30px;
        }

        @media(max-width: 991px) {
            padding: 20px 0 70px;
        }

        @media(max-width: 500px) {
            p {
                font-size: 16px;
            }

            .btn-wrapper {
                position: fixed;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: 10px 20px;
                background-color: #fff;
                z-index: 9999;

                .button {
                    width: 100%;
                }
            }
        }
    }

    @-webkit-keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
    }
    @keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
    }

    .verified {
        height: 100%;
        padding: 100px 0;
        margin-bottom: 0;

        h1 {
            margin:0 auto 20px;
            font-weight: 700;
            font-size: 28px;
            line-height: 1.3;
            max-width: 300px;
        }

        p {
            padding: 0;
            line-height: 1.4;
            max-width: 300px;
            margin:0 auto;
        }

        img {
            margin-bottom: 20px;
        }
    }
</style>