<template>
    <div class="footer-main">
        <div class="o-container">
            <article>
                <img :src="require(`@/assets/img/${theme}.svg`)" alt="main logo"/>
                <div style="width: 400px">
                    <small class="u-display-block">
                        <span class="u-display-block mb--">
                            {{copyright}} <br />
                            All Intellectual Property Rights Reserved
                        </span>
                        This site is protected by reCAPTCHA and the Google
                        <a class="clr-gold" style="font-size: inherit" href="https://policies.google.com/privacy">Privacy Policy</a> and
                        <a class="clr-gold" style="font-size: inherit" href="https://policies.google.com/terms">Terms of Service</a> apply.
                    </small>

                </div>
            </article>
            <article>
                <ul class="link-list">
                    <li><router-link :to="{name: 'privacy'}">Privacy Policy</router-link></li>
                    <li class="divider"></li>
                    <li><router-link :to="{name: 'terms'}">Terms &amp; Conditions</router-link></li>
                    <li class="divider"></li>
                    <li><router-link :to="{name: 'imprint'}">Imprint</router-link></li>
                </ul>
                <ul class="mb0 partners-link">
                    <li><img src="@/assets/img/amazon.svg" alt="amazon"></li>
                    <li><img src="@/assets/img/ssl.svg" alt="ssl"></li>
                    <li><img src="@/assets/img/verified-members.svg" alt="verified"></li>
                    <li><img src="@/assets/img/visa.svg" alt="visa"></li>
                    <li><img src="@/assets/img/master.svg" alt="master"></li>
                </ul>
            </article>
        </div>
    </div>
</template>

<script>

import ThemeMixin from "@/mixins/ThemeMixin";

export default {
    name: "Footer",
    mixins: [ThemeMixin],
    created() {
        this.copyright = process.env.VUE_APP_COPYRIGHT
    }
}
</script>

<style scoped lang="scss">
    .footer-main {
        padding: 30px 0;

    }

    .o-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >article {
            &:first-child {
                display: flex;
                align-items: flex-start;

                img {
                    min-width: 60px;
                    margin-right: 20px;
                }

                small {
                    display: block;
                    max-width: 350px;
                    color: #000000;
                }


            }
            .partners-link {
                display: flex;
                align-items: flex-end;


                li {
                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }

                @media(max-width: 991px) {
                    flex-wrap: nowrap;
                }
            }
            .link-list {
                display: flex;
                font-size: 16px;
                color: $color-type7;
                margin-bottom: 10px;

                @media(max-width: 991px) {
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                }

                a {
                    color: #000000;
                    font-size: 14px;

                    &:hover {
                        color: $color-type19;
                    }
                }
            }
        }
    }

    .divider {
        margin: 0 5px;
        height: 18px;
        width: 1px;
        background-color: #000000;
    }

</style>