<template>
    <div class="message-row__box" :class="message.body && !message.file.preview? 'text-only':''">
        <div class="temporary-box" >
            <div class="temporary-box__img-wrapper">
                <img v-if="message.attachment_type === ITEM_TYPE_PHOTO" :src="message.file.preview" alt="User Photo" />
                <video v-if="message.attachment_type === ITEM_TYPE_VIDEO" :src="message.file.preview" />
                <div class="text-wrap" v-if="message.body">
                    {{ message.body}}
                </div>
            </div>
            <ProgressCircle v-if="message.file.source" :percent="message.uploaded" />
        </div>
    </div>
</template>

<script>
import ProgressCircle from "@/components/generic/ProgressCircle";
import { ITEM_TYPE_VIDEO, ITEM_TYPE_PHOTO } from "@/types/item";

export default {
    name: "TemporaryMessage",
    components: {ProgressCircle},
    props: {
        message: {
            type: Object,
            required: true
        }
    },
    created() {
        this.ITEM_TYPE_VIDEO = ITEM_TYPE_VIDEO
        this.ITEM_TYPE_PHOTO = ITEM_TYPE_PHOTO
    }
}
</script>

<style scoped lang="scss">

    .message-row__box {
        background-color: #b2b2b2!important;
    }
    .temporary-box {
        position: relative;
        width: 220px!important;
        overflow: hidden;
        padding: 0;
        background-color: transparent;
        display: flex;
        flex-direction: column;

        .text-only & {
            max-width: 100%;
            min-width: 0;
            width: 100%!important;
        }
    }

    .temporary-box__img-wrapper {
        width: 100%;
        height: 100%;

        img, video {
            object-fit: cover;
            max-width: initial;
            width: 100%;
            aspect-ratio: 1 / 1;
            filter: blur(2px);

        }
    }

    .text-wrap {
        border-radius: 0 0 20px 20px;
        padding: 10px 12px;
        font-size: 16px;
        line-height: 1.3;
        text-align: left;
        width: 100%;

    }
</style>