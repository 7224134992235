export default {

    namespaced: true,

    state: {
        public: {
            photos: {
                total: 0,
                approved: 0
            },
            videos: {
                total: 0,
                approved: 0
            }
        },
        secret: {
            photos: {
                total: 0,
                approved: 0
            },
            videos: {
                total: 0,
                approved: 0
            }
        },
        deleted: [],
        uploaded: [],
        ready: []
    },

    mutations: {

        setPublic(state, value) {
            state.public = value
        },

        setSecret(state, value) {
            state.secret = value
        },

        setDeleted(state, value) {
            state.deleted = value
        },

        setUploaded(state, value) {
            state.uploaded = value
        },

        setReady(state, value) {
            state.ready = value
        }

    },

    actions: {

        setPublic({commit}, value) {
            commit('setPublic', value)
        },

        setSecret({commit}, value) {
            commit('setSecret', value)
        },

        setPublicPhotosTotal(context, value) {
            context.commit('setPublic', {
                ...context.state.public,
                photos: {
                    ...context.state.public.photos,
                    total: value
                }
            })
        },

        setSecretPhotosTotal(context, value) {
            context.commit('setSecret', {
                ...context.state.secret,
                photos: {
                    ...context.state.secret.photos,
                    total: value
                }
            })
        },

        addDeleted(context, value) {
            context.commit('setDeleted', [
                ...context.state.deleted.filter(e => e !== value),
                value
            ])
        },

        trackUploadedFile(context, value) {
            let data = [...context.state.uploaded].map(e => e.hash === value.hash ? {...e, ...value} : e)

            if( ! data.find(e => e.hash === value.hash)) {
                data = [...data, value]
            }

            context.commit('setUploaded', data)
        },

        removeTrackedFile(context, value) {
            context.commit('setUploaded', context.state.uploaded.filter(e => e.hash !== value.hash))
        },

        addReady(context, value) {

            context.commit('setReady', [...context.state.ready.filter(e => e.id !== value.id), value])

        }

    },

    getters: {

    }


}