<template>
    <div class="price">
        <div class="price__box-modal" v-if="paymentModal">
            <div @click="packageClickHandler(p)" style="cursor: pointer" v-for="p in packages" :key="p.id"
                 class="price__item price__item--modal o-flex o-flex--center o-flex--justify" :class="{'is-active': p.name.toLowerCase().includes('best')}">
                <div class="u-text-left">
                    <h3><svg v-if="p.name.toLowerCase().includes('best')" class="best-value"
                             xmlns="http://www.w3.org/2000/svg" width="20" height="16">
                            <path data-name="Icon awesome-crown"
                                  d="M16.5 14h-13a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5Zm2-10A1.5 1.5 0 0 0 17 5.5a1.47 1.47 0 0 0 .137.619l-2.262 1.356a1 1 0 0 1-1.381-.363l-2.547-4.456a1.5 1.5 0 1 0-1.894 0L6.506 7.112a1 1 0 0 1-1.381.362L2.866 6.119A1.5 1.5 0 1 0 1.5 7a1.532 1.532 0 0 0 .241-.025L4 13h12l2.259-6.025A1.532 1.532 0 0 0 18.5 7a1.5 1.5 0 1 0 0-3Z"
                                  fill="#c59264"/>
                        </svg>{{p.credits}} {{ $t('credits.credits') }}</h3>
                    <h4 class="mb0">{{ p.currency.toUpperCase() }} {{ p.price.toFixed(2) }}</h4>
                </div>
                <button :disabled="working" class="button button--primary">
                    {{ ucFirst($t('credits.buy_credits')) }}
                </button>
            </div>
        </div>
        <div class="price__boxes" v-else>
            <div @click="packageClickHandler(p)" style="cursor: pointer" v-for="p in packages" :key="p.id"
                 class="price__item" :class="{'is-active': p.name.toLowerCase().includes('best')}">
                <div class="price__item-row">
                    <h2 class="heading">
                        <svg v-if="p.name.toLowerCase().includes('best')" class="best-value"
                             xmlns="http://www.w3.org/2000/svg" width="20" height="16">
                            <path data-name="Icon awesome-crown"
                                  d="M16.5 14h-13a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5Zm2-10A1.5 1.5 0 0 0 17 5.5a1.47 1.47 0 0 0 .137.619l-2.262 1.356a1 1 0 0 1-1.381-.363l-2.547-4.456a1.5 1.5 0 1 0-1.894 0L6.506 7.112a1 1 0 0 1-1.381.362L2.866 6.119A1.5 1.5 0 1 0 1.5 7a1.532 1.532 0 0 0 .241-.025L4 13h12l2.259-6.025A1.532 1.532 0 0 0 18.5 7a1.5 1.5 0 1 0 0-3Z"
                                  fill="#c59264"/>
                        </svg>
                        {{p.name}}
                    </h2>
                    <div class="price-box">
                        {{$t('credits.unlock_chats', {chats: unlockableGalleries(p.credits)})}}
                    </div>
                </div>
                <div class="price__item-row">
                    <div class="divider"></div>
                    <h3>{{p.credits}} {{ $t('credits.credits') }}</h3>
                </div>
                <div class="price__item-row">
                    <h4>{{ p.currency.toUpperCase() }} {{ p.price.toFixed(2) }}</h4>
                    <button :disabled="working || ! confirmed" class="button button--primary">
                        {{ ucFirst($t('credits.buy_credits')) }}
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ThemeMixin from "@/mixins/ThemeMixin";
import {ucFirst} from "@/utilities/general";

export default {
    name: "DefaultPromotion",
    mixins: [ThemeMixin],
    emits: ['selected'],
    props: {
        packages: {
            type: Array
        },
        working: {
            type: Boolean,
            required: true
        },
        promotion: {
            type: Object,
            required: true
        },
        paymentModal: {
            type: Boolean,
            default: false
        },
        confirmed: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        ucFirst,
        packageClickHandler(p) {

            this.$emit('selected', p)

        },
        unlockableGalleries(credits) {
            return Math.round(parseInt(credits) / process.env.VUE_APP_UNLOCK_CONVERSATION)
        },

    }
}
</script>

<style scoped lang="scss">

.price {
    text-align: center;
    max-width: 1080px;
    margin: 0 auto 100px;
}

.price__boxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 60px;
    gap: 30px;
}

.price__item-row {
    position: relative;

    .heading {
        font-size: 14px;
        line-height: 38px;
        letter-spacing: -0.57px;
        text-transform: uppercase;


        @media(min-width: 992px) {
            display: flex;
            align-items: center;
            flex-direction: column;
            font-size: 20px;

            .best-value {
                margin-top: -17px;
            }
        }

        @media(max-width: 991px) {

            .best-value {
                margin-right: 10px;
            }
        }
    }

    .button--primary {
        width: 100%;
    }
}

.price__item {
    border-radius: 10px;
    border: solid 1px transparent;
    background-color: $color-type1;
    padding: 50px 30px 40px;
    position: relative;
    box-shadow: 0px 0px 12px -3px $color-type6;
    max-width: 500px;
    transition: all .3s ease-in-out;

    &:hover {
        box-shadow: 0px 0px 12px 0px $color-type6;
    }

    &.is-active {
        background-color: #222222;

        h2,
        h3 {
            color: $color-type1;
        }

        p, h4 {
            color: #959595;
        }

        .divider {
            background-color: #DEDEDE;
        }
    }

    h2 {
        font-size: 17px;
        transition: all .3s ease-in-out;
        margin-bottom: 40px;
    }

    .price-box {
        margin-bottom: 16px;
        color: $color-type19;
        font-size: 22px;
        font-weight: 600;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        letter-spacing: -0.4px;
    }

    p {
        font-size: 18px;
        line-height: 1;
        transition: all .3s ease-in-out;
        margin-bottom: 20px;
        color: #959595;
        margin-top: -5px;
    }


    .divider {
        width: 100%;
        max-width: 136px;
        margin: 0 auto;
        background-color: #DEDEDE;
        height: 1px;
        margin-bottom: 20px;
    }

    h3 {
        margin-bottom: 4px;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.2;
        transition: all .3s ease-in-out;
        letter-spacing: -0.9px;
    }

    h4 {
        color: $color-type9;
        margin-bottom: 45px;
        font-size: 18px;
        line-height: 1.6;
        transition: all .3s ease-in-out;
        font-weight: 400;
    }
}

.price__item--modal {
    padding: 12px 15px!important;

    h3 {
        margin-bottom: 0;
        display: flex;
        align-items: center;

        svg {
          margin-right: 5px;
        }
    }

    h4 {
        font-size: 14px;

        .line {
            position: relative;
            margin-left: 5px;

            &:before {
              width: 110%;
              height: 1px;
              background-color: #FF0000;
              content:"";
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) rotate(-9deg);
            }
        }
    }

    &.is-active {
      h4 {
        color: #959595;
      }
    }

    @media(max-width: 991px) {
      h3 {
        font-size: 18px;

        .best-value {
          transform: translate(0,0);
        }
      }
    }
}

@media(max-width: 991px) {

    .price {
        margin-bottom: 40px;
        padding: 0 20px;
    }

    .price > p {
        font-size: 16px;
    }

    .price__item {
        padding: 10px 20px;
        margin: 10px auto;
        width: 100%;
    }

    .price__boxes {
        display: block;
        margin-top: 20px;
    }

    .price__item-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:first-child {
            h2 {
                font-size: 14px;
                margin-bottom: 0;
            }
        }

        &:nth-child(2) {
            flex-direction: row-reverse;

            p {
                font-size: 14px;
                margin-bottom: 10px;
            }

            h3 {
                font-size: 20px;
            }
        }

        &:last-child {

            h4 {
                margin-bottom: 20px;
                font-size: 14px;
                line-height: 1.2;
            }
        }

        .divider {
            display: none;
        }

        .button--primary {
            width: auto;
        }

        .price-box {
            line-height: 1;
            font-size: 22px;
            align-items: center;
            margin-bottom: 0;

            br {
                display: none;
            }
        }
    }
}

@media(max-width: 480px) {
    .price__item {
        padding: 5px 15px 10px;

        .price-box {
            font-size: 15px;
        }

        h3 {
            font-size: 16px!important;
        }

        .button--primary {
            font-size: 14px!important;
            height: 30px;
            padding: 0 10px;
        }
    }
}

</style>