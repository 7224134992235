import {initializeApp} from "firebase/app";
import {getMessaging, getToken, isSupported} from "firebase/messaging";
import {subscribeToPushNotifications} from "@/api/notifications";
import {
    PUSH_NOTIFICATION_KEY,
    PUSH_NOTIFICATIONS_REJECTED,
    PUSH_NOTIFICATIONS_SUBSCRIBED,
    PUSH_NOTIFICATIONS_DELAYED
} from "@/types/pushNotifications";
import {isValidDate} from "@/utilities/general";
import store from "@/store";

navigator.serviceWorker?.addEventListener('controllerchange', () => {
    console.log('***********************  App update check')
    store.commit('profile/add', 'check_app_version')
})

const firebaseConfig = {
    apiKey: "AIzaSyAmEbIiZ8A-QYOhNduflHuD5SE8uLb0Owg",
    authDomain: "sugardaddywebapp.firebaseapp.com",
    projectId: "sugardaddywebapp",
    storageBucket: "sugardaddywebapp.appspot.com",
    messagingSenderId: "498232470013",
    appId: "1:498232470013:web:501e02f8ba5fef719fd194",
    measurementId: "G-0NXMK16MCV"
};

const app = initializeApp(firebaseConfig);

export const requestPushNotification = async () => {

    if(pushNotificationsDecided())
        throw new Error("Already asked")

    const permission = await Notification.requestPermission()

    if (permission === "granted") {
        return permission
    }

    if(['rejected', 'permission denied', 'denied'].includes(permission)) {
        throw Error(`Permission denied`)
    }

    return null

}

export const getUserToken = async () => {

    const supported = await isSupported()

    if(!supported)
        throw Error('Unsupported Firebase messaging')

    return getToken(
        getMessaging(app),
        {
            userVisibleOnly: true,
            vapidKey: process.env.VUE_APP_PUSH_NOTIFICATIONS_VAPID_KEY
        }
    )

}

export const subscribe = (token) => {
    return new Promise((resolve, reject) => {
        subscribeToPushNotifications(token)
            .then(response => {
                localStorage.setItem(PUSH_NOTIFICATION_KEY, JSON.stringify({
                    status: PUSH_NOTIFICATIONS_SUBSCRIBED,
                    token,
                    delay_until: null
                }))
                resolve(response)
            })
            .catch(error => reject(error))
        })
}

export const pushNotificationsAccepted = () => pushNotificationSupported() && window?.Notification?.permission === "granted"
export const pushNotificationsRejected = () => pushNotificationSupported() && ['denied', 'permission denied'].includes(window?.Notification?.permission)

export const pushNotificationsDecided = () =>
    pushNotificationsAccepted() || pushNotificationsRejected()

export const reject = () => localStorage.setItem(PUSH_NOTIFICATION_KEY, JSON.stringify({
    status: PUSH_NOTIFICATIONS_REJECTED,
    token: null,
    delay_until: null
}))

export const delay = () => {

    let date = new Date();
    date.setDate(date.getDate() + 1);

    localStorage.setItem(PUSH_NOTIFICATION_KEY, JSON.stringify({
        status: PUSH_NOTIFICATIONS_DELAYED,
        token: null,
        delay_until: date
    }))

}

export const getDelayedDate = () => {
    if( ! localStorage.getItem(PUSH_NOTIFICATION_KEY) )
        return null

    const payload = JSON.parse(localStorage.getItem(PUSH_NOTIFICATION_KEY))

    if( ! payload?.delay_until )
        return null

    if( ! isValidDate(payload.delay_until))
        return null

    return new Date(payload.delay_until)
}

export const pushNotificationsDelayed = () => getDelayedDate() && (getDelayedDate() > new Date())

export const pushNotificationSupported = () => "Notification" in window