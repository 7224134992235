<template>
    <Modal class="modal modal--mob modal-profile-setting" :size="'small'">
        <template v-slot:body>
            <ul>
                <li @click="$emit('open', {type: 'report'})"><a>{{$t('block_report.report')}}</a></li>
                <li @click="$emit('open', {type: 'block'})"><a>{{$t('block_report.block')}}</a></li>
            </ul>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal";
export default {
    name: "ProfileOptions",
    components: {Modal}
}
</script>