<template>
    <Modal class="modal modal--mob" @close="$emit('close')">
        <template #body>
            <article>
                <svg xmlns="http://www.w3.org/2000/svg" width="63.495" height="64"><g data-name="Group 75" transform="translate(-143.293 -224)"><path data-name="Union 10" d="M153.322 287.604a4.326 4.326 0 0 1-2.52-3.926v-7.25a9.967 9.967 0 0 1-7.509-9.647v-22.534a9.963 9.963 0 0 1 9.95-9.953h33.8a9.965 9.965 0 0 1 9.953 9.953v22.534a9.963 9.963 0 0 1-9.953 9.95h-17.178l-11.933 10.232-.013.011a4.341 4.341 0 0 1-2.8 1.027 4.286 4.286 0 0 1-1.797-.397Zm-5.146-43.357v22.534a5.072 5.072 0 0 0 5.067 5.067 2.441 2.441 0 0 1 2.441 2.446v8.169l11.688-10.026a2.441 2.441 0 0 1 1.589-.589h18.085a5.075 5.075 0 0 0 5.071-5.067v-22.534a5.077 5.077 0 0 0-5.071-5.071h-33.803a5.075 5.075 0 0 0-5.067 5.071Zm10.985 18.12a2.441 2.441 0 1 1 0-4.882h21.97a2.441 2.441 0 1 1 0 4.882Zm.161-9.819a2.442 2.442 0 1 1 0-4.883h21.648a2.442 2.442 0 0 1 0 4.883Z" fill="#c7c7c7"/><circle data-name="Ellipse 23" cx="13" cy="13" r="13" transform="translate(180.788 224)" fill="#e93d60"/><text data-name="5" transform="translate(188.788 244)" fill="#fff" font-size="18" font-family="OpenSans-Bold, Open Sans" font-weight="700"><tspan x="0" y="0">5</tspan></text></g></svg>
                <h2>{{$t('verify.message_limit.title')}}</h2>
                <p>{{$t('verify.message_limit.limit_reached')}} {{$t('verify.message_limit.verify_account')}}</p>
                <router-link :to="{name: 'verification'}" @click="$emit('success')" class="button button--man mb-">{{ucFirst($t('verify.verify_modal.get_verified'))}}</router-link>
                <button @click="$emit('success')" class="button button--fourth w-100">{{ucFirst($t('verify.verify_modal.stay_basic'))}}</button>
            </article>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal";
import { ucFirst } from "@/utilities/general";

export default {
    name: "DailyLimitReached",
    components: { Modal },
    methods: {
        ucFirst
    }
}
</script>

<style scoped lang="scss">

article {
    text-align: center;
}

.box {
    width: 50%;
    margin: 5px;
    border-radius: 16px;
    box-shadow: 0 0 5px rgba(0,0,0,0.4);
    background-color: $color-type1;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .main-icon {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%,-50%);
    }
}

p {
    margin-bottom: 40px;
    line-height: 1.3;
}

h2 {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 20px;
}

.button--man {
    height: $button1-height;
    $button1-font-size: 16px;
    border-radius: $button1-radius;
    font-weight: 600;
    width: 100%;
}

@media(max-width: 768px) {

    h2 {
        font-size: 16px;
    }
}

@media(max-width: 600px) {
    h2 {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

</style>