<template>
    <Modal class="modal modal--mob" :closable="false">
        <template #body>
            <UploadProfilePhoto @finish="finishHandler" />
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import UploadProfilePhoto from "@/components/photos/UploadProfilePhoto";
import { GTM_EVENT_PROFILE_PHOTO_MODAL, GTM_EVENT_PROFILE_PHOTO_MODAL_UPLOAD, GTM_EVENT_PROFILE_PHOTO_MODAL_SKIP } from "@/service/gtm/types";
import { pushDataLayer } from "@/service/gtm";
import { setAsDisplayed } from "@/service/popups";
import { PROFILE_PHOTO_MODAL_KEY, PROFILE_PHOTO_MODAL_SILENCE_DURATION } from "@/service/popups/config";

export default {
    name: "PreActionProfilePhoto",
    mixins: [AuthenticatedMixin],
    components: {UploadProfilePhoto, Modal},
    methods: {
        finishHandler( {uploaded} ) {
            this.$emit('success')
            pushDataLayer(uploaded ? GTM_EVENT_PROFILE_PHOTO_MODAL_UPLOAD : GTM_EVENT_PROFILE_PHOTO_MODAL_SKIP, this.auth.user)
        }
    },
    mounted() {
        pushDataLayer(GTM_EVENT_PROFILE_PHOTO_MODAL, this.auth.user)
        setAsDisplayed(PROFILE_PHOTO_MODAL_KEY, PROFILE_PHOTO_MODAL_SILENCE_DURATION)
    }
}
</script>