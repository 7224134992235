import { addToFavorite, removeFavorites } from "@/api/matches/favorites";
import store from "@/store";
import { TYPE_FAVORITE } from "@/types/bubble";

export const addToFavoriteService = async user_id => {

    try {
        const { data } = await addToFavorite(user_id)

        await store.dispatch('search/addMyFavorite', user_id)

        if(data?.favorite_me) {

            await store.dispatch('bubble/add', {
                type: TYPE_FAVORITE,
                id: `${TYPE_FAVORITE}_${data.id}`,
                payload: {
                    user: {
                        ...data,
                        mutual: true
                    }
                }
            })

        }

        if(store.state.search.data.data?.find(e => e.id === user_id)) {

            await store.dispatch('search/setData', {
                ...store.state.search.data,
                data: [
                    ...store.state.search.data.data.map(e => {
                        return {
                            ...e,
                            my_favorite: e.id === user_id ? 1 : e.my_favorite
                        }
                    })
                ]

            })

        }

        return Promise.resolve(data)

    } catch (e) {
        return Promise.reject(e)
    }

}

export const removeFromFavoriteService = async user_id => {

    try {
        await removeFavorites(user_id)
        await store.dispatch('search/removeMyFavorite', user_id)

        if(store.state.search.data.data?.find(e => e.id === user_id)) {
            await store.dispatch('search/setData', {
                ...store.state.search.data,
                data: [
                    ...store.state.search.data.data.map(e => {
                        return {
                            ...e,
                            my_favorite: e.id === user_id ? 0 : e.my_favorite
                        }
                    })
                ]
            })
        }
    } catch(e) {
        return Promise.reject(e)
    }

}