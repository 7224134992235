import { TYPE_TEMPORARY } from "@/types/message";
import { isVideo } from "@/utilities/files";
import { ITEM_TYPE_VIDEO, ITEM_TYPE_PHOTO} from "@/types/item";

class SendMessagePayload {
    constructor(hash, created_at, author_id, text, finished, uploaded, conversation_id, file = null, extension = null, item_id = null, action = QUEUE_ACTION_SEND) {
        this.hash               = hash
        this.type               = TYPE_TEMPORARY
        this.attachment_type    = extension ? (isVideo(extension) ? ITEM_TYPE_VIDEO : ITEM_TYPE_PHOTO) : null
        this.created_at         = created_at
        this.author_id          = author_id
        this.body               = text
        this.file               = file
        this.finished           = finished
        this.uploaded           = uploaded
        this.item_id            = item_id
        this.conversation_id    = conversation_id
        this.error              = null
        this.extension          = extension
        this.action             = action
    }
}

export default SendMessagePayload

export const QUEUE_ACTION_SEND      = 'send'
export const QUEUE_ACTION_UPLOAD    = 'upload'
export const QUEUE_ACTION_WAIT      = 'wait'