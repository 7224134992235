export class Swipe {

    constructor(config = {}) {
        this.startX = 0
        this.endX = 0
        this.startY = 0
        this.endY = 0
        this.startTime = null
        this.endTime = null
        this.callback = null

        this.config = {
            time: 200,
            pixels: 20,
            nextReturnValue: 1,
            previousReturnValue: -1,
            debug: false,
            ...config
        }
    }

    start(event) {
        this.startX     = event.changedTouches[0].clientX
        this.startY     = event.changedTouches[0].clientY
        this.startTime  = event.timeStamp
    }

    end(event) {
        this.endX     = event.changedTouches[0].clientX
        this.endY     = event.changedTouches[0].clientY
        this.endTime  = event.timeStamp

        if(this.endTime - this.startTime > this.config.time) {
            if(this.config.debug) console.log('Too slow')
            return
        }

        if(Math.abs(this.startY - this.endY) > Math.abs(this.startX - this.endX)) {
            if(this.config.debug) console.log('Y axis dominant')
            return
        }

        if(Math.abs(this.startX - this.endX) < this.config.pixels) {
            if(this.config.debug) console.log('Distance too short')
            return
        }

        this.callback(this.startX < this.endX ? this.config.previousReturnValue : this.config.nextReturnValue)

    }

    handler(callback) {

        this.callback = callback

    }

}