<template>
    <Modal class="modal modal--mob modal--block-user" :size="'small'">
        <template #body>
            <h2 class="mb--">{{$t('block_report.report')}} {{user.username}}</h2>
            <p>{{$t('block_report.tell_us_why')}} {{user.username}}.</p>
            <div v-if="!type">
                <ul>
                    <li @click="type = $t('block_report.profile_content')"><a :class="type === $t('block_report.profile_content') ? 'active' : ''">{{$t('block_report.profile_content')}}</a></li>
                    <li @click="type = $t('block_report.inperson_behavior')"><a :class="type === $t('block_report.inperson_behavior') ? 'active' : ''">{{$t('block_report.inperson_behavior')}}</a></li>
                    <li @click="type = $t('block_report.too_far_away')"><a :class="type === $t('block_report.too_far_away') ? 'active' : ''">{{$t('block_report.too_far_away')}}</a></li>
                    <li @click="type = $t('block_report.other')"><a :class="type === $t('block_report.other') ? 'active' : ''">{{$t('block_report.other')}}</a></li>
                </ul>
            </div>
            <div v-if="type" class="mt">

                <div class="input-box">
                    <textarea :disabled="working" placeholder="Enter your reason here..." class="input input--secondary input--textarea" v-model="reason"></textarea>
                </div>
                <button @click="reportUser" class="button button--primary w-100" :disabled="reason.length < 10 || !type.length || working">{{$t('block_report.report')}}</button>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal";
import { report } from "@/api/users";
import toastr from "toastr";

export default {
    name: "ReportUserDialog",
    components: {Modal},
    props: {
        user: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            type: '',
            reason: '',
            working: false
        }
    },
    methods: {
        async reportUser() {
            this.working = true

            try {
                await report(this.user.id, this.reason, this.type)
                toastr.success(this.$i18n.t('block_report.user_reported'))
                this.$emit('close')
            } catch (e) {
                console.log('Error reporting user', e)
                toastr.error(this.$i18n.t('credits.error_processing'))
            } finally {
                this.working = false
            }
        }
    },
}
</script>

<style scoped lang="scss">
    .modal {
        h2 {
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 30px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 20px;
        }

        li {

            &:not(:last-child) {
                margin-bottom: 15px;
            }

            a {
                font-size: 18px;
                line-height: 24px;
                color: $color-type18;

                &:hover {
                    color: $color-type19;
                }
            }
        }
    }
</style>