<template>
    <Search />
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import Search from "@/components/search/Search";

export default {
    name: "SearchPage",
    components: {Search},
    mixins: [AuthenticatedMixin],
}
</script>

<style scoped>

</style>