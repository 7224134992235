import store from '@/store'
import { userForToken } from "@/api/auth";
import {
    setAuthenticatedUser,
    setAuthenticatedUserLocation,
    setAuthenticatedUserSearchLocationHistory,
    setAuthenticatedUserItemCount
} from "@/service/auth";
import { setCredits } from "@/service/user";
import {
    accessResponse,
    addedToFavorites,
    connect,
    newMessage, newRequest,
    newVisitor,
    removedFromFavorites, systemNotifications,
    itemReady, creditsUpdated, presenceChannel
} from "@/connection/websocket/connection";
import * as listeners from "@/connection/websocket/listeners";
import { loadNotifications } from "@/service/notifications";
import {TYPE_GENDER_FEMALE, TYPE_GENDER_MALE} from "@/types/user";
import { pushDataLayer } from "@/service/gtm";
import { GTM_EVENT_TYPE_LOGIN } from "@/service/gtm/types";
import { getRaw } from "@/api/access";
import { getIsDisplayed } from "@/service/popups";
import { PROFILE_PHOTO_MODAL_KEY } from "@/service/popups/config";
import moment from "moment";
import { identifyUser } from "@/service/user/identify";
import {pushNotificationsAccepted, pushNotificationSupported} from "@/service/push_notifications/push";

/**
 *
 * @returns {Promise<Object|false>}
 */
export const bootUser = async (silent = false) => {

    if(localStorage.getItem('auth_token')) {

        if( ! store.state.authenticate.auth.user.id) {

            try {

                const { data } = await userForToken(localStorage.getItem('auth_token'))

                await setAuthenticatedUser(data)

                pushDataLayer(GTM_EVENT_TYPE_LOGIN, data)

                connect()
                    .then(connection => {
                        presenceChannel(connection)
                        addedToFavorites(connection).listen('.favorite', listeners.addedToFavoritesListener)
                        removedFromFavorites(connection).listen('.unfavorite', listeners.removedFromFavoritesListener)
                        newMessage(connection).listen('.message.sent', listeners.newMessageListener)
                        newVisitor(connection).listen('.visit', listeners.newVisitorListener)
                        newRequest(connection).listen('.item.request.sent', listeners.newRequestListener)
                        accessResponse(connection).listen('.item.request.response', listeners.accessResponseListener)
                        systemNotifications(connection).listen('.system', listeners.systemNotificationsListener)
                        itemReady(connection).listen('.item.updated', listeners.itemReadyListener)

                        if(store.state.authenticate.auth.user.gender === TYPE_GENDER_MALE) {
                            creditsUpdated(connection).listen('.credits.updated', listeners.creditsUpdatedListener)
                        }

                    })
                    .then(() => {
                        store.dispatch('profile/add', 'connected')
                    })

                loadNotifications()

                registerInteractionListeners()

                checkIsInstalledInterval()

                identifyUser(store.state.authenticate.auth.user).then(() => console.log('stat checked'))

                await Promise.all([
                    store.dispatch('profile/add', 'password'),
                    store.dispatch('profile/add', 'email'),
                    store.dispatch('profile/add', 'location'),
                    store.dispatch('profile/add', 'description')
                ])

                let shouldPushSecretPhoto = false

                if( ! store.state.authenticate.auth.user.photo) {

                    if(store.state.authenticate.auth.user.gender === TYPE_GENDER_FEMALE ) {
                        await store.dispatch('profile/add', 'profile_photo')
                    }

                    if(store.state.authenticate.auth.user.gender === TYPE_GENDER_MALE && ! getIsDisplayed(PROFILE_PHOTO_MODAL_KEY)) {

                        if(moment.duration(moment.utc().diff(moment.utc(store.state.authenticate.auth.user.created_at, 'YYYY-MM-DD HH:mm:ss'))).asMinutes() < 30) {

                            await store.dispatch('profile/add', 'profile_photo')
                            shouldPushSecretPhoto = true

                        } else {

                            await store.dispatch('profile/add', moment().date() % 2 === 0 ? 'secret_profile_photo' : 'profile_photo')

                        }

                    }

                }

                if( data.gender === TYPE_GENDER_MALE) {
                    await setCredits()

                    if( ! data.offers.length) {
                        await store.dispatch('profile/add', 'offers')
                    }

                    if( ! store.state.credits.amount && ! data.flow) {
                        await store.dispatch('profile/add', 'premium')
                    }

                }

                if( ! store.state.search.location) {

                    setAuthenticatedUserLocation()
                    setAuthenticatedUserSearchLocationHistory()

                }

                setAuthenticatedUserItemCount().then(() => {
                    store.dispatch('profile/add', 'photos')
                    store.dispatch('profile/add', 'verified')
                })

                if( shouldPushSecretPhoto) {
                    await store.dispatch('profile/add', 'secret_profile_photo')
                }

                if(store.state.authenticate.auth.user.email_verified_at) {
                    getRaw().then(({data}) => store.dispatch('access/setAvailable', data))
                }

                if(data.gender === TYPE_GENDER_MALE && data.flow) {
                    await store.dispatch('profile/add', 'flow_based_redirect')
                }

                await store.dispatch('application/setPushSupported', pushNotificationSupported())
                await store.dispatch('application/setPushEnabled', pushNotificationsAccepted())

                return Promise.resolve(store.state.authenticate.auth)


            } catch (e) {
                console.log(e)
                return Promise.resolve(false)

            } finally {

                if( ! silent) {
                    await store.dispatch('application/setBootstrapping', false)
                }

            }

        } else {

            if( ! store.state.search.location) {
                setAuthenticatedUserLocation()
                setAuthenticatedUserSearchLocationHistory()
            }

            return Promise.resolve(store.state.authenticate.auth)

        }

    }

    return Promise.resolve(false)

}


export const isMobile = () => {
    // return true
    // return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))
}

/**
 * @param string
 * @returns {null|string}
 */
export const extractHostnameFromString = string => {
    try {
        const { hostname } = new URL(string)
        return hostname.includes('www.') ? hostname.replace('www.', '') : hostname
    } catch {
        return null;
    }
}

export const registerInteractionListeners = () => {
    document.body.addEventListener('click', () => setInteracted())
    document.body.addEventListener('keydown', () => setInteracted())
}

export const setInteracted = async () => {
    if(store.state.application.interacted)
        return

    await store.dispatch('application/setInteracted', !! navigator.userActivation?.hasBeenActive)
}

export const checkIsInstalled = () => window.matchMedia('(display-mode: standalone)')?.matches ?? window.navigator?.standalone === true

export const checkIsInstalledInterval = () => {
    let interval = setInterval(() => {
        if(checkIsInstalled()) {
            store.dispatch('application/setInstalled', true).then(() => clearInterval(interval))
        }
    }, 5000)
}