import { send } from "@/api/messages";
import { createPreSignedURL, createItem } from "@/api/files";
import { uploadToBucket } from "@/service/files";
import {QUEUE_ACTION_SEND, QUEUE_ACTION_UPLOAD, QUEUE_ACTION_WAIT} from "@/service/messages/queue";

export default {

    namespaced: true,

    state: {

        messages: [],

        sendQueue: [],

        attachmentsReady: [],

        working: false

    },

    mutations: {

        addMessage(state, payload) {

            state.messages = [...state.messages.filter(e => e.conversation_id !== payload.conversation_id), payload]
        },

        removeMessage(state, payload) {

            state.messages = state.messages.filter(e => e.id !== payload.id)

        },

        enqueueMessage(state, payload) {
            state.sendQueue = [...state.sendQueue, payload]
        },

        removeFromQueue(state, payload) {
            state.sendQueue = state.sendQueue.filter(e => e.hash !== payload.hash)
        },

        setSendingQueue(state, payload) {
            state.sendQueue = payload
        },

        setWorking(state, payload) {
            state.working = payload
        },

        updateMessage(state, payload) {
            state.sendQueue = state.sendQueue.map(e => {
                if(e.hash === payload.hash) {
                    return {
                        ...e,
                        ...payload
                    }
                }
                return e
            })
        },

        setAttachmentsReady(state, payload) {
            state.attachmentsReady = payload
        }

    },

    actions: {

        addMessage({commit}, payload) {
            commit('addMessage', payload)
        },

        async removeMessage({commit}, payload) {
            commit('removeMessage', payload)
        },

        async enqueueMessage(context, payload) {

            context.commit('enqueueMessage', payload)

            await context.dispatch('process')

        },

        async removeFromQueue(context, payload) {
            context.commit('removeFromQueue', payload)
            await context.dispatch('process')
        },

        updateMessage({commit}, payload) {

            commit('updateMessage', payload)

        },

        async addAttachmentReady(context, payload) {

            context.commit('setAttachmentsReady', [
                ...context.state.attachmentsReady,
                payload
            ])

            const messagePayload = context.state.sendQueue.find(e => e.item_id === payload.id)

            if(messagePayload) {

                await context.dispatch('updateMessage', {
                    hash: messagePayload.hash,
                    finished: true,
                    action: QUEUE_ACTION_SEND
                })

                await context.dispatch('process')

            }

        },

        async process(context) {

            if( ! context.state.sendQueue.some(e => [QUEUE_ACTION_SEND, QUEUE_ACTION_UPLOAD].includes(e.action)))
                return

            if(context.state.working)
                return

            context.commit('setWorking', true)

            const payload = context.state.sendQueue.find(e => [QUEUE_ACTION_SEND, QUEUE_ACTION_UPLOAD].includes(e.action))

            try {

                if(payload.action === QUEUE_ACTION_SEND) {
                    await send(payload.conversation_id, payload.body, payload.hash, payload.item_id)
                    context.commit('setAttachmentsReady', context.state.attachmentsReady.filter(e => e.id !== payload.item_id))
                }

                if(payload.action === QUEUE_ACTION_UPLOAD) {

                    const preSignedURL = await createPreSignedURL(payload.extension)

                    await uploadToBucket(preSignedURL.data, payload.file.source, async progress => {
                        context.commit('updateMessage', {
                            ...payload,
                            uploaded: (progress.loaded * 100) / progress.total
                        })
                    })

                    const itemData = await createItem({
                        file: preSignedURL.data.filename,
                        type: payload.attachment_type,
                        private: 0,
                        conversation_id: payload.conversation_id
                    })

                    await context.dispatch('updateMessage', {
                        hash: payload.hash,
                        item_id: itemData.data.id,
                        action: context.state.attachmentsReady.find(e => e.id === itemData.data.id) ? QUEUE_ACTION_SEND : QUEUE_ACTION_WAIT
                    })

                    context.commit('setWorking', false)
                    return context.dispatch('process')

                }


            } catch (e) {
                console.log('Error processing queue', e)
            }

            context.commit('setWorking', false)
            await context.dispatch('removeFromQueue', {hash: payload.hash})

        }

    },

    getters: {

    }

}