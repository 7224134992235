<template>
    <div class="upload-loader">
        <div class="icon-wrapper">
            <svg v-if="percent < 100" width="68" class="progress-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                <circle class="progress" :style="{strokeDashoffset: value}" fill="none" stroke-linecap="round" cx="20" cy="20" r="15.915494309" />
            </svg>
            <svg v-if="percent === 100" class="processing-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20"  viewBox="0 0 861.143 861.144" xml:space="preserve"><path fill="#fff" d="M803.661 613.847c21.129-49.956 31.842-102.991 31.842-157.634 0-46.664-7.883-92.431-23.43-136.031-15.025-42.134-36.846-81.294-64.859-116.393-27.734-34.751-60.746-64.539-98.117-88.534-38.072-24.445-79.621-42.192-123.492-52.748-28.191-6.782-56.543 10.571-63.326 38.761s10.572 56.542 38.762 63.325c64.291 15.47 122.572 52.651 164.109 104.694 20.75 26.001 36.908 54.99 48.023 86.162 11.5 32.249 17.332 66.151 17.332 100.764 0 80.114-31.197 155.435-87.848 212.083-56.65 56.649-131.971 87.848-212.084 87.848-80.114 0-155.434-31.198-212.083-87.849-56.65-56.648-87.848-131.969-87.848-212.083 0-59.197 17.208-116.435 49.763-165.521 28.053-42.3 66.007-76.562 110.547-100.007v58.027c0 28.995 23.505 52.5 52.5 52.5s52.5-23.505 52.5-52.5V52.5c0-28.995-23.505-52.5-52.5-52.5H144.567c-28.995 0-52.5 23.505-52.5 52.5s23.505 52.5 52.5 52.5h84.328C174.456 136.276 127.939 179.822 92.9 232.655c-44.001 66.346-67.259 143.65-67.259 223.557 0 54.643 10.714 107.679 31.843 157.634 20.398 48.225 49.587 91.524 86.759 128.695 37.171 37.171 80.471 66.361 128.696 86.759 49.956 21.13 102.991 31.844 157.634 31.844 54.644 0 107.677-10.714 157.634-31.844 48.225-20.397 91.523-49.587 128.695-86.759 37.171-37.17 66.36-80.47 86.759-128.694z"/></svg>
            <svg v-else class="upload-img" xmlns="http://www.w3.org/2000/svg" width="18.687" height="27"><g data-name="Icon feather-upload" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"><path data-name="Path 90" d="M16.566 25.5H2.122"/><path data-name="Path 91" d="M16.566 8.722 9.344 1.5 2.122 8.722"/><path data-name="Path 92" d="M9.344 1.5v17.333"/></g></svg>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProgressCircle",
    props: {
        percent: {
            type: Number,
            default: 0
        },
    },
    computed: {
        value() {
            return -100 - this.percent
        }
    }
}
</script>

<style scoped lang="scss">

.upload-loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: rgba(#000, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-wrapper {
        background-color: rgba(#000, 0.6);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .processing-icon {
        animation: spin 1s infinite linear;
    }
}

    .progress-circle {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .progress {
            stroke: #fff;
            stroke-width:2px;
            stroke-dasharray: 100;
            stroke-dashoffset:-120;
            transform-origin: center;
            transform: rotate(-90deg);
        }

    }

    @keyframes spin {
        from {transform:rotate(0deg) translate(0,-0.5px);}
        to {transform:rotate(360deg) translate(0,-0.5px);}
    }
</style>