<template>
    <Modal class="modal modal--mob u-text-center">
        <template #body>
            <h2><strong>{{$t('profile.add_offer')}}</strong></h2>
            <p>{{$t('profile.offer_amount')}}</p>

            <Form ref="offerForm" :validation-schema="validationRules" @submit="submit">
                <div class="input-box">
                    <label>{{$t(`profile.offer_per_meeting`)}}</label>
                    <Field inputmode="numeric" name="meeting" :validateOnInput="true" :placeholder="$t('credits.success.amount') + ` (${auth.user.local_currency.name.toUpperCase()})`" class="input input--primary" v-model="offers[0].amount" type="number" min="50" max="100000" step="1" />
                    <ErrorMessage class="text-error text-left form-text" name="meeting" />
                </div>

                <div class="input-box">
                    <label>{{$t(`profile.offer_per_weekend`)}}</label>
                    <Field inputmode="numeric" name="weekend" :validateOnInput="true" :placeholder="$t('credits.success.amount') + ` (${auth.user.local_currency.name.toUpperCase()})`" class="input input--primary" v-model="offers[1].amount" type="number" min="50" max="100000" step="1" />
                    <ErrorMessage class="text-error text-left form-text" name="weekend" />
                </div>

                <div class="input-box">
                    <label>{{$t(`profile.offer_per_month`)}}</label>
                    <Field inputmode="numeric" name="month" :validateOnInput="true" :placeholder="$t('credits.success.amount') + ` (${auth.user.local_currency.name.toUpperCase()})`" class="input input--primary" v-model="offers[2].amount" type="number" min="50" max="100000" step="1" />
                    <ErrorMessage class="text-error text-left form-text" name="month" />
                </div>
                <button type="submit" :disabled="!valid || working" class="button button--primary w-100">{{$t('settings.save')}}</button>
            </Form>
        </template>
    </Modal>
</template>

<script>

import Modal from "@/components/generic/Modal";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { updateProfile } from "@/service/user";
import toastr from "toastr";
import { Form, ErrorMessage, Field, defineRule } from "vee-validate";
import { min_value, max_value, numeric } from "@vee-validate/rules";
import { GTM_EVENT_DATING_OFFER_MODAL, GTM_EVENT_DATING_OFFER_SUCCESS} from "@/service/gtm/types";
import { pushDataLayer } from "@/service/gtm";

export default {
    name: "PreActionOffers",
    mixins: [AuthenticatedMixin],
    components: {Modal, Form, ErrorMessage, Field},
    data() {
        return {
            min: 50,
            max: 100000,
            working: false,
            offers: [
                {
                    type: 'meeting',
                    amount: null
                },
                {
                    type: 'weekend',
                    amount: null
                },
                {
                    type: 'month',
                    amount: null
                }
            ],
            validationRules: {

            }
        }
    },
    computed: {
        valid() {

            if(this.offers.every(e => ! e.amount))
                return false

            for(let offer of this.offers) {
                if(offer.amount && (offer.amount < 50 || offer.amount > 100000))
                    return false
            }

            return true

        }
    },
    methods: {

        async submit() {

            this.working = true

            try {
                await updateProfile({offers: this.offers.filter(e => e.amount)})
                toastr.success(this.$i18n.t('profile.edit.profile_changed'))
                this.working = false
                pushDataLayer(GTM_EVENT_DATING_OFFER_SUCCESS, this.auth.user)
                this.$emit('success')
            } catch {
                toastr.error(this.$i18n.t('credits.error_processing'))
                this.working = false
            }

        }

    },
    created() {
        defineRule('min_value', min_value)
        defineRule('max_value', max_value)
        defineRule('numeric', numeric)

        this.validationRules = {
            meeting: 'numeric|min_value:50|max_value:100000',
            weekend: 'numeric|min_value:50|max_value:100000',
            month: 'numeric|min_value:50|max_value:100000',
        }
    },
    mounted() {
        pushDataLayer(GTM_EVENT_DATING_OFFER_MODAL, this.auth.user)
    }
}
</script>

<style lang="scss" scoped>

    .input-box {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        margin-bottom: 20px;

        input {
            font-size: 16px;
        }
    }

    label {
        text-align: left;
    }

    h2 {
        margin-bottom: 10px;
        letter-spacing: -0.9px;
        font-size: 22px;
        line-height: 38px;
    }

    p {
        max-width: 320px;
        margin: 0 auto 30px;
        line-height: 24px;
    }

    button.button--primary {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .text-error {
        color: $color-type24;
        font-size: small;
    }
</style>