<template>
    <section class="status-box status-box--pending" v-if="!transaction || transaction?.status === TRANSACTION_TYPE_PENDING">
        <article class="">
            <h1>{{$t('credits.success.title')}} {{$t('credits.success.pending')}}</h1>
            <ContentLoader/>
        </article>
    </section>

    <section class="status-box status-box--completed" v-else-if="transaction.status === TRANSACTION_TYPE_COMPLETED">
        <article :class="glow?'is-active':''">
            <h1>{{$t('credits.success.title')}} {{$t('credits.success.completed')}}</h1>

            <div class="success-checkmark">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                    <circle class="path circle" fill="none" stroke="#43ca85" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                    <polyline class="path check" fill="none" stroke="#43ca85" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                </svg>
            </div>

            <ul>
                <li>
                    <p>{{$t('credits.success.amount')}}:</p>
                    <span>{{Number(transaction.amount).toFixed(2)}}</span>
                </li>
                <li>
                    <p>{{$t('credits.success.currency')}}:</p>
                    <span>{{transaction.currency}}</span>
                </li>
                <li>
                    <p>{{$t('credits.success.credits')}}:</p>
                    <span>{{transaction.credits}}</span>
                </li>
            </ul>

            <slot name="success_action"></slot>
        </article>
    </section>

    <section class="status-box status-box--failed" v-else-if="transaction.status === TRANSACTION_TYPE_FAILED">
        <article :class="glow?'is-active':''">
            <h1>{{$t('credits.success.title')}} {{$t('credits.success.failed')}}</h1>

            <div class="failed-checkmark">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                    <circle class="path circle" fill="none" stroke="#a82e2e" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                    <line class="path line" fill="none" stroke="#a82e2e" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                    <line class="path line" fill="none" stroke="#a82e2e" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                </svg>
            </div>

            <ul>
                <li>
                    <p>{{$t('credits.success.amount')}}:</p>
                    <span>{{Number(transaction.amount).toFixed(2)}}</span>
                </li>
                <li>
                    <p>{{$t('credits.success.currency')}}:</p>
                    <span>{{transaction.currency}}</span>
                </li>
                <li>
                    <p>{{$t('credits.success.credits')}}:</p>
                    <span>{{transaction.credits}}</span>
                </li>
            </ul>
            <div>
                <slot name="failed_action"></slot>
            </div>

        </article>
    </section>
</template>

<script>
    import ContentLoader from "@/components/ContentLoader.vue";
    import {TRANSACTION_TYPE_COMPLETED, TRANSACTION_TYPE_FAILED, TRANSACTION_TYPE_PENDING} from "@/types/transactions";

    export default {
        name: "PaymentStatus",
        components: {ContentLoader},
        props: {
            transaction: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                working: false,
                glow: false,
            }
        },
        methods: {
            removeGlow() {
                setTimeout(() => this.glow = false, 1000);
            },
        },
        watch: {
            transaction: {
                handler() {
                    this.glow = true
                    this.removeGlow()
                },
                immediate: true
            }
        },
        created() {
            this.TRANSACTION_TYPE_COMPLETED = TRANSACTION_TYPE_COMPLETED
            this.TRANSACTION_TYPE_PENDING   = TRANSACTION_TYPE_PENDING
            this.TRANSACTION_TYPE_FAILED    = TRANSACTION_TYPE_FAILED
        }
    }
</script>

<style scoped lang="scss">
.status-box {
    padding: 100px 0 50px;

    article {
        width: 100%;
        margin: 0 auto;
        max-width: 500px;
        border-radius: 20px;
        background-color: #fff;
        box-shadow: 0 0 10px rgb(230, 230, 230);
        border: 1px solid rgb(230, 230, 230);
        text-align: center;
        padding: 50px;
        transition: all .6s ease-in-out;

    }

    h1 {
        margin-bottom: 30px;
    }

    ul {
        margin-bottom: 50px;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        p {
            margin-bottom: 0;
            font-size: 16px;
            color: rgb(119, 119, 119);
        }

        span {
            font-size: 22px;
            font-weight: 600;
            color: rgb(0, 0, 0);
        }
    }
}

.status-box--failed {

    article {

        &.is-active {
            box-shadow: 0 0 15px #a82e2e;
            border: 1px solid #a82e2e;
        }
    }
}

.status-box--completed {
    article {

        &.is-active {
            box-shadow: 0 0 15px #43ca85;
            border: 1px solid #43ca85;
        }
    }
}

.status-box--pending {
    article {
        border:none;
        box-shadow: none;
        background-color: transparent;

        h1 {
            margin-bottom: -150px;
        }
    }
}

.success-checkmark {

    svg {
        background-color: rgba(#43ca85, .2);
    }
}

.failed-checkmark {

    svg {
        background-color: rgba(#a82e2e, .2);
    }
}

.success-checkmark,
.failed-checkmark {
    margin: 0 auto;
    text-align: center;

    svg {
        width: 100px;
        display: block;
        margin: 40px auto;
        border-radius: 50%;
    }
}

.pay-pal {
    margin-top: 20px;
    h2 {
        margin-bottom: 0;
        margin-right: 10px;
    }

    p {
        margin-top: 20px;
        font-size: 14px;
        line-height: 1.3;
    }
}


.path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &.circle {
        -webkit-animation: dash 1s ease-in-out;
        animation: dash 1s ease-in-out;
    }
    &.line {
        stroke-dashoffset: 1000;
        -webkit-animation: dash 1s .35s ease-in-out forwards;
        animation: dash 1s .35s ease-in-out forwards;
    }
    &.check {
        stroke-dashoffset: -100;
        -webkit-animation: dash-check 1s .35s ease-in-out forwards;
        animation: dash-check 1s .35s ease-in-out forwards;
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
}

@keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
}
</style>