<template>
    <Modal class="modal modal--mob modal-radio-buttons">
        <template #header>
            <h2>{{$t('form_fields.tattoo_label')}}</h2>
        </template>
        <template #body>
            <div v-for="t in tattoos" :key="t.code" class="radiobox">
                <input class="form-check-input" type="radio" :id="t.code" :checked="value === t.value">
                <label @click="update(t.value)" class="radiobox__label" :for="t.code">
                    {{$t(`form_fields.tattoo_options_${t.value}`)}}
                </label>
            </div>
        </template>
        <template #footer>
            <a @click="$emit('success')" class="skip">{{$t('credits.skip_for_now')}}</a>
        </template>
    </Modal>
</template>

<script>

import Modal from "@/components/generic/Modal";
import { tattoos } from "@/service/forms"
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";

export default {
    name: "OnDemandTattoo",
    mixins: [AuthenticatedMixin],
    components: { Modal },
    props: {
        value: {
            type: [Number, null],
            required: true
        }
    },

    methods: {

        update(value) {

            this.$emit('set', {
               tattoo: value
            })

        }

    },

    created() {
        this.tattoos = tattoos
    },
    mounted() {
        this.tattoo = this.auth.user.tattoo
    },
    beforeUnmount() {
        clearTimeout(this.timeout)
    }
}
</script>