<template>
    <Header @request-register="$emit('request-register')" />
    <main class="main-small-header">
        <section class="login">
            <div class="o-container">
                <article>
                    <div v-if="!done">
                        <h1><strong>{{$t('forgot_password.title')}}</strong></h1>
                        <p class="u-text-left">{{$t('forgot_password.text')}}</p>
                        <Form ref="loginForm" @submit="onSubmit" :validation-schema="schema">
                            <div class="input-box u-text-left">
                                <Field autofocus v-model="email" id="email" :class="`input input--primary`" :disabled="working" :placeholder="$t('form_fields.email_or_username')" name="email" />
                                <ErrorMessage class="text-error text-left form-text" name="email"  />
                            </div>
                            <div class="input-box mb-">
                                <Button :disabled="working" :class-name="['button', 'button--primary', 'w-100']">
                                    <template v-slot:content>
                                        {{ $t('forgot_password.submit') }}
                                    </template>
                                </Button>
                            </div>
                        </Form>
                    </div>
                    <div v-else class="u-text-center">
                        <svg width="114" height="113" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="114" height="112.761" rx="16" fill="#ECECEC"/><path d="M40.781 56c0 3.094.806 6.045 2.325 8.669a26.361 26.361 0 0 1 3.051-3.625c1.504-1.504 4.015.033 3.406 2.07-1.343 4.479-2.106 9.143-2.049 13.832.02 1.64-1.756 2.626-3.158 1.772-4.001-2.439-8.474-4.065-13.06-5.223-2.05-.517-2.126-3.43-.086-3.978a26.39 26.39 0 0 1 4.14-.782A25.85 25.85 0 0 1 32.03 56c0-.372.008-.742.023-1.11l8.742.364c-.01.247-.015.497-.015.746ZM79.242 71.428l-7.058-5.171A17.24 17.24 0 0 0 75.53 56c.001-5.942-2.988-11.41-7.994-14.628l4.732-7.36C79.792 38.846 84.282 47.067 84.28 56a25.925 25.925 0 0 1-5.04 15.428ZM58.156 38.625c-7.742 0-14.618 5.196-16.721 12.634l-8.42-2.38c3.164-11.19 13.503-19.004 25.141-19.004 2.962 0 5.87.493 8.647 1.466l-2.894 8.258a17.337 17.337 0 0 0-5.753-.974ZM75.072 75.91a26.132 26.132 0 0 1-16.916 6.215v-8.75a17.38 17.38 0 0 0 11.247-4.131l5.669 6.666Z" fill="#C4C4C4"/></svg>
                        <h1>{{$t('forgot_password.sent')}}</h1>
                        <p>Check your email, insert new password and continue browsing</p>
                    </div>
                    <router-link :to="{name: 'login'}" class="button button--fourth w-100">{{$t('forgot_password.return')}}</router-link>
                </article>
            </div>
        </section>
    </main>
</template>

<script>
import { defineRule } from 'vee-validate'
import { required, min } from '@vee-validate/rules'
import { Form, Field, ErrorMessage } from 'vee-validate'
import Button from "@/components/generic/Button";
import Header from "@/components/Header";
import { requestPasswordReset } from "@/api/auth";
import toastr from "toastr";

export default {
    name: "ForgotPasswordPage",
    components: {Form, Field, ErrorMessage, Button, Header},
    data() {
        return {
            working: false,
            done: false,
            email: ''
        }
    },
    methods: {
        async onSubmit () {

            try {
                this.working = true
                await requestPasswordReset(this.email)
                this.done = true
            } catch {
                toastr.error(this.$i18n.t('credits.error_processing'))
            } finally {
                this.working = false
            }

        },
    },
    created() {
        defineRule('required', required)
        defineRule('min', min)
        this.schema = {
            email: 'required',
        }
    }
}
</script>

<style scoped lang="scss">

main.main-small-header {
    min-height: calc(100vh - 180px);
    height: auto;

    @media(max-width: 991px) {
        background-color: #fff;
    }
}

.login {
    padding: 90px 0;

    article {
        padding: 60px 90px 80px;
        max-width: 520px;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 10px;
    }

    h1 {
        margin-bottom: 30px;
        font-size: 30px;
        color: $color-type19;
    }

    p.u-text-left {
        line-height: 1.4;
        margin: 0 0 30px;
        font-size: 16px;
    }

    svg {
        margin-bottom: 30px;
    }

    @media(max-width: 991px) {
        padding: 30px 0;

        article {
            padding: 30px 20px;
            background-color: #fff;
            border-radius: 0;
        }
    }
}
</style>