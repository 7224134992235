<script>
import { mapState, mapActions } from "vuex";
import { recommended } from "@/api/users";
import {
    ORGANIC_EVENTS, ANIMATION_EVENTS, TYPE_ANIMATION_OFFER
} from "@/types/bubble";
import { ANIMATION_TIMES } from "@/utilities/animationConfig";
import { setUserAsAnimated, getValidAnimatedUsersIDs } from "@/service/animations";
import { shuffle } from "@/utilities/general";

export default {
    name: "Animations",
    data() {
        return {
            schedule: [],
            unwatch: null,
            recommended: [],
            timesRepeat: 3,
        }
    },
    render() {
        return null
    },
    computed: {
        ...mapState({
            notifications: state => state.bubble.data,
            profiles: state => state.animations.profiles
        })
    },
    methods: {
        ...mapActions({
            addBubble: 'bubble/add',
            setAnimationProfiles: 'animations/setProfiles'
        }),
        hasOrganicEvent(bubbleArray) {
            for(let i of bubbleArray) {
                if(ORGANIC_EVENTS.includes(i.type)) {
                    return true
                }
            }
            return false
        },

        clearAnimations() {
            this.schedule.forEach(timeout => clearTimeout(timeout))
            this.schedule = []
            console.log('animations cleared')
        },

        buildAnimations() {

            if( ! this.profiles)
                return

            const displayed = getValidAnimatedUsersIDs()
            let animations = []

            const animationEvents = ANIMATION_EVENTS.filter(e => e !== TYPE_ANIMATION_OFFER)

            animationEvents.forEach(event => {
                const profile = this.profiles[event]?.find(e => ! displayed.includes(e.id))
                if(profile) {
                    animations.push({
                        type: event,
                        profile
                    })
                    displayed.push(profile.id)
                }
            })

            animations = shuffle([...animations, ...(this.profiles[TYPE_ANIMATION_OFFER]?.slice(0, 3).map(e => ({type: TYPE_ANIMATION_OFFER, profile: e})) ?? [])])
            console.log('animations', animations.map(e => ({id: e.id, username: e.username, type: e.type})))

            const [min, max]    = ANIMATION_TIMES
            const time          = Math.floor(Math.random() * (max - min + 1) + min) * 1000

            for(let i = 0; i < animations.length; i++) {

                if( ! animations[i] || ! animations[i].profile)
                    continue

                this.schedule = [
                    ...this.schedule,
                    setTimeout(() => {
                        this.addBubble({
                            type: animations[i].type,
                            id: `${animations[i].type}_${animations[i].profile.id}`,
                            payload: {
                                user: {
                                    ...animations[i].profile
                                }
                            }
                        })
                        setUserAsAnimated(animations[i].profile)
                    }, time * (i+1))
                ]

            }

            console.log('animations set', this.schedule.length)

        }
    },
    async mounted() {

        const data  = await recommended()

        if(data?.data) {
            await this.setAnimationProfiles(data.data)
        }

        this.buildAnimations()

        this.unwatch = this.$watch('notifications', async data => {

            if( ! data.length)
                return

            if( ! this.hasOrganicEvent(data))
                return

            this.clearAnimations()

            await this.buildAnimations()

        })

    },
    beforeUnmount() {
        this.schedule.forEach(timeout => clearTimeout(timeout))
        this.schedule = []
        if(this.unwatch) {
            this.unwatch()
        }
    }
}
</script>