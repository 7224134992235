<template>
        <Modal :closable="false" class="modal modal--mob modal--registration">
            <template #header>

                <div class="u-text-center">
                    <h1>{{$t('signup.verify_email.one_more_step')}}</h1>
                    <p>
                        {{$t('signup.verify_email.almost_there')}} <strong>{{auth.user.email}}</strong>
                        {{$t(`signup.verify_email.you_need.${theme}`)}}
                    </p>
                </div>
            </template>
            <template #body>
                <a :href="`https://mail.google.com?authuser=${auth.user.email}`" v-if="auth.user.email.includes('@gmail.com')" class="button button--primary w-100 mb-">{{$t('signup.open_gmail')}}</a>
                <button class="button button--black w-100 mb-" @click="$emit('success')">{{$t('signup.verify_email.did_not_receive_email')}}</button>
                <button @click="logoutHandler" class="button button--gray w-100">{{$t('auth.logout')}}</button>
                <ul>
                    <li>
                        {{$t('verify.minutes')}}
                    </li>
                    <li>
                        {{$t('verify.spam')}}
                    </li>
                    <li>
                        {{$t(`verify.remember.${theme}`)}}
                    </li>
                </ul>
            </template>
        </Modal>
</template>

<script>

import Modal from "@/components/generic/Modal";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { mapState, mapActions } from "vuex";
import { pushDataLayer } from "@/service/gtm";
import { GTM_EVENT_VERIFY_MODAL_VIEW } from "@/service/gtm/types";
import { logout } from "@/service/auth";

export default {
    name: "PreActionEmail",
    components: {Modal},
    mixins: [AuthenticatedMixin],
    data: () => ({
        show: false,
        theme: process.env.VUE_APP_THEME
    }),
    computed: {
        ...mapState({
            emailVerified: state => state.authenticate.auth.user.email_verified_at
        })
    },
    watch: {
        emailVerified: function(value) {
            if(value) {
                this.remove('email')
            }
        }
    },
    methods: {
        ...mapActions({
            remove: "profile/remove"
        }),
        async logoutHandler() {
            await logout()
            await this.$router.push({name: 'login'})
        }
    },
    mounted() {
        pushDataLayer(GTM_EVENT_VERIFY_MODAL_VIEW, this.auth.user)
    }
}
</script>

<style scoped lang="scss">

    .button--black {
        letter-spacing: -0.74px;
    }


    h1 {
        font-size: 22px;
        margin-bottom: 30px;
        font-weight: 700;
        letter-spacing: -0.9px;
    }

    p {
        color: $color-type18;
        font-size: 16px;
        line-height: 1.2;
        margin: 0 auto 40px;
        max-width: 350px;
        text-align: center;

        strong {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #F5F5F5;
            height: 40px;
            border-radius: 20px;
            margin-top: 20px;
            margin-bottom: 20px;
            color: #000;
        }
    }

    ul {
        padding-left: 20px;
        margin-top: 30px;
        margin-bottom: 0;
    }

    li {
        padding: 8px 0;
        font-size: 14px;
        color: $color-type18;
        line-height: 1.4;
        letter-spacing: -0.57px;

        &:before {
            content: '•';
            color: black;
            font-size: 10px;
            vertical-align: sub;
            padding-right: 8px;
            vertical-align: middle;
            display: inline-block;
            margin-top: -5px;
        }
    }

    @media(max-width: 991px) {

        h1 {
            margin-bottom: 20px;
        }

        ul {
            padding-left: 0;
        }
    }

</style>