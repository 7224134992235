<template>
    <Modal class="modal modal--mob modal-radio-buttons">
        <template #header>
            <h2>{{$t('form_fields.body_type_label')}}</h2>
        </template>
        <template #body>
            <div v-for="type in this.bodyTypes.filter(e=>e.genders.includes(auth.user.gender))" :key="type.code" class="radiobox">
                <input class="form-check-input" type="radio" :id="type.code" :checked="value === type.value">
                <label @click="update(type.value)" class="radiobox__label" :for="type.code">
                    {{$t(`form_fields.body_type_options_${type.value}`)}}
                </label>
            </div>
        </template>
        <template #footer>
            <a @click="$emit('success')" class="skip">{{$t('credits.skip_for_now')}}</a>
        </template>
    </Modal>
</template>

<script>

import Modal from "@/components/generic/Modal";
import { bodyTypes } from "@/service/forms"
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";

export default {
    name: "OnDemandBodyType",
    mixins: [AuthenticatedMixin],
    props: {
        value: {
            type: [Number, null],
            required: true
        }
    },
    components: { Modal },
    data() {
        return {
            bodyType: null,
            working: false
        }
    },

    methods: {

        update(value) {

            this.$emit('set', {
                body_type: value
            })

        }

    },

    created() {
        this.bodyTypes = bodyTypes
    },
    mounted() {
        this.bodyType = this.auth.user.body_type
    }
}
</script>