import axios from "axios";

export const getOrCreate = user_id => {
    return axios.post('conversation', {user_id})
}

export const conversations = (query = null) => {
    return axios.get(`conversation${ query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const conversation = id => {
    return axios.get(`conversation/${id}`)
}

export const messages = (conversationID, query = null) => {
    return axios.get(`conversation/${conversationID}/messages${ query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const send = (conversationId, message, hash = '', item_id = null) => {

    const payload = {conversation_id: conversationId, message, hash}

    if(item_id) {
        payload.item_id = item_id
    }

    return axios.post(`message`, payload)
}

export const sendAttachment = (payload) => {
    return axios.post(`message`, payload)
}

export const read = message_id => {
    return axios.post('message/read', {message_id})
}

export const unread = () => {
    return axios.get('conversation/unread')
}

export const refund = id => {
    return axios.post(`conversation/${id}/refund`)
}