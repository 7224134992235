<template>
    <teleport to="#modals">
        <Modal class="modal modal--mob modal--recharge" :closable="true" @close="$emit('success')" :modalStyle="transaction?'':'background-color: #F5F5F5'">
            <template v-slot:body>
                <PaymentStatus v-if="transaction" :transaction="transaction" />
                <template v-else>

                    <Payment :paymentModal="true" :toggle="true" @success="successHandler" :promo="promo" />

                    <ul class="partners-link">
                        <li><img src="../../../assets/img/verified-members.svg" alt="verified"></li>
                        <li><img src="../../../assets/img/amazon.svg" alt="amazon"></li>
                        <li><img src="../../../assets/img/visa.svg" alt="visa"></li>
                        <li><img src="../../../assets/img/master.svg" alt="master"></li>
                    </ul>
                </template>
            </template>
        </Modal>
    </teleport>
</template>
<script>

import Modal from "@/components/generic/Modal.vue";
import Payment from "@/components/payment/Payment.vue";
import PaymentStatus from "@/components/payment/PaymentStatus.vue";
import { mapState } from "vuex";
import {getDefaultPromotion} from "@/service/promotions";

export default {
    name: "RechargeModal",
    components: {
       Modal, Payment, PaymentStatus
    },
    emits: ['success'],
    data() {
        return {
            transaction: null
        }
    },
    computed: {
        ...mapState({
            promo: state => getDefaultPromotion(state.promotions.promotions)
        })
    },
    methods: {
        successHandler(payload) {

            if(payload.payment_url) {
                return window.location.href = payload.payment_url
            }

            if(! payload.no_action) {

                this.transaction = payload

                setTimeout(() => {
                    this.$emit('success')
                }, 2000)

            }

        }
    }

}
</script>
<style lang="scss" scoped>
    .modal {
        text-align: center;

        h2.h4 {
            font-weight: 400;
            margin-bottom: 30px;
        }
    }

    .promotion-heading {
      background-color: #3CBF6C;
      padding: 15px 20px 35px;
      letter-spacing: -0.66px;
      color: #F5F5F5;
      text-transform: uppercase;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;

      span {
        display: flex;
        border-radius: 10px;
        background-color: #F5F5F5;
        color: #3CBF6C;
        letter-spacing: -0.49px;
        text-transform: uppercase;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        padding: 4px 10px;
      }
    }

    .divider {
      width: 100%;
      margin: 0 auto;
      background-color: #DEDEDE;
      height: 1px;
      margin-bottom: 10px;
    }

    .partners-link {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 40px;

        li {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        @media(max-width: 991px) {
            flex-wrap: nowrap;
            margin-bottom: 15px;
        }
    }
</style>

<style lang="scss">
    .modal--recharge {

        &.modal--mob .modal-header .close {
            display: block!important;
            top: 0!important;
        }

         .price__item {
            padding: 5px 10px;

            .price-box {
                font-size: 26px;
            }

            h3 {
                font-size: 16px!important;
            }

            .button--primary {
                font-size: 13px!important;
                height: 36px;
                padding: 0 10px;
                max-width: 44%;
            }
        }

        .payment-type {
            padding: 0;
            margin-bottom: 20px;
        }

        .status-box {
            padding: 0;

            article {
                border: none;
                box-shadow: none;
                padding: 30px 30px 0;

                &.is-active {
                    box-shadow: none;
                    border: none;
                }
            }
        }

        @media(max-width: 992px) {
            .price {
                padding: 0!important;
                margin-bottom: 30px;
            }
        }

        @media(min-width: 992px) {

            .heading {
                flex-direction: row!important;

                .best-value {
                    margin-right: 10px;
                    margin-top: 0!important;
                    position: relative;
                    bottom: auto;
                    top: 0;
                    left: 0;
                    transform: translate(0, 0);
                }
            }

            .price {
                margin-bottom: 40px;
                padding: 0;
            }

            .price > p {
                font-size: 16px;
            }

            .price__item {
                padding: 10px 20px;
                margin: 10px auto;
                width: 100%;
            }

            .price__boxes {
                display: block;
                margin-top: 20px;
            }

            .price__item-row {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:first-child {
                    h2 {
                        font-size: 14px;
                        margin-bottom: 0;
                    }
                }

                &:nth-child(2) {
                    flex-direction: row-reverse;

                    p {
                        font-size: 14px;
                        margin-bottom: 10px;
                    }

                    h3 {
                        font-size: 20px;
                    }
                }

                &:last-child {

                    h4 {
                        margin-bottom: 20px;
                    }
                }

                .divider {
                    display: none;
                }

                .button--primary {
                    width: auto;
                }

                .price-box {
                    line-height: 1;
                    align-items: center;
                    margin-bottom: 0;

                    br {
                        display: none;
                    }
                }
            }
        }
    }

</style>