<template>
    <div class="bubble-notification-modal">

        <div class="bubble-notification-modal__header">
            <div class="icon-wrapper">
                <svg version="1.2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 37 59"><defs><clipPath clipPathUnits="userSpaceOnUse" id="a"><path d="M-356-703h750V631h-750z"/></clipPath></defs><g clip-path="url(#a)"><path d="M16.7 58.8S4.8 56.9 1.9 46.3C-.9 36 3.3 30.2 7.6 25.5c4.3-4.7 12.9-11 9.1-24.5 0 0 17.2 9.3 10.3 33.6 0 0 5-3.9 4.3-10.9 0 0 5.9 9.1 5.7 18-.2 10.2-7.9 15.2-14 17.3 0 0 4.3-5.7-3.8-12-5.5-4.2-.9-14.5-.9-14.5S2.4 42 16.7 58.8z" style="fill:#bfa46a"/></g></svg>
            </div>

            <div class="img-wrapper">
                <img :src="getUserPhotoURL(bubble.payload.user)" alt="User Photo">
                <!-- <img src="@/assets/img/icons-left.svg" class="icons-left" alt="Left Icon">
                <img src="@/assets/img/icons-right.svg" class="icons-right" alt="Right Icon"> -->
            </div>
        </div>

        <!-- <img src="@/assets/img/match.png" class="category-img" alt="Match Img"> -->
        <div class="text">
            {{$t('notifications.perfect_match.title')}}
        </div>
        <h2>{{$t(`notifications.perfect_match.subtitle.${bubble.payload.user.gender === TYPE_GENDER_MALE ? 'male' : 'female'}`)}}</h2>
        <button @click="messageUser" :disabled="working" class="button button--primary">
            {{$t(`notifications.actions.contact_now.${bubble.payload.user.gender === TYPE_GENDER_MALE ? 'male' : 'female'}`)}}
        </button>
        <router-link class="button button--fourth" @click="$emit('close'); pushDataLayer(GTM_EVENT_ONLINE_PROFILE_CLICK_2, auth.user)" :to="{name: 'user', params: {id: bubble.payload.user.id}}">
            {{$t(`notifications.actions.check.${bubble.payload.user.gender === TYPE_GENDER_MALE ? 'male' : 'female'}`)}}
        </router-link>
    </div>
</template>

<script>
import { TYPE_GENDER_MALE } from "@/types/user";
import { getUserPhotoURL } from "@/service/user/profile";
import { getOrCreate } from "@/api/messages";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import {
    GTM_EVENT_PERFECT_MATCH_OPEN, GTM_EVENT_PERFECT_MATCH_CLICK_1, GTM_EVENT_ONLINE_PROFILE_CLICK_2
} from "@/service/gtm/types";
import { pushDataLayer } from "@/service/gtm";
import toastr from "toastr";
import { mapActions } from "vuex";

export default {
    name: "SearchMatch",
    mixins: [AuthenticatedMixin],
    props: {
        bubble: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            working: false
        }
    },
    methods: {
        pushDataLayer,
        getUserPhotoURL,
        ...mapActions({
            addPreAction: "profile/add"
        }),
        async messageUser() {
            this.working = true

            try {
                const { data } = await getOrCreate(this.bubble.payload.user.id)
                pushDataLayer(GTM_EVENT_PERFECT_MATCH_CLICK_1, this.auth.user)
                await this.$router.push({name: 'messages', params: {conversation: data.id}})
            } catch (e) {
                if(e?.response?.status === 406) {
                    await this.addPreAction('daily_limit')
                } else {
                    toastr.error(this.$i18n.t('credits.error_processing'))
                }
            }

            this.working = false

            this.$emit('close')
        }
    },
    created() {
        this.TYPE_GENDER_MALE                   = TYPE_GENDER_MALE
        this.GTM_EVENT_ONLINE_PROFILE_CLICK_2   = GTM_EVENT_ONLINE_PROFILE_CLICK_2
    },
    mounted() {
        pushDataLayer(GTM_EVENT_PERFECT_MATCH_OPEN, this.auth.user)
    }
}
</script>