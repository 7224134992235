import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { CACHE_KEY, CACHE_VALID_FOR_SECONDS } from "@/config/fingerprint";
import { getCookie, setCookie } from "@/service/cookie";
export const identifyUser = async ({id}) => {

    if(process.env.VUE_APP_ENVIRONMENT === 'local')
        return

    if( ! process.env.VUE_APP_FPJS_API_KEY || ! process.env.VUE_APP_FPJS_REGION) {
        return console.log('Identify service not set')
    }

    if( ! getCookie(CACHE_KEY) || parseInt(getCookie(CACHE_KEY)) !== parseInt(id)) {

        await createFingerprint(id)

        setCookie(CACHE_KEY, id, CACHE_VALID_FOR_SECONDS)

    }

}

const createFingerprint = async id => {

    return new Promise(resolve => {

        const fpPromise  = FingerprintJS.load({
            apiKey: process.env.VUE_APP_FPJS_API_KEY,
            scriptUrlPattern: [
                `${process.env.VUE_APP_FPJS_URL}/${process.env.VUE_APP_FPJS_BEHAVIOR_PATH}/${process.env.VUE_APP_FPJS_AGENT_DOWNLOAD_PATH}?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>`,
                FingerprintJS.defaultScriptUrlPattern
            ],
            endpoint: [
                `${process.env.VUE_APP_FPJS_URL}/${process.env.VUE_APP_FPJS_BEHAVIOR_PATH}/${process.env.VUE_APP_FPJS_GET_RESULT_PATH}?region=${process.env.VUE_APP_FPJS_REGION}`,
                FingerprintJS.defaultEndpoint,
            ]
        })

        fpPromise
            .then(fp => resolve(fp.get({linkedId: id})))

    })

}