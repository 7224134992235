<template>
    <article class="email-address-box">

        <div class="header-mob">
            <BackButton />
            <h1>{{$t('settings.email_settings')}}</h1>
        </div>
        <p>
            {{$t('settings.email.your_email')}} <br>
            {{$t('settings.email.account_and_site')}}
        </p>
        <Form ref="emailForm" :validation-schema="validationSchema" @submit="update">
            <div class="input-box">
                <p>{{$t('form_fields.email_label')}}</p>
                <Field name="email" v-model="email" type="text" class="input input--primary" :placeholder="auth.user.email" />
                <ErrorMessage class="text-error" name="email"/>
            </div>
            <div class="btn-wrapper">
                <button :disabled="email.length < 1" ref="save" class="w-100 button button--primary">{{$t('settings.save')}}</button>
            </div>
        </Form>
    </article>
</template>

<script>
import {updateProfile} from "@/service/user";
import { Form, Field, ErrorMessage } from 'vee-validate'
import { defineRule } from 'vee-validate';
import {required, email } from '@vee-validate/rules'
import toastr from "toastr";
import BackButton from "@/components/generic/BackButton";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";

export default {
    name: "Email",
    components: {BackButton, Form, Field, ErrorMessage},
    mixins: [AuthenticatedMixin],
    data() {
        return {
            email: '',
            validationSchema: {}
        }
    },
    created() {
        defineRule('required', required)
        defineRule('email', email)

        this.validationSchema = {
            email: 'required|email',
        }
    },
    methods: {

        async update() {

            this.$refs.save.disabled = true

            try {
                await updateProfile({email: this.email})
                toastr.success(this.$i18n.t('settings.email.changed_successfully'))

            } catch (e) {
                console.log(e.response.data && e.response.data.errors)

                if(e.response.data && e.response.data.errors) {
                    this.$refs.emailForm.setErrors(e.response.data.errors)
                } else {
                    toastr.error(this.$i18n.t('credits.error_processing'))
                }

            }

            this.$refs.save.disabled = false

        }
    }
}
</script>

<style scoped lang="scss">

@import "../../assets/styles/components/_components.settings.scss";

.email-address-box {
    
    p {
        margin-top: 30px;
        font-size: 16px;
        line-height: 20px;
        color: $color-type10;
    }

    h1 {
        font-size: 22px;
        line-height: 1.3;
        font-weight: 700;
    }

    form {
        margin-top: 40px;
    }

    .btn-wrapper {
        margin-top: 20px;

        @media(max-width: 500px) {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: $color-type3;
            padding: 10px 20px;
            border-radius: 10px 10px 0 0;
            .button {
                width: 100%;
                margin-left: 0!important;
            }
        }
    }

    @media(max-width: 991px) {
        position: fixed;
        left: 0;
        top: 0;
        padding: 20px 20px 70px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        overflow: auto;
    }
}
</style>