<template>
    <Modal class="modal modal--mob modal-radio-buttons">
        <template #header>
            <h2>{{$t('form_fields.hair_color_label')}}</h2>
        </template>
        <template #body>
            <div v-for="h in hairColors" :key="h.code" class="radiobox">
                <input class="form-check-input" type="radio" :id="h.code" :checked="value === h.value">
                <label @click="update(h.value)" class="radiobox__label" :for="h.code">
                    {{$t(`form_fields.hair_color_options_${h.value}`)}}
                </label>
            </div>
        </template>
        <template #footer>
            <a @click="$emit('success')" class="skip">{{$t('credits.skip_for_now')}}</a>
        </template>
    </Modal>
</template>

<script>

import Modal from "@/components/generic/Modal";
import { hairColor } from "@/service/forms"
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";

export default {
    name: "OnDemandHairColor",
    mixins: [AuthenticatedMixin],
    components: { Modal },
    props: {
        value: {
            type: [Number, null],
            required: true
        }
    },
    data() {
        return {
            hairColor: null,
            timeout: null,
            working: false
        }
    },

    methods: {

        update(value) {
            this.$emit('set', {
                hair_color: value
            })

        }

    },

    created() {
        this.hairColors = hairColor
    },
    mounted() {
        this.hairColor = this.auth.user.hair_color
    },
    beforeUnmount() {
        clearTimeout(this.timeout)
    }
}
</script>