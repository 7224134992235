<template>
    <div class="search-box" :class="{'is-active': showResults && (results.length || (recent && recent.length))}">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.167 15.167" width="12.167"><g fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.167" data-name="Icon feather-map-pin"><path d="M11.583 6.311c0 4.455-5.5 8.273-5.5 8.273s-5.5-3.818-5.5-8.273a5.617 5.617 0 0 1 5.5-5.728 5.617 5.617 0 0 1 5.5 5.728Z" data-name="Path 24"/><path d="M7.917 6.31a1.872 1.872 0 0 1-1.834 1.908A1.872 1.872 0 0 1 4.25 6.31a1.872 1.872 0 0 1 1.833-1.909A1.872 1.872 0 0 1 7.917 6.31Z" data-name="Path 25"/></g></svg>
        <input :tabindex="index" @click="focusHandler" @focusout="hide" @focusin="focusHandler" v-model="term" @keydown="handle" autocomplete="new-password" class="input input--primary" :class="rounded === 'rounded'?'rounded':''" type="text" :placeholder="placeholder" />
        <slot :items="items" :location="location" :set="set">
            <transition name="fade">
                <div class="autocomplete-dropdown" v-if="active">
                    <div v-if="location?.location" class="current-location">
                        <h3>{{$t('profile.current_location')}}</h3>
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 12.167 15.167"><g data-name="Icon feather-map-pin" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.167"><path data-name="Path 24" d="M11.583 6.311c0 4.455-5.5 8.273-5.5 8.273s-5.5-3.818-5.5-8.273a5.617 5.617 0 0 1 5.5-5.728 5.617 5.617 0 0 1 5.5 5.728Z"/><path data-name="Path 25" d="M7.917 6.31a1.872 1.872 0 0 1-1.834 1.908A1.872 1.872 0 0 1 4.25 6.31a1.872 1.872 0 0 1 1.833-1.909A1.872 1.872 0 0 1 7.917 6.31Z"/></g></svg>
                            <i>{{location.location}}</i>
                        </p>
                    </div>
                    <div v-if="items.filter(e => e.type === 'search').length" class="autocomplete-dropdown__results">
                        <h3>{{$t('search.results')}}</h3>
                        <div class="autocomplete-dropdown__item" :class="key === pointer ? 'selected' : ''" @click="set(result)" v-for="(result, key) in items.filter(e => e.type === 'search')" :key="result.place_id">
                            <div style="width:calc(100% - 20px)">
                                <span>
                                    {{result.description}}
                                </span>
                            </div>
                            <svg style="min-width: 10px;" xmlns="http://www.w3.org/2000/svg" width="10" height="10"><g data-name="Icon feather-arrow-up-left"><path data-name="Path 28" d="M9.167 10a.831.831 0 0 1-.589-.244L.244 1.423A.834.834 0 0 1 1.423.244l8.333 8.333A.833.833 0 0 1 9.167 10Z"/><path data-name="Path 29" d="M.833 10A.833.833 0 0 1 0 9.167V.833A.833.833 0 0 1 .833 0h8.333a.833.833 0 0 1 0 1.667h-7.5v7.5A.833.833 0 0 1 .833 10Z"/></g></svg>
                        </div>
                    </div>
                    <div v-if="items.filter(e => e.type === 'history').length" class="autocomplete-dropdown__recent-results">
                        <h3>{{$t('search.recent')}}</h3>

                        <div @click="set(item)" v-for="(item, key) in items.filter(e => e.type === 'history')" :key="key" :class="key + items.filter(e => e.type === 'search').length === pointer ? 'selected' : ''" class="autocomplete-dropdown__item">
                            <div style="width:calc(100% - 20px)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" style="margin-left: -3px;" viewBox="0 0 21 18"><path data-name="Icon material-history" d="M12 0a9 9 0 0 0-9 9H0l3.89 3.89.07.14L8 9H5a7.034 7.034 0 1 1 2.06 4.94l-1.42 1.42A9 9 0 1 0 12 0Zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V5Z"/></svg>
                                <span>{{item.location}}</span>
                            </div>
                            <svg style="min-width: 10px;" xmlns="http://www.w3.org/2000/svg" width="10" height="10"><g data-name="Icon feather-arrow-up-left"><path data-name="Path 28" d="M9.167 10a.831.831 0 0 1-.589-.244L.244 1.423A.834.834 0 0 1 1.423.244l8.333 8.333A.833.833 0 0 1 9.167 10Z"/><path data-name="Path 29" d="M.833 10A.833.833 0 0 1 0 9.167V.833A.833.833 0 0 1 .833 0h8.333a.833.833 0 0 1 0 1.667h-7.5v7.5A.833.833 0 0 1 .833 10Z"/></g></svg>
                        </div>
                    </div>
                </div>
            </transition>
        </slot>
    </div>
</template>

<script>

export default {
    name: "SearchLocation",
    props: {
        placeholder: {
            type: String,
            default: "Enter your address"
        },
        class: {
            type: String,
            default: ''
        },
        location: {
            type: Object
        },
        recent: {
            type: Array,
            default: () => []
        },
        rounded: {
            type: String
        },
        index: {
            type: Number,
            default: -1
        }
    },
    emits: ['active', 'locationUpdated', 'focus'],
    data() {
        return {
            geocoder: null,
            autocomplete: null,
            payload: null,
            timeout: null,
            term: '',
            tmp: '',
            results: [],
            showResults: false,
            closeTimeout: null,
            pointer: -1
        }
    },
    computed: {
        items() {
            return [
                ...this.results.map(e => ({...e, type: 'search'})),
                ...this.recent.map(e => ({...e, type: 'history'}))
            ]
        },
        active() {
            return this.showResults && this.items.length
        }
    },
    watch: {
        location: {
            handler(value) {
                if(value && value.location) {
                    this.payload = value
                    this.term = value.location
                }
            }
        },
        active(value) {
            this.$emit('active', !! value);
        }
    },
    methods: {
        reset() {
            this.payload = null
            this.results = []
            this.$emit('locationUpdated', this.payload)
        },
        getGoogleLocationData: function getGoogleLocationData(obj, type, name) {
            return obj.reduce((result, item) => {
                return item.types.includes(type) ? item[name] : result;
            }, '');
        },
        hide() {

            this.showResults = false;
            this.pointer = -1

            if( ! this.payload) {
                return this.term = ''
            }

            clearTimeout(this.closeTimeout)
            this.closeTimeout = setTimeout(() => {
                if(this.term !== this.payload?.location) {
                    this.term = this.payload.location
                }
            }, 100)

            this.$emit('focus', false)
        },

        focusHandler() {
            this.term = ''
            this.results = []
            this.showResults = true
            this.$emit('focus', true)
        },

        handle(e) {

            // Tab key
            if(e.keyCode === 9) {
                return
            }

            // Enter key
            if(e.keyCode === 13) {

                if( ! this.items[this.pointer])
                    return

                if(this.items[this.pointer].type === 'search') {
                    return this.set(this.items[this.pointer])
                }

                return this.set(this.items[this.pointer])
            }

            // Arrow up key
            if(e.keyCode === 38) {
                if(this.pointer - 1 < 0) {
                    return this.pointer = this.results.length + this.recent.length - 1
                }
                return this.pointer--
            }

            // Arrow down key
            if(e.keyCode === 40) {

                if( ! this.showResults)
                    this.showResults = true

                if(this.pointer + 2 > this.results.length + this.recent.length) {
                    return this.pointer = 0
                }
                return this.pointer++
            }

            this.pointer = -1

            this.search()

        },

        search() {

            clearTimeout(this.timeout)

            this.timeout = setTimeout(() => {

                this.autocomplete.getPlacePredictions({
                    input: this.term,
                    types: ['(cities)'],
                    fields: ['address_components', 'geometry.location', 'place_id', 'formatted_address'],
                }, (predictions, status) => {
                    if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
                        this.results = []
                        this.showResults = false
                        return
                    }
                    this.results = predictions
                    this.showResults = true
                })

            }, 400)

        },

        readData(place) {

            return {
                location: `${
                    this.getGoogleLocationData(place.address_components, 'locality', 'long_name') ||
                    this.getGoogleLocationData(place.address_components, 'administrative_area_level_3', 'long_name')
                }, ${this.getGoogleLocationData(place.address_components, 'country', 'long_name')}`,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                city: this.getGoogleLocationData(place.address_components, 'locality', 'long_name') ||
                    this.getGoogleLocationData(place.address_components, 'administrative_area_level_3', 'long_name'),
                state: this.getGoogleLocationData(place.address_components, 'administrative_area_level_1', 'long_name') ||
                    this.getGoogleLocationData(place.address_components, 'administrative_area_level_2', 'long_name'),
                country: this.getGoogleLocationData(place.address_components, 'country', 'short_name'),
                country_long: this.getGoogleLocationData(place.address_components, 'country', 'long_name')
            }

        },

        setPayload(payload) {
            this.payload = payload

            this.term = this.payload.location
            this.$emit('locationUpdated', this.payload)
            this.showResults = false
            this.pointer = -1
            this.$emit('focus', false)
        },

        async set(item) {

            if(item.type === 'search') {

                const { results } = await this.geocoder.geocode({placeId: item.place_id})
                if( ! results || ! results.length)
                    return

                return this.setPayload(this.readData(results[0]))

            }

            return this.setPayload(item)

        }
    },
    created() {
        if(this.location && this.location.location) {
            this.payload = this.location
            this.term = this.payload.location
        }
        this.geocoder = new window.google.maps.Geocoder()
        this.autocomplete = new window.google.maps.places.AutocompleteService()
    },
}
</script>

<style scoped lang="scss">

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }


    .search-box {
        position: relative;

        >svg {
            position: absolute;
            left: 15px;
            top: 10px;

            @media(max-width: 991px) {
                left: 12px;
            }
        }

        .input--primary {
            font-size: 18px;
            letter-spacing: -0.74px;
        }

        &.is-active {
            input {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .autocomplete-dropdown {
        position: absolute;
        left: 0;
        top: 100%;
        margin-top: -1px;
        background-color: $color-type1;
        // border: 1px solid $color-type5;
        width: 100%;
        padding: 10px;
        z-index: 99;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        h3 {
            margin-bottom: 15px;
            font-size: 14px;
            font-weight: 700;
        }
    }

    .autocomplete-dropdown__item {
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: all .3s ease;
        white-space: nowrap;
        margin-bottom: 10px;

        >div {
            display: flex;
            align-items: center;

            >svg {
                margin-right: 5px;
                min-width: 15px;
            }
        }


        span {
            display: inline-block;
            align-items: center;
            width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            line-height: 1.3;

        }

        &:hover,
        &.selected {
            color: #bfa46a;

            >svg {

                path {
                    fill: #bfa46a;
                }
            }
        }
    }

    .autocomplete-dropdown__recent-results {
        margin-top: 20px;
    }


    .input--secondary {
        border-radius: 6px;
    }

    .rounded {
        padding-left: 37px;
        height: 48px;
        border: 1px solid $color-type5;
        background-color: $color-type1;
        font-size: 14px;
    }

    .current-location {

        h3 {
            margin-bottom: 10px;
            color: #959595;
        }
        p {
            font-size: 18px;
            display: flex;
            align-items: center;
            padding-left: 0;

            svg {
                margin-right: 5px;
            }
        }
    }

    .autocomplete-dropdown__results {
        margin-top: 20px;
    }
</style>