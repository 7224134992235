<template>
    <div class="tab-section">
        <ContentLoader v-if="loading"/>
        <article class="no-data" v-if="!loading && !favorites.length">
            <div class="img-wrapper">
                <img alt="Image" width="100" height="100" src="@/assets/img/no_favorites.svg">
            </div>
            <h1>{{$t('matches.favorites.title')}}</h1>
            <p class="u-text-center">{{$t('matches.favorites.text')}}</p>
            <router-link class="button button--primary" :to="{name: 'search'}">{{$t('matches.search')}}</router-link>
        </article>
        <article class="list">
            <div v-if="favorites.length">
                <transition-group appear name="fade-in" tag="ul">
                    <li v-for="(f, index) in favorites" :key="f.id"  :style="'--animate:' + index">
                        <router-link :to="{name: 'user', params: {id: f.id}}">
                            <div class="user-info">
                                <div class="img-wrapper">
                                    <Image alt="Profile photo" :src="getUserPhotoURL(f, 'small')" :blurred="!! f.profile_photo?.private && ! access.includes(f.id)"/>
                                </div>
                                <OnlineStatus class="active-user" v-if="isOnline(f)" />
                                <p class="mb0">
                                    <span class="o-flex o-flex--center">
                                        <Premium v-if="f.premium" />
                                        <Verified v-if="f.profile_verified_at" />
                                        <strong>{{f.username}}</strong>
                                    </span>
                                    <span>
                                        {{f.address}}
                                    </span>
                                </p>
                            </div>
                        </router-link>
                        <Favorite :profile="f" />
                    </li>
                </transition-group>
            </div>
        </article>
        <Pagination @page-click="load" :info="pagination" />
    </div>
</template>

<script>
import Pagination from "@/components/generic/Pagination";
import { getFavorites } from "@/api/matches/favorites";
import { getUserPhotoURL, isOnline } from "@/service/user/profile";
import Favorite from "@/components/search/Favorite";
import { getPaginationPayload } from "@/service/general";
import ContentLoader from "@/components/ContentLoader";
import OnlineStatus from "@/components/user/OnlineStatus";
import Premium from "@/components/generic/Premium.vue";
import Verified from "@/components/generic/Verified.vue";
import Image from "@/components/generic/Image.vue";
import {mapState} from "vuex";

export default {
    name: "Favorites",
    components: {Image, Verified, Premium, Pagination, Favorite, ContentLoader, OnlineStatus},
    data() {
        return {
            loading: false,
            favorites: [],
            pagination: null
        }
    },
    computed: {
        ...mapState({
            access: state => state.access.available
        })
    },
    methods: {
        getUserPhotoURL,
        isOnline,
        async load(page = 1) {
            this.loading = true
            this.favorites = []
            const { data } = await getFavorites({page: page})
            this.pagination = getPaginationPayload(data)

            this.favorites = data.data.map(e => {
                return {
                    ...e,
                    my_favorite: 1
                }
            })
            this.loading = false
        },
    },
    mounted() {
        this.load()
    }
}
</script>

<style scoped lang="scss">
    main {
        height: auto;
        min-height: 100%!important;

    }
    // @import "../../assets/styles/components/_components.page-tab-content.scss";
</style>