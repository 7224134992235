<template>
    <Modal class="modal modal--mob modal--registration" @close="$emit('success')">
        <template #body>
            <article>
                <svg class="main-icon" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 32 32"><g data-name="Group 121"><path data-name="Polygon 2" d="M14.51 1.665a2 2 0 0 1 2.98 0l1.268 1.417a2 2 0 0 0 2.018.6l1.821-.5a2 2 0 0 1 2.5 1.606l.315 1.924a2 2 0 0 0 1.366 1.578l1.834.587a2 2 0 0 1 1.23 2.69l-.776 1.819a2 2 0 0 0 .294 2.055l1.251 1.523a2 2 0 0 1-.421 2.923l-1.614 1.1a2 2 0 0 0-.861 1.894l.236 1.956a2 2 0 0 1-1.943 2.24l-1.9.041a2 2 0 0 0-1.762 1.137l-.83 1.737a2 2 0 0 1-2.858.838l-1.6-.994a2 2 0 0 0-2.106 0l-1.6.994a2 2 0 0 1-2.858-.838l-.83-1.737a2 2 0 0 0-1.762-1.137l-1.9-.041a2 2 0 0 1-1.943-2.24l.236-1.956a2 2 0 0 0-.861-1.894l-1.614-1.1a2 2 0 0 1-.421-2.923l1.242-1.524a2 2 0 0 0 .294-2.055l-.776-1.819a2 2 0 0 1 1.23-2.69l1.833-.586a2 2 0 0 0 1.364-1.582L6.9 4.784a2 2 0 0 1 2.5-1.606l1.821.5a2 2 0 0 0 2.018-.6Z" fill="#2c7ddb"/><path data-name="Icon metro-checkmark" d="m21.668 9.461-8.165 8.163-3.81-3.81-2.719 2.722 6.529 6.531 10.886-10.885Z" fill="#fff"/></g></svg>
                <h2>{{$t('profile.verification.get_verified')}}</h2>
                <p>{{$t('verify.verify_modal.simple_and_fast')}}!</p>
                <ul>
                    <li>
                        <svg width="22" viewBox="0 0 24 24" xml:space="preserve"><path fill="#000000" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                        {{$t(`verify.verify_modal.verified.verified_badge`)}}
                    </li>
                    <li>
                        <svg width="22" viewBox="0 0 24 24" xml:space="preserve"><path fill="#000000" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                        {{$t(`verify.verify_modal.verified.premium_listing`)}}
                    </li>
                    <li>
                        <svg width="22" viewBox="0 0 24 24" xml:space="preserve"><path fill="#000000" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                        {{$t(`verify.verify_modal.verified.profile_promotions`)}}
                    </li>
                    <li>
                        <svg width="22" viewBox="0 0 24 24" xml:space="preserve"><path fill="#000000" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                        {{$t(`verify.verify_modal.verified.more_money`)}}
                    </li>
                    <li>
                        <svg width="22" viewBox="0 0 24 24" xml:space="preserve"><path fill="#000000" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                        {{$t(`verify.verify_modal.verified.attract_sugardaddies.${theme}`)}}
                    </li>
                    <li>
                        <svg width="22" viewBox="0 0 24 24" xml:space="preserve"><path fill="#000000" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                        {{$t(`verify.verify_modal.verified.unlimited_messaging`)}}
                    </li>
                </ul>
                <button @click="handler" class="button button--man mb-">{{ucFirst($t('verify.verify_modal.get_verified'))}}</button>
                <button @click="stayHandler" class="button button--fourth w-100">{{ucFirst($t('verify.verify_modal.stay_basic'))}}</button>
            </article>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal";
import ThemeMixin from "@/mixins/ThemeMixin";
import { ucFirst } from "@/utilities/general";
import { pushDataLayer } from "@/service/gtm";
import { GTM_EVENT_GET_VERIFIED_MODAL, GTM_EVENT_GET_VERIFIED_MODAL_GET_VERIFIED, GTM_EVENT_GET_VERIFIED_MODAL_STAY } from "@/service/gtm/types";
import { setAsDisplayed } from "@/service/popups";
import { VERIFIED_MODAL_KEY, VERIFIED_MODAL_SILENCE_DURATION} from "@/service/popups/config";

export default {
    name: "PreActionVerified",
    mixins: [ThemeMixin],
    components: {Modal},
    methods: {
        ucFirst,
        handler() {
            this.$router.push({name: "verification"})
            this.$emit('success')
            pushDataLayer(GTM_EVENT_GET_VERIFIED_MODAL_GET_VERIFIED, this.$store.state.authenticate.auth.user)
        },
        stayHandler() {
            this.$emit('success')
            pushDataLayer(GTM_EVENT_GET_VERIFIED_MODAL_STAY, this.$store.state.authenticate.auth.user)
        }
    },
    mounted() {
        pushDataLayer(GTM_EVENT_GET_VERIFIED_MODAL, this.$store.state.authenticate.auth.user)
        setAsDisplayed(VERIFIED_MODAL_KEY, VERIFIED_MODAL_SILENCE_DURATION)
    }
}
</script>

<style scoped lang="scss">

    article {
        text-align: center;
    }

    .box {
        width: 50%;
        margin: 5px;
        border-radius: 16px;
        box-shadow: 0 0 5px rgba(0,0,0,0.4);
        background-color: $color-type1;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .main-icon {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%,-50%);
        }
    }

    h2 {
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    ul {
        margin-bottom: 30px;
        display: inline-block;

        li {
            line-height: 2;
            font-size: 16px;
            font-family: 'Open Sans',sans-serif;
            display: flex;
            align-items: center;

            svg {
                margin-right: 7px;
            }
        }
    }

    .button--man {
        height: $button1-height;
        $button1-font-size: 16px;
        border-radius: $button1-radius;
        font-weight: 600;
        width: 100%;
    }

    @media(max-width: 768px) {

        h2 {
            font-size: 16px;
        }

        li {
            font-size: 14px;
            line-height: 1.5;
        }
    }

    @media(max-width: 600px) {
        h2 {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

</style>