<template>
    <div>
        <Header v-if="!logged" @request-register="$emit('request-register')" />
        <main>
            <div class="o-container">
                <section class="imprint">
                    <div class="header-mob">
                        <router-link :to="{name: 'search'}" class="back-mob"><svg xmlns="http://www.w3.org/2000/svg" width="14.004" height="14.001"><path data-name="Union 6" d="M6.289 13.708.262 7.669a1 1 0 0 1 .03-1.379l6-6a1 1 0 0 1 1.417 0 1 1 0 0 1 0 1.413l-4.3 4.295H13a1 1 0 0 1 1 1 1 1 0 0 1-1 1H3.41l4.292 4.3a1 1 0 0 1 0 1.412A1 1 0 0 1 7 14a1 1 0 0 1-.711-.292Z"/></svg></router-link>
                        <h1>{{$t('general.imprint.imprint')}}</h1>
                    </div>
                    <article>
                        <h1 class="heading-mob"><strong>{{$t('general.imprint.imprint')}}</strong></h1>
                        <h2>{{$t('general.imprint.provider_address')}}:</h2>
                        <p>Maiatsaco Ltd, Zodias 6, 2281 Latsia, Cyprus</p>
                        <h2>{{$t('general.imprint.contact')}}:</h2>
                        <p>Email: office@sugardaddy.date</p>
                        <h2>{{$t('general.imprint.sales_tax')}}:</h2>
                        <p>CY10338387G</p>
                        <h2>{{$t('general.imprint.registration_number')}}:</h2>
                        <p>He338387</p>
                        <h2>{{$t('general.imprint.registration_location')}}:</h2>
                        <p>Cyprus</p>
                        <h2>{{$t('general.imprint.managing_director')}}:</h2>
                        <p>Artem Simonian</p>
                    </article>
                </section>
            </div>
        </main>
    </div>
</template>
<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import Header from "@/components/Header";
export default {
    name: "Imprint",
    mixins: [AuthenticatedMixin],
    components: {Header}
}
</script>
<style scoped lang="scss">

main {
    height: auto;
    min-height: 100%;

    @media(max-width: 991px) {
        padding-top: 30px;
    }
}

.header-mob {
    display: none;

    @media(max-width: 500px) {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        h1 {
            margin-bottom: 0!important;
            font-size: 22px!important;
            font-weight: 700!important;
        }

        .back-mob {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            margin-right: 10px;
        }
    }
}

.imprint {
    padding: 60px 100px;
    border-radius: 10px;
    background-color: #fff;
    height: auto;
    overflow: hidden;

    h1 {
        margin-bottom: 40px;
        font-size: 30px;
    }

    h2 {
        margin-bottom: 10px;
    }

    p {
        font-size: 14px;
    }

    @media(max-width: 991px) {
        padding: 30px 0;
        margin-top: 0;

        article {
            padding: 0 10px;
        }
    }

    .heading-mob {
        @media(max-width: 500px) {
            display: none;
        }
    }
}

</style>