import i18n from "@/i18n";
import moment from "moment";

const hoursOnline = 10
const hoursNew = 48

/**
 *
 * @param dateString
 * @returns <String>
 */
export const lastSeenSince = (dateString) => {

    const diff = moment.duration(moment.utc().diff(moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss')))
    const years = Math.floor(diff.asYears())
    const months = Math.floor(diff.asMonths())
    const days = Math.floor(diff.asDays())
    const hours = Math.floor(diff.asHours())

    if(years > 0) {
        return i18n.global.t(`general.time_since_year${years === 1 ? '' : 's'}`, ['',years])
    }

    if(months > 0) {
        return i18n.global.t(`general.time_since_month${months === 1 ? '' : 's'}`, ['',months])
    }

    if(days > 0) {
        return i18n.global.t(`general.time_since_day${days === 1 ? '' : 's'}`, ['',days])
    }

    if(hours > hoursOnline) {
        return i18n.global.t(`general.time_since_hour${hours === 1 ? '' : 's'}`, ['',hours])
    }

    return i18n.global.t(`general.time_since_online_now`)

}

export const isOnline = user => user?.last_activity && moment.utc().diff(moment.utc(user.last_activity, 'YYYY-MM-DD HH:mm:ss'), 'hours') <= hoursOnline

export const getUserPhotoURL = (user, size = "medium") => {

    return user.profile_photo?.source ?
        user.profile_photo.source[size] :
        require(`@/assets/img/avatar-${user.gender === 0 ? 'male' : 'female'}.svg`)

}

export const isNew = user => moment.utc().diff(moment.utc(user.created_at, 'YYYY-MM-DD HH:mm:ss'), 'hours') <= hoursNew
