<template>
    <div class="wrapper">
        <svg viewBox="0 0 100 100">
            <path y="50" :class="animationClass" class="lock-top" d="M64,50V18.7C64,12,58.9,6.6,52.6,6.6h-3.5c-6.3,0-11.3,5.4-11.3,12.1v25.9"/>
            <circle class="lock-outline" cx="50.9" cy="65.4" r="27" />
            <path :class="animationClass" class="lock-body" d="M50.9,41.4c-13.2,0-24,10.7-24,24c0,13.2,10.7,24,24,24c13.2,0,24-10.7,24-24C74.9,52.2,64.1,41.4,50.9,41.4z M56.2,61.9
            c-1.1,1.5-1.3,3-1.3,4.8c0.1,3,0.1,6.1,0,9.1c-0.1,2.8-1.6,4.4-4,4.5c-2.5,0.1-4.3-1.6-4.5-4.4c-0.1-1.9,0-3.9,0-5.8c0,0,0,0,0,0
            c0-1.4,0.1-2.8,0-4.2c-0.2-1.3-0.5-2.7-1.2-3.8c-1.5-2.7-1.1-6.3,1.1-8.3c2.4-2.2,6-2.3,8.6-0.2C57.3,55.5,58,59.2,56.2,61.9z"/>
            <path :class="animationClass" class="lock-spinner" d="M73.3,65.7c0,12.2-9.9,22.1-22.1,22.1">
                <animateTransform attributeType="xml"
                                attributeName="transform"
                                type="rotate"
                                from="0 50.9 65.4"
                                to="360 50.9 65.4"
                                dur="0.8s"
                                repeatCount="indefinite"/>
            </path>
        </svg>
    </div>
</template>
<script>

import { wait } from "@/utilities/general";
import { ANIMATION_COMPLETED } from "@/utilities/unlockAnimation";

export default {
    name: "UnlockAnimation",
    data() {
        return {
            queue: [],
            working: false,
            animationClass: 'loaded'
        }
    },
    props: {
        status: {
            type: Number,
            default: 0
        }
    },
    watch: {

        status(value) {
            console.log(value)
            this.queue = [
                ...this.queue,
                value
            ]
        },
        queue(value) {
            console.log('queue', [...value])
            this.process()
        }
    },
    methods: {
        async process() {

            if(this.working)
                return

            if( ! this.queue.length)
                return

            this.working = true

            const [status, ...rest] = this.queue

            this.queue = [
                ...rest
            ]

            await wait(0.5)

            this.setAnimationClass(status)

            if(status === ANIMATION_COMPLETED) {
                this.$emit('animated')
            }

            this.working = false

            await this.process()

        },
        setAnimationClass(value) {

            switch (value) {
                case 0:
                    this.animationClass = 'loaded'
                    break
                case 1:
                    this.animationClass = 'loading'
                    break
                default:
                    this.animationClass = ''
            }
        }
    }
}
</script>
<style lang="scss" scoped>

    .wrapper {
        width: 180px;
        height: 200px;
        margin: 0 auto;
    }
    .lock-top{
        fill: none;
        stroke: $color-type25;
        stroke-width: 5;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 300 300;
        stroke-dashoffset: -30;
        transition: all 300ms ease-out;
    }
    .lock-top.loaded {
        stroke-dashoffset: 0;
        stroke: #fa113d
    }
    .lock-top.loaded ~ .lock-body {
        fill: #fa113d;
    }
    .lock-top.loading {
        stroke: #000;
        opacity: 0.3;
        stroke-dashoffset: 0;
    }

    .lock-outline{
        fill: #fff;
    }
    .lock-body {
        fill: $color-type25;
        opacity: 1;
        transition: all 300ms ease-out;
    }
    .lock-body.loading{
        fill: #000 !important;
        opacity: 0.3;
    }

    .lock-spinner {
        fill: none;
        stroke: #000;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        opacity: 0;
        transition: opacity 200ms ease;
    }
    .lock-spinner.loading{
        opacity: 1;
    }
</style>