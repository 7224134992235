<template>

    <article v-if="!loaded" class="no-blocked-users">
        <ContentLoader />
    </article>
    <article class="list" v-if="users?.data?.length && loaded">
        <div class="header-mob">
            <router-link :to="{name: 'settings'}" class="back-mob"><svg xmlns="http://www.w3.org/2000/svg" width="14.004" height="14.001"><path data-name="Union 6" d="M6.289 13.708.262 7.669a1 1 0 0 1 .03-1.379l6-6a1 1 0 0 1 1.417 0 1 1 0 0 1 0 1.413l-4.3 4.295H13a1 1 0 0 1 1 1 1 1 0 0 1-1 1H3.41l4.292 4.3a1 1 0 0 1 0 1.412A1 1 0 0 1 7 14a1 1 0 0 1-.711-.292Z"/></svg></router-link>
            <h1>{{$t('users.blocked')}}</h1>
        </div>
        <div class="info-box">
            <div class="icon-wrapper o-flex o-flex--center o-flex--justify-center">
                <svg  class="mr-" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2M8.5 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM18 8l5 5M23 8l-5 5" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <h2 class="mb0">{{$t('settings.blocked_members.members_you_blocked')}}</h2>
            </div>
        </div>
        <ul>
            <li v-for="u in users.data" :key="u.id">
                <router-link :to="{name: 'user', params: {id: u.id}}">
                    <div class="user-info">
                        <div class="img-wrapper">
                            <img :src="getUserPhotoURL(u)" alt="user photo">
                        </div>
                        <p class="mb0">
                            <strong>{{u.username}},</strong> {{ u.age }} <br>
                            <span>{{u.address}}</span>
                        </p>
                    </div>
                </router-link>
                <a @click="openModal(u)" class="unblock clr-gold">
                    {{$t('block_report.unblock')}}
                </a>
            </li>
        </ul>
        <Pagination @page-click="load" :info="pagination" />
    </article>

    <article v-else-if="!users?.data?.length && loaded" class="no-blocked-users">
        <div class="header-mob">
            <router-link :to="{name: 'settings'}" class="back-mob"><svg xmlns="http://www.w3.org/2000/svg" width="14.004" height="14.001"><path data-name="Union 6" d="M6.289 13.708.262 7.669a1 1 0 0 1 .03-1.379l6-6a1 1 0 0 1 1.417 0 1 1 0 0 1 0 1.413l-4.3 4.295H13a1 1 0 0 1 1 1 1 1 0 0 1-1 1H3.41l4.292 4.3a1 1 0 0 1 0 1.412A1 1 0 0 1 7 14a1 1 0 0 1-.711-.292Z"/></svg></router-link>
            <h1>{{$t('users.blocked')}}</h1>
        </div>
        <div>
            <img src="@/assets/img/no-blocked-users.svg" alt="no blocked users"/>
            <h2>{{$t('block_report.seems')}}</h2>
            <p>{{$t('block_report.always')}}</p>
        </div>
    </article>
    <transition name="fade">
        <teleport to="#modals">
            <Modal class="modal modal--unblock-user modal--mob" @close="showUnblockModal = false;" :size="'small'" v-if="showUnblockModal">
                <template #header>
                    <h2>{{$t('block_report.are_you_sure_unblock')}}</h2>
                </template>
                <template #body>

                    <div class="user-info">
                        <div class="img-wrapper">
                            <img :src="getUserPhotoURL(user, 'small')" alt="user photo">
                        </div>
                        <p class="mb0">
                            <strong>{{user.username}},</strong> {{ user.age }} <br>
                            <span>{{user.address}}</span>
                        </p>
                    </div>

                    <button :disabled="working" @click="unblockUser(userId);" class="button button--primary w-100">{{$t('block_report.unblock')}}</button>
                </template>
            </Modal>
        </teleport>
    </transition>
</template>

<script>
import { blockedUsers } from "@/api/users";
import Pagination from "@/components/generic/Pagination";
import { getUserPhotoURL } from "@/service/user/profile";
import {getPaginationPayload} from "@/service/general";
import Modal from "@/components/generic/Modal";
import toastr from "toastr";
import ContentLoader from "@/components/ContentLoader";
import { unblockUser } from "@/service/user";

export default {
    name: "BlockedUsersPage",
    components: {ContentLoader, Pagination, Modal},
    data() {
        return {
            users: [],
            pagination: null,
            showUnblockModal:false,
            user: null,
            userId: null,
            working: false,
            loaded: false
        }
    },
    methods: {
        getUserPhotoURL,
        async load(page = 1) {
            try {
                const { data } = await blockedUsers({page: page})
                this.pagination = getPaginationPayload(data)
                this.users = data
            } catch (e) {
                console.log('Error loading blocked users', e)
            } finally {
                this.loaded = true
            }
        },
        async unblockUser(id) {

            this.working = true

            try {
                await unblockUser(id)
                this.showUnblockModal = false
                toastr.success(this.$i18n.t('block_report.user_unblocked'))
                await this.load()
            } catch(e) {
                toastr.error(this.$i18n.t('credits.error_processing'))
            } finally {
                this.working = false
            }


        },

        openModal(user) {
            this.user = user;
            this.userId = user.id;
            this.showUnblockModal = !this.showUnblockModal;
        }
    },
    mounted() {
        this.load()
    }
}
</script>


<style scoped lang="scss">
    @import "@/assets/styles/components/_components.settings.scss";

    .modal--unblock-user {
        .user-info {
            justify-content: center;
            margin-bottom: 30px;
            margin-top: 40px;
        }
    }

    .unblock {
        font-weight: 600;

        &:hover {
            color: #000!important;
        }
    }

    .no-blocked-users {
        text-align: center;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        img {
            margin-bottom: 20px;
        }

        h2 {
            margin-bottom: 20px;
            font-weight: 700;
            font-size: 28px;
            line-height: 1.3;
            max-width: 300px;
        }

        p {
            padding: 0;
            line-height: 1.4;
            text-align: center!important;
            max-width: 300px!important;
            font-size: 16px!important;
        }

        .header-mob {
            position: absolute;
            left: 20px;
            top: 0;
            display: none;
        }

        @media(max-width: 991px) {
            position: fixed;
            left: 0;
            top: 0;
            padding: 20px;
            width: 100%;
            height: 100%;
            background-color: #fff;
            justify-content: center;


          .header-mob {
                top: 20px;
                display: flex;
            }
        }

        .loader-wrapper {
            height: auto;
            min-height: 300px;
        }
    }
    .blocked-users {
        padding: 50px 0;
        text-align: center;
        display: flex;
        flex-direction: column;

        h1 {
            margin-bottom: 10px;
        }

        p {
            max-width: 680px;
            font-size: 18px;
            line-height: 1.6;
            margin:0 auto 30px;
            text-align: left;
        }

        @media(max-width: 991px) {
            padding: 20px 0 70px;

            .o-container {
                padding: 0 20px;
            }
        }
    }

    .list {
        margin: 0 auto;
        max-width: 680px;
        overflow: auto;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 4px 0;

            @media(max-width: 991px) {
                position: relative;

                &:before {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: calc(100% - 75px);
                    height: 1px;
                    background-color: #F5F5F5;
                    content:"";
                }
            }
        }

        .info-box {
            text-align: left;
            max-width: 680px;
            border-radius: 5px;
            padding: 35px 40px;
            background-color: #efefef;
            color: #000000;
            margin: 0 auto 40px;
            position: relative;

            h2 {
                font-size: 20px;
                line-height: 24px;
                font-weight: 600;
                margin-bottom: 0;
            }

            svg {
                min-width: 24px;
            }

            @media(max-width: 991px) {
                padding: 20px;
                margin: 0 auto 20px;

                 h2 {
                     font-size: 16px;
                     line-height: 1.3;
                 }
            }
        }

        @media(max-width: 991px) {
            position: fixed;
            left: 0;
            top: 0;
            padding: 20px 20px 70px;
            width: 100%;
            height: 100%;
            background-color: #fff;
        }
    }

    .user-info {
        display: flex;
        align-items: center;

        .img-wrapper {
            margin-right: 15px;
            overflow: hidden;
            border-radius: 50%;
            width: 80px;
            height: 80px;

            img {
                object-fit:cover;
                min-width: 100%;
                min-height: 100%;
            }

            @media(max-width: 991px) {
                width: 60px;
                height: 60px;
            }
        }

        p {
            text-align: left;
            color: #000;
            line-height: 1.5;

            @media(max-width: 991px) {
                font-size: 16px;
                line-height: 1.3;

                strong {
                    font-weight: 600;
                }

                span {
                    font-size: 14px;
                }
            }
        }
    }
</style>