<template>
    <div>
        <div class="review">
            <h2 class="mb-">{{theme}}</h2>
            <p>{{$t('payment.limited_offer_1.reviews', [reviews])}}</p>
            <div class="o-flex o-flex--center">
                <svg class="mr-" xmlns="http://www.w3.org/2000/svg" width="108" height="20"><g data-name="Group 440"><path fill="#00b67a" d="M88 0h20v20H88z" data-name="Rectangle 202"/><path fill="#00b67a" d="M66 0h20v20H66z" data-name="Rectangle 203"/><path fill="#00b67a" d="M44 0h20v20H44z" data-name="Rectangle 204"/><path fill="#00b67a" d="M22 0h20v20H22z" data-name="Rectangle 205"/><path fill="#00b67a" d="M0 0h20v20H0z" data-name="Rectangle 206"/><path fill="#fff" d="m93.625 17 1.75-5.25L91 8.25h5.25L98 3l1.75 5.25H105l-4.371 3.5-2.122 1.434 2.516-.238L102.374 17 98 13.5 93.625 17Z" data-name="Subtraction 10"/><path fill="#fff" d="m71.625 17 1.75-5.25L69 8.25h5.25L76 3l1.75 5.25H83l-4.371 3.5-2.122 1.434 2.516-.238L80.374 17 76 13.5 71.625 17Z" data-name="Subtraction 11"/><path fill="#fff" d="m49.625 17 1.75-5.25L47 8.25h5.25L54 3l1.75 5.25H61l-4.371 3.5-2.122 1.434 2.516-.238L58.374 17 54 13.5 49.625 17Z" data-name="Subtraction 12"/><path fill="#fff" d="m27.625 17 1.75-5.25L25 8.25h5.25L32 3l1.75 5.25H39l-4.371 3.5-2.122 1.434 2.516-.238L36.374 17 32 13.5 27.625 17Z" data-name="Subtraction 13"/><path fill="#fff" d="m5.625 17 1.75-5.25L3 8.25h5.25L10 3l1.75 5.25H17l-4.371 3.5-2.122 1.434 2.516-.238L14.374 17 10 13.5 5.625 17Z" data-name="Subtraction 14"/></g></svg>
                <p class="mb0">{{rating}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import ThemeMixin from "@/mixins/ThemeMixin";
export default {
    name: "Review",
    mixins: [ThemeMixin],
    data() {
        return {
            reviews: null,
            rating: null,
        }
    },

    mounted() {
        if(this.theme === 'sugardaddy') {
            this.reviews = "158"
            this.rating = "4.6"
        }else {
            this.reviews = "96"
            this.rating = "4.4"
        }
    }
}
</script>
<style lang="scss" scoped>
    .review {
        margin-right: 50px;

        h2 {
            font-weight: 700;
        }

        p {
            font-size: 12px;
            line-height: 19px;
            letter-spacing: 0.05px;
            color: #6A6A6A;
            margin-bottom: 5px;
        }

        >div {
            p {
                font-size: 14px;
            }
        }
    }
</style>