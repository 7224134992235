<template>
    <Modal @close="$emit('success')" class="modal modal--mob modal--registration">
        <template #body>
            <article>
                <svg class="main-icon" viewBox="0 0 50 50" width="50" xml:space="preserve"><circle cx="25" cy="25" r="25" fill="#cab17c"/><path d="m40.7 19.8-4.1 13.6c-.1.2-.3.4-.5.4H13.9c-.2 0-.5-.2-.5-.4L9.3 19.8c-.1-.3.1-.6.4-.7.2 0 .3 0 .5.1l8.3 5.3c.3.2.6.1.8-.2l5.3-9.6c.2-.3.5-.4.8-.2.1.1.2.1.2.2l5.3 9.6c.2.3.5.4.8.2l8.3-5.3c.3-.2.6-.1.8.2-.1.1-.1.2-.1.4z" fill-rule="evenodd" clip-rule="evenodd" fill="#fff"/></svg>
                <h2>
                    {{$t('credits.get_credits_short')}}
                </h2>
                <p>{{$t('credits.unlock')}}!</p>
                <ul>
                    <li>
                        <svg width="22" viewBox="0 0 24 24" xml:space="preserve"><path fill="#000000" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                        {{$t(`credits.premium_modal.with_credits.crown_badge`)}}
                    </li>
                    <li>
                        <svg width="22" viewBox="0 0 24 24" xml:space="preserve"><path fill="#000000" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                        {{$t(`credits.premium_modal.with_credits.premium_search`)}}
                    </li>
                    <li>
                        <svg width="22" viewBox="0 0 24 24" xml:space="preserve"><path fill="#000000" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                        {{$t(`credits.premium_modal.with_credits.profile_promotions`)}}
                    </li>
                    <li>
                        <svg width="22" viewBox="0 0 24 24" xml:space="preserve"><path fill="#000000" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                        {{$t(`credits.premium_modal.with_credits.more_sugarbabies`)}}
                    </li>
                    <li>
                        <svg width="22" viewBox="0 0 24 24" xml:space="preserve"><path fill="#000000" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                        {{$t(`credits.premium_modal.with_credits.unlock_galleries`)}}
                    </li>
                    <li>
                        <svg width="22" viewBox="0 0 24 24" xml:space="preserve"><path fill="#000000" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                        {{$t(`credits.premium_modal.with_credits.unlimited_messaging`)}}
                    </li>
                </ul>
                <button @click="buyClickHandler" class="button button--primary w-100 mb-">{{ucFirst($t('credits.premium_modal.buy_credits'))}}</button>
                <button @click="stayClickHandler" class="button button--fourth w-100">{{ucFirst($t('credits.premium_modal.stay_basic'))}}</button>
            </article>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal";
import ThemeMixin from "@/mixins/ThemeMixin";
import { ucFirst } from "@/utilities/general";
import { pushDataLayer } from "@/service/gtm";
import { GTM_EVENT_GET_CREDITS_MODAL, GTM_EVENT_GET_CREDITS_MODAL_CLICK_BUY, GTM_EVENT_GET_CREDITS_MODAL_CLICK_STAY } from "@/service/gtm/types";
import { setAsDisplayed } from "@/service/popups";
import { PREMIUM_MODAL_KEY, PREMIUM_MODAL_SILENCE_DURATION } from "@/service/popups/config";

export default {
    name: "PreActionPremium",
    mixins: [ThemeMixin],
    components: {Modal},
    methods: {
        ucFirst,
        buyClickHandler() {
            this.$emit('success')
            this.$router.push({name: 'credits'})
            pushDataLayer(GTM_EVENT_GET_CREDITS_MODAL_CLICK_BUY, this.$store.state.authenticate.auth.user)
        },
        stayClickHandler() {
            this.$emit('success')
            pushDataLayer(GTM_EVENT_GET_CREDITS_MODAL_CLICK_STAY, this.$store.state.authenticate.auth.user)
        }
    },
    mounted() {
        pushDataLayer(GTM_EVENT_GET_CREDITS_MODAL, this.$store.state.authenticate.auth.user)
        setAsDisplayed(PREMIUM_MODAL_KEY, PREMIUM_MODAL_SILENCE_DURATION)
    }
}
</script>

<style scoped lang="scss">

    article {
        text-align: center;
    }

    .box {
        width: 50%;
        margin: 5px;
        border-radius: 16px;
        box-shadow: 0 0 5px rgba(0,0,0,0.4);
        background-color: $color-type1;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .main-icon {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%,-50%);
        }
    }

    h2 {
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    ul {
        margin-bottom: 30px;
        display: inline-block;

        li {
            line-height: 2;
            font-size: 16px;
            font-family: 'Open Sans',sans-serif;
            display: flex;
            align-items: center;

            svg {
                margin-right: 7px;
            }
        }
    }

    .button--man {
        height: $button1-height;
        $button1-font-size: 16px;
        border-radius: $button1-radius;
        font-weight: 600;
        width: 100%;
    }

    @media(max-width: 768px) {

        h2 {
            font-size: 16px;
        }

        li {
            font-size: 14px;
            line-height: 1.5;
        }
    }

    @media(max-width: 600px) {
        h2 {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>