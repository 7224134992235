<template>
    <div class="tab-section">
        <ContentLoader v-if="loading"/>
        <div v-if="!loading">

            <article class="info-box">
                <h1>{{$t('access.my_access.title')}}</h1>
                <p v-if="auth.user.gender">
                    {{$t('access.my_access.text')}}
                </p>
                <p v-else>
                    {{$t('access.my_access.text_male', [, 10])}}
                </p>
            </article>
            <article class="list">
                <div v-if="access.length">
                    <transition-group appear name="fade-in" tag="ul">
                        <li v-for="(a, index) in access" :key="a.id" :style="'--animate:' + index">
                            <router-link :to="{name: 'user', params: {id: a.owner.id}}">
                                <div class="user-info">
                                    <div class="img-wrapper" :class="notifications.social?.access?.my_access?.includes(a.id) ? 'is-active' : ''">
                                        <img alt="user photo" :src="getUserPhotoURL(a.owner, 'small')">
                                    </div>
                                    <OnlineStatus class="active-user" v-if="isOnline(a.owner)" />
                                    <p class="mb0">
                                        <span class="o-flex o-flex--center">
                                            <Premium v-if="a.owner.premium" />
                                            <Verified v-if="a.owner.profile_verified_at" />
                                            <strong>{{a.owner.username}}</strong>
                                        </span>
                                        <span>
                                            {{a.owner.address}}
                                        </span>
                                    </p>
                                </div>
                            </router-link>
                            <div class="o-flex o-flex--center">
                                <button :disabled="working" @click="unlock(a.owner_id, $event)" v-if="!a.system_approved_at" class="button button--primary">{{$t('access.my_access.unlock')}}</button>
                                <router-link :to="{name: 'user', params: {id: a.owner_id}, query: {load: 'gallery'}}" v-else class="button button--primary">{{$t('general.open')}}</router-link>
                            </div>
                        </li>
                    </transition-group>
                </div>
            </article>
            <Pagination @page-click="load" :info="pagination" />
        </div>
        <transition name="fade">
            <Modal v-if="unlockModal" size="small" class="modal">
                <template #body>
                    <UnlockAnimation @animated="successCallback" :status="unlockAnimation" />
                </template>
            </Modal>
        </transition>
    </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { getMyAccess } from "@/api/access";
import { getUserPhotoURL, isOnline } from "@/service/user/profile";
import Pagination from "@/components/generic/Pagination";
import { getPaginationPayload } from "@/service/general";
import toastr from "toastr";
import { mapState } from "vuex";
import { read } from "@/service/notifications";
import {handleInsufficientFundsError, unlockAccess} from "@/service/user";
import ContentLoader from "@/components/ContentLoader";
import OnlineStatus from "@/components/user/OnlineStatus";
import Modal from "@/components/generic/Modal";
import UnlockAnimation from "@/components/generic/UnlockAnimation";
import { ANIMATION_INIT, ANIMATION_IN_PROGRESS, ANIMATION_COMPLETED } from "@/utilities/unlockAnimation";
import { wait } from "@/utilities/general";
import { TYPE_ITEM_ACCESS } from "@/types/access";
import Premium from "@/components/generic/Premium.vue";
import Verified from "@/components/generic/Verified.vue";

export default {
    name: "MyAccess",
    mixins: [AuthenticatedMixin],
    components: {Verified, Premium, Pagination, ContentLoader, OnlineStatus, Modal, UnlockAnimation},
    data() {
        return {
            loading: false,
            access: [],
            pagination: null,
            timeout: null,
            unlockAnimation: ANIMATION_INIT,
            unlockModal: false,
            successCallback: null,
            working: false
        }
    },
    computed: {
        ...mapState({
            notifications: state => state.notifications
        })
    },
    methods: {
        getUserPhotoURL,
        isOnline,
        async load(page = 1) {
            this.loading = true
            this.access = []
            const { data } = await getMyAccess({page: page})
            this.access = data.data
            this.pagination = getPaginationPayload(data)
            this.loading = false
        },
        async unlockedFinishedHandler() {

            await wait(1)

            this.unlockModal = false

            this.unlockAnimation = ANIMATION_INIT

        },
        async unlock(owner_id) {

            this.working = true

            this.unlockModal = true

            await this.$nextTick(() => {
                this.unlockAnimation = ANIMATION_IN_PROGRESS
            })

            try {

                await unlockAccess(owner_id, TYPE_ITEM_ACCESS)

                this.unlockAnimation = ANIMATION_COMPLETED

                this.successCallback = () => {

                    this.access = this.access.map(e => {

                        const item = {...e}

                        if(e.owner_id === owner_id) {
                            item.system_approved_at = true
                        }

                        return item
                    })

                    this.unlockedFinishedHandler()

                }

            } catch(e) {

                this.unlockAnimation = ANIMATION_INIT
                this.unlockModal = false
                this.working = false

                if(e.paymentRequired) {
                    return await handleInsufficientFundsError()
                }

                toastr.error(this.$i18n.t('credits.error_processing'))

            }

            this.working = false
        }
    },
    watch: {
        '$store.state.notifications.social.access.my_access': {
            handler: async function(value) {

                if( ! value.length)
                    return

                this.access = []

                await this.load(this.pagination?.current_page || 1)

                clearTimeout(this.timeout)

                this.timeout = setTimeout(() => {
                    read('my_access', this.notifications.social.access.my_access)
                }, 3000)
            }
        }
    },
    mounted() {
        this.load()
        if(this.notifications.social.access.my_access.length) {
            this.timeout = setTimeout(() => {
                read('my_access', this.notifications.social.access.my_access)
            }, 3000)
        }
    },
    beforeUnmount() {
        if(this.notifications.social.access.my_access.length) {
            clearTimeout(this.timeout)
            read('my_access', this.notifications.social.access.my_access)
        }
    }
}
</script>
<style scoped lang="scss">
    main {
        height: auto;
        min-height: 100%!important;

    }
</style>