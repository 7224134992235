<template>
    <teleport to="#modals">
        <Slider key="photo" @close="slider.showSlides = 0; slider.currentSlide = 0;" v-if="slider.showSlides" :current="slider.currentSlide" @displaying="(slide) => {this.slider.currentSlide = slide}" :slides="slides">
            <template v-slot:content="{ displaying }">
                <MediaSlider :slides="slides" :displaying="displaying" />
            </template>
            <template #footer>
                <div v-if="!selfView && slides[slider.currentSlide]?.type === ITEM_TYPE_PHOTO" class="slider-info">
                    <p>{{$t('users.like_what_you_see')}}</p>
                    <button :disabled="chatLoading || selfView" @click="getConversation(profile.id)" class="button">{{ucFirst($t('users.message_me'))}}</button>
                </div>
            </template>
        </Slider>
    </teleport>
    <article class="profile-wrapper" :class="{'self-profile': selfView, 'is-active': animationTrigger}">
        <BackButton class="mob back">
            <template #content>
                <svg xmlns="http://www.w3.org/2000/svg" width="14.004" height="14.001"><path data-name="Union 6" fill="#fff" d="M6.289 13.708.262 7.669a1 1 0 0 1 .03-1.379l6-6a1 1 0 0 1 1.417 0 1 1 0 0 1 0 1.413l-4.3 4.295H13a1 1 0 0 1 1 1 1 1 0 0 1-1 1H3.41l4.292 4.3a1 1 0 0 1 0 1.412A1 1 0 0 1 7 14a1 1 0 0 1-.711-.292Z"/></svg>
            </template>
        </BackButton>
        <aside>
            <div  class="img-wrapper">
                <div :class="{'private': ! visible }" @click="profilePictureClickHandler" class="img-box">
                    <Image :src="getUserPhotoURL(user, 'medium')" />
                </div>
                <div class="private-photo" v-if=" ! visible">
                    <svg xmlns="http://www.w3.org/2000/svg" width="33.337" height="32" data-name="Component 7 – 19"><g data-name="Group 35"><path fill="#fff" d="M9.819 32a9.887 9.887 0 0 1-6.944-16.858 9.782 9.782 0 0 1 12.791-.954L29.347.426a1.439 1.439 0 0 1 2.045 0 1.459 1.459 0 0 1 0 2.057l-2.02 2.032 3.543 3.567a1.462 1.462 0 0 1 0 2.057L27.587 15.5a1.482 1.482 0 0 1-2.045 0L22 11.933l-4.286 4.312A9.871 9.871 0 0 1 9.819 32m0-16.841a6.861 6.861 0 0 0-4.9 2.041 6.927 6.927 0 1 0 9.8 0 6.861 6.861 0 0 0-4.9-2.041m14.223-5.283 2.522 2.537 3.283-3.3-2.521-2.54Z" data-name="Path 26"/></g></svg>
                    <h2><strong>{{$t('users.private_profile')}}</strong></h2>
                    <p>{{$t('users.private_profile_access', {username: profile.username})}}</p>
                    <button :disabled="working" @click="sendAccess($event)" v-if="!userAccess" class="button button--primary">{{ucFirst($t(`users.request_access`))}}</button>
                    <button :disabled="working" @click="unlock($event)" v-if="userAccess?.status === 1 && !userAccess?.system_approved_at" class="button button--primary">{{ucFirst($t(`access.my_access.unlock`))}}</button>
                    <button v-if="[TYPE_ACCESS_PENDING, TYPE_ACCESS_DECLINED].includes(userAccess?.status)" disabled class="button button--primary">{{ucFirst($t(`users.request_${userAccess?.status === 0 ? 'pending' : 'rejected'}`))}}</button>
                </div>
                <ul>
                    <li><span class="status-box" :class="`box-${isOnline(profile) ? 'online' : 'offline'}`"><span :class="`dot-${isOnline(profile) ? 'online' : 'offline'}`"></span>{{lastSeenSince(this.profile.last_activity)}}</span></li>
                </ul>
            </div>
        </aside>
        <div class="content animation-item">
            <div class="user-info">
                <div class="user-info__box">
                    <div>
                        <h1 class="o-flex o-flex--center ">
                            <Premium v-if="profile.premium" :width="20" class="mr--"/>
                            <Verified v-if="profile.profile_verified_at" :width="20" />
                            <div class="o-flex o-flex--center"><span>{{profile.username}}</span><pre class="mb0">, {{profile.age}}</pre></div>
                            <span class="box-status" :class="`box-${isOnline(profile) ? 'online' : 'offline'}`">
                                <OnlineStatus v-if="isOnline(profile)" />
                                <i>{{lastSeenSince(this.profile.last_activity)}}</i>
                            </span>
                        </h1>
                    </div>
                    <h2>{{profile.address}}</h2>
                    <div class="tags-wrapper">
                        <p v-if="profile.interested_in?.length">{{$t('users.looking_for')}}</p>
                        <ul v-if="profile.interested_in?.length" class="tags-list">
                            <li v-for="i in profile.interested_in" :key="i">
                                <a class="tag cursor-auto"># {{$t(`form_fields.interested_in.${interestsFields.find(e => e.value === i.interested).code}`)}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="user-info__actions" v-if="!selfView">
                    <button :disabled="chatLoading || selfView" @click="getConversation(profile.id)" type="button" class="button button--primary">
                        {{ucFirst($t('users.message_me'))}}
                    </button>
                    <div class="actions-box">
                        <Favorite :profile="profile" />
                        <a v-if="profile.total_private_photos || profile.total_private_videos || $store.state.items.secret.photos.total" class="secret" @click="secret = !secret">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 17 15.997"><g data-name="Group 35"><path data-name="Path 26" d="M5.007 16a5.028 5.028 0 0 1-3.54-1.444 4.891 4.891 0 0 1 0-6.984 5.063 5.063 0 0 1 6.523-.477L14.965.213a.744.744 0 0 1 1.043 0 .719.719 0 0 1 0 1.028l-1.03 1.016 1.806 1.783a.721.721 0 0 1 0 1.028l-2.716 2.68a.767.767 0 0 1-1.043 0l-1.808-1.783-2.185 2.156a4.89 4.89 0 0 1-.483 6.432A5.03 5.03 0 0 1 5.007 16m0-8.419A3.534 3.534 0 0 0 2.51 8.6a3.45 3.45 0 0 0 0 4.927 3.572 3.572 0 0 0 5 0A3.45 3.45 0 0 0 7.5 8.6 3.534 3.534 0 0 0 5 7.58m7.26-2.643 1.286 1.268 1.674-1.65-1.285-1.269Z"/></g></svg>
                        </a>
                        <a class="settings" @click="showProfileOptions = true">
                            <svg width="36" viewBox="0 0 24 24" xml:space="preserve"><circle fill="#000000;" cx="12" cy="12" r="1.5"/><circle fill="#000000;" cx="12" cy="5" r="1.5"/><circle fill="#000000;" cx="12" cy="19" r="1.5"/></svg>
                        </a>
                    </div>
                </div>

            </div>
            <div v-if="profile.gender === TYPE_GENDER_MALE && profile.offers.length" class="offer-info">
                <div class="offer-info__header">
                    <div class="icon-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"><g data-name="Group 331"><g data-name="Group 329" transform="translate(-34 -746)"><circle data-name="Ellipse 27" cx="15" cy="15" r="15" transform="translate(34 746)" fill="#fff" opacity=".5"/></g><path data-name="Icon simple-cashapp" d="m20.112 11.081-.939.939a.505.505 0 0 1-.677.01 5.05 5.05 0 0 0-3.253-1.192c-.98 0-1.96.323-1.96 1.222s1.05 1.212 2.263 1.667c2.121.707 3.879 1.6 3.879 3.677 0 2.263-1.758 3.818-4.626 3.99l-.262 1.216a.5.5 0 0 1-.485.394h-1.808l-.091-.01a.505.505 0 0 1-.384-.6l.283-1.284a6.606 6.606 0 0 1-2.909-1.586v-.014a.485.485 0 0 1 0-.687l1.01-.98a.5.5 0 0 1 .677 0 4.885 4.885 0 0 0 3.424 1.333c1.313 0 2.192-.556 2.192-1.434s-.889-1.111-2.566-1.737c-1.778-.636-3.465-1.535-3.465-3.636 0-2.444 2.03-3.636 4.434-3.747l.253-1.242a.485.485 0 0 1 .485-.384h1.8l.1.01a.484.484 0 0 1 .374.576l-.274 1.388a7.581 7.581 0 0 1 2.505 1.4l.02.02a.482.482 0 0 1 0 .687Z"/></g></svg>
                    </div>
                    <div class="text" @click="offerBox = ! offerBox">
                        {{$t('profile.offer')}}:
                        <span v-if="!offerBox">{{$t('profile.click_to_view')}}...</span>
                    </div>
                </div>
                <div class="offer-info__body" v-if="offerBox">
                    <ul>
                        <li v-for="o in profile.offers" :key="o.id">
                            <span>{{$t(`profile.offer_per_` + o.type)}}</span>
                            <span>{{profile.local_currency.name.toUpperCase()}} {{o.amount}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="user-about">

                <ul v-if="slides" class="video-list">
                    <li @click="unlock" v-if="! selfView && userAccess?.status === TYPE_ACCESS_APPROVED && ! userAccess?.system_approved_at && (profile.total_private_photos || profile.total_private_videos)">
                        <div class="granted private-item">
                            <div class="icon-wrapper">
                                <svg data-name="Component 7 – 2" xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 25 23.997"><g data-name="Group 35"><path data-name="Path 26" d="M7.364 24a7.415 7.415 0 0 1-5.208-12.645 7.335 7.335 0 0 1 9.592-.716L22.007.32a1.079 1.079 0 0 1 1.533 0 1.094 1.094 0 0 1 0 1.543l-1.514 1.523 2.657 2.675a1.1 1.1 0 0 1 0 1.543l-4 4.019a1.111 1.111 0 0 1-1.533 0L16.5 8.949l-3.214 3.233A7.4 7.4 0 0 1 7.364 24m0-12.63A5.145 5.145 0 0 0 3.69 12.9a5.195 5.195 0 1 0 7.346 0 5.145 5.145 0 0 0-3.673-1.531m10.666-3.963 1.891 1.9 2.462-2.476-1.89-1.9Z"/></g></svg>
                            </div>
                            <p><strong>{{$t('access.total_private_posts', [profile.total_private_photos + profile.total_private_videos])}}</strong><br><span>{{ucFirst($t('access.click_to_unlock'))}}</span></p>
                        </div>
                    </li>
                    <li v-if="!selfView && (profile.total_private_photos || profile.total_private_videos) && (! userAccess || userAccess?.status !== TYPE_ACCESS_APPROVED)">
                        <div @click="!userAccess ? showRequestModal = true : null" class="private-item">
                            <div class="icon-wrapper">
                                <svg data-name="Component 7 – 2" xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 25 23.997"><g data-name="Group 35"><path data-name="Path 26" d="M7.364 24a7.415 7.415 0 0 1-5.208-12.645 7.335 7.335 0 0 1 9.592-.716L22.007.32a1.079 1.079 0 0 1 1.533 0 1.094 1.094 0 0 1 0 1.543l-1.514 1.523 2.657 2.675a1.1 1.1 0 0 1 0 1.543l-4 4.019a1.111 1.111 0 0 1-1.533 0L16.5 8.949l-3.214 3.233A7.4 7.4 0 0 1 7.364 24m0-12.63A5.145 5.145 0 0 0 3.69 12.9a5.195 5.195 0 1 0 7.346 0 5.145 5.145 0 0 0-3.673-1.531m10.666-3.963 1.891 1.9 2.462-2.476-1.89-1.9Z"/></g></svg>
                            </div>
                            <p v-if="!userAccess"><strong>{{$t('access.total_private_posts', [profile.total_private_photos + profile.total_private_videos])}}</strong><br><span>{{ucFirst($t(`users.request_access`))}}</span></p>
                            <p v-if="[TYPE_ACCESS_PENDING, TYPE_ACCESS_DECLINED].includes(userAccess?.status)"><strong>{{$t('access.total_private_posts', [profile.total_private_photos + profile.total_private_videos])}}</strong><br><span>{{ucFirst($t('users.request_pending'))}}</span></p>
                        </div>
                    </li>
                    <li v-for="(p, k) in slides" :key="p.id">
                        <div class="relative" v-if="p.type === ITEM_TYPE_PHOTO">
                            <Image @click="openSlider(k)" :src="p.source.medium"/>
                            <div v-if="p.private" class="status-info">
                                <span><strong>{{$t('file_control.secret')}}</strong></span>
                            </div>
                        </div>
                        <div class="relative h-100" v-if="p.type === ITEM_TYPE_VIDEO">
                            <Video :src="p.source.original" :preload="mobile ? 'metadata' : null" @click="openSlider(k)" muted />
                            <div v-if="p.private" class="status-info">
                                <span><strong>{{$t('file_control.secret')}}</strong></span>
                            </div>
                            <div @click="openSlider(k)" class="play">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" width="60" height="60"><defs><filter id="a" x="14.5" y="10.5" width="35.999" height="39" filterUnits="userSpaceOnUse"><feOffset/><feGaussianBlur stdDeviation="2.5" result="blur"/><feFlood flood-opacity=".4"/><feComposite operator="in" in2="blur"/><feComposite in="SourceGraphic"/></filter></defs><g data-name="Group 159" transform="translate(-60 -194)"><circle data-name="Ellipse 30" cx="30" cy="30" r="30" transform="translate(60 194)" fill="#fff" opacity=".5"/><g transform="translate(60 194)" filter="url(#a)"><path data-name="Icon awesome-play" d="M41.893 28.064 25.394 18.31A2.237 2.237 0 0 0 22 20.246v19.5a2.248 2.248 0 0 0 3.394 1.936l16.5-9.75a2.248 2.248 0 0 0-.001-3.868Z" fill="#fff"/></g></g></svg>
                            </div>
                            <div v-if="p.verification" @click="openSlider(k)" class="verification-box">
                                <svg class="verified-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 32 32"><g data-name="Group 121"><path data-name="Polygon 2" d="M14.51 1.665a2 2 0 0 1 2.98 0l1.268 1.417a2 2 0 0 0 2.018.6l1.821-.5a2 2 0 0 1 2.5 1.606l.315 1.924a2 2 0 0 0 1.366 1.578l1.834.587a2 2 0 0 1 1.23 2.69l-.776 1.819a2 2 0 0 0 .294 2.055l1.251 1.523a2 2 0 0 1-.421 2.923l-1.614 1.1a2 2 0 0 0-.861 1.894l.236 1.956a2 2 0 0 1-1.943 2.24l-1.9.041a2 2 0 0 0-1.762 1.137l-.83 1.737a2 2 0 0 1-2.858.838l-1.6-.994a2 2 0 0 0-2.106 0l-1.6.994a2 2 0 0 1-2.858-.838l-.83-1.737a2 2 0 0 0-1.762-1.137l-1.9-.041a2 2 0 0 1-1.943-2.24l.236-1.956a2 2 0 0 0-.861-1.894l-1.614-1.1a2 2 0 0 1-.421-2.923l1.242-1.524a2 2 0 0 0 .294-2.055l-.776-1.819a2 2 0 0 1 1.23-2.69l1.833-.586a2 2 0 0 0 1.364-1.582L6.9 4.784a2 2 0 0 1 2.5-1.606l1.821.5a2 2 0 0 0 2.018-.6Z" fill="#2c7ddb"/><path data-name="Icon metro-checkmark" d="m21.668 9.461-8.165 8.163-3.81-3.81-2.719 2.722 6.529 6.531 10.886-10.885Z" fill="#fff"/></g></svg>
                                <span>{{$t('users.verification_video')}}</span>
                            </div>
                        </div>
                    </li>
                </ul>

                <div :class="{'granted': userAccess?.status === TYPE_ACCESS_APPROVED}" v-if="! selfView && (profile.total_private_photos || profile.total_private_videos) && ! userAccess?.system_approved_at" class="access-secret">
                    <div class="access-secret__flag">
                        <div class="icon">
                            <svg data-name="Component 7 – 4" xmlns="http://www.w3.org/2000/svg" width="17" height="15.997"><g data-name="Group 35"><path data-name="Path 26" d="M5.007 16a5.028 5.028 0 0 1-3.54-1.444 4.891 4.891 0 0 1 0-6.984 5.063 5.063 0 0 1 6.523-.477L14.965.213a.744.744 0 0 1 1.043 0 .719.719 0 0 1 0 1.028l-1.03 1.016 1.806 1.783a.721.721 0 0 1 0 1.028l-2.716 2.68a.767.767 0 0 1-1.043 0l-1.808-1.783-2.185 2.156a4.89 4.89 0 0 1-.483 6.432A5.03 5.03 0 0 1 5.007 16m0-8.419A3.534 3.534 0 0 0 2.51 8.6a3.45 3.45 0 0 0 0 4.927 3.572 3.572 0 0 0 5 0A3.45 3.45 0 0 0 7.5 8.6 3.534 3.534 0 0 0 5 7.58m7.26-2.643 1.286 1.268 1.674-1.65-1.285-1.269Z"/></g></svg>
                        </div>
                        <div class="access-secret__content">
                            <h3 class="h2 mb- o-flex o-flex--center">
                                {{$t('users.access_secret_albums')}}
                            </h3>
                            <p>{{$t('access.user_has_total_posts', [profile.username, profile.total_private_photos + profile.total_private_videos])}}</p>
                        </div>
                    </div>
                    <button :disabled="working" @click="sendAccess($event)" v-if="!userAccess" class="button button--black w-100">{{ucFirst($t(`users.request_access`))}}</button>
                    <button :disabled="working" @click="unlock($event)" v-if="userAccess?.status === 1 && !userAccess?.system_approved_at" class="button button--black w-100">{{ucFirst($t(`access.my_access.unlock`))}}</button>
                    <button v-if="[TYPE_ACCESS_PENDING, TYPE_ACCESS_DECLINED].includes(userAccess?.status)" disabled class="button button--black w-100">{{ucFirst($t(`users.request_${userAccess?.status === 0 ? 'pending' : 'rejected'}`))}}</button>
                </div>
                <h3 v-if="(profile.greeting && profile.greeting_approved) || (profile.description && profile.description_approved)"><strong>{{$t('users.bio')}}</strong></h3>
                <p v-if="profile.description && profile.description_approved">
                    <span>
                        {{profile.description}}
                    </span>
                </p>
                <p v-if="profile.greeting && profile.greeting_approved">
                    <span>
                        {{profile.greeting}}
                    </span>
                </p>
                <h3 v-if="profile.needs && profile.needs_approved"><strong>{{$t('users.looking_for')}}</strong></h3>
                <p v-if="profile.needs && profile.needs_approved">
                    {{profile.needs}}
                </p>
                <h3><strong>{{$t('users.physical')}}</strong></h3>
                <ul class="physical__list">
                    <li v-if="profile.body_type !== null">{{$t('form_fields.body_type_label')}}<span>{{$t(`form_fields.body_type_options_${profile.body_type}`)}}</span></li>
                    <li v-if="profile.ethnicity !== null">{{$t('form_fields.ethnicity_label')}}<span>{{$t(`form_fields.ethnicity_options_${profile.ethnicity}`)}}</span></li>
                    <li v-if="profile.height !== null">{{$t('form_fields.height_label')}}<span>{{profile.height}}cm</span></li>
                    <li v-if="profile.hair_color !== null">{{$t('form_fields.hair_color_label')}}<span>{{$t(`form_fields.hair_color_options_${profile.hair_color}`)}}</span></li>
                    <li v-if="profile.eye_color !== null">{{$t('form_fields.eye_color_label')}}<span>{{$t(`form_fields.eye_color_options_${profile.eye_color}`)}}</span></li>
                    <li v-if="profile.piercing !== null">{{$t('form_fields.piercing_label')}}<span>{{$t(`form_fields.piercing_options_${profile.piercing}`)}}</span></li>
                    <li v-if="profile.tattoo !== null">{{$t('form_fields.tattoo_label')}}<span>{{$t(`form_fields.tattoo_options_${profile.tattoo}`)}}</span></li>
                </ul>

                <h3
                    v-if="['education', 'smoking', 'drinking', 'relationship', 'income', 'net_worth'].some(e => profile[e] !== null)"
                ><strong>{{$t('users.lifestyle')}}</strong></h3>
                <div class="lifestyle">

                    <ul>
                        <li v-if="profile.education !== null">
                            {{$t('form_fields.education_label')}}
                            <span>{{$t(`form_fields.education_options_${profile.education}`)}}</span>
                        </li>
                        <li v-if="profile.smoking !== null">
                            {{$t('form_fields.smoking_label')}}
                            <span>{{$t(`form_fields.smoking_options_${profile.smoking}`)}}</span>
                        </li>
                        <li v-if="profile.drinking !== null">
                            {{$t('form_fields.drinking_label')}}
                            <span>{{$t(`form_fields.drinking_options_${profile.drinking}`)}}</span>
                        </li>
                        <li v-if="profile.relationship !== null">
                            {{$t('form_fields.relationship_label')}}
                            <span>{{$t(`form_fields.relationship_options_${profile.relationship}`)}}</span>
                        </li>
                        <li v-if="profile.income !== null">
                            {{$t('form_fields.income_label')}}
                            <span>{{$t(`form_fields.income_options_${profile.income}`)}}</span>
                        </li>
                        <li v-if="profile.net_worth !== null ">
                            {{$t('form_fields.net_worth_label')}}
                            <span>{{$t(`form_fields.net_worth_options_${profile.net_worth}`)}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </article>
    <transition name="fade" mode="out-in">
        <ProfileOptions @open="openUserDialog" v-if="showProfileOptions" @close="showProfileOptions = false" />
    </transition>

    <transition name="fade" mode="out-in">
        <Modal @close="showRequestModal = false" :size="'small'" v-if="showRequestModal">
            <template #header></template>
            <template #body>
                <div class="u-text-center">
                    <h2>{{ucFirst($t('users.request_access'))}}</h2>
                    <p>{{$t(`users.request_access_description.${profile.gender === TYPE_GENDER_MALE ? 'male' : 'female'}`)}}</p>
                    <button class="button button--primary w-100" :disabled="working" @click="sendAccessModalButtonHandler">{{$t('general.send')}}</button>
                </div>
            </template>
        </Modal>
    </transition>

    <transition name="fade" mode="out-in">
        <Modal v-if="secret" class="modal modal--mob modal-profile-secret" :size="'small'" @close="secret = false">
            <template v-slot:header>
                <h2>{{$t('users.access_secret_albums')}}</h2>
            </template>
            <template v-slot:body>
                <ul>
                    <li v-if="profile.total_private_photos || profile.total_private_videos">
                        <div class="checkbox">
                            <input class="form-check-input"  type="checkbox" @click="sendAccess" :disabled="working || userAccess && userAccess.status !== null" :checked="userAccess && userAccess?.status !== null" id="request_access">
                            <label class="checkbox__label" for="request_access">
                                <div class="checkbox__icon">
                                    <svg width="16" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                                </div>
                                <div class="checkbox__text">
                                    <h3>{{ucFirst($t('users.request_access'))}}</h3>
                                    <p>{{$t(`users.request_access_description.${profile.gender === TYPE_GENDER_MALE ? 'male' : 'female'}`)}}</p>
                                </div>
                            </label>
                        </div>
                    </li>
                    <li v-if="$store.state.items.secret.photos.approved || $store.state.items.secret.videos.approved">
                        <div class="checkbox">
                            <input @click="giveAccess" class="form-check-input" :disabled="working || userOffer?.status === 1" type="checkbox" :checked="userOffer && userOffer.status === 1" id="give_access">
                            <label class="checkbox__label" for="give_access">
                                <div class="checkbox__icon">
                                    <svg width="16" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                                </div>
                                <div class="checkbox__text">
                                    <h3>{{ucFirst($t('users.give_access'))}}</h3>
                                    <p>{{$t('users.give_access_description')}}</p>
                                </div>
                            </label>
                        </div>
                    </li>
                </ul>
            </template>
        </Modal>
    </transition>
    <component @success="$router.push({name: 'settings-blocked-users'})" @close="userDialog = null" :user="user" :is="userDialog" v-if="userDialog"></component>
    <transition name="fade">
        <Modal v-if="unlockModal" class="modal" size="small">
            <template #body>
                <UnlockAnimation :status="unlockingStatus" @animated="successCallback" />
            </template>
        </Modal>
    </transition>
</template>

<script>

import { getUserAccess, userGallery, userVideoGallery, getUserOffer } from "@/api/users";
import { getUserPhotoURL, lastSeenSince, isOnline } from "@/service/user/profile"
import { interests as interestsFields}  from "@/service/forms";
import Slider from "@/components/generic/Slider";
import Favorite from "@/components/search/Favorite";
import { sendPhotoAccess, givePhotoAccess, respond } from "@/api/access";
import toastr from "toastr";
import {handleInsufficientFundsError, unlockAccess} from "@/service/user";
import { getOrCreate } from "@/api/messages";
import Modal from "@/components/generic/Modal";
import { TYPE_GENDER_MALE } from "@/types/user";
import ProfileOptions from "@/components/user/ProfileOptions";
import BlockUserDialog from "@/components/user/BlockUserDialog";
import ReportUserDialog from "@/components/user/ReportUserDialog";
import { ucFirst } from "@/utilities/general";
import MediaSlider from "@/components/generic/MediaSlider";
import { ITEM_TYPE_PHOTO, ITEM_TYPE_VIDEO } from "@/types/item";
import {TYPE_ACCESS_PENDING, TYPE_ACCESS_APPROVED, TYPE_ACCESS_DECLINED, TYPE_ITEM_ACCESS} from "@/types/access";
import Image from "@/components/generic/Image";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { mapState, mapActions } from "vuex";
import OnlineStatus from "@/components/user/OnlineStatus";
import BackButton from "@/components/generic/BackButton";
import { ANIMATION_INIT, ANIMATION_IN_PROGRESS, ANIMATION_COMPLETED } from "@/utilities/unlockAnimation";
import UnlockAnimation from "@/components/generic/UnlockAnimation";
import { wait } from "@/utilities/general";
import Video from "@/components/generic/Video.vue";
import Premium from "@/components/generic/Premium.vue";
import Verified from "@/components/generic/Verified.vue";
import {pushDataLayer} from "@/service/gtm";
import { REQUEST_SECRET_GALLERY_ACCESS, MESSAGE_ME_CLICK } from "@/service/gtm/types";

export default {
    name: "Profile",
    mixins: [AuthenticatedMixin],
    components: {
        Video,
        Verified,
        BackButton,
        MediaSlider, Image,
        ProfileOptions, Slider, Favorite, Modal,
        BlockUserDialog, ReportUserDialog, OnlineStatus,
        UnlockAnimation, Premium
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        userPublicPhotos: {
            type: Object
        },
        userPublicVideos: {
            type: Object
        },
        access: {
            type: [Object, null],
            required: true
        },
        offer: {
            type: [Object, null],
            required: true
        }
    },
    data() {
        return {
            animationTrigger: false,
            profile: null,
            unlockRequired: false,
            slider: {
                showSlides: false,
                currentSlide: 0,
                template: []
            },
            interestsFields,
            showProfileOptions: false,
            userDialog: null,
            secret: false,
            like: false,
            showLimitReachedNotification: false,
            photos: {
                public: {
                    display: 4,
                    items: {}
                },
                secret: {
                    display: 4,
                    items: {}
                }
            },
            videos: {
                public: {
                    display: 4,
                    items: {}
                },
                secret: {
                    display: 4,
                    items: {}
                }
            },
            chatLoading: false,
            working: false,
            userAccess: null,
            userOffer: null,
            showRequestModal: false,
            offerBox: false,
            unlockModal: false,
            unlockingStatus: ANIMATION_INIT,
            successCallback: null
        }
    },

    computed: {
        ...mapState({
            mobile: state => state.application.mobile
        }),
        haveVideos() {
            return this.videos.public.items?.data?.length || this.videos.secret.items?.data?.length || this.profile.total_private_videos
        },
        slides() {
            return [...this.videos.public.items.data ?? [], ...this.videos.secret.items.data ?? [], ...this.photos.public.items.data ?? [], ...this.photos.secret.items.data ?? []]
                .sort((a, b) =>(b?.created_at > a?.created_at) ? 1 : ((a?.created_at > b?.created_at) ? -1 : 0))
        },
        selfView() {
            return this.profile.id === this.auth.user.id
        },
        ...mapState({
            liveFavorite: state => state.search.myFavorite
        }),

        visible() {

            if(this.selfView)
                return true

            if(this.profile.profile_photo?.private) {

                if( ! this.userAccess)
                    return false

                return this.userAccess.system_approved_at

            }

            return true

        }
    },

    watch: {

        liveFavorite(data) {

            if(data.includes(this.profile.id) && ! this.profile.my_favorite) {
                this.profile = {
                    ...this.profile,
                    my_favorite: 1
                }
            }

        },

        '$store.state.notifications.social.access.my_access': {
            handler() {

                getUserAccess(this.user.id)
                    .then(({data}) => {

                        this.userAccess = data

                        if(this.userAccess?.system_approved_at && this.userAccess?.status === 1) {

                            userGallery(this.profile.id,{page: 1, private: 1, perPage: 50})
                                .then(response => this.photos.secret.items = response.data)
                                .catch(e => console.log(e))

                            userVideoGallery(this.profile.id,{page: 1, private: 1, perPage: 50})
                                .then(response => this.videos.secret.items = response.data)
                                .catch(e => console.log(e))

                        }

                    })
                    .catch(e => console.log('Error fetching user access from Profile watcher', e))

            }
        },

        '$store.state.notifications.social.access.pending': {
            handler(value) {

                if( ! value.includes(parseInt(this.$route.params.id)))
                    return

                this.working = true

                getUserOffer(this.$route.params.id)
                    .then(({data}) => this.userOffer = data)
                    .catch(e => console.log('Error fetching user offer from Profile watcher', e))
                    .then(() => this.working = false)

            }
        }

    },


    methods: {
        ucFirst,
        isOnline,
        lastSeenSince,
        getUserPhotoURL,
        userGallery,
        ...mapActions({
            addPreAction: "profile/add"
        }),
        openUserDialog({type}) {

            this.showProfileOptions = false

            switch (type) {
                case 'block':
                    return this.userDialog = 'BlockUserDialog'
                case 'report':
                    return this.userDialog = 'ReportUserDialog'
            }

        },

        profilePictureClickHandler() {

            if( ! this.slides.length)
                return

            const index = this.slides.findIndex(e => e.id === this.profile.profile_photo?.id)

            this.openSlider(index !== -1 ? index : 0)
        },


        openSlider(current) {

            if(current < 0)
                return

            this.slider.currentSlide = current

            this.slider.showSlides = true

        },

        async sendAccessModalButtonHandler() {
            await this.sendAccess()
            this.showRequestModal = false
        },

        async sendAccess() {

            if(this.profile.private_photos_access !== null)
                return

            this.working = true

            try {

                const { data } = await sendPhotoAccess(this.profile.id)

                this.userAccess = data

                toastr.success(this.$i18n.t('users.request_sent'))

                pushDataLayer(REQUEST_SECRET_GALLERY_ACCESS, this.auth.user)


            } catch(e) {
                toastr.error(this.$i18n.t('credits.error_processing'))
            }

            this.working = false

        },

        async giveAccess() {

            if(this.userOffer && this.userOffer.status === 1)
                return

            this.working = true

            try {

                let data

                if(this.userOffer) {
                    data = await respond(this.userOffer.id, 1)
                } else {
                    data = await givePhotoAccess(this.profile.id)
                }

                this.userOffer = data.data

                toastr.success(this.$i18n.t('users.access_sent'))


            } catch {

                toastr.error(this.$i18n.t('credits.error_processing'))

            }

            this.working = false

        },

        async unlock() {
            this.working = true

            this.unlockModal = true

            await this.$nextTick(() => {
                this.unlockingStatus = ANIMATION_INIT
                this.unlockingStatus = ANIMATION_IN_PROGRESS
            })

            try {

                this.userAccess = await unlockAccess(this.profile.id, TYPE_ITEM_ACCESS)

                this.successCallback = async () => {

                    userGallery(this.profile.id,{page: 1, private: 1, perPage: 50})
                        .then(response => this.photos.secret.items = response.data)
                        .catch(e => console.log(e))

                    userVideoGallery(this.profile.id,{page: 1, private: 1, perPage: 50})
                        .then(response => this.videos.secret.items = response.data)
                        .catch(e => console.log(e))

                    await wait(1)

                    this.unlockModal = false
                    this.unlockingStatus = ANIMATION_INIT

                }

                this.unlockingStatus = ANIMATION_COMPLETED

            } catch(e) {

                this.unlockModal = false
                this.unlockingStatus = ANIMATION_INIT

                if(e.paymentRequired) {
                    return await handleInsufficientFundsError()
                }

                toastr.error(this.$i18n.t('credits.error_processing'))
            }

            this.working = false
        },

        async getConversation(user_id) {
            this.chatLoading = true
            try {
                const { data } = await getOrCreate(user_id)
                this.chatLoading = false
                pushDataLayer(MESSAGE_ME_CLICK, this.auth.user)
                await this.$router.push({name: 'messages', params: {conversation: data.id}})
            } catch (e) {
                if(e?.response?.status === 406) {
                    await this.addPreAction('daily_limit')
                } else {
                    toastr.error(this.$i18n.t('credits.error_processing'))
                }
                this.chatLoading = false
            }

        },

        animateContent() {
            this.animationTrigger = true;
        }


    },

    created() {

        this.profile                            = this.user

        this.TYPE_GENDER_MALE                   = TYPE_GENDER_MALE

        this.ITEM_TYPE_VIDEO                    = ITEM_TYPE_VIDEO
        this.ITEM_TYPE_PHOTO                    = ITEM_TYPE_PHOTO

        this.TYPE_ACCESS_APPROVED               = TYPE_ACCESS_APPROVED
        this.TYPE_ACCESS_DECLINED               = TYPE_ACCESS_DECLINED
        this.TYPE_ACCESS_PENDING                = TYPE_ACCESS_PENDING
    },


    async mounted() {

        this.userAccess = this.access
        this.userOffer  = this.offer
        setTimeout(this.animateContent, 50);


        if(this.userPublicPhotos) {
            this.photos.public.items = this.userPublicPhotos
        }

        if(this.userPublicVideos) {
            this.videos.public.items = this.userPublicVideos
        }


        if(this.profile.total_private_photos && this.userAccess && this.userAccess.status === 1 && this.userAccess.system_approved_at !== null) {

            userGallery(this.profile.id,{page: 1, private: 1, perPage: 50})
                .then(response => this.photos.secret.items = response.data)
                .catch(e => console.log('Error fetching unlocked gallery', e))

        }

        if(this.profile.total_private_videos && this.userAccess && this.userAccess.status === 1 && this.userAccess.system_approved_at !== null) {

            userVideoGallery(this.profile.id,{page: 1, private: 1, perPage: 50})
                .then(response => this.videos.secret.items = response.data)
                .then(() => {
                    if(this.$route.query?.load === 'video') {
                        let index = this.slides.findIndex(e => e.type === ITEM_TYPE_VIDEO)
                        if(index !== -1) {
                            this.slider.currentSlide = index
                            this.slider.showSlides = true
                        }

                    } else if(this.$route.query?.load === 'gallery') {
                        let index = this.slides.findIndex(e => e.id !== undefined)
                        if(index !== -1) {
                            this.openSlider(index)
                        }
                    }
                })
                .catch(e => console.log('Error fetching unlocked gallery', e))

        } else {
            if(this.$route.query?.load === 'video') {
                let index = this.slides.findIndex(e => e.type === ITEM_TYPE_VIDEO)
                if(index !== -1) {
                    this.openSlider(index)
                }
            } else if(this.$route.query?.load === 'gallery') {
                this.openSlider(0)
            }
        }



        if(this.$route.query?.load === 'chat') {
            await this.getConversation(this.profile.id)
        }

    }

}
</script>

<style scoped lang="scss">

    .status-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        position: absolute;
        z-index: 2;
        left: 6px;
        bottom: 6px;

        span {
            height: 23px;
            display: inline-flex;
            align-items: center;
            padding: 0 10px;
            margin-top: 4px;
            background-color: rgba($color-type18, .7);
            border-radius: 12px;
            color: $color-type1;
            font-size: 14px;

        }
        @media(max-width: 500px) {
            span {
                height: 19px;
                font-size: 11px;
            }
        }
    }

    .offer-info {
        border-radius: 10px;
        background-color: #EFEFEF;
        padding: 14px;
        margin-bottom: 30px;
    }
    .offer-info__header {
        display: flex;
        align-items: center;

        .icon-wrapper {
            margin-right: 10px;
        }

        .text {
            color: #000000;
            font-size: 16px;
            line-height: 34px;
            cursor: pointer;
            font-weight: 600;

            span {
                color: #959595;
                font-style: italic;
                font-weight: 400;
                margin-left: 10px;
            }
        }
    }

    .offer-info__body {
        padding-left: 40px;
        ul {
            margin-bottom: 0;
        }

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #000;
            font-size: 15px;

            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }

    .verified-icon {
        margin-right: 5px;
    }



    .back {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.3);
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 20px;
        top: 15px;
        z-index: 9;
        display: none;

        svg {
            pointer-events: none;
        }

        @media(max-width: 991px) {
            display: flex;
        }
    }
    .profile-wrapper {
        padding: 60px 0;
        display: grid;
        grid-template-columns: 300px 1fr;

        @media(max-width: 991px) {
            display: block;
            padding: 20px 10px 60px;
        }

        @media(max-width: 500px) {
            display: block;
            padding: 20px 0 60px;

            &.self-profile {
                padding-bottom: 0;
            }
            .animation-item {
                visibility: hidden;
                opacity: 0;
                transition: all .4s ease-in-out;
            }

            &.is-active {
                .animation-item {
                    visibility: visible;
                    opacity: 1;
                    margin-top: 110vw;
                }
            }
        }
    }

    .img-wrapper {
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 34px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        position: relative;
        aspect-ratio: 1 / 1.25;

        img {
            background: #e6e6e6;
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
            display: block;
        }

        .private-photo {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 0 20px;

            @media(max-width: 500px) {
                padding-bottom: 10%;
            }
        }

        h2 {
            color: #fff;
            margin-bottom: 10px;
            margin-top: 20px;
        }

        p {
            color: #fff;
            max-width: 270px;
            font-size: 15px;
            line-height: 22px;
        }

        .img-box {
            overflow: hidden;
            height: 100%;
            width: 100%;

            &.private {
                img {
                    filter: blur(25px) brightness(70%);
                }
            }
        }

        .button--primary {
            max-width: 182px;
        }

        ul {
            padding: 20px;
            position: absolute;
            left: 0;
            bottom: 0;
            margin-bottom: 0;
        }

        .status-box {
            display: inline-flex;
            margin-bottom: 0;
            align-items: center;
            padding: 0 10px;
            height: 22px;
            border-radius: 11px;
            font-size: 14px;
            color: #fff;

            .dot-offline {
                background-color: $color-type12;
                height: 8px;
                width: 8px;
                border-radius: 50%;
                margin-right: 4px;
            }

            &.box-online {
                background-color: rgba($color-type26, .75);

                span {
                    background-color: $color-type1;
                }
            }

            &.box-offline {
                background-color: rgba(#000, .5);

                span {
                    background-color: $color-type1;
                }
            }

            @media(max-width: 500px) {
                display: none;
            }
        }

        @media(max-width: 991px) {
            max-width: 500px;

        }

        @media(max-width: 500px) {
            border-radius: 0;
            margin-bottom: 0;
            position: fixed;
            width: 100%;
            left: 0;
            top: 0;
            // padding-bottom: 120%;
        }
    }

    .physical {
        h2 {
            color: $color-type12;
            margin-bottom: 14px;
        }
    }

    .physical__list {
        li {
            color: $color-type12;
            font-size: 16px;
            padding: 12px 0;
            display: flex;
            line-height: 1.6;
            justify-content: space-between;
            border-bottom: 1px solid #DEDEDE;

            span {
                max-width: 160px;
                line-height: 1.2;
                height: auto;
                border-radius: $button-tag-radius;
                padding: 3px 10px;
                background-color: $color-type18;
                color: $color-type1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .content {
        padding-left: 60px;

        @media(max-width: 991px) {
            padding-left: 0;
        }

        @media(max-width: 500px) {
            margin-top: 100vh;
            position: relative;
            z-index: 5;
            background-color: $color-type1;
            border-radius: 10px 10px 0 0;
            padding: 20px;
        }
    }

    .user-info {
        display: flex;
        justify-content: space-between;
        // border-bottom: 1px solid #e6e6e6;
        padding-bottom: 25px;
        margin-bottom: 30px;
        flex-wrap: wrap;

        @media(max-width: 500px) {
            padding-bottom: 0;
            margin-bottom: 0;
            border: none;
        }
    }

    .user-info__box {
        h1 {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 10px;
            color: $color-type18;
            font-weight: 400;

            span {
                font-weight: 600;
            }

            svg {
                transform: translate(0, 3px);
            }

            @media(max-width: 500px) {
                font-size: 24px;
            }
        }

        h2 {
            margin-bottom: 10px;
            color: $color-type18;
            font-weight: 400;
            font-size: 16px;

            @media(max-width: 500px) {
                font-size: 16px;
            }
        }

        .box-status {
            color: $color-type18;
            font-weight: 400;
            font-size: 16px;
            display: flex;
            align-items: center;
            margin-bottom: 19px;


            .dot-offline {
                background-color: $color-type12;
                height: 12px;
                width: 12px;
                border-radius: 50%;
                margin-left: 5px;
            }

            .dot-online {
                background-color: $color-type25;
                height: 12px;
                width: 12px;
                border-radius: 50%;
                margin-left: 5px;
            }

            i {
                display: none;
            }

            @media(max-width: 500px) {
                position: absolute;
                left: 20px;
                bottom: 100%;
                transform: translate(0,-20px);
                margin-bottom: 0;
                height: 24px;
                border-radius: 12px;
                padding: 0 10px;
                font-size: 14px;
                color: $color-type1;

                span {
                    margin-left: 0!important;
                    margin-right: 5px;
                }

                i {
                    display: block;
                }

                &.box-online {
                    background-color: $color-type26;

                    span {
                        background-color: $color-type1;
                    }
                }

                &.box-offline {
                    background-color: rgba(#000, .5);

                    span {
                        background-color: $color-type1;
                    }
                }
            }
        }

        p {
            color: $color-type12;
        }
    }

    .tags-wrapper {
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
        margin-bottom: 30px;

        p {
            font-size: 16px;
            white-space: nowrap;
            margin-right: 5px;
            margin-bottom: 0;
        }

        ul {
            margin-bottom: 0;
        }
    }

    .tags-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;

        li {
            margin-right: 5px;
            margin-bottom: 5px;
        }

        .tag {
            color: $color-type18;
            font-weight: 700;
        }
    }

    .image-list,
    .video-list {
        display: flex;
        margin-left: -5px;
        margin-right: -5px;
        margin-bottom: 20px;
        flex-wrap: wrap;

        li {
            margin: 0 3px 6px;
            position: relative;
            width: calc(33.33% - 10px);
            aspect-ratio: 1 / 1;
            overflow: hidden;
            cursor: pointer;

            img,
            video {
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
                object-position: 50% 50%;

            }

            video {
                width: 100%;
                height: 100%;
            }

            &.last-photo {
                >div:not(.overlay-gallery) {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.7);
                    color: $color-type1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 5;
                    font-size: 50px;
                    cursor: pointer;

                    @media(max-width: 991px) {
                        font-size: 30px;
                    }
                }
            }
        }

        @media(max-width: 500px) {
            margin-left: -1px;
            margin-right: -1px;

            li {
                margin: 0 1px 2px;
                width: calc(33.33% - 2px);
                border-radius: 0px;
            }
        }
    }

    .private-item {
        display: flex;
        flex-direction: column;
        background-color: #D6D6D6;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        &.granted {
            background-color: #BEF2D6;

            .icon-wrapper {
                background-color: rgba(#fff, .5);
            }
        }

        .icon-wrapper {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            @media(max-width: 500px) {
                margin-bottom: 10px;
            }
        }

        p {
            color: #000000;
            line-height: 1.2;
            font-size: 16px;
            margin-bottom: 0;
            text-align: center;

            span {
                font-size: 14px;
            }

            @media(max-width: 500px) {
                font-size: 12px;
                color: #000000!important;
                line-height: 1.1!important;

                span {
                    font-size: 11px;
                }
            }
        }
    }

    .verification-box {
        position: absolute;
        left: 5px;
        bottom: 5px;
        padding: 5px 10px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        background-color: rgba($color-type28, 0.7);
        border-radius: 15px;
        color: $color-type1;
        font-size: 12px;
        height: 30px;
        font-family: "OpenSans", sans-serif;

        svg {
            margin-right: 5px;
        }

        @media(max-width: 768px) {
            height: auto;
            padding: 5px;

            svg {
                margin-right: 0;
            }

            span {
                display: none;
            }
        }
    }

    .play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @media(max-width: 500px) {
            svg {
                width: 36px;
            }
        }
    }

    .video-list {
        li {
            > svg {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
        }

        .verification {
            position: absolute;
            bottom: 10px;
            left: 10px;
            height: 30px;
            border-radius: 15px;
            color: $color-type1;
            font-size: 13px;
            z-index: 9;
            padding: 5px 10px;
            background: rgba(0, 0, 0, 0.7);
            display: flex;
            align-items: center;
        }
    }

    .access-secret {
        border-radius: 7px;
        padding: 14px;
        display: block;
        background-color: $color-type3;
        margin-top: 25px;
        margin-bottom: 25px;

        &.granted {
            background-color: #BEF2D6;

            .icon {
                background-color: rgba(#fff, .5);
            }

            .button--black {
                background-color: #1DAB5F;

                &:hover {
                    background-color: darken(#1DAB5F, 10%);
                }
            }
        }

        .access-secret__flag {
            display: flex;

            h3 {
                margin-top: 6px;
            }

            p {
                margin-bottom: 10px;
                font-size: 15px;
            }
        }

        .icon {
            min-width: 30px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $color-type1;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }

        .button {
            white-space: nowrap;
        }

        @media(max-width: 500px) {
            padding: 14px;
            border-radius: 10px;
            background-color: $color-type3;
            display: block;

            p {
                margin-bottom: 0;
            }

            .button {
                background-color: $color-type18;
                height: 40px;
                width: 100%;
                margin-top: 14px;
                font-weight: 400;
                color: $color-type1;
            }

        }
    }

    .user-info__actions {
        display: grid;
        grid-template-columns: 1fr 145px;
        gap: 45px;
        width: 100%;

        .button {
            width: 100%;
        }

        @media(max-width: 500px) {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: 999;
            background-color: $color-type3;
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            border-radius: 10px 10px 0 0;
            gap: 20px;

            .button {
                background-color: $color-type19;
                height: 40px;
                width: 170px;
                min-width: 170px;

            }

            .actions-box {
                background-color: transparent;
                width: 100%;
                height: auto;
                margin-bottom: 0;
                padding: 0;
                justify-content: space-around;
                max-width: 180px;
            }

            .vertical {
                display: none!important;
            }
        }
    }

    .actions-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .like {
            &.is-active {
                path {
                    fill: $color-type19;
                    stroke: $color-type19;

                }
            }
        }
    }
    .settings {
        height: 22px;
        display: block;
        width: 22px;

        svg {
            width: 30px;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }

    .user-about {
        h3:not(.h2) {
            margin-bottom: 15px;
            font-size: 18px;
            line-height: 26px;
            color: $color-type12;

            &:not(:first-of-type) {
                margin-top: 35px;

            }
        }

        p {
            color: $color-type12;
            line-height: 1.5;
        }
    }

    .lifestyle {
        li {
            color: $color-type12;
            font-size: 16px;
            padding: 12px 0;
            display: flex;
            line-height: 1.6;
            justify-content: space-between;
            border-bottom: 1px solid #DEDEDE;

            span {
                max-width: 160px;
                line-height: 1.2;
                height: auto;
                border-radius: $button-tag-radius;
                padding: 3px 10px;
                background-color: $color-type18;
                color: $color-type1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    video {
        max-width: 100%;
    }

    .slider-info {
        position: absolute;
        left: 0;
        bottom: 20px;
        text-align: center;
        width: 100%;
        padding: 20px 0;
        bottom: 0;
        background: linear-gradient(0deg, rgba(0,0,0,1) 17%, rgba(0,0,0,0) 100%);

        p {
            margin-bottom: 8px;
            font-size: 18px;
            opacity: 0.8;
            color: $color-type1;
        }

        .button {
            height: $button1-height;
            border-radius: $button1-radius;
            border:2px solid $color-type19;
            padding: 0 28px;
            font-size: 18px;
            color: $color-type19;
            background-color: transparent;
            width: auto;

            &:hover {
                background-color: $color-type19;
                color: $color-type18;
            }
        }
    }
</style>

<style lang="scss">

    .modal-mask {
        @media(max-width: 500px) {
            align-items: flex-end;
            background-color: $color-type18;
        }
    }

    .actions-box {

        .like {

            svg {
                width: 26px;
            }
        }
    }
</style>