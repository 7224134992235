<template>

    <a class="back" @click.self.prevent="$emit('close');">
        <svg xmlns="http://www.w3.org/2000/svg" width="14.004" height="14.001"><path data-name="Union 6" fill="#fff" d="M6.289 13.708.262 7.669a1 1 0 0 1 .03-1.379l6-6a1 1 0 0 1 1.417 0 1 1 0 0 1 0 1.413l-4.3 4.295H13a1 1 0 0 1 1 1 1 1 0 0 1-1 1H3.41l4.292 4.3a1 1 0 0 1 0 1.412A1 1 0 0 1 7 14a1 1 0 0 1-.711-.292Z"/></svg>
    </a>
    <div @click.self.prevent="$emit('close'); this.displaying = -1;" class="slider-overlay">
        <a v-if="slides.length > 1" class="prev" @click="previous">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m8.5 12.8 5.7 5.6c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-4.9-5 4.9-5c.4-.4.4-1 0-1.4-.2-.2-.4-.3-.7-.3-.3 0-.5.1-.7.3l-5.7 5.6c-.4.5-.4 1.1 0 1.6 0-.1 0-.1 0 0z" fill="#fff"/></svg>
        </a>
        <a class="close" @click="$emit('close');">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" viewBox="0 0 10.003 10"><path  fill="#fff" data-name="Icon ionic-ios-close" d="M6.187 5 9.76 1.427A.837.837 0 0 0 8.576.243L5.003 3.811 1.43.238A.837.837 0 1 0 .246 1.422L3.815 5 .242 8.573a.837.837 0 1 0 1.184 1.184l3.573-3.573 3.573 3.573a.837.837 0 1 0 1.184-1.184Z"/></svg>
        </a>
        <div @click.self.prevent="$emit('close'); this.displaying = -1;" class="slider-inner">
            <div ref="slider-wrapper" class="slide-wrapper">
                <transition-group tag="div" :name="direction">
                    <div class="slide" :key="displaying">
                        <slot name="content" :displaying="displaying"></slot>
                    </div>
                </transition-group>
            </div>
        </div>
        <div class="slider-footer">
            <slot name="footer"></slot>
        </div>
        <a  v-if="slides.length > 1" class="next" @click="next">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.54 11.29 9.88 5.64a1 1 0 0 0-1.42 0 1 1 0 0 0 0 1.41l4.95 5L8.46 17a1 1 0 0 0 0 1.41 1 1 0 0 0 .71.3 1 1 0 0 0 .71-.3l5.66-5.65a1 1 0 0 0 0-1.47Z" fill="#fff"/></svg>
        </a>
    </div>
</template>

<script>
import { Swipe } from "@/service/photo/swipe";

export default {
    name: "Slider",
    emits: ['close', 'displaying'],
    props: {
        slides: {
            type: Array,
        },
        current: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            displaying: 0,
            direction: '',
            swipe: null

        };
    },

    methods: {

        touchStartHandler(event) {
            console.log('start', event)
            this.swipe.start(event)

        },
        touchEndHandler(event) {
            console.log('end', event)
            this.swipe.end(event)
        },

        next() {

            this.direction = 'slide-out'

            if(this.displaying >= this.slides.length - 1) {
                this.displaying = 0
            } else {
                this.displaying += 1
            }

            this.$emit('displaying', this.displaying)

        },

        previous() {

            this.direction = 'slide-in'

            if(this.displaying <= 0) {
                this.displaying = this.slides.length - 1
            } else {
                this.displaying -= 1
            }

            this.$emit('displaying', this.displaying)

        },

        keydownHandler(e) {
            e.preventDefault();
            
            switch (e.key) {
            case "Escape":
                this.$emit('close')
                break;
            case "ArrowRight":
                this.next()
                break;
            case "ArrowLeft":
                this.previous()
                break;
            default:
                break;
            }
        }
    },

    mounted() {
        this.displaying = this.current
        document.getElementsByTagName('body')[0].classList.add('modal-active')
        this.swipe = new Swipe({time: 800, pixels: 15, nextReturnValue: 'next', previousReturnValue: 'previous', debug: true})
        this.swipe.handler((direction) => this[direction]())
        this.$refs["slider-wrapper"].addEventListener( 'touchstart', e => this.touchStartHandler(e), {passive: true})
        this.$refs["slider-wrapper"].addEventListener( 'touchend', e => this.touchEndHandler(e), {passive: true})
        this.$emit('displaying', this.displaying)
        document.addEventListener("keydown", this.keydownHandler)
    },
    beforeUnmount() {
        document.getElementsByTagName('body')[0].classList.remove('modal-active')
        this.$refs["slider-wrapper"].removeEventListener('touchstart', e => this.touchStartHandler(e))
        this.$refs["slider-wrapper"].removeEventListener('touchend', e => this.touchStartHandler(e))
        document.removeEventListener("keydown", this.keydownHandler)
    }
}
</script>

<style scoped lang="scss">

    html {
        touch-action: manipulation;
    }

    .back {
        display: none;

        svg {
            pointer-events: none;
        }

        @media(max-width: 991px) {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            left: 20px;
            top: 15px;
            z-index: 9999999;
        }
    }

    .close {
        display: none;

        @media(min-width: 992px) {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            right: 30px;
            top: 30px;
            z-index: 999999;
        }
    }

    .prev,
    .next {
        position: absolute;
        top: 0;
        transition:all .3s ease;
        height: 100%;
        z-index: 9;
        display: flex;
        align-items: center;

        svg {
            width: 40px;
        }
    }

    .prev {
        left: 0;
        padding-right: 20px;
        padding-left: 20px;

        @media(max-width: 500px) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .next {
        right: 0;
        padding-right: 20px;
        padding-left: 20px;

        @media(max-width: 500px) {
            padding-right: 0;
            padding-left: 0;

        }
    }

    .controls {
        z-index: 100;
    }

    .slider-overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.93);
        z-index: 999999;
        display: flex;
        justify-content: center;

        @media(max-width: 500px) {
            background-color: $color-type18;
        }
    }

    .slider-inner {
        position: relative;
        width: 900px;
        max-width: 90%;
        max-height: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
    }

    .slide {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;

    }


    .slide-in-enter-active,
    .slide-in-leave-active,
    .slide-out-enter-active,
    .slide-out-leave-active {
        transition: all 200ms ease-in-out;
    }
    .slide-in-enter-from {
        transform: translateX(-100%) !important;
    }
    .slide-in-leave-to {
        transform: translateX(100%) !important;
    }
    .slide-out-enter-from {
        transform: translateX(100%) !important;
    }
    .slide-out-leave-to {
        transform: translateX(-100%) !important;
    }

    @media(max-width: 768px) {
        .slider-inner {
            max-width: 100%;
        }
    }
</style>
