export const TYPE_FAVORITE                  = 'favorite'
export const TYPE_MESSAGE                   = 'message'
export const TYPE_ACCESS_REQUESTED          = 'access_requested'
export const TYPE_MATCH                     = 'match'
export const TYPE_ACCESS_APPROVED           = 'access_approved'
export const TYPE_PROFILE_VISIT             = 'profile_visit'

export const TYPE_ANIMATION_SEARCH_MATCH    = 'search_match'
export const TYPE_ANIMATION_ONLINE          = 'online'
export const TYPE_ANIMATION_NEW_VIDEO       = 'new_video'
export const TYPE_ANIMATION_PREMIUM         = 'premium'
export const TYPE_ANIMATION_OFFER           = 'offers'


export const ORGANIC_EVENTS = [
    TYPE_FAVORITE, TYPE_MESSAGE, TYPE_ACCESS_REQUESTED,
    TYPE_MATCH, TYPE_ACCESS_APPROVED, TYPE_PROFILE_VISIT
]

export const ANIMATION_EVENTS = [
    TYPE_ANIMATION_SEARCH_MATCH, TYPE_ANIMATION_ONLINE, TYPE_ANIMATION_NEW_VIDEO, TYPE_ANIMATION_PREMIUM,
    TYPE_ANIMATION_OFFER
]