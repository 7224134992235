import Echo from "laravel-echo";
window.Pusher = require('pusher-js');
import store from "@/store";

let echo = null

export const connect = () => {
    if( ! echo ) {
        echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.VUE_APP_PUSHER_APP_KEY,
            wsHost: process.env.VUE_APP_PUSHER_HOST,
            wsPort: process.env.VUE_APP_PUSHER_PORT,
            wssPort: process.env.VUE_APP_PUSHER_PORT,
            forceTLS: false,
            encrypted: true,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
            cluster: process.env.VUE_APP_PUSHER_CLUSTER,
            channelAuthorization: {
                endpoint: `${process.env.VUE_APP_WEBSOCKET_URL}broadcasting/auth`,
                headers: {
                    'Authorization': `Bearer ${store.state.authenticate?.auth?.token}`,
                    'Accept': 'application/json'
                }
            }
        });

        return new Promise(resolve => {

            echo.connector.pusher.connection.bind("connected", () => {
                store.commit('application/setConnected', true)
                return resolve(echo)
            })

            echo.connector.pusher.connection.bind("unavailable", function (error) {
                store.commit('application/setConnected', false)
                console.error("Connection unavailable", error);
            });

            echo.connector.pusher.connection.bind("failed", function (error) {
                store.commit('application/setConnected', false)
                console.error("Connection failed", error);
            });

        })


    }

    return Promise.resolve(echo)



}

export const connection = () => echo

export const disconnect = () => {
    if(echo) {
        echo.disconnect()
        echo = null
    }
}

export const addedToFavorites = (connection) => {
    return connection.private(`added_to_favorites.${store.state.authenticate.auth.user.id}`)
}

export const removedFromFavorites = (connection) => {
    return connection.private(`removed_from_favorites.${store.state.authenticate.auth.user.id}`)
}

export const newMessage = (connection) => {
    return connection.private(`message_to_user.${store.state.authenticate.auth.user.id}`)
}

export const newVisitor = connection => {
    return connection.private(`profile_visit.${store.state.authenticate.auth.user.id}`)
}

export const newRequest = connection => {
    return connection.private(`access_request.${store.state.authenticate.auth.user.id}`)
}

export const accessResponse = connection => {
    return connection.private(`access_response.${store.state.authenticate.auth.user.id}`)
}

export const systemNotifications = connection => {
    return connection.private(`user.${store.state.authenticate.auth.user.id}.system`)
}

export const itemReady = connection => {
    return connection.private(`user.${store.state.authenticate.auth.user.id}.item_updated`)
}

export const creditsUpdated = connection => {
    return connection.private(`credits_updated.${store.state.authenticate.auth.user.id}`)
}

export const presenceChannel = connection => {
    connection.join('user_online')
}