export default {
    namespaced: true,

    state: {
        available: []
    },

    mutations: {
        setAvailable(state, payload) {
            state.available = payload
        }
    },

    actions: {
        setAvailable(context, payload) {
            context.commit('setAvailable', payload)
        },

        add(context, payload) {
            context.commit('setAvailable', [...context.state.available, payload])
        }
    }
}