import axios from "axios";

export const getNotifications = () => {
    return axios.get('user/notifications')
}

export const putNotifications = data => {
    return axios.put('user/notifications', data)
}

export const subscribeToPushNotifications = token => axios.post('user/notifications/push', {token})