import {pushDataLayer} from "@/service/gtm";
import {GTM_EVENT_BUY_CREDITS_CLICK} from "@/service/gtm/types";
import store from "@/store";
import {handleInsufficientFundsError} from "@/service/user";
import router from "@/router";

export const requestBuyCreditsRouteHandler = async () => {

    pushDataLayer(GTM_EVENT_BUY_CREDITS_CLICK, store.state.authenticate.auth.user)

    if( ! store.state.credits.amount) {
        return await handleInsufficientFundsError()
    }

    await router.push({name: 'credits'})

}