import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
            logged: 'authenticate/logged',
            auth: 'authenticate/auth',
            profilePhoto: 'authenticate/profilePhoto'
        })
    }
}