import { allPromotions } from "@/api/credits";
import store from "@/store";
import {SONI_FLOW_1} from "@/types/flows";

class PromotionComponent {

    constructor(promotion, component, header) {
        this.promotion = promotion
        this.component = component
        this.header    = header
    }

}

const createPromotionComponent = promotion => {

    switch (promotion?.template) {
        case 'time_limited':
            return new PromotionComponent(promotion, 'LimitedPromotionDays', 'LimitedPromotionDaysHeader')
        case 'quantity_limited':
            return new PromotionComponent(promotion, 'LimitedPromotionPackage', 'LimitedPromotionPackageHeader')
        case 'discount':
            return new PromotionComponent(promotion, 'Discount', 'DefaultPromotionHeader')
        case 'black_friday_50':
            return new PromotionComponent(promotion, 'BlackFriday50', 'BlackFriday50Header')
        case 'black_friday_20':
            return new PromotionComponent(promotion, 'BlackFriday20', 'BlackFriday20Header')
        case SONI_FLOW_1:
            return new PromotionComponent(promotion, 'SFlowOne', 'SFlowOneHeader')
        default:
            return new PromotionComponent(promotion, 'DefaultPromotion', 'DefaultPromotionHeader')

    }
}

/**
 *
 * @param {Array} promotions
 * @returns {PromotionComponent}
 */
export const getDefaultPromotion = promotions => {

    const defaultPromotion = promotions.find(e => e.default) ?? promotions[0]
    const outPromotion = promotions.find(e => ! e.default) ?? defaultPromotion

    return createPromotionComponent(outPromotion)

}

export const setAvailablePromotions = async() => {

    const { data } = await allPromotions()

    await store.dispatch('promotions/setPromotions', data)

}