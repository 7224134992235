<template>
    <div class="progress-bar">
        <div class="bar" :style="'width:' +  completed + '%'"></div>
    </div>
</template>

<script>
export default {
    name: "ProgressBar",
    props: {
        completed: {
            type: Number,
            default: 0,
        }
    }
}
</script>


<style scoped lang="scss">
    .progress-bar {
        background-color: #e6e6e6;
        height: 10px;
        width: 100%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 10px;

        span {
            color: $color-type17;
            font-weight: 700;
        }

        .bar {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            border-radius: 5px;
            background-color: $color-type18;
            transition: all .3s ease;
        }

        @media(max-width: 991px) {
            height: 10px;
            border-radius: 5px;
            margin-bottom: 5px;
        }
    }

</style>