import axios from "axios";

export const createPreSignedURL = extension => {
    return axios.post('item/pre-signed', {extension})
}

export const uploadToS3PreSigned = (preSignedPayload, formData, parameters) => {
    return axios.create({headers: null}).post(preSignedPayload.formAttributes.action, formData, parameters)
}

export const createItem = data => {
    return axios.post('item', data)
}