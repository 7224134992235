<template>
    <div>
        <Header v-if="!logged" @request-register="$emit('request-register')" />
        <main>
            <section class="terms">
                <div class="o-container">
                    <article>
                        <div class="header-mob">
                            <router-link :to="{name: 'search'}" class="back-mob"><svg xmlns="http://www.w3.org/2000/svg" width="14.004" height="14.001"><path data-name="Union 6" d="M6.289 13.708.262 7.669a1 1 0 0 1 .03-1.379l6-6a1 1 0 0 1 1.417 0 1 1 0 0 1 0 1.413l-4.3 4.295H13a1 1 0 0 1 1 1 1 1 0 0 1-1 1H3.41l4.292 4.3a1 1 0 0 1 0 1.412A1 1 0 0 1 7 14a1 1 0 0 1-.711-.292Z"/></svg></router-link>
                            <h1>{{$t('navigation.footer.terms')}}</h1>
                        </div>
                        <div v-html="$t('terms.raw')"></div>
                    </article>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import Header from "@/components/Header";
export default {
    name: 'Terms',
    mixins: [AuthenticatedMixin],
    components: {Header}
}
</script>

<style scoped lang="scss">
    .header-mob {
        display: none;

        @media(max-width: 500px) {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            h1 {
                margin-bottom: 0!important;
                font-size: 22px!important;
                font-weight: 700!important;
            }

            .back-mob {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                margin-right: 10px;
            }
        }
    }

    .terms {
        padding: 70px 0;

        article {
            width: 100%;
            margin: 0 auto;
            max-width:650px;

            h1 {
                font-weight: 900;
                margin-bottom: 48px;
                font-size: 26px;
            }

            h2 {
                margin-bottom: 15px;
                margin-top: 35px;
            }
        }

        @media(max-width: 991px) {
            padding: 40px 0 70px;

            article {
                h1 {
                    margin-bottom: 20px;
                }

                br {
                    display: none;
                }
            }

            ul {
                padding-left: 15px;
            }
        }

        @media(max-width: 500px) {
            padding: 20px 0 70px;

        }
    }
</style>

<style lang="scss">
    .terms {
        ul {
            padding-left: 30px;
            list-style-type: disc;
        }

        li {
            padding: 10px 10px;
            line-height: 1.5;

        }

        em {
            line-height: 1.5;
            font-style: italic;
            font-size: 14px;
        }

        p {
            line-height: 1.5;

            a {
                color: #bfa46a;
            }
        }

        @media(max-width: 991px) {
            ul {
                padding-left: 15px;
            }
        }

        .heading-mob {
            @media(max-width: 500px) {
                display: none;
            }
        }
    }
</style>
