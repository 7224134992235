<template>
    <main>
        <section class="credits" :class="{'black-friday': ['black_friday_50', 'black_friday_20'].includes(promo.promotion?.template)}">
            <div class="o-container">
                <Payment @success="successHandler" :promo="promo">
                    <template #footer>
                        <PaymentContent :template="promo.promotion?.template !== SONI_FLOW_1"/>
                    </template>
                </Payment>
            </div>
        </section>
    </main>
</template>

<script>
import ThemeMixin from "@/mixins/ThemeMixin";
import { pushDataLayer } from "@/service/gtm";
import { GTM_EVENT_VIEW_CREDITS_PAGE} from "@/service/gtm/types";
import {getDefaultPromotion} from "@/service/promotions";
import {mapState} from "vuex";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import PaymentContent from "@/components/payment/PaymentContent.vue";
import Payment from "@/components/payment/Payment.vue";
import {includes} from "core-js/internals/array-includes";
import {SONI_FLOW_1} from "@/types/flows";

export default {
    name: "Credits",
    mixins: [ThemeMixin, AuthenticatedMixin],
    components: {
        Payment,
        PaymentContent
    },
    emits: ['requestRegister'],
    computed: {
        ...mapState({
            promo: state => getDefaultPromotion(state.promotions.promotions),
            card: state => state.credits.card
        }),

    },
    methods: {
        SONI_FLOW_1() {
            return SONI_FLOW_1
        },
        includes,
        successHandler(data) {

            if(data.no_action) {
                return
            }

            if(data.payment_url) {
                return window.location.href = data.payment_url
            }

            return this.$router.push({name: 'status', query: {ti_mer: data.id}})
        }
    },
    created() {
        this.SONI_FLOW_1 = SONI_FLOW_1
    },
    mounted() {
        pushDataLayer(GTM_EVENT_VIEW_CREDITS_PAGE, this.$store.state.authenticate.auth.user)
    },
}
</script>

<style scoped lang="scss">

main {
    height: auto;
    min-height: 100%;
}



.credits {
    padding: 50px 0;

    &.black-friday {
        background-color: #000;
    }

    @media(max-width: 991px) {
        padding-top: 15px;

        .o-container {
            padding: 0;
        }
    }
}

</style>

<style lang="scss">

.black-friday {
    .price__heading {
        .back-button {
            svg {
                path {
                    fill: #fff;

                }
            }
        }

        h2 {
            color: #fff;
        }
    }


    .payment-type {

        h3 {
            color: #fff;
        }
    }
}

</style>