<template>
    <Header @request-register="$emit('request-register')" />
    <section class="delete-profile-page">
        <div class="o-container">
            <article>
                <img class="main-logo" :src="require(`@/assets/img/${theme.toLowerCase()}.svg`)" alt="">
                <svg class="emoy" xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120"><g data-name="Group 474" transform="translate(-120 -208)"><rect width="120" height="120" fill="#dedede" data-name="Rectangle 241" rx="60" transform="translate(120 208)"/><path fill="#fff" d="M164.707 242.847a6.187 6.187 0 0 1 4.688 1.656 6.735 6.735 0 0 1 1.588 4.839 6.543 6.543 0 0 1-1.624 4.743 6.171 6.171 0 0 1-4.65 1.679 6.171 6.171 0 0 1-4.595-1.7 6.426 6.426 0 0 1-1.681-4.722 6.618 6.618 0 0 1 1.642-4.8 6.145 6.145 0 0 1 4.632-1.695Zm30.485 0a6.187 6.187 0 0 1 4.691 1.656 6.735 6.735 0 0 1 1.584 4.839 6.543 6.543 0 0 1-1.624 4.743 6.171 6.171 0 0 1-4.65 1.679 6.108 6.108 0 0 1-4.632-1.716 6.525 6.525 0 0 1-1.642-4.706 6.664 6.664 0 0 1 1.624-4.8 6.133 6.133 0 0 1 4.65-1.695Zm-15.057 29.179a57.193 57.193 0 0 1 18.306 2.86 45.509 45.509 0 0 1 14.947 8.249v9.227a53.145 53.145 0 0 0-15.649-7.859 58.576 58.576 0 0 0-17.531-2.66 56.718 56.718 0 0 0-17.475 2.713 52.921 52.921 0 0 0-15.261 7.732v-9.153a43.494 43.494 0 0 1 14.652-8.267 55.71 55.71 0 0 1 18.011-2.842Z" data-name="Path 187"/></g></svg>
                <h1><strong>{{$t('profile.delete.deleted')}}</strong></h1>
                <p>{{$t('profile.delete.thank_you')}}</p>
            </article>
        </div>
    </section>
</template>

<script>
import Header from "@/components/Header";
import ThemeMixin from "@/mixins/ThemeMixin";
export default {
    name: "DeletedProfilePage",
    mixins: [ThemeMixin],
    emits: ['requestRegister'],
    components: {Header},

    data() {
        return {
            theme: '',
        }
    },
    created() {
        this.theme = process.env.VUE_APP_THEME
    }
}
</script>

<style lang="scss" scoped>
    .delete-profile-page {
        height: 100%;
        padding: 20px 0 50px;

        .o-container {
            height: 100%;
        }

        article {
            padding: 60px 100px;
            border-radius: 10px;
            background-color: #fff;
            min-height: 100%;
            height: auto;
            overflow: hidden;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            h1 {
                letter-spacing: -0.9px;
            }

            p {
                font-size: 18px;
                line-height: 28px;
            }

            .main-logo {
                margin-bottom: 60px;
            }
        }

        .emoy {
            margin-bottom: 30px;
        }

        @media(max-width: 991px) {
            padding: 10px 0;
            // height: 50vh;

            article {
                padding: 20px;
            }
        }

        @media(max-width: 480px) {
            padding: 40px 0 0;
            height: auto;

            .main-logo {
                margin-bottom: 20px!important;
            }

            .emoy {
                width: 80px;
            }
        }
    }
</style>