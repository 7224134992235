import axios from "axios";

export const search = (query = null) => {
    return axios.get(`user/search${query ? ('?' + query) : ''}`)
}

export const user = id => {
    return axios.get(`user/${id}`)
}

export const userGallery = (id, query = null) => {
    return axios.get(`user/${id}/photos?` + (query ?  new URLSearchParams(query).toString() : ''))
}

export const userVideoGallery = (id, query = null) => {
    return axios.get(`user/${id}/videos?` + (query ?  new URLSearchParams(query).toString() : ''))
}

export const getUserAccess = user_id => {
    return axios.get(`access/${user_id}`)
}

export const getUserOffer = user_id => {
    return axios.get(`access/offer/${user_id}`)
}

export const report = (user_id, reason, type) => {
    return axios.post(`/user/${user_id}/report`, {reason, type})
}

export const block = (user_id, reason) => {
    return axios.post(`/user/${user_id}/block`, {reason})
}

export const blockedUsers = (query = null) => {
    return axios.get(`user/blocked${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const unblock = user_id => axios.post(`user/${user_id}/unblock`)

export const recommended = () => axios.get('user/recommended')