<template>
    <main>
        <Register :gender="$route.params.gender" />
    </main>
</template>

<script>

import Register from "@/components/auth/Register";

export default {
    name: "RegisterPage",
    components: {
        Register,
    },
    beforeCreate() {
        if( ! this.$route.params.gender || ! ['man', 'woman'].includes(this.$route.params.gender))
            this.$router.push({name: 'home'})

    },
    mounted() {

    }
}
</script>

<style scoped lang="scss">

    main {
        min-height: 100vh!important;
        position: relative;
        height: auto;

        @media(max-width: 991px) {

            .header-main {
                box-shadow: none;
                background-color: #fff;
            }
        }
    }

    .type {
        .nav-item {
            margin-left: 20px;
        }
    }
</style>