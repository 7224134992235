export const COLLECTABLE_PARAMS = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
    "cid",
    "clickid",
    "flow"
]

/**
 *
 * @param query
 * @returns Object
 */
export const collectMarketingParams = query => {

    const payload = {}

    COLLECTABLE_PARAMS.forEach(parameter => {
        if(query[parameter]) {
            payload[parameter] = query[parameter]
        }
    })

    return payload

}

export const storeMarketingParams = params => {

    if( ! Object.keys(params).length)
        return

    localStorage.setItem('marketing', JSON.stringify(params))
}

export const loadMarketingParams = () => {


    if( ! localStorage.getItem('marketing'))
        return null

    try {

        const payload = JSON.parse(localStorage.getItem('marketing'))

        return COLLECTABLE_PARAMS.reduce((acc, item) => {
            if(payload[item]) {

                if(['cid', 'clickid'].includes(item)) {
                    acc.click_id        = payload[item]
                    acc.click_id_source = item === 'cid' ? 'voluum' : 'refchamp'
                } else {
                    acc[item] = payload[item]
                }

            }
            return acc
        }, {})


    } catch {
        return null
    }
}