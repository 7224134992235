<template>
    <main>
        <div class="o-container">
            <section class="tabs-pages">
                <article>
                    <h1>Secret access</h1>
                    <ul class="tabs-pages__list">
                        <li>
                            <router-link :class="`font-weight-${page === '/access/my-access' ? 'bold' : 'normal'}`" :to="{name: 'my-access'}">
                                <div v-if="count('access', 'my_access')" class="messages-numb">{{ count('access', 'my_access') }}</div>
                                {{ $t('navigation.sub_nav.my_access') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="`font-weight-${'/access/pending' === page ? 'bold' : 'normal'}`" :to="{name: 'pending'}">
                                <div v-if="count('access', 'pending')" class="messages-numb">{{ count('access', 'pending') }}</div>
                                {{ $t('navigation.sub_nav.pending') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="`font-weight-${'/access/approved' === page ? 'bold' : 'normal'}`" :to="{name: 'approved'}">{{$t('navigation.sub_nav.approved')}}
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="`font-weight-${'/access/declined' === page ? 'bold' : 'normal'}`" :to="{name: 'declined'}">{{$t('navigation.sub_nav.declined')}}
                            </router-link>
                        </li>
                    </ul>
                </article>
                <router-view v-slot="{ Component }">
                    <transition name="fade" mode="out-in">
                        <component :is="Component" />
                    </transition>
                </router-view>
            </section>
        </div>
    </main>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "AccessPage",
    computed: {
        page() {
            return this.$route.path
        },
        ...mapGetters({
            count: 'notifications/countSocial'
        })
    }
}
</script>

<style scoped lang="scss">
    main {
        display: flex;

        @media(max-width: 991px) {
            background-color: #fff;
            height: 100%!important;
        }
    }
</style>