<template>
    <Modal class="modal modal--password" :closable="false" :size="'medium'" :modalStyle="'padding:80px 105px 80px 105px'">
        <template #header>
            <div class="u-text-center">
                <h1>{{$t('profile.update_password.title')}}</h1>
                <p class="text">
                    {{$t('profile.update_password.description.2')}}
                </p>
            </div>
        </template>
        <template #body>
            <Form ref="registrationForm" :validation-schema="validationSchema" @submit="update">

                <div class="input-box">
                    <div class="o-flex o-flex--center o-flex--justify">
                        <p>{{$t('form_fields.email_label')}}</p>
                        <CheckMark :text="ucFirst($t('profile.update_password.next'))" />
                    </div>
                    <Field type="text" class="input input--primary" :value="auth.user.email" readonly name="email" />
                </div>
                <div class="input-box">
                    <div class="o-flex o-flex--center o-flex--justify">
                        <p>{{$t('form_fields.password_label')}}</p>
                        <CheckMark v-if="password.length >= 6 && password.length <= 45" :text="ucFirst($t('profile.update_password.next'))" />
                    </div>
                    <Field :type="passwordShowNew?'password':'text'" :validateOnInput="true" class="input input--primary" :placeholder="$i18n.t('form_fields.password_placeholder')" v-model="password" name="password" />
                    <a class="view" @click="passwordShowNew = !passwordShowNew">
                        <svg class="show-pass" xmlns="http://www.w3.org/2000/svg" width="18" height="12"><path data-name="Icon awesome-eye" d="M17.891 5.544A10.023 10.023 0 0 0 9 0 10.025 10.025 0 0 0 .109 5.544a1.011 1.011 0 0 0 0 .912A10.023 10.023 0 0 0 9 12a10.025 10.025 0 0 0 8.891-5.544 1.011 1.011 0 0 0 0-.912ZM9 10.5A4.5 4.5 0 1 1 13.5 6 4.5 4.5 0 0 1 9 10.5ZM9 3a2.978 2.978 0 0 0-.791.118 1.5 1.5 0 0 1-2.091 2.091A2.993 2.993 0 1 0 9 3Z" fill="#4b4b4b"/></svg>
                    </a>
                    <ErrorMessage class="text-error text-left form-text" name="password"  />
                </div>

                <button ref="passwordButton" :disabled="! password.length" type="submit" class="button button--primary w-100">{{$t('profile.update_password.update')}}</button>
            </Form>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal";
import { Form, Field, ErrorMessage } from 'vee-validate'
import { defineRule } from 'vee-validate';
import {required, min, max } from '@vee-validate/rules'
import { updateProfile } from "@/service/user";
import toastr from "toastr";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { ucFirst } from "@/utilities/general";
import CheckMark from "@/components/generic/CheckMark";

export default {
    name: "PreActionPassword",
    mixins: [AuthenticatedMixin],
    components: {Modal, Form, Field, ErrorMessage, CheckMark},
    data() {
        return {
            password: '',
            validationSchema: null,
            passwordShowNew: true
        }
    },
    created() {
        defineRule('required', required)
        defineRule('min', min)
        defineRule('max', max)

        this.validationSchema = {
            password: 'required|min:6|max:45'
        }
    },
    methods: {
        ucFirst,
        async update() {

            this.$refs.passwordButton.disabled = true

            try {
                await updateProfile({password: this.password})
                this.$emit('success')
            } catch {
                toastr.error(this.$i18n.t('credits.error_processing'))
            }

            this.$refs.passwordButton.disabled = false

        }
    }
}
</script>

<style scoped lang="scss">

    h1 {
        font-size: 26px;
    }

    .text {
        color: $color-type12;
        margin: 20px auto;
        max-width: 374px;
        line-height: 1.1;
        font-size: 15px;
    }

    .button--primary {
        margin-bottom: 0;
        height: 45px;
        margin-top: 10px;
        text-transform: uppercase;
    }
</style>