<template>
    <div>
        <transition name="fade" mode="out-in">
            <div class="overlay-mob" @click="dropdownTrigger = false" v-if="dropdownTrigger"></div>
        </transition>
        <transition name="fade" mode="out-in">
            <div class="overlay" @click="dropdownProfileTrigger = false; " v-if="dropdownProfileTrigger"></div>
        </transition>

        
        <!-- :class="{'noSearchbar':$route.meta?.headerRemoveSearchbar }" -->
        <header class="header-main" :class="{'profile-header': $route.name === 'general' || $route.name === 'user', 'noSearchbar':$route.meta?.headerRemoveSearchbar}">
            <div class="o-container">
                <div class="nav-left o-flex o-flex--center">
                    <router-link active-class="active" :to="{name: 'search'}" class="header-logo">
                        <img :src="require(`@/assets/img/${theme}.svg`)" alt="main logo"/>
                    </router-link>
                    <transition name="fade" mode="out-in">
                        <div class="overlay-search" v-if="searchActive"></div>
                    </transition>
                    <div id="search_filters" class="search-filters-main" :class="{active: searchActive}">
                        <div class="search-header">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.004" height="14.001"><path d="M6.289 13.708.262 7.669a1 1 0 0 1 .03-1.379l6-6a1 1 0 0 1 1.417 0 1 1 0 0 1 0 1.413l-4.3 4.295H13a1 1 0 0 1 1 1 1 1 0 0 1-1 1H3.41l4.292 4.3a1 1 0 0 1 0 1.412A1 1 0 0 1 7 14a1 1 0 0 1-.711-.292Z" data-name="Union 6"/></svg>
                            <SearchLocation @active="(value) => {this.searchActive = value}" :rounded="'rounded'" :placeholder="$t('form_fields.enter_your_city')" :location="stateSearchLocation" :recent="historyLocations" @location-updated="setLocation" />
                        </div>
                    </div>
                </div>
                <nav class="header-nav">
                    <ul class="nav__list">
                        <li v-if="auth.user.gender === TYPE_GENDER_MALE" class="nav__list-item nav__list-item-buy-credits">
                            <a @click="buyCreditsClickHandler" href="javascript:void(0)" class="button button--primary button--header">{{$t('general.buy_credits')}}</a>
                        </li>
                        <li class="nav__list-item" :class="`nav-item${$route.name === 'search' ? ' active' : ''}`">
                            <router-link active-class="active" @click="menuItemClickHandler('search')"  :to="{name: 'search'}" class="nav__link">
                                <svg class="nav-icon-search" xmlns="http://www.w3.org/2000/svg" width="22.414" height="22.414"><g data-name="Group 195" transform="translate(15563 -6734)" fill="none" stroke="#000" stroke-width="2"><ellipse data-name="Ellipse 32" cx="8.955" cy="8.993" rx="8.955" ry="8.993" transform="translate(-15562 6735)"/><path data-name="Path 81" d="m-15542 6755-4.384-4.383" stroke-linecap="round"/></g></svg>
                            </router-link>
                        </li>
                        <li class="nav__list-item" :class="`nav-item${$route.name === 'messages' ? ' active' : ''}`">
                            <div v-if="unread" class="messages-numb"></div>
                            <a href="javascript:void(0)" :class="{active: (this.$route.name === 'messages')}" @click="messagesLinkClickHandler" class="nav__link">
                                <svg class="nav-icon-chat" xmlns="http://www.w3.org/2000/svg" width="22" height="22"><g data-name="Group 199" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"><path data-name="Path 4" d="M4.705 1h12.588A3.711 3.711 0 0 1 21 4.707V13.1a3.71 3.71 0 0 1-3.707 3.7h-6.4L6.45 20.61h0a1.616 1.616 0 0 1-1.041.382A1.612 1.612 0 0 1 3.8 19.391v-2.7A3.712 3.712 0 0 1 1 13.1V4.707A3.71 3.71 0 0 1 4.705 1Z" stroke-linejoin="round"/><path data-name="Path 82" d="M6.5 10.5h9"/><path data-name="Path 83" d="M6.5 6.5h9"/></g></svg>
                            </a>
                        </li>
                        <li class="nav__list-item">
                            <div v-if="grouped('matches')" class="messages-numb"></div>
                            <router-link active-class="active" @click="menuItemClickHandler('admirers')" :to="{name: 'admirers'}" :class="['admirers', 'favorites', 'visitors', 'viewed'].includes($route.name) ? ' router-link-active router-link-exact-active active' : ''" class="nav__link">
                                <svg class="nav-icon-matches" xmlns="http://www.w3.org/2000/svg" width="24" height="22"><path data-name="Shape 2" d="M7.234 1a5.7 5.7 0 0 0-2.964.8l-.035.021a6.6 6.6 0 0 0-3.236 5.78 5.716 5.716 0 0 0 .271 1.837l.026.071.016.074C2.177 13.606 8.435 18.654 12 21.004c3.566-2.352 9.813-7.367 10.645-11.274l.015-.069.023-.066a6.064 6.064 0 0 0 .318-1.994 6.606 6.606 0 0 0-3.22-5.771l-.027-.016a5.54 5.54 0 0 0-2.9-.77 6.228 6.228 0 0 0-4.13 1.539l-.726.654-.726-.653A6.048 6.048 0 0 0 7.234 1Z" fill="none" stroke="#000" stroke-linejoin="round" stroke-width="2"/></svg>
                            </router-link>
                        </li>
                        <li class="nav__list-item">
                            <div v-if="grouped('access')" class="messages-numb"></div>
                            <router-link active-class="active" @click="menuItemClickHandler('myaccess')" :to="{name: 'my-access'}" :class="['my-access', 'pending', 'approved', 'declined'].includes($route.name) ? ' router-link-active router-link-exact-active active' : ''" class="nav__link">
                                <svg class="nav-icon-access" xmlns="http://www.w3.org/2000/svg" width="26.164" height="25.416"><g data-name="Group 200" transform="rotate(-45 -15825.755 -15239.244)" fill="none" stroke="#000" stroke-width="2"><circle data-name="Ellipse 33" cx="6" cy="6" r="6" transform="translate(-15421 6739)"/><path data-name="Rectangle 100" stroke-linejoin="round" d="M-15403 6745h5v4h-5z"/><path data-name="Path 85" d="M-15409 6745.001h13.943" stroke-linecap="round"/></g></svg>
                            </router-link>
                        </li>
                        <li v-if="logged && ! mobile" class="nav__list-item user-info" v-click-outside="close">
                            <a class="nav__link bars" :class="dropdownTrigger? 'is-active': ''" @click="dropdownTrigger = ! dropdownTrigger">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </a>

                            <div class="user-info__dropdown" :class="dropdownTrigger?'is-active':''">
                                <ProfileMenu :key="1" @close="closeProfileMenu" :available-credits="credits" />
                            </div>
                        </li>
                        <li v-if="logged && mobile" class="nav__list-item user-info">
                            <a class="nav__link bars" :class="dropdownTrigger? 'is-active': ''"  @click="dropdownTrigger = ! dropdownTrigger">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
        <div class="profile-dropdown-mobile" :class="{'profile-header': ['general', 'user'].includes($route.name), 'is-active': dropdownTrigger}">
            <ProfileMenu @close="closeProfileMenu" :key="2" :available-credits="credits" />
        </div>
    </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { mapState, mapGetters, mapActions } from "vuex";
import ProfileMenu from "@/components/ProfileMenu";
import SearchLocation from "@/components/location/SearchLocation";
import { TYPE_GENDER_MALE } from "@/types/user";
import { pushDataLayer } from "@/service/gtm";
import {GTM_EVENT_PAGE_LINK_CLICK, SEARCH_LOCATION_FILTER} from "@/service/gtm/types";
import ThemeMixin from "@/mixins/ThemeMixin";
import { requestBuyCreditsRouteHandler } from "@/service/credits";


export default {
    name: "Navbar",
    mixins: [AuthenticatedMixin, ThemeMixin],
    components: { ProfileMenu, SearchLocation },
    data() {
        return {
            expanded: false,
            dropdownTrigger: false,
            dropdownProfileTrigger:false,
            searchActive: false
        }
    },
    computed: {
        ...mapState({
            credits: state => state.credits.amount,
            stateSearchLocation: state => state.search.location,
            historyLocations: state => state.search.historyLocations,
            mobile: state => state.application.mobile
        }),
        ...mapGetters({
            grouped: 'notifications/countGroupedSocial',
            unread: 'notifications/countConversations'
        }),
    },
    watch: {
        stateSearchLocation: {
            async handler() {
                if( this.$route.name !== 'search') {
                    await this.$router.push({name: 'search'})
                }
            },
            deep: true
        },
    },
    methods: {
        pushDataLayer,
        ...mapActions({
            setStateSearchLocation: 'search/setLocation'
        }),
        async messagesLinkClickHandler() {

            this.menuItemClickHandler('messages')

            if( this.$route.name !== 'messages') {
                await this.$router.push({name: 'messages'})
            }

        },
        buyCreditsClickHandler() {
            this.closeProfileMenu()
            requestBuyCreditsRouteHandler()
        },
        closeProfileMenu() {
            this.dropdownTrigger = false
            this.dropdownProfileTrigger = true
        },
        menuItemClickHandler(page) {
            this.closeProfileMenu()
            pushDataLayer(GTM_EVENT_PAGE_LINK_CLICK.replace('{page}', page), this.auth.user)
        },
        async setLocation(location) {
            if(location) {
                await this.setStateSearchLocation(location)
                this.page = 1
            }
            pushDataLayer(SEARCH_LOCATION_FILTER, this.auth.user)
        },

        close() {

            if(this.dropdownTrigger) {
                this.dropdownTrigger = false
            }
        }
    },
    created() {
        this.TYPE_GENDER_MALE = TYPE_GENDER_MALE
        this.SEARCH_LOCATION_FILTER = SEARCH_LOCATION_FILTER
    }
}
</script>

<style lang="scss">
    .search-header {
        width: 360px;

        @media (max-width: 991px) {
            width: 100%;
        }
    }
</style>

<style scoped lang="scss">

    .button--header {
        font-size: 14px;
        font-weight: 400;
    }

    @media(min-width: 992px) {

        .search-filters-main {
            position: relative;
            z-index: 999999;
        }
    }


    .overlay-search {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000000, .7);
        z-index: 99999;
        cursor: pointer;

        @media(max-width: 991px) {
            display: none;
        }
    }

    .overlay-mob {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-type18, .7);
        z-index: 8;
        cursor: pointer;
        display: none;

        @media (max-width: 991px) {
            display: block;
        }
    }

    .header-main {
        padding: 20px 0;

        .o-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 991px) {
                justify-content: flex-start;
            }
        }

        @media (max-width: 991px) {
            padding: 12px 0;
            // padding-right: 46px;

            &.noSearchbar {
                padding: 0;
            }
        }
    }

    .header-logo {
        display: block;
        margin-right: 30px;

        @media (max-width: 991px) {
            margin-right: 10px;

            img {
                max-width: inherit;
                width: 48px;
            }
        }
    }

    .header-main__bars {
        font-size: 30px;
        color: #000;
        display: none;

        @media (max-width: 992px) {
            display: block;
        }
    }

    .header-nav {
        display: flex;
        align-items: center;
        margin-right: -12px;

        @media (max-width: 991px) {
            justify-content: center;
            width: 100%;
            position: fixed;
            z-index: 999;
            left: 0;
            bottom: 0;
            height: 60px;
            width: 100%;
            background: transparent linear-gradient(270deg, #E1B685 0%, #B1784C 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px 10px 0px 0px;
            -webkit-overflow-scrolling: touch
        }
    }

    .nav-left {
        @media (max-width: 991px) {
            width: 100%;
            padding-left: 10px;

            .noSearchbar & {
                display: none;
            }

            #search_filters {
                width: 100%;
            }
        }
    }
    .nav__list {
        margin-bottom: 0;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            width: 100%;
            justify-content: space-around;
        }
    }

    .header-user-nav {
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            display: none;
        }
    }

    .nav__list-item {
        position: relative;

        &.nav__list-item-buy-credits {
            margin-right: 17px;

            @media (max-width: 991px) {
                display: none;
            }
        }

        &--mobile-show {
            display: none;
        }

        .messages-numb {
            min-width: 8px;
            height: 8px;
            background-color: $color-type32;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            position: absolute;
            bottom: 100%;
            right: 0;
            transform: translate(-2px, 1px);
        }

        @media (max-width: 991px) {
            &--mobile-hide {
                display: none;
            }
            &--mobile-show {
                display: flex;
            }
        }
    }

    .user-info {
        position: relative;
        padding: 0 12.5px;

        @media (max-width: 991px) {
            position: initial;
        }
    }

    .dropdown__trigger {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: $color-type4;
        cursor: pointer;

        >span {
            margin-left: 15px;
            margin-right: 8px;
            line-height: 1.3;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 700;
            display: block;
        }

        svg, path {
            transition: none;
        }
    }

    .user-info__dropdown {
        position: absolute;
        right: 0;
        top: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 8px;
        background-color: $color-type1;
        transition: max-height 0.3s cubic-bezier(0,1,0,1);
        max-height: 0;
        box-shadow: 0 2px 8px 0 rgba(0,0,0,0.16);
        min-width: 360px;
        padding: 0 20px;
        z-index: 10;
        margin-top: 20px;

        &.is-active {
            max-height: 99999px;
            transition: max-height 0.5s cubic-bezier(1,0,1,0);
        }

        @media (max-width: 991px) {
            display: none;
        }
    }

    .nav__link {
        padding: 0 12.5px;
        transition: all 0s ease;

        &.active {

            .nav-icon-search {
                g {
                    fill: #000;
                }
            }

            .nav-icon-chat {
                path {
                    fill: #000;
                }
            }

            .nav-icon-matches {
                path {
                    fill: #000;
                }
            }

            .nav-icon-access {
                circle {
                    fill: #000;
                }
            }
        }

        @media (max-width: 991px) {

            display: flex;
            align-items: center;

            .user-info & {

                path {
                    fill: #fff;
                }
            }

            span {
                display: none;
            }

            .nav-icon-search {
                g {
                    stroke: #fff;
                }
            }

            .nav-icon-chat {
                g {
                    stroke: #fff;
                }
            }

            .nav-icon-matches {
                path {
                    stroke: #fff;
                }
            }

            .nav-icon-access {
                g {
                    stroke: #fff;
                }
            }

            &.active {

                .nav-icon-search {
                    g {
                        fill: #fff;
                    }
                }

                .nav-icon-chat {
                    path {
                        fill: #fff;
                    }
                }

                .nav-icon-matches {
                    path {
                        fill: #fff;
                    }
                }

                .nav-icon-access {
                    circle {
                        fill: #fff;
                    }
                }
            }
        }
    }

    .bars {
        width: 25px;
        height: 20px;
        margin-top: 2px;
        padding: 0;
        position: relative;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        display: block;

        &:hover,
        &:focus {
            color: #000;
        }

        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: #000;

            border-radius: 2px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;

            @media (max-width: 991px) {
                background: #fff;
            }
        }


        span:nth-child(1) {
        top: 0px;
        }

        span:nth-child(2),span:nth-child(3) {
        top: 8px;
        }

        span:nth-child(4) {
        top: 16px;
        }

        &.is-active span:nth-child(1) {
            top: 10px;
            width: 0%;
            left: 50%;
        }

        &.is-active span:nth-child(2) {
            transform: rotate(45deg);
        }

        &.is-active span:nth-child(3) {
            transform: rotate(-45deg);
        }

        &.is-active span:nth-child(4) {
            top: 10px;
            width: 0%;
            left: 50%;
        }
    }

    .divider-vertical {
        width: 1px;
        height: 40px;
        background-color: $color-type12;
        margin-left: 30px;
        margin-right: 20px;
    }

    .amount {
        display: flex;
        align-items: center;
        margin-right: 25px;
        color: $color-type4;
        font-size: 16px;
    }
    .amount__icon {
        color: $color-type19;
        margin-right: 10px;
        font-size: 24px;
    }

    .avatar-wrap {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        img {
            object-fit: cover;
            object-position: 50% 50%;
        }
    }

    .profile-dropdown-mobile {
        display: none;

        @media (max-width: 991px) {
            display: block;
            position: fixed;
            left: 0;
            bottom: 0;
            overflow: hidden;
            background-color: $color-type1;
            transform: translate(0, 100%);
            transition: all .3s ease-in-out;
            box-shadow: 0 2px 8px 0 rgba(0,0,0,0.16);
            width: 100%;
            padding: 0 20px 60px;
            z-index: 9;
            max-height: 100%;
            overflow: auto;
            height: 100vh;
            opacity: 0;
            visibility: hidden;

            &.is-active {
                transform: translate(0, 0);
                opacity: 1;
                visibility: visible;
            }

            &.profile-header {
                display: none!important;
            }
        }
    }

</style>