<template>
    <div class="message-row__box" :class="message.type === TYPE_ATTACHMENT && message.attachment? 'with-image' : ''">
        <div :class="{blur: !conversation.system_approved_at && message.author_id !== auth.user.id && auth.user.gender === TYPE_GENDER_MALE}" class="img-text-wrapper" v-if="message.type === TYPE_ATTACHMENT && message.attachment">
            <!-- MAIN IMAGE - VIDEO MESSAGE START -->
            <a href="javascript:void(0);" class="image-wrap" @click="$emit('select', message)" >
                <Image
                    class="img-wrap-box"
                    :class="message.body ? 'with-text': ''"
                    v-if="message.attachment.type === ITEM_TYPE_PHOTO" :src="message.attachment.source.medium"
                    alt="User Photo"
                />

                <Video
                    v-if="message.attachment.type === ITEM_TYPE_VIDEO"
                    class="img-wrap-box"
                    :class="message.body ? 'with-text': ''"
                    :src="message.attachment.source.original"
                    :preload="mobile ? 'metadata' : null"
                />

                <div class="upload-loader" v-if="message.attachment.type === ITEM_TYPE_VIDEO">
                    <div class="icon-wrapper" v-if="message.attachment.type === ITEM_TYPE_VIDEO">
                        <svg class="play-icon" style="margin-left: 2px" width="20" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M133 440a35.37 35.37 0 0 1-17.5-4.67c-12-6.8-19.46-20-19.46-34.33V111c0-14.37 7.46-27.53 19.46-34.33a35.13 35.13 0 0 1 35.77.45l247.85 148.36a36 36 0 0 1 0 61l-247.89 148.4A35.5 35.5 0 0 1 133 440Z"/></svg>
                    </div>
                </div>
            </a>
            <div class="text-wrap" v-if="message.body">
                {{ message.body}}
            </div>
            <!-- MAIN IMAGE - VIDEO MESSAGE END -->
        </div>

        <!-- MAIN TEXT START -->
        <div :class="{'locked-text': !conversation.system_approved_at && message.author_id !== auth.user.id && auth.user.gender === TYPE_GENDER_MALE}" class="text-wrap" v-else>{{!conversation.system_approved_at && message.author_id !== auth.user.id && auth.user.gender === TYPE_GENDER_MALE ? preview(message.body) : message.body}}</div>
        <!-- MAIN TEXT END -->

    </div>
</template>

<script>
import Image from "@/components/generic/Image";
import { TYPE_ATTACHMENT } from "@/types/message";
import { ITEM_TYPE_PHOTO, ITEM_TYPE_VIDEO } from "@/types/item";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { TYPE_GENDER_MALE } from "@/types/user";
import { ucFirst } from "@/utilities/general";
import Video from "@/components/generic/Video.vue";
import { mapState } from "vuex";


export default {
    name: "ChatMessage",
    mixins: [AuthenticatedMixin],
    components: { Image, Video },
    emits: ['unlockRequest'],
    props: {
        message: {
            type: Object,
            required: true
        },
        conversation: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            mobile: state => state.application.mobile
        })
    },
    methods: {
        ucFirst,
        preview(text) {
            return text.length > 20 ? (text.substring(0, 20) + '...') : text;
        },
    },
    created() {
        this.TYPE_ATTACHMENT    = TYPE_ATTACHMENT
        this.ITEM_TYPE_PHOTO    = ITEM_TYPE_PHOTO
        this.ITEM_TYPE_VIDEO    = ITEM_TYPE_VIDEO
        this.TYPE_GENDER_MALE   = TYPE_GENDER_MALE
    }
}
</script>
<style scope lang="scss">

    .message-row__box {

        >.text-wrap {
            width: 100%;
            padding: 10px 12px;

            &.locked-text {
                position: relative;

                &:before{
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    box-shadow: inset 0 2px 2px $color-type3;
                    z-index: 2;
                    background: linear-gradient(90deg, rgba($color-type3, 0) 0%, $color-type3 30px);
                }
            }
        }

        &.with-image {
            background-color: transparent!important;

            .img-text-wrapper {
                width: 220px;
            }
        }
    }

    .img-wrap-box {
        width: 220px!important;
        aspect-ratio: 1 / 1;
        display: flex;
        flex-direction: column;

        &.with-text {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    video.img-wrap-box {
        background-color: #000;
        object-fit: cover
    }

    .image-wrap {
        width: 100%;
        position: relative;
        display: block;
        aspect-ratio: 1 / 1;

        &:hover,
        &:focus {
            color: #fff;
        }
    }

    .img-text-wrapper {
        position: relative;
        z-index: -1;

        .text-wrap {
            background-color: #000;
            border-radius: 0 0 20px 20px;
            padding: 10px 12px;
            font-size: 16px;
            line-height: 1.3;
            color: #fff;
        }

        &.blur {
            filter: blur(7px);
            cursor: normal;
            pointer-events: none;
        }
    }

    .upload-loader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        aspect-ratio: 1 / 1;
        z-index: 5;
        background-color: rgba(#000, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;

        .icon-wrapper {
            background-color: rgba(#000, 0.6);
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

</style>