<template>
    <video v-if="!preload" :class="{'blurred':blurred}">
        <source :src="src" type="video/mp4">
    </video>
    <video v-else :preload="preload" :class="{'blurred':blurred}">
        <source :src="`${src}#t=0.001`" type="video/mp4">
    </video>
</template>

<script>
export default {
    name: "Video",
    props: {
        src: {
            type: String,
            required: true
        },
        preload: {
            type: String,
            default: null
        },
        blurred: {
            type: Boolean,
            default: false
        },
    }
}
</script>

<style scoped lang="scss">
    video {
        transition: all .3s ease;
        width: 100%;
        max-height: 450px;
        background-color: #000;

        &.blurred {
            filter: blur(25px) brightness(70%);
        }
    }
</style>