<template>
    <main>
        <div class="o-container">
            <PaymentStatus v-if="transaction" :transaction="transaction">
                <template #success_action>
                    <router-link :to="{name: 'search'}" @click="backHomeHandler" class="button button--green">{{$t('credits.success.back')}}</router-link>
                </template>
                <template #failed_action>
                    <router-link :to="{name: 'credits'}" @click="tryAgainHandler" class="button button--primary ml- mr- mb-">{{$t('credits.success.try_again')}}</router-link>
                </template>
            </PaymentStatus>
        </div>
    </main>
</template>

<script>
import { getTransaction } from "@/api/transactions";
import toastr from "toastr";
import { TRANSACTION_TYPE_COMPLETED, TRANSACTION_TYPE_PENDING, TRANSACTION_TYPE_FAILED} from "@/types/transactions";
import { connect } from "@/connection/websocket/connection";
import { GTMSale, pushDataLayer } from "@/service/gtm";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import {
    GTM_EVENT_TYPE_PAYMENT_INITIAL, GTM_EVENT_TYPE_PAYMENT_REPEATED, GTM_COLLECTABLE_NAME,
    GTM_EVENT_PAYMENT_FAILED_PAGE, GTM_EVENT_PAYMENT_FAILED_TRY_AGAIN, GTM_EVENT_PAYMENT_SUCCESS,
    GTM_EVENT_PAYMENT_SUCCESS_BACK_HOME
} from "@/service/gtm/types";
import { setAvailablePromotions } from "@/service/promotions";
import PaymentStatus from "@/components/payment/PaymentStatus.vue";

export default {
    name: "PaymentStatusPage",
    mixins: [AuthenticatedMixin],
    components: {PaymentStatus},
    data() {
        return {
            working: false,
            transaction: null
        }
    },
    created() {
        this.TRANSACTION_TYPE_COMPLETED = TRANSACTION_TYPE_COMPLETED
        this.TRANSACTION_TYPE_PENDING   = TRANSACTION_TYPE_PENDING
        this.TRANSACTION_TYPE_FAILED    = TRANSACTION_TYPE_FAILED
    },
    methods: {
        tryAgainHandler() {
            pushDataLayer(GTM_EVENT_PAYMENT_FAILED_TRY_AGAIN, this.auth.user)
        },
        backHomeHandler() {
            pushDataLayer(GTM_EVENT_PAYMENT_SUCCESS_BACK_HOME, this.auth.user)
        },
        storeGTM() {

            if(this.existCollectableTransactionID())
                return

            GTMSale(
                this.auth.user?.credits?.total_spent > 0 ? GTM_EVENT_TYPE_PAYMENT_REPEATED : GTM_EVENT_TYPE_PAYMENT_INITIAL,
                {
                    ...this.auth.user,
                    currencyCode: this.transaction.currency.toUpperCase(),
                    transactionId: this.transaction.id,
                    transactionTotal: this.transaction.amount,
                    transactionProducts: [{
                        name: this.transaction.package.name,
                        price: this.transaction.package[this.transaction.currency.toLowerCase()],
                        quantity: 1
                    }]
                }
            )

            this.collectTransactionID()
        },
        collectTransactionID() {
            if( ! sessionStorage.getItem(GTM_COLLECTABLE_NAME)) {
                sessionStorage.setItem(GTM_COLLECTABLE_NAME, JSON.stringify([this.transaction.id]))
            }
        },
        existCollectableTransactionID() {

            const data = sessionStorage.getItem(GTM_COLLECTABLE_NAME)

            if( ! data) {
                return false
            }

            return Array.from(JSON.parse(data)).includes(this.transaction.id)
        }
    },
    async mounted() {

        if(this.$route.query.ti_mer) {

            try {

                const { data } = await getTransaction(this.$route.query.ti_mer)

                if( ! data) {
                    await this.$router.push({name: 'credits'})
                    return toastr.error(this.$i18n.t('credits.error_processing'))
                }

                this.transaction = data

                if(this.transaction.status === this.TRANSACTION_TYPE_COMPLETED) {

                    pushDataLayer(GTM_EVENT_PAYMENT_SUCCESS, this.auth.user)
                    await setAvailablePromotions()
                    return this.storeGTM()

                }

                if(this.transaction.status === this.TRANSACTION_TYPE_FAILED) {
                    pushDataLayer(GTM_EVENT_PAYMENT_FAILED_PAGE, this.auth.user)
                }

                if(this.transaction.status === this.TRANSACTION_TYPE_PENDING) {

                    const connection = await connect()

                    connection.private(`transaction.updated.${this.$route.query.ti_mer}`)
                        .listen('.transaction-updated', async ({transaction}) => {
                            this.transaction.status = transaction.status

                            if(transaction.status === this.TRANSACTION_TYPE_COMPLETED) {
                                pushDataLayer(GTM_EVENT_PAYMENT_SUCCESS, this.auth.user)
                                this.storeGTM()
                                await setAvailablePromotions()
                            }

                            if(transaction.status === this.TRANSACTION_TYPE_FAILED) {
                                pushDataLayer(GTM_EVENT_PAYMENT_FAILED_PAGE, this.auth.user)
                            }
                        })

                }

            } catch (e) {
                console.log(e, 'Error loading transaction')
                toastr.error(this.$i18n.t('credits.error_processing'))
            }


        }

    }
}
</script>

<style lang="scss" scoped>
main {
    min-height: calc(100vh - 196px);
}


.button--green {
    font-size: 16px;
}


</style>