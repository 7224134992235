<template>
    <div class="new-user-avatar">
        <Image :src="getUserPhotoURL(user)" />
        <div class="messages-numb">{{type === TYPE_ANIMATION_OFFER ? '$' : 1}}</div>
        <div v-if="type === TYPE_ANIMATION_OFFER && user.offers.length" class="offer-box">
            {{$t('profile.offer_custom', {period: user.offers[0].type, currency: user.local_currency.name.toUpperCase(), amount: user.offers[0].amount})}}
        </div>
    </div>
</template>

<script>
import Image from "@/components/generic/Image";
import { getUserPhotoURL } from "@/service/user/profile";
import sound from "@/assets/sound/sound_notification.mp3"
import { playSound } from "@/service/notifications";
import { TYPE_ANIMATION_OFFER } from "@/types/bubble";

export default {
    name: "Bubble",
    components: {Image},
    props: {
        user: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    methods: {
        getUserPhotoURL,
    },
    created() {
        this.TYPE_ANIMATION_OFFER = TYPE_ANIMATION_OFFER
    },
    mounted() {
        playSound(sound)
    }
}
</script>

<style lang="scss" scoped>
.new-user-avatar {
    margin-bottom: 10px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: gray;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 4px 8px 0px;
    overflow: hidden;

    .messages-numb {
        font-size: 12px;
        min-width: 20px;
        padding: 0 7px;
        height: 20px;
        color: #fff;
        background-color: #f03b6e;
        border-radius: 10px;
        display: inline-flex;
        align-items: center;
        position: absolute;
        font-weight: 700;
        bottom: 100%;
        right: 100%;
        transform: translate(20px, 20px);
    }

    img {
        border-radius: 50%;
    }
    @media (max-width: 991px) {
        width: 80px;
        height: 80px;
        margin-bottom: 5px;
    }

    .offer-box {
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translate(-10px, -50%);
        border-radius: 20px 20px 2px 20px;
        background-color: #000;
        padding: 10px 12px;
        color: #fff;
        width: 145px;
        font-size: 16px;
        line-height: 20px;
    }
}
</style>