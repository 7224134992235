import { uploadToS3PreSigned } from "@/api/files";
import CryptoJS from "crypto-js";
import {ITEM_TYPE_PHOTO, ITEM_TYPE_VIDEO} from "@/types/item";

export const uploadToBucket = async (preSignedPayload, content, onProgress) => {

    let f = new FormData();

    f.append('key', preSignedPayload.filename);
    f.append('X-Amz-Credential', preSignedPayload.formInputs['X-Amz-Credential']);
    f.append('X-Amz-Algorithm', preSignedPayload.formInputs['X-Amz-Algorithm']);

    if(preSignedPayload.formInputs['X-Amz-Security-Token'])
        f.append('X-Amz-Security-Token', preSignedPayload.formInputs['X-Amz-Security-Token']);

    f.append('X-Amz-Date', preSignedPayload.formInputs['X-Amz-Date']);
    f.append('Policy', preSignedPayload.formInputs['Policy']);
    f.append('X-Amz-Signature', preSignedPayload.formInputs['X-Amz-Signature']);
    f.append('acl', preSignedPayload.formInputs['acl']);
    f.append('file', content);

    const parameters = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    if( typeof onProgress === 'function') {
        parameters.onUploadProgress = onProgress
    }

    return uploadToS3PreSigned(preSignedPayload, f, parameters)

}

export const getExtensionFromFilename = filename => {
    return filename.substring(filename.lastIndexOf('.')+1, filename.length).toLowerCase() || filename;
}

export const base64ToBlob = (b64Data, contentType) => {

    const byteString = atob(b64Data.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: contentType });

}

/**
 *
 * @param type
 * @returns {string}
 * @throws Error
 */
export const getExtensionFromMimeType = type => {

    switch (type.split('/')[1]) {
        case 'jpeg':
            return 'jpeg'
        case 'jpg':
            return 'jpg'
        case 'gif':
            return 'gif'
        case 'png':
            return 'png'
        case 'quicktime':
            return 'mov'
        case 'mp4':
            return 'mp4'
        default:
            throw Error('Format not supported')
    }

}

export const hashFile = async file => {

    return new Promise(resolve => {

        const reader = new FileReader()

        reader.onloadend = () => {
            let wordArray = CryptoJS.lib.WordArray.create(reader.result)
            let hash = CryptoJS.MD5(wordArray).toString()
            return resolve(hash)
        }

        reader.readAsArrayBuffer(file)

    })

}

export const getExtensionFromBase64 = base64 =>  base64.match(/[^:/]\w+(?=;|,)/)[0];

// This function is used to detect the actual image type,
export const getMimeType = (file, fallback = null) => {
    const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
    }
    switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}

export class SelectedFile {
    constructor(path, src, type = ITEM_TYPE_PHOTO, extension, hash = null) {
        this.path       = path
        this.src        = src
        this.hash       = hash
        this.type       = type
        this.extension  = extension
    }
}

export const createSelectedFile = async (file, hash = null) => {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
            resolve(new SelectedFile(
                URL.createObjectURL(file),
                reader.result,
                file.type.includes('video') ? ITEM_TYPE_VIDEO : ITEM_TYPE_PHOTO,
                getExtensionFromMimeType(file.type),
                hash
            ))
        }
    })
}

