<template>
    <ApplicationLoader v-if="bootstrapping" />

    <!-- adding this code to disable right click @contextmenu.prevent -->

    <div v-else>
        <router-view v-slot="{ Component }">
            <!-- <transition-group name="fade" mode="out-in"> -->
                <Navbar key="navbar" v-if="logged && ( ! mobile || (mobile && ! $route.meta?.navbarHidden))"/>
                <component key="component" @request-register="genderModalShow = true" :is="Component"/>
            <!-- </transition-group> -->
            <Footer :class="{
                'home-footer':$route.name === 'home',
                'verification-footer':$route.name === 'verification',
                'credits-footer':$route.name === 'credits'
                }"
                v-if="!$route.meta?.footerHidden" />
        </router-view>
        <BubbleNotification v-if="logged && $route.meta && $route.meta.auth" />
        <PreActionDialogs v-if="logged && $route.meta && $route.meta.auth" />
        <Animations v-if="logged" />
        <SendingPanel :queue="sendingQueue" v-if="logged && sendingQueue.length && $route.name !== 'messages'" />
        <Modal v-if="genderModalShow" class="modal modal--register" :size="'small'"  @close="genderModalShow = false">
            <template v-slot:header>
                <h2>{{$t('homepage.which_are_you')}}</h2>
            </template>
            <template v-slot:body>
                <router-link :to="{name: 'register', params: {gender: 'man'}}" @click="genderModalShow = false" class="button button--man mb">{{$t('homepage.i_am_man')}}</router-link>

                <router-link :to="{name: 'register', params: {gender: 'woman'}}" @click="genderModalShow = false" class="button button--woman">{{$t('homepage.i_am_woman')}}</router-link>
            </template>
        </Modal>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PreActionDialogs from "@/components/PreActionDialogs";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import ApplicationLoader from "@/components/ApplicationLoader";
import Modal from "@/components/generic/Modal";
import BubbleNotification from "@/components/generic/BubbleNotification";
import ThemeMixin from "@/mixins/ThemeMixin";
import Animations from "@/components/Animations";
import SendingPanel from "@/components/generic/SendingPanel";
import { TYPE_GENDER_MALE } from "@/types/user";
import { setAvailablePromotions } from "@/service/promotions";
import {
    pushNotificationSupported
} from "@/service/push_notifications/push";


export default {
    mixins: [ThemeMixin],
    data() {
        return {
            genderModalShow: false
        }
    },
    components: {
        PreActionDialogs, Navbar, Footer, ApplicationLoader, Modal, BubbleNotification,
        Animations, SendingPanel
    },
    computed: {
        ...mapState({
            bootstrapping: state => state.application.bootstrapping,
            mobile: state => state.application.mobile,
            connected: state => state.application.connected,
            sendingQueue: state => state.messages.sendQueue,
            card: state => state.credits.card,
            interacted: state => state.application.interacted,
            application: state => state.application,
        }),
        ...mapGetters({
            logged: 'authenticate/logged',
            auth: 'authenticate/auth',
            currency: 'authenticate/currency'
        }),
        notificationsSupported() {
            return pushNotificationSupported() ? 'Yes' : 'No'
        },
        notifications() {
            return  Notification && Notification?.permission === 'granted' ? 'Yes' : 'No'
        },
    },
    methods: {
        ...mapActions({
            add: 'profile/add',
            remove: 'profile/remove',
        })
    },
    watch: {
        connected(value) {
            if(value) {
                return this.remove('connected')
            }

            setTimeout(() => {

                if( ! value) {
                    this.add('connected')
                }

            }, 200)


        },
        currency() {

            if(this.logged && this.auth.user.gender === TYPE_GENDER_MALE) {
                setAvailablePromotions()
            }

        }
    },
    async mounted() {

        document.addEventListener('swUpdated', ({detail}) => {

            if( ! detail || ! detail.waiting)
                return

            console.log('***********************  App updated', detail)
            detail?.waiting?.postMessage({type: 'SKIP_WAITING'})

        }, {once: true})

    },
    created() {
        this.TYPE_GENDER_MALE = TYPE_GENDER_MALE
    }
}
</script>
<style scoped lang="scss">
    .modal {

        .button {
            display: flex;
            width: 100%;
            height: 50px;
        }

        h2 {
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 16px;
        }
    }
</style>

<style lang="scss">
    .modal--register {
        .modal-container {
            padding: 60px 20px;
        }
    }
</style>
