<template>
    <a href="javascript:;" @click="logout" class="logout"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"><g data-name="Icon ionic-ios-power"><path data-name="Path 56" d="M18.127 2.735a1.045 1.045 0 0 0-.676-.246 1.044 1.044 0 0 0-1.037 1.126 1.042 1.042 0 0 0 .367.71 8.88 8.88 0 1 1-14.69 6.726 8.8 8.8 0 0 1 3.14-6.726 1.041 1.041 0 0 0 .12-1.472 1.042 1.042 0 0 0-1.467-.118A10.873 10.873 0 0 0 0 11.046a11 11 0 1 0 18.127-8.311Z"/><path data-name="Path 57" d="M11.051 12.06a1.061 1.061 0 0 0 1.06-1.06V1.06a1.06 1.06 0 1 0-2.12 0V11a1.061 1.061 0 0 0 1.06 1.06Z"/></g></svg> {{$t('auth.logout')}}</a>
</template>

<script>

import { logout as logoutService } from "@/service/auth";

export default {
    name: "Logout",
    methods: {
        async logout() {
            await logoutService()
            await this.$router.push({name: 'login'})
        }
    }
}
</script>

<style scoped lang="scss">
    .logout {
        display: flex!important;
        align-items: center;

        svg {
            margin-right: 30px;
        }
    }
</style>