<template>
    <transition name="fade-img">
        <img v-show="loaded" :class="{'blurred':blurred}" :src="src" @load="loaded = true" :alt="alt">
    </transition>
</template>

<script>
export default {
    name: "Image",
    props: {
        src: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            default: ''
        },
        blurred: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loaded: false
        }
    }
}
</script>

<style scoped lang="scss">

    img {
        -webkit-transition: all .3s ease;
        transition: all .3s ease;

        &.blurred {
            filter: blur(8px) brightness(90%);
        }
    }

    img.v-enter, img.v-leave {
        opacity: 0;
    }
</style>