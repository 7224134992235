<template>
    <div class="switch-wrap">
        <input type="checkbox" :id="id" @change="switchHandler" v-model="value">
        <label :for="id" class="switch-box">
            <slot></slot>
            <span></span>
        </label>
    </div>
</template>
<script>
export default {
    props: {
        checked: {
            type: Boolean,
            default: true
        }
    },
    emits: ['switch'],
    data() {
        return {
            id: null,
            value: false
        }
    },
    watch: {
        checked: {
            handler(value) {
                this.value = value
            },
            immediate: true
        }
    },
    methods: {
        switchHandler() {
            this.$emit('switch', this.value)
        }
    },
    created() {
        this.id = `switch_${this.$.uid}`
    }
}
</script>
<style lang="scss" scoped>
    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
    }

    label {
        cursor: pointer;
    }

    .switch-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            min-width: 42px;
            height: 18px;
            border-radius: 9px;
            background-color: #DEDEDE;
            position: relative;
            -webkit-transition: all .3s ease;
            transition: all .3s ease;

            &:before {
                position: absolute;
                left: 0;
                top: 50%;
                -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
                content: "";
                width: 26px;
                height: 26px;
                background-color: $color-type8;
                border-radius: 50%;
                -webkit-transition: all .3s ease;
                transition: all .3s ease;

            }
        }
    }

    input:checked + label {
        span {
            background-color: #E9D4C2;

            &:before {
                background-color: $color-type19;
                -webkit-transform: translate(16px, -50%);
                transform: translate(16px, -50%);
            }
        }
    }
</style>