import axios from "axios";

export const addToFavorite = (user_id) => {
    return axios.post(`favorite`, {user_id: user_id})
}

export const removeFavorites = (user_id) => {
    return axios.delete(`favorite`, {data: {user_id: user_id}})
}

export const getFavorites = (query = null) => {
    return axios.get(`favorite${ query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const toggleFavorite = user => {
    return user.my_favorite ? removeFavorites(user.id) : addToFavorite(user.id)
}