<template>
    <main>
        <div class="o-container">
            <section class="contact">
                <article>
                    <div class="header-mob">
                        <router-link :to="{name: 'search'}" class="back-mob"><svg xmlns="http://www.w3.org/2000/svg" width="14.004" height="14.001"><path data-name="Union 6" d="M6.289 13.708.262 7.669a1 1 0 0 1 .03-1.379l6-6a1 1 0 0 1 1.417 0 1 1 0 0 1 0 1.413l-4.3 4.295H13a1 1 0 0 1 1 1 1 1 0 0 1-1 1H3.41l4.292 4.3a1 1 0 0 1 0 1.412A1 1 0 0 1 7 14a1 1 0 0 1-.711-.292Z"/></svg></router-link>
                        <h1>{{$t('other.contact_us')}}</h1>
                    </div>
                    <h1 class="heading-mob"><strong>{{$t('other.contact_us')}}</strong></h1>

                    <Form ref="form" :validation-schema="validationSchema" @submit="onSubmit">
                        <div class="input-box">
                            <p>{{$t('form_fields.contact_message_label')}}</p>
                            <Field v-model="message" name="message" as="textarea" cols="30" rows="10" class="input input--primary input--textarea" />
                            <ErrorMessage class="text-error text-left form-text" name="message"  />
                        </div>
                        <div class="o-flex o-flex--right">
                            <button ref="submitButton" class="button button--primary">{{$t('other.send_message')}}</button>
                        </div>
                    </Form>
                    <div class="link-wrapper">
                        <p>{{VUE_APP_SUPPORT_EMAIL}}</p>
                    </div>
                </article>
            </section>
        </div>
    </main>
</template>

<script>
import {Form, Field, ErrorMessage} from "vee-validate";
import {defineRule} from "vee-validate";
import {required, min} from "@vee-validate/rules";
import {support} from "@/api/support";
import toastr from "toastr";

export default {
    name: 'Contact',
    components: {Form, Field, ErrorMessage},
    data() {
        return {
            message: '',
            validationSchema: null
        }
    },
    created() {
        defineRule('required', required)
        defineRule('min', min)
        this.validationSchema = {
            message: 'required|min:10'
        }
        this.VUE_APP_SUPPORT_EMAIL = process.env.VUE_APP_SUPPORT_EMAIL
    },
    methods: {
        async onSubmit() {

            this.$refs.submitButton.disabled = true

            try {

                await support({
                    message: this.message,
                    device: window.navigator.userAgent
                })

                toastr.success(this.$i18n.t('messages.message_sent'))

                this.$refs.form.resetForm()

            } catch {

                toastr.error(this.$i18n.t('credits.error_processing'))

            }

            this.$refs.submitButton.disabled = false

        }
    }
}
</script>

<style scoped lang="scss">

    main {
        @media (max-width: 991px) {
            background-color: #fff;
        }
    }

    .header-mob {
        display: none;

        @media(max-width: 500px) {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            h1 {
                margin-bottom: 0!important;
                font-size: 22px!important;
                font-weight: 700!important;
            }

            .back-mob {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                margin-right: 10px;
            }
        }
    }
    .contact {
        padding: 60px 100px;
        border-radius: 10px;
        background-color: #fff;
        min-height: calc(100% - 40px);
        height: auto;
        overflow: hidden;
        article {

            h1 {
                font-weight: 900;
                margin-bottom: 60px;
            }

            button {
                margin-top: 20px;
                // height: 60px;
                // border-radius: 30px;
                // width: 246px;
            }
        }

        .heading-mob {
            @media(max-width: 500px) {
                display: none;
            }
        }

        form {
            margin-bottom: 20px;
        }

        @media(max-width: 991px) {
            padding: 30px 0 70px;
            margin-top: 0;

            article {

                h1 {
                    margin-bottom: 20px;
                }
            }

            form {
                .o-flex {
                    justify-content: center;
                }

                .button {
                    height: 40px;
                    width: auto;
                    padding: 0 16px;
                    font-size: 16px;border-radius: 20px;
                    margin-top: 0;
                }
            }

            .link-wrapper {
                text-align: center;
            }
        }
    }

    .link {
        color: #bfa46a;
        text-decoration: underline;
        margin-bottom: 20px;
        font-size: 13px;
        display: inline-flex;
    }

    small {
        font-size: 13px;
    }

    .text-error {
        color: red;
        font-size: small;
    }
</style>
