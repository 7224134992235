<template>
    <div v-if="template" class="possibilities">
        <h2>{{$t('credits.what_can_do')}}</h2>

        <ul class="can-do-list">
            <li>
                <div class="icon-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.164" height="25.416"><g data-name="Group 200" transform="rotate(-45 -15825.755 -15239.244)" fill="none" stroke="#000" stroke-width="2"><circle data-name="Ellipse 33" cx="6" cy="6" r="6" transform="translate(-15421 6739)"/><path data-name="Rectangle 100" stroke-linejoin="round" d="M-15403 6745h5v4h-5z"/><path data-name="Path 85" d="M-15409 6745.001h13.943" stroke-linecap="round"/></g></svg>
                </div>
                <div class="list-content">
                    <h3 class="mb-">{{$t('credits.unlock_conversation')}}</h3>
                    <p>{{$t('credits.only')}} <span class="u-font-700">{{conversation_price}} {{$t('credits.credits')}}</span> {{$t('credits.to_unlock_conversation')}} {{$t('credits.then_all')}}</p>
                </div>
            </li>
            <li v-if="false">
                <div class="icon-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22.414" height="22.414"><g data-name="Icon feather-image" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path data-name="Path 67" d="M3.222 1h15.556A2.222 2.222 0 0 1 21 3.222v15.556A2.222 2.222 0 0 1 18.778 21H3.222A2.222 2.222 0 0 1 1 18.778V3.222A2.222 2.222 0 0 1 3.222 1Z"/><path data-name="Path 68" d="M8.777 7.111a1.667 1.667 0 1 1-1.666-1.667 1.667 1.667 0 0 1 1.666 1.667Z"/><path data-name="Path 69" d="m21 14.334-5.556-5.556L3.222 21"/></g></svg>
                </div>
                <div class="list-content">
                    <h3 class="mb-">{{$t('credits.unlock_album')}}</h3>
                    <p><span class="u-font-700">{{gallery_price}} {{$t('credits.credits')}}</span> {{$t('credits.unlock_album')}}</p>
                </div>
            </li>
            <li>
                <div class="icon-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21.02"><path data-name="Icon feather-star" d="m11 1 3.09 6.26L21 8.27l-5 4.87 1.18 6.88L11 16.77l-6.18 3.25L6 13.14 1 8.27l6.91-1.01Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>
                </div>
                <div class="list-content">
                    <h3 class="mb-">{{$t('credits.more')}}</h3>
                    <ul>
                        <li class="p0"><p v-html="'· ' + $t('credits.vip_badge')"></p></li>
                        <li class="p0"><p v-html="'· ' + $t('credits.listed_higher')"></p></li>
                        <li class="p0"><p v-html="'· ' + $t('credits.priority_support')"></p></li>
                    </ul>
                </div>
            </li>
        </ul>

        <ul class="list">
            <li>
                <svg width="22" viewBox="0 0 24 24" xml:space="preserve"><path fill="#000000" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                {{$t('credits.spend')}}
            </li>
            <li>
                <svg width="22" viewBox="0 0 24 24" xml:space="preserve"><path fill="#000000" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                {{$t('credits.billing')}}
            </li>
            <li>
                <svg width="22" viewBox="0 0 24 24" xml:space="preserve"><path fill="#000000" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                {{$t('credits.expire')}}
            </li>
        </ul>
    </div>

</template>
<script>
    export default {
        name: "PaymentContent",
        props: {
            template: {
                type: Boolean
            },
        },
        created() {
            this.conversation_price = process.env.VUE_APP_UNLOCK_CONVERSATION
            this.gallery_price = process.env.VUE_APP_UNLOCK_GALLERY
        }
    }
</script>

<style scoped lang="scss">
.possibilities {
    padding: 80px 100px;
    border-radius: 10px;
    background-color: #fff;
    height: auto;
    overflow: hidden;
    text-align: left;

    h2 {
        color: #222222;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 20px;
        text-align: left;
    }

    small {
        font-size: 13px;
        color: #777777;
        line-height: 1.3;
        margin: 20px 0;
        display: block;

        a {
            font-size: 13px;
            line-height: 1.3;
            text-decoration: underline;
        }
    }

    @media(max-width: 991px) {
        padding: 40px 20px;

        h2 {
            margin-bottom: 20px;
            font-size: 20px;
        }

        .list-content {
            h3 {
                font-size: 18px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}


.can-do-list {
    margin-bottom: 30px;

    li {
        padding: 20px 0;
        display: flex;
        align-items: flex-start;
    }

    h3 {
        font-size: 22px;
        font-weight: 700;
        letter-spacing: -0.9px;
        margin-bottom: 0;
    }

    p {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 1.35;
        letter-spacing: 0.07px;

        span {
            color: $color-type19;
        }
    }

    .icon-wrapper {
        width: 60px;
        min-width: 60px;
        margin-right: 20px;
        height: 60px;
        border-radius: 50%;
        background-color: #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: center;

        @media( max-width: 991px) {
            width: 40px;
            min-width: 40px;
            margin-right: 10px;
            height: 40px;
        }
    }
}

.list {
    margin: 0 auto;
    max-width: 600px;

    li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 1.35;

        svg {
            margin-right: 5px;
            min-width: 22px;
            margin-top: 2px;
        }
    }
}
</style>