<template>
    <div class="payment-type">
        <h3>{{$t('payment.payment_method')}}:</h3>
        <div class="payment-type-wrapper">
            <div @click="changePaymentTypeClickHandler" class="payment-type-header  o-flex o-flex--center o-flex--justify">
                <div class="payment-type-selected o-flex o-flex--center mr">
                    <div v-if="!changePaymentType" class="checkblock-modal mt0">
                        <input type="radio" v-model="type" :id="type" :value="type">
                        <label :for="type">
                            <span class="checkbox-icon"></span>
                            <img :src="`/img/payment_types/${type}.svg`" alt="">
                            <span class="text mb0 ml-" v-if="[CREDIT_CARD, DIRECT_DEBIT, ONLINE_BANK_TRANSFER, BANK_TRANSFER].includes(type)"><strong>{{$t(`payment.${type.toLowerCase()}`)}}</strong></span>
                        </label>
                    </div>
                    <p class="mb0 u-text-left" v-else>{{$t('payment.select_type')}}</p>
                </div>
                <a v-if="available.length > 1" href="javascript:;" class="clr-blue change-trigger"
                   :class="{'active': changePaymentType}">
                    {{$t('payment.change')}}
                    <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><g data-name="Group 55"><path d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z" data-name="Icon awesome-angle-down"/></g></svg>
                </a>
            </div>
            <div class="payment-type-dropdown" v-if="available.length > 1 && changePaymentType">
                <div :key="i" v-for="i in available" class="checkblock-modal">
                    <input type="radio" v-model="type" :id="`${$.uid}_${i}`" :value="i" @change="changePaymentType = !changePaymentType">
                    <label :for="`${$.uid}_${i}`">
                        <span class="checkbox-icon"></span>
                        <img :src="`/img/payment_types/${i}.svg`" alt="">
                        <span class="text mb0 ml-" v-if="[CREDIT_CARD, DIRECT_DEBIT, ONLINE_BANK_TRANSFER, BANK_TRANSFER].includes(i)"><strong>{{$t(`payment.${i.toLowerCase()}`)}}</strong></span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {CREDIT_CARD, DIRECT_DEBIT, ONLINE_BANK_TRANSFER, BANK_TRANSFER, PAYPAL} from "@/types/payment";

export default {
    name: "PaymentTypes",
    emits: ['select'],
    props: {
        types: {
            type: Array
        }
    },
    data() {
        return {
            changePaymentType: false,
            type: null
        }
    },
    computed: {
        available() {

            return [...this.types].sort((a, b) => {

                if(a === CREDIT_CARD)
                    return -1

                if(b === CREDIT_CARD)
                    return 1

                if( a === PAYPAL) {
                    return -1
                }

                if(b !== CREDIT_CARD && b === PAYPAL) {
                    return 1
                }

                return a.localeCompare(b)

            })
        },
    },
    watch: {
        available: {
            handler: function (value) {
                this.type = value[0]
            },
            immediate: true
        },
        type: {
            handler: function (value) {
                this.$emit('select', value)
            },
            immediate: true
        }
    },
    methods: {
        changePaymentTypeClickHandler() {

            if(this.available.length < 2) {
                return
            }

            this.changePaymentType = ! this.changePaymentType

        }
    },
    created() {
        this.CREDIT_CARD            = CREDIT_CARD
        this.DIRECT_DEBIT           = DIRECT_DEBIT
        this.ONLINE_BANK_TRANSFER   = ONLINE_BANK_TRANSFER
        this.BANK_TRANSFER          = BANK_TRANSFER
    }
}
</script>

<style scoped lang="scss">
    .payment-type {
        max-width: 380px;
        margin-top: 20px;

        h3 {
            margin-bottom: 10px;
            letter-spacing: 0.06px;
            font-weight: 400;
            font-size: 18px;
            line-height: 1.2;
        }

        @media(max-width: 991px) {
            padding: 0 20px;

            h3 {
                font-size: 16px;
                margin-bottom: 6px;
                text-align: left;
            }
        }

        @media(max-width: 480px) {
            max-width: 100%;
        }
    }

    .payment-type-wrapper {
        background-color: #fff;
        box-shadow: 0px 3px 16px #0000000D;
        border-radius: 10px;
        padding: 12px;
    }

    .payment-type-header {
        .change-trigger {
            display: flex;
            align-items: center;

            svg {
                margin-left: 10px;
            }

            &.active {
                svg {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    .payment-type-dropdown {

        ul {
            margin-top: 12px;

        }

        li {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    .checkblock-modal {
        margin-top: 18px;

        input {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            z-index: -1;
        }

        label {

            display: flex;
            align-items: center;
            transition: all .3s ease;
            cursor: pointer;

            .checkbox-icon {
                border-radius: 4px;
                min-width: 16px;
                width: 16px;
                height: 16px;
                position: relative;
                border: 1px solid #000000;
                background-color: #fff;
                margin-right: 15px;
                transition: all .3s ease;


                &:before {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50% , -50%);
                    content:"";
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                    transition: all .3s ease;
                    visibility: hidden;
                    opacity: 0;
                    background-color: #fff;
                }
            }
        }

        input:checked + label {
            .checkbox-icon {
                background-color: #000000;

                &:before {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
</style>