<template>
    <Modal class="modal modal--mob u-text-center">
        <template #body>
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="55.148"><path data-name="Icon material-signal-wifi-off" d="M64 15.285a53.092 53.092 0 0 0-32-11 50.762 50.762 0 0 0-11.409 1.32l28.4 28.371ZM45.86 37.883 8 0 4.509 3.519l5.636 5.663A49.773 49.773 0 0 0 0 15.285L31.973 55.12l.027.027.027-.027 10.722-13.361 9.127 9.127 3.491-3.491-9.507-9.512Z" fill="#c7c7c7"/></svg>
            <h2><strong>Oops!</strong></h2>
            <p>{{$t('general.not_connected')}}</p>
        </template>
    </Modal>
</template>

<script>

import Modal from "@/components/generic/Modal";

export default {
    name: "PreActionConnected",
    components: {Modal}
}
</script>

<style lang="scss" scoped>

    svg {
        margin-bottom: 20px;
    }

    h2 {
        margin-bottom: 10px;
        letter-spacing: -0.9px;
        font-size: 22px;
        line-height: 38px;
    }

    p {
        max-width: 250px;
        margin: 0 auto 30px;
        line-height: 24px;
    }
</style>