<template>
    <div class="small-new">
        {{$t('users.is_new')}}
    </div>
</template>
<script>
export default {
    name: "NewBox"
}
</script>
<style lang="scss" >
    .small-new {
        height: 20px;
        border-radius: 10px;
        background-color: rgba(#000, .5);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 600;
        padding: 0 10px;
    }

</style>