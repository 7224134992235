<template>
    <div v-if="!paymentModal" class="price__heading">
        <div class="price__heading-box">
            <BackButton class="back-button"/>
            <h2 class="h1"> {{ $t('payment.limited_offer_1.limited') }}</h2>
        </div>
        <p>{{ $t('payment.limited_offer_1.grab') }}</p>
        <div class="time-left-box">
            <Review/>
        </div>
    </div>
    <div v-else>
        <div class="amount-box">
            <img src="../../assets/img/dollar-icon.svg" alt="Dollar icon"> {{credits}}
        </div>
        <h2 class="h4">{{$t('credits.not_enough_credits')}}? {{$t('credits.recharge')}}:</h2>
    </div>
</template>

<script>
import BackButton from "@/components/generic/BackButton.vue";
import Review from "@/components/generic/Review.vue";
import {mapState} from "vuex";

export default {
    name: "LimitedPromotionPackageHeader",
    components: {Review, BackButton},
    props: {
        promotion: {
            type: Object
        },
        paymentModal: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            credits: state => state.credits.amount
        })
    }
}
</script>

<style lang="scss" scoped>

    .price__heading {
        @media(max-width: 991px) {
            padding: 0 20px;
        }
    }

    .price__heading {
        text-align: left;

        .h1 {
            font-size: 26px;
            margin-bottom: 0;
            font-weight: 700;

        }

        h2 {
            margin-bottom: 10px;
            font-weight: 700;
        }

        p {
            font-size: 18px;
            line-height: 24px;
        }

        @media(max-width: 991px) {
            p {
                font-size: 16px;
            }
        }
    }

    .amount-box {
        border-radius: 15px;
        background-color: #fff;
        padding-left: 40px;
        position: relative;
        display: inline-flex;
        align-items: center;
        font-weight: 700;
        height: 30px;
        width: auto;
        padding-right: 20px;
        margin-bottom: 10px;
        font-size: 18px;
        font-family: "Open Sans", sans-serif!important;

        img {
            position: absolute;
            top: 0;
            left: 0;
        }

        @media(max-width: 350px) {
            min-width: 105px;
        }
    }

    .price__heading-box {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .back-button {
            margin-right: 20px;
            display: none;
        }

        @media(max-width: 991px) {
            margin-bottom: 25px;

            .back-button {
                display: flex;
            }
        }
    }

    .time-left-box {
        display: flex;
        align-items: center;

        @media(max-width: 991px) {
            justify-content: space-between;
        }
    }
</style>