<template>
    <span class="done-box">
        <svg xmlns="http://www.w3.org/2000/svg" width="10.24" height="8"><path data-name="Icon metro-checkmark" d="m8.64 0-4.8 4.8L1.6 2.56 0 4.16 3.84 8l6.4-6.4Z" fill="#1dab5f"/></svg>
        {{text}}
    </span>
</template>

<script>
export default {
    name: "CheckMark",
    props: {
        text: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped lang="scss">
    .done-box {
        color: #1dab5f;
        display: flex;
        align-items: center;
        letter-spacing: -0.66px;
        font-size: 14px;
        font-weight: 700;
        margin-right: 16px;

        svg {
            margin-right: 5px;
        }
    }
</style>