export default {

    namespaced: true,

    state: {
        profiles: []
    },

    mutations: {

        setProfiles(state, payload) {
            state.profiles = payload
        }

    },

    actions: {

        setProfiles({commit}, payload) {
            commit('setProfiles', payload)
        }

    }

}