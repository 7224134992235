import moment from "moment";
import i18n from "@/i18n";
import {getOrCreate, read, unread} from "@/api/messages";
import store from "@/store";
import toastr from "toastr";
import router from "@/router";

/**
 *
 * @param {Array} messages
 * @returns {*|Map<string, Array>}
 */
export const groupMessages = messages => {

    let map = new Map()

    if( ! messages || ! messages.length)
        return map

    return sortMessages([...messages]).reduce((acc, el) => {

        let date

        if(moment.utc(el.created_at, 'YYYY-MM-DD HH:mm:ss').isSame(new Date(), 'day')) {
            date = i18n.global.t('messages.today')
        } else {
            date = moment.utc(el.created_at, 'YYYY-MM-DD HH:mm:ss').local().format('ll')
        }

        if(acc.get(date)) {
            return acc.set(date, [
                ...acc.get(date),
                el
            ])
        }
        return acc.set(date, [el])
    }, map)

}

export const sortMessages = (messages, direction = 'desc') => {
    if(direction === 'desc') {
        return messages.sort((a, b) => moment(a.created_at, 'YYYY-MM-DD HH:mm:ss').diff(moment(b.created_at, 'YYYY-MM-DD HH:mm:ss')))
    }

    return messages.sort((a, b) => moment(b.created_at, 'YYYY-MM-DD HH:mm:ss').diff(moment(a.created_at, 'YYYY-MM-DD HH:mm:ss')))
}

export const getUnreadConversations = async() => {
    const { data } = await unread()
    console.log(data)
}

/**
 *
 * @param {Object} message
 * @returns {Promise<void>}
 */
export const readConversation = async(message) => {
    try {

        await read(message.id)

        await store.dispatch('notifications/readConversation', message.conversation_id)

    } catch(e) {
        console.log('Error reading conversation messages', e)
    }
}

export const goToConversationWithUser = async user_id => {

    try {
        const { data } = await getOrCreate(user_id)
        return await router.push({name: 'messages', params: {conversation: data.id}})
    } catch (e) {
        if(e?.response?.status === 406) {
            await store.dispatch('profile/add', 'daily_limit')
        } else {
            toastr.error(i18n.global.t('credits.error_processing'))
        }
    }
}