<template>
    <span class="dot-online"></span>
</template>

<script>
export default {
    name: "OnlineStatus",
}
</script>

<style lang="scss" scoped>
    .dot-online {
        background-color: $color-type25;
        height: 8px;
        min-width: 8px;
        width: 8px;
        border-radius: 50%;
        margin-right: 4px;
        display: block;
    }
</style>