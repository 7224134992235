<template>
    <Modal class="modal modal--mob modal-radio-buttons">
        <template #header>
            <h2>{{$t('form_fields.ethnicity_label')}}</h2>
        </template>
        <template #body>
            <div v-for="e in ethnicities" :key="e.code" class="radiobox">
                <input class="form-check-input" type="radio" :id="e.code" :value="e.value" :checked="value === e.value">
                <label @click="update(e.value)" class="radiobox__label" :for="e.code">
                    {{$t(`form_fields.ethnicity_options_${e.value}`)}}
                </label>
            </div>
        </template>
        <template #footer>
            <a @click="$emit('success')" class="skip">{{$t('credits.skip_for_now')}}</a>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { ethnicities } from "@/service/forms";

export default {
    name: "OnDemandEthnicity",
    mixins: [AuthenticatedMixin],
    props: {
        value: {
            type: [Number, null],
            required: true
        }
    },
    components: {Modal},
    data() {
        return {

        }
    },
    methods: {
        update(value) {

            this.$emit('set', {
                ethnicity: value
            })
        }
    },
    created() {
        this.ethnicities = ethnicities
    },
    mounted() {
        this.ethnicity = this.auth.user.ethnicity
    }
}
</script>