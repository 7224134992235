import axios from "axios";

export const getMyAccess = (query = null) => {
    return axios.get(`access${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const getPending = (query = null) => {
    return axios.get(`access/pending${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const respond = (request_id, status) => {
    return axios.post(`access/respond`, {request_id, status})
}

export const getApproved = (query = null) => {
    return axios.get(`access/approved${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const getDeclined = (query = null) => {
    return axios.get(`access/declined${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const sendPhotoAccess = user_id => {
    return axios.post(`access`, {user_id})
}

export const givePhotoAccess = user_id => {
    return axios.post(`access/offer`, {user_id})
}

export const unlockAccess = (user_id, type) => {
    return axios.post(`access/unlock`, {user_id, type})
}

export const getRaw = async () => {
    return axios.get(`access/raw`)
}