import axios from "axios";
import i18n from "@/i18n";
import {localize} from "@vee-validate/i18n";
import {availableLanguages} from "@/service/languages";
import {clientLogout} from "@/service/auth";
import router from "@/router";
const languages = availableLanguages()

export default {

    namespaced: true,

    state: {
        lang: 'en',
        auth: {
            user: {
                gender: 0,
                id: 0
            },
            token: null
        },
    },

    mutations: {
        setUser(state, user) {
            state.auth.user = user
        },
        setToken(state, token) {

            state.auth.token = token

            if(token) {

                localStorage.setItem('auth_token', token)

                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

            } else {

                localStorage.removeItem('auth_token')

            }

        },
        setLang(state, lang) {
            state.lang = lang
            i18n.global.locale = lang
            localize(lang)
        }
    },
    actions: {
        setLoggedUser(context, payload) {
            context.commit('setUser', payload.user)
            context.commit('setToken', payload.token)
            context.commit('setLang', payload.user.lang)

            axios.interceptors.response.use(response => response, async error => {
                if(error.response?.status === 401) {
                    await clientLogout()
                    return router.push({name: 'login'})
                }
                return Promise.reject(error)
            })
        },
        logoutUser(context) {
            context.commit('setUser', {
                gender: 0,
                id: 0
            })
            context.commit('setToken', null)
        },
        setUser({commit}, user) {
            commit('setUser', user)
            commit('setLang', user.lang)
            commit('profile/set', ['email', 'password', 'description', 'location'], {root: true})
        },
        setLang(context, lang) {
            context.commit('setLang', lang)
        },
        setProfileVerified(context, payload) {
            context.commit('setUser', {...context.state.auth.user, profile_verified_at: payload})
        }
    },
    getters: {
        logged(state) {
            return !! (state.auth.token && state.auth.user.id)
        },
        languages() {

            return languages.map(language => ({code: language, name: i18n.global.t(`profile.edit.language_${language}`)}))

        },
        language(state) {
            return state.lang
        },
        profilePhoto: state => (size = "medium") => {

            if( ! state.auth.user)
                return null

            if( state.auth.user.photo)
                return state.auth.user.photo.source[size]

            return require(`@/assets/img/avatar-${state.auth.user.gender === 0 ? 'male' : 'female'}.svg`)
        },
        auth(state) {
            return state.auth
        },
        currency(state) {
            return state.auth.user.local_currency?.name
        }
    },
}