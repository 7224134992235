<template>
    <div class="bubble-notification-modal">

        <div class="bubble-notification-modal__header">
            <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"><g data-name="Group 250" transform="translate(-150 -390)"><circle data-name="Ellipse 59" cx="30" cy="30" r="30" transform="translate(150 390)" fill="#f5f5f5"/><g data-name="Group 40"><path data-name="Subtraction 1" d="M171.048 436a2.576 2.576 0 0 1-2.573-2.575v-4.32a5.925 5.925 0 0 1-4.475-5.748v-13.426a5.937 5.937 0 0 1 5.928-5.931h20.14a5.938 5.938 0 0 1 5.932 5.931v13.426a5.937 5.937 0 0 1-5.931 5.929h-10.237l-7.11 6.1-.008.007a2.585 2.585 0 0 1-1.666.607Zm2.406-18.182a1.454 1.454 0 1 0 0 2.909h13.091a1.454 1.454 0 0 0 0-2.909Zm.1-5.85a1.454 1.454 0 1 0 0 2.909h12.9a1.454 1.454 0 1 0 0-2.909Z" fill="#c59264"/></g></g></svg>
            </div>

            <div class="img-wrapper">
                <img :src="getUserPhotoURL(bubble.payload.user)" alt="User Photo">
            </div>
        </div>
        <div class="text">
            {{$t('notifications.message.title')}}
        </div>
        <h2>{{$t(`notifications.message.subtitle.${bubble.payload.user.gender === TYPE_GENDER_FEMALE ? 'female' : 'male'}`)}}</h2>
        <button :disabled="working" class="button button--primary" @click="messageUser">{{$t('emails.messages.action')}}</button>
        <router-link  class="button button--fourth" @click="$emit('close'); pushDataLayer(GTM_EVENT_NEW_MESSAGE_CLICK_2, auth.user)" :to="{name: 'user', params: {id: bubble.payload.user.id}}">{{$t(`notifications.actions.check.${bubble.payload.user.gender === TYPE_GENDER_FEMALE ? 'female' : 'male'}`)}}</router-link>
    </div>
</template>

<script>
import { TYPE_GENDER_FEMALE } from "@/types/user";
import { getUserPhotoURL } from "@/service/user/profile";
import { getOrCreate } from "@/api/messages";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { GTM_EVENT_NEW_MESSAGE_OPEN, GTM_EVENT_NEW_MESSAGE_CLICK_1, GTM_EVENT_NEW_MESSAGE_CLICK_2 } from "@/service/gtm/types";
import { pushDataLayer } from "@/service/gtm";

export default {
    name: "Message",
    mixins: [AuthenticatedMixin],
    props: {
        bubble: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            working: false
        }
    },
    methods: {
        pushDataLayer,
        getUserPhotoURL,
        async messageUser() {
            this.working = true
            const { data } = await getOrCreate(this.bubble.payload.user.id)
            pushDataLayer(GTM_EVENT_NEW_MESSAGE_CLICK_1, this.auth.user)
            this.working = false
            await this.$router.push({name: 'messages', params: {conversation: data.id}})
            this.$emit('close')
        }
    },
    created() {
        this.TYPE_GENDER_FEMALE             = TYPE_GENDER_FEMALE
        this.GTM_EVENT_NEW_MESSAGE_CLICK_2  = GTM_EVENT_NEW_MESSAGE_CLICK_2
    },
    mounted() {
        pushDataLayer(GTM_EVENT_NEW_MESSAGE_OPEN, this.auth.user)
    }
}
</script>