<template>
    <div class="bubble-notification-modal">

        <div class="bubble-notification-modal__header">
            <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"><g data-name="Group 255" transform="translate(-150 -366)"><circle data-name="Ellipse 59" cx="30" cy="30" r="30" transform="translate(150 366)" fill="#f5f5f5"/><path data-name="Icon simple-cashapp" d="m191.226 388.162-1.879 1.878a1.01 1.01 0 0 1-1.354.02 10.1 10.1 0 0 0-6.5-2.384c-1.96 0-3.919.646-3.919 2.444 0 1.818 2.1 2.424 4.525 3.333 4.242 1.414 7.758 3.192 7.758 7.353 0 4.525-3.515 7.636-9.252 7.98l-.532 2.424a.99.99 0 0 1-.97.788h-3.615l-.182-.02a1.01 1.01 0 0 1-.768-1.192l.566-2.566a13.212 13.212 0 0 1-5.818-3.17v-.02a.97.97 0 0 1 0-1.374l2.02-1.96a.99.99 0 0 1 1.354 0 9.769 9.769 0 0 0 6.848 2.667c2.626 0 4.384-1.111 4.384-2.869s-1.778-2.222-5.131-3.475c-3.556-1.273-6.929-3.071-6.929-7.273 0-4.889 4.061-7.273 8.869-7.495l.505-2.485a.97.97 0 0 1 .97-.768h3.6l.2.02a.967.967 0 0 1 .747 1.152l-.55 2.769a15.161 15.161 0 0 1 5.01 2.808l.04.04a.963.963 0 0 1 0 1.374Z" fill="#c59264"/></g></svg>
            </div>

            <div class="img-wrapper">
                <img :src="getUserPhotoURL(bubble.payload.user)" alt="User Photo">
            </div>
        </div>
        <div class="text">
            {{$t('profile.offer')}}
        </div>
        <p v-for="o in bubble.payload.user.offers" :key="o.id">
            {{$t('profile.offer_custom', {period: o.type, currency: bubble.payload.user.local_currency.name.toUpperCase(), amount: o.amount})}}
        </p>
        <button :disabled="working" @click="sendMessage" class="button button--primary">
            {{$t('users.send_message')}}
        </button>
        <button @click="visitProfile" class="button button--fourth">
            {{$t('users.view_profile')}}
        </button>
    </div>
</template>

<script>

import { getUserPhotoURL } from "@/service/user/profile";
import { TYPE_GENDER_MALE } from "@/types/user";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { pushDataLayer } from "@/service/gtm";
import { GTM_EVENT_DATING_OFFER_OPEN, GTM_EVENT_DATING_OFFER_CTA_1, GTM_EVENT_DATING_OFFER_CTA_2 } from "@/service/gtm/types";
import {getOrCreate} from "@/api/messages";
import toastr from "toastr";
import { mapActions } from "vuex";

export default {
    name: "Offer",
    mixins: [AuthenticatedMixin],
    props: {
        bubble: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            working: false
        }
    },
    methods: {
        getUserPhotoURL,
        pushDataLayer,
        ...mapActions({
            addPreAction: "profile/add"
        }),
        async sendMessage() {
            this.working = true
            try {
                const { data } = await getOrCreate(this.bubble.payload.user.id)
                pushDataLayer(GTM_EVENT_DATING_OFFER_CTA_1, this.auth.user)
                await this.$router.push({name: 'messages', params: {conversation: data.id}})
            } catch (e) {
                if(e?.response?.status === 406) {
                    await this.addPreAction('daily_limit')
                } else {
                    toastr.error(this.$i18n.t('credits.error_processing'))
                }
            }
            this.working = false

            this.$emit('close')
        },
        visitProfile() {
            this.$router.push({name: 'user', params: {id: this.bubble.payload.user.id}})
            pushDataLayer(GTM_EVENT_DATING_OFFER_CTA_2, this.auth.user)
            this.$emit('close')
        }
    },
    created() {
        this.TYPE_GENDER_MALE               = TYPE_GENDER_MALE
        this.GTM_EVENT_DATING_OFFER_CTA_1   = GTM_EVENT_DATING_OFFER_CTA_1
        this.GTM_EVENT_DATING_OFFER_CTA_2   = GTM_EVENT_DATING_OFFER_CTA_2
    },
    mounted() {
        pushDataLayer(GTM_EVENT_DATING_OFFER_OPEN, this.auth.user)
    }
}
</script>