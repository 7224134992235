<template>
    <Modal class="modal modal--mob" :closable="false">
        <template #body>
            <section :class="`status-box status-box--completed`">
                <article>
                    <div class="success-checkmark">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle class="path circle" fill="none" stroke="#43ca85" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                            <polyline class="path check" fill="none" stroke="#43ca85" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                        </svg>
                    </div>
                    <p>{{$t('general.app_updated')}}!</p>
                    <button :disabled="working" class="button button--primary mb-" @click="clickHandler">{{ucFirst($t('signup.select_required_photos.continue'))}}</button>
                </article>
            </section>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal.vue"
import { ucFirst } from "@/utilities/general";

export default {
    name: "PreActionNewVersion",
    components: { Modal },
    data() {
        return {
            working: false,
        }
    },
    methods: {
        ucFirst,
        clickHandler() {
            this.working = true
            this.$emit('success')
        }
    }
}
</script>

<style scoped lang="scss">
    article {
        text-align: center;
    }
    .button--primary {
        width: 100%;
    }


    .status-box--completed {
        article {

            &.is-active {
                box-shadow: 0 0 15px #43ca85;
                border: 1px solid #43ca85;
            }
        }
    }


    .success-checkmark {

        svg {
            background-color: rgba(#43ca85, .2);
            width: 100px;
            display: block;
            margin: 20px auto;
            border-radius: 50%;
        }

        margin: 0 auto;
        text-align: center;

    }


    .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
        &.circle {
            -webkit-animation: dash 1s ease-in-out;
            animation: dash 1s ease-in-out;
        }
        &.line {
            stroke-dashoffset: 1000;
            -webkit-animation: dash 1s .35s ease-in-out forwards;
            animation: dash 1s .35s ease-in-out forwards;
        }
        &.check {
            stroke-dashoffset: -100;
            -webkit-animation: dash-check 1s .35s ease-in-out forwards;
            animation: dash-check 1s .35s ease-in-out forwards;
        }
    }

    @-webkit-keyframes dash {
        0% {
            stroke-dashoffset: 1000;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }

    @keyframes dash {
        0% {
            stroke-dashoffset: 1000;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }

    @-webkit-keyframes dash-check {
        0% {
            stroke-dashoffset: -100;
        }
        100% {
            stroke-dashoffset: 900;
        }
    }

    @keyframes dash-check {
        0% {
            stroke-dashoffset: -100;
        }
        100% {
            stroke-dashoffset: 900;
        }
    }
</style>