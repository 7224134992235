<template>
    <main>
        <div class="o-container">
            <ContentLoader v-if="loading" class="loader" />
            <Profile v-if="user" :user="user" :user-public-photos="photos" :user-public-videos="videos" :access="access" :offer="offer" />
            <div v-if=" ! user && ! loading" class="user-not-found">
                <article>
                    <svg width="114" height="113" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="114" height="112.761" rx="16" fill="#ECECEC"/><path fill-rule="evenodd" clip-rule="evenodd" d="M28.846 66.113a13.131 13.131 0 0 1 9.285-3.846h19.351a13.131 13.131 0 0 1 13.131 13.13v5.53a2.073 2.073 0 0 1-4.147 0v-5.53a8.984 8.984 0 0 0-8.984-8.984H38.131a8.984 8.984 0 0 0-8.984 8.985v5.529a2.073 2.073 0 0 1-4.147 0v-5.53a13.13 13.13 0 0 1 3.846-9.284ZM47.807 33.24a8.984 8.984 0 1 0 0 17.97 8.984 8.984 0 0 0 0-17.97Zm-13.131 8.985c0-7.252 5.879-13.131 13.13-13.131 7.253 0 13.132 5.879 13.132 13.13 0 7.253-5.88 13.132-13.131 13.132-7.252 0-13.131-5.88-13.131-13.131ZM72.602 43.523c.81-.81 2.123-.81 2.932 0l13.823 13.822a2.073 2.073 0 0 1-2.933 2.932L72.602 46.455a2.073 2.073 0 0 1 0-2.932Z" fill="#C4C4C4" stroke="#C4C4C4" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M89.357 43.523c.81.81.81 2.123 0 2.932L75.534 60.277a2.073 2.073 0 0 1-2.932-2.932l13.822-13.822c.81-.81 2.123-.81 2.933 0Z" fill="#C4C4C4" stroke="#C4C4C4" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    <h1>{{$t('users.not_found')}}</h1>
                    <p>{{$t('users.search_correctly')}}</p>
                    <router-link :to="{name: 'search'}" class="button button--primary">{{$t('continue')}}</router-link>
                </article>
            </div>
        </div>
    </main>
</template>

<script>
import Profile from "@/components/user/Profile"
import {user, userVideoGallery, userGallery, getUserAccess, getUserOffer} from "@/api/users";
import ContentLoader from "@/components/ContentLoader";

export default {
    name: "UserPage",
    data() {
        return {
            loading: true,
            user: null,
            photos: null,
            videos: null,
            access: null,
            offer: null,
            unwatch: null
        }
    },
    components: {Profile, ContentLoader},

    methods: {
        reset() {
            this.loading = true
            this.user = null
            this.photos = null
            this.videos = null
            this.access = null
            this.offer = null
        },
        async load() {

            this.reset()

            try {

                const { data }  = await user(this.$route.params.id)


                const [photos, videos, access, offer] = await Promise.allSettled([
                    userGallery(data.id,{page: 1, perPage: 50}),
                    userVideoGallery(data.id, {page: 1, private: 0, perPage: 50}),
                    getUserAccess(this.$route.params.id),
                    getUserOffer(this.$route.params.id)
                ])


                if(photos.status === 'fulfilled') {
                    this.photos = photos.value.data
                }

                if(videos.status === 'fulfilled') {
                    this.videos = videos.value.data
                }

                if(access.status === 'fulfilled') {
                    this.access = access.value.data
                }

                if(offer.status === 'fulfilled') {
                    this.offer = offer.value.data
                }

                this.user       = data

            } catch(e) {

                this.user = null

            } finally {

                this.loading    = false

            }

        }
    },

    beforeUnmount() {
        this.unwatch()
    },

    async mounted() {

        this.unwatch = this.$watch(() => this.$route.params, (to) => {
            if(to.id && to.id !== this.user?.id) {
                this.load()
            }
        })

       await this.load()

    }
}
</script>

<style scoped lang="scss">

    main {
        display: flex;
        height: 100%;

        @media(max-width: 500px) {
            background-color: #fff;
            z-index: 9999;
            position: relative;
        }
    }

    .user-not-found {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        article {
            width: 100%;
            max-width: 365px;
            text-align: center;
        }

        h1 {
            font-weight: 700;
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 12px;
        }

        svg {
            margin-bottom: 37px;
        }

        p {
            margin-bottom: 28px;
            font-size: 16px;
            line-height: 22px;
        }
    }

    .o-container {
        min-height: 100%;

        @media(max-width: 500px) {
            padding: 0;
        }
    }

    .loader {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>