<template>
    <div class="upload-photo-wrap">
        <div class="img-wrapper">
            <Image :src="file.src" v-if="file.type === ITEM_TYPE_PHOTO" :blurred="secret" />
            <Video v-if="file.type === ITEM_TYPE_VIDEO" controls autoplay :src="file.src" :blurred="secret"/>
            <div v-if="![ITEM_TYPE_PHOTO, ITEM_TYPE_VIDEO].includes(file.type)">Type not supported</div>
            <svg v-if="secret" xmlns="http://www.w3.org/2000/svg" width="33.337" height="32" data-name="Component 7 – 19" data-v-1b383f20=""><g data-name="Group 35" data-v-1b383f20=""><path fill="#fff" d="M9.819 32a9.887 9.887 0 0 1-6.944-16.858 9.782 9.782 0 0 1 12.791-.954L29.347.426a1.439 1.439 0 0 1 2.045 0 1.459 1.459 0 0 1 0 2.057l-2.02 2.032 3.543 3.567a1.462 1.462 0 0 1 0 2.057L27.587 15.5a1.482 1.482 0 0 1-2.045 0L22 11.933l-4.286 4.312A9.871 9.871 0 0 1 9.819 32m0-16.841a6.861 6.861 0 0 0-4.9 2.041 6.927 6.927 0 1 0 9.8 0 6.861 6.861 0 0 0-4.9-2.041m14.223-5.283 2.522 2.537 3.283-3.3-2.521-2.54Z" data-name="Path 26" data-v-1b383f20=""></path></g></svg>
        </div>
        <div class="secret-profile-photo">
            <SwitchButton :checked="secret" @switch="value => secret = value">
                <p class="mb0 switch-box-paragraph">
                    <slot name="switch"></slot>
                </p>
            </SwitchButton>
            <transition name="slidedown">
                <p v-if="secret">
                    <slot name="description"></slot>
                </p>
            </transition>
        </div>
        <div class="btn-wrapper o-flex">
            <button class="button button--fourth" @click="$emit('close')">{{$t('file_control.cancel')}}</button>
            <button class="button button--primary" @click="upload">{{ucFirst($t('profile.edit.save'))}}</button>
        </div>
    </div>
</template>

<script>
import Image from "@/components/generic/Image.vue";
import Video from "@/components/generic/Video.vue";
import SwitchButton from "@/components/generic/SwitchButton.vue";
import { base64ToBlob, uploadToBucket, SelectedFile } from "@/service/files";
import { createItem, createPreSignedURL } from "@/api/files";
import { ITEM_TYPE_PHOTO, ITEM_TYPE_VIDEO } from "@/types/item";
import { ucFirst } from "@/utilities/general";
export default {
    name: "UploadItem",
    components: { Image, SwitchButton, Video },
    emits: ['finished', 'close', 'error', 'progress'],
    props: {
        verification: {
            type: Boolean,
            default: false
        },
        file: {
            type: SelectedFile,
            required: true
        }
    },
    data() {
        return {
            secret: false
        }
    },
    methods: {
        ucFirst,
        async upload() {
            try {
                this.$emit('progress', 0)
                const data = this.file.src
                const hash = this.file.hash
                const preSignedURL  = await createPreSignedURL(this.file.extension)
                await uploadToBucket(preSignedURL.data, base64ToBlob(data), (e) => this.$emit('progress', e))
                const payload = {file: preSignedURL.data.filename, type: this.file.type, private: this.secret ? 1 : 0}
                if(this.verification) {
                    payload.verification = 1
                }
                const item = await createItem(payload)
                this.$emit('finished', {...item.data, hash })

            } catch(e) {
                this.$emit('error', e)
            }
        }
    },
    beforeUnmount() {
        this.secret = false
    },
    created() {
        this.ITEM_TYPE_PHOTO = ITEM_TYPE_PHOTO
        this.ITEM_TYPE_VIDEO = ITEM_TYPE_VIDEO
    }
}
</script>

<style lang="scss" scoped>
    .upload-photo-wrap {

    }

    .img-wrapper {
        overflow: hidden;
        margin-bottom: 20px;
        position: relative;
        max-height: 450px;

        img {
            width: 100%;
        }

        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    }

    .btn-wrapper {
        justify-content: space-between;

        button {
            width:  calc(50% - 5px);
            margin-top: 10px;
        }
    }
</style>