import { getNotifications, putNotifications } from "@/api/notifications";
import store from "@/store";
import {unread} from "@/api/messages";

// TODO: Optimise this
export const loadNotifications = () => {

    getNotifications()
        .then(({data: {access, matches}}) => store.dispatch('notifications/setSocial', {access, matches}))
        .catch(e => console.log('Error loading notifications', e))

    unread()
        .then(({data}) => store.dispatch('notifications/setMessages', data))
        .catch(e => console.log('Error loading unread messages', e))

}

export const read = async (type, ids) => {

    await putNotifications({type, ids})

    loadNotifications()

}

export const playSound = sound => new Audio(sound).play().catch(e => console.log('Cannot play sound', e))