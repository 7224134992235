export default {

    namespaced: true,

    state: {
        promotions: []
    },

    mutations: {
        setPromotions(state, payload) {
            state.promotions = payload
        }
    },

    actions: {

        setPromotions(context, payload) {
            context.commit('setPromotions', payload)
        },

        addPromotion(context, payload) {
            context.commit('setPromotions', [
                ...context.state.promotions.filter(e => e.id !== payload.id),
                payload
            ])
        }
    }

}