export default {

    namespaced: true,

    state: {
        bootstrapping: false,
        connected: false,
        mobile: false,
        interacted: false,
        browser: {
            name: '',
            version: '',
        },
        os: {
            name: '',
            version: '',
        },
        agent: '',
        installed: false,
        push_supported: false,
        push_enabled: false
    },

    mutations: {
        setBootstrapping(state, payload) {
            state.bootstrapping = payload
        },
        setConnected(state, payload) {
            state.connected = payload
        },
        setMobile(state, payload) {
            state.mobile = payload
        },
        setInteracted(state, payload) {
            state.interacted = payload
        },
        setBrowser(state, payload) {
            state.browser = payload
        },
        setOS(state, payload) {
            state.os = payload
        },
        setAgent(state, payload) {
            state.agent = payload
        },
        setInstalled(state, payload) {
            state.installed = payload
        },
        setPushEnabled(state, payload) {
            state.push_enabled = payload
        },
        setPushSupported(state, payload) {
            state.push_supported = payload
        }

    },

    actions: {
        setBootstrapping({commit}, payload) {
            commit('setBootstrapping', payload)
        },
        setConnected({commit}, payload) {
            commit('setConnected', payload)
        },
        setMobile({commit}, payload) {
            commit('setMobile', payload)
        },
        setInteracted({commit}, payload) {
            commit('setInteracted', payload)
        },
        setBrowser({commit}, payload) {
            commit('setBrowser', payload)
        },
        setOS({commit}, payload) {
            commit('setOS', payload)
        },
        setAgent({commit}, payload) {
            commit('setAgent', payload)
        },
        setInstalled({commit}, payload) {
            commit('setInstalled', payload)
        },
        setPushEnabled({commit}, payload) {
            commit('setPushEnabled', payload)
        },
        setPushSupported({commit}, payload) {
            commit('setPushSupported', payload)
        }
    },

    getters: {

    }

}