import {deleteGalleryPhoto, uploadGalleryPhoto, uploadPhoto as upload, updatePhoto, setPhotoAsProfile as APISetPhotoAsProfile} from "@/api/profile";
import store from "@/store";

/**
 *
 * @param photo
 * @returns {Promise<unknown>}
 */
export const uploadProfilePhoto = async (photo) => {
    try {
        const { data } = await upload(photo)

        const payload = {
            ...store.state.authenticate.auth.user,
            photo: data,
            photo_id: data.id
        }

        await store.dispatch('authenticate/setUser', payload)

        return Promise.resolve(data)
    } catch(e) {
        return Promise.reject(e)
    }
}

export const setPhotoAsProfile = async (photo) => {

    try {

        await APISetPhotoAsProfile(photo)

        const payload = {
            ...store.state.authenticate.auth.user,
            photo: photo,
            photo_id: photo.id
        }

        await store.dispatch('authenticate/setUser', payload)

    } catch (e) {
        return Promise.reject(e)
    }

}

/**
 *
 * @param photo {FormData}
 * @param progressCallback {function|null}
 * @returns {Promise<*>}
 */
export const addGalleryPhoto = (photo, progressCallback = null) => {

    return uploadGalleryPhoto(photo, progressCallback)

}

/**
 *
 * @param id {Number}
 * @returns {Promise<*>}
 */
export const deletePhoto = id => {
    return deleteGalleryPhoto(id)
}

export const updatePhotoService = (id, data) => {
    return updatePhoto(id, data)
}