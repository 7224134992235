<template>
    <div class="bubble-notification-modal">

        <div class="bubble-notification-modal__header">
            <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"><g data-name="Group 252" transform="translate(-150 -366)"><circle data-name="Ellipse 59" cx="30" cy="30" r="30" transform="translate(150 366)" fill="#f5f5f5"/><g data-name="Group 245"><g data-name="Group 35"><path data-name="Path 26" d="M173.126 412a9.875 9.875 0 1 1 5.868-17.812l13.731-13.762a1.453 1.453 0 0 1 2.052 2.057l-2.028 2.033 3.556 3.567a1.458 1.458 0 0 1 0 2.057l-5.347 5.36a1.491 1.491 0 0 1-2.052 0l-3.557-3.566-4.3 4.312A9.873 9.873 0 0 1 173.126 412" fill="#c59264"/></g></g></g></svg>
            </div>

            <div class="img-wrapper">
                <img :src="getUserPhotoURL(bubble.payload.user)" alt="User Photo">
            </div>
        </div>
        <div class="text">
            {{$t('notifications.access.title')}}
        </div>
        <h2>{{$t(`notifications.access.subtitle.${bubble.payload.user.gender === TYPE_GENDER_FEMALE ? 'female' : 'male'}`)}}</h2>
        <button @click="approve" :disabled="working" class="button button--primary">{{$t(`notifications.actions.approve.${bubble.payload.user.gender === TYPE_GENDER_FEMALE ? 'female' : 'male'}`)}}</button>
        <router-link class="button button--fourth" @click="$emit('close'); pushDataLayer(GTM_EVENT_SECRET_ACCESS_GRANTED_CLICK_2, auth.user)" :to="{name: 'user', params: {id: bubble.payload.user.id}}">{{$t(`notifications.actions.check.${bubble.payload.user.gender === TYPE_GENDER_FEMALE ? 'female' : 'male'}`)}}</router-link>
    </div>
</template>

<script>

import { getUserPhotoURL } from "@/service/user/profile";
import { TYPE_GENDER_FEMALE } from "@/types/user";
import { respond } from "@/api/access";
import toastr from "toastr";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import {
    GTM_EVENT_REQUESTED_SECRET_ACCESS_OPEN, GTM_EVENT_SECRET_ACCESS_GRANTED_CLICK_1,
    GTM_EVENT_SECRET_ACCESS_GRANTED_CLICK_2
} from "@/service/gtm/types";
import { pushDataLayer } from "@/service/gtm";

export default {
    name: "AccessRequested",
    mixins: [AuthenticatedMixin],
    props: {
        bubble: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            working: false
        }
    },
    methods: {
        pushDataLayer,
        getUserPhotoURL,
        async approve() {
            try {
                this.working = true
                await respond(this.bubble.payload.request.id, 1)
                pushDataLayer(GTM_EVENT_SECRET_ACCESS_GRANTED_CLICK_1, this.auth.user)
                this.$emit('close')
            } catch {
                toastr.error(this.$i18n.t('credits.error_processing'))
            } finally {
                this.working = false
            }
        }
    },
    created() {
        this.TYPE_GENDER_FEMALE                         = TYPE_GENDER_FEMALE
        this.GTM_EVENT_SECRET_ACCESS_GRANTED_CLICK_2    = GTM_EVENT_SECRET_ACCESS_GRANTED_CLICK_2
    },
    mounted() {
        pushDataLayer(GTM_EVENT_REQUESTED_SECRET_ACCESS_OPEN, this.auth.user)
    }
}
</script>

<style lang="scss" scoped>

</style>