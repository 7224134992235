<template>
    <div class="banner-pwa">
        <div class="icon-wrapper-circle">
            <svg v-if="isOS(iOS)" width="54px" viewBox="0 0 45.594 56">
                <path d="M44.138 19.092a12.684 12.684 0 0 0-6.06 10.669 12.347 12.347 0 0 0 7.516 11.324 29.37 29.37 0 0 1-3.848 7.947c-2.4 3.45-4.9 6.894-8.709 6.894s-4.788-2.212-9.185-2.212c-4.284 0-5.808 2.285-9.291 2.285s-5.914-3.192-8.709-7.113A34.3 34.3 0 0 1 0 30.344c0-10.888 7.079-16.662 14.046-16.662 3.7 0 6.788 2.431 9.112 2.431 2.212 0 5.662-2.576 9.874-2.576a13.2 13.2 0 0 1 11.106 5.556ZM31.033 8.927a12.5 12.5 0 0 0 2.974-7.8A5.376 5.376 0 0 0 33.9 0a12.526 12.526 0 0 0-8.238 4.245 12.175 12.175 0 0 0-3.086 7.589 5.142 5.142 0 0 0 .106 1.014 4.383 4.383 0 0 0 .762.073 10.854 10.854 0 0 0 7.589-3.993Z"/>
            </svg>
            <svg v-if="isOS(Android)" width="54px" viewBox="0 0 50.751 56.002">
                <g data-name="Group 1242">
                    <path fill="#ea4335" d="M23.701 26.748.21 51.359a6.372 6.372 0 0 0 9.33 3.781l26.431-15.054Z" data-name="Path 11688"/>
                    <path fill="#fbbc04" d="m47.454 22.566-11.426-6.55-12.87 11.29 12.918 12.743 11.343-6.477a6.214 6.214 0 0 0 0-11.01Z" data-name="Path 11689"/>
                    <path fill="#4285f4" d="M.21 4.655A6.057 6.057 0 0 0 0 6.265v43.481a6.407 6.407 0 0 0 .21 1.61l24.3-23.981Z" data-name="Path 11690"/>
                    <path fill="#34a853" d="m23.876 28.007 12.148-11.99L9.627.893A6.512 6.512 0 0 0 6.354 0 6.372 6.372 0 0 0 .21 4.639Z" data-name="Path 11691"/>
                </g>
            </svg>
        </div>

        <div class="banner-pwa__content">
            <h1>{{$t('pwa.install.banner.title')}}</h1>
            <p>{{$t('pwa.install.banner.description')}}</p>
            <a href="javascript:;" @click="$emit('select')" class="button button--primary">{{$t('pwa.install.banner.install')}}</a>
        </div>
    </div>
</template>

<script>
import { isOS } from "@/service/general";
import { iOS, Android} from "@/types/device";

export default {
    name: 'PWABanner',
    emits: ['select'],
    methods: {
        isOS
    },
    created() {
        this.iOS = iOS
        this.Android = Android
    }
}
</script>

<style scoped lang="scss">
.banner-pwa {
    background-color: #fff;
    padding: 30px;
    display: flex;
    border-radius: 10px;
    gap: 30px;
    flex: 1 1 100%;


    .icon-wrapper-circle {
        width: 100px;
        min-width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: $color-type3;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .banner-pwa__content {
        h1 {
            font-weight: 700;
            margin-bottom: 10px;
            line-height: 1.3;
            font-size: 22px;
        }

        p {
            line-height: 1.3;
        }
    }

    @media(max-width: 768px) {
        flex-direction: column;
        text-align: center;
        padding: 30px 20px 40px;

        .icon-wrapper-circle {
            margin: 0 auto;
        }
    }
}
</style>