<template>
    <Modal class="modal modal--mob modal-radio-buttons">
        <template #header>
            <h2>{{$t('form_fields.piercing_label')}}</h2>
        </template>
        <template #body>
            <div v-for="p in piercings" :key="p.code" class="radiobox">
                <input class="form-check-input" type="radio" :id="p.code" :checked="value === p.value">
                <label @click="update(p.value)" class="radiobox__label" :for="p.code">
                    {{$t(`form_fields.piercing_options_${p.value}`)}}
                </label>
            </div>
        </template>
        <template #footer>
            <a @click="$emit('success')" class="skip">{{$t('credits.skip_for_now')}}</a>
        </template>
    </Modal>
</template>

<script>

import Modal from "@/components/generic/Modal";
import { piercings } from "@/service/forms"
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";

export default {
    name: "OnDemandPiercing",
    mixins: [AuthenticatedMixin],
    components: { Modal },
    props: {
        value: {
            type: [Number, null],
            required: true
        }
    },

    methods: {

        update(value) {

            this.$emit('set', {
                piercing: value
            })

        }

    },

    created() {
        this.piercings = piercings
    },
    mounted() {
        this.piercing = this.auth.user.piercing
    },
    beforeUnmount() {
        clearTimeout(this.timeout)
    }
}
</script>