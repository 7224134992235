export default {

    namespaced: true,

    state: {
        social: {
            matches: {
                admirers: [],
                visitors: []
            },
            access: {
                my_access: [],
                pending: []
            }
        },
        messages: []
    },

    mutations: {
        setSocial(state, payload) {
            state.social = payload
        },
        setMessages(state, payload) {
            state.messages = payload
        }
    },

    actions: {

        setSocial({commit}, payload) {
            commit('setSocial', payload)
        },

        addFavorite(context, payload) {

            context.commit('setSocial', {
                ...context.state.social,
                matches: {
                    ...context.state.social.matches,
                    admirers: [...context.state.social.matches.admirers.filter(e => e !== payload), payload]
                }
            })

        },

        removeFavorite(context, payload) {
            context.commit('setSocial', {
                ...context.state.social,
                matches: {
                    ...context.state.social.matches,
                    admirers: [...context.state.social.matches.admirers.filter(e => e !== payload)]
                }
            })
        },

        addVisitor(context, payload) {
            context.commit('setSocial', {
                ...context.state.social,
                matches: {
                    ...context.state.social.matches,
                    visitors: [...context.state.social.matches.visitors.filter(e => e !== payload), payload]
                }
            })
        },

        addRequest(context, payload) {
            context.commit('setSocial', {
                ...context.state.social,
                access: {
                    ...context.state.social.access,
                    pending: [...context.state.social.access.pending.filter(e => e !== payload), payload]
                }
            })
        },

        addMyAccess(context, payload) {
            context.commit('setSocial', {
                ...context.state.social,
                access: {
                    ...context.state.social.access,
                    my_access: [...context.state.social.access.my_access.filter(e => e !== payload), payload]
                }
            })
        },

        removeMyAccess(context, payload) {
            context.commit('setSocial', {
                ...context.state.social,
                access: {
                    ...context.state.social.access,
                    my_access: [...context.state.social.access.my_access.filter(e => e !== payload)]
                }
            })
        },

        setMessages({commit}, payload) {
            commit('setMessages', payload)
        },

        addMessage(context, payload) {

            if(context.state.messages.find(e => e.conversation_id === payload.conversation_id)) {

                return context.commit('setMessages',
                    context.state.messages.map(e => {
                        if(e.conversation_id === payload.conversation_id) {
                            return {
                                ...e,
                                counter: e.counter + 1
                            }
                        }
                        return e
                    })
                )

            }

            context.commit('setMessages', [
                ...context.state.messages,
                {
                    conversation_id: payload.conversation_id,
                    counter: 1
                }
            ])

        },

        readConversation(context, payload) {

            context.commit('setMessages', context.state.messages.filter(e => e.conversation_id !== payload))

        }
    },

    getters: {

        countGroupedSocial: state => (key) => {
            if(state.social[key] !== undefined) {
                let total = 0
                for(const entry in state.social[key]) {
                    total += state.social[key][entry].length
                }
                return total
            }

            return 0
        },

        countSocial: state => (group, key) => {
            if(state.social[group][key] !== undefined)
                return state.social[group][key].length

            return 0
        },

        countConversations(state) {
            return state.messages.length
        },

        countMessages: (state) => (conversation_id) => {

            const conversation = state?.messages?.find(e => e.conversation_id === conversation_id)

            if( ! conversation)
                return 0

            return conversation.counter

        }

    }

}