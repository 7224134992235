import {TYPE_GENDER_MALE, TYPE_GENDER_FEMALE} from "@/types/user";

export const bodyTypes =  [
    {
        'code': 'slim',
        'value': 0,
        'genders': [0, 1]
    },
    {
        'code': 'fit',
        'value': 1,
        'genders': [0, 1]
    },
    {
        'code': 'muscular',
        'value': 2,
        'genders': [0, 1]
    },
    {
        'code': 'average',
        'value': 3,
        'genders': [0, 1]
    },
    {
        'code': 'extra',
        'value': 4,
        'genders': [0, 1]
    },
    {
        'code': 'curvy',
        'value': 5,
        'genders': [1]
    },
    {
        'code': 'full',
        'value': 6,
        'genders': [1]
    },
    {
        'code': 'heavy',
        'value': 7,
        'genders': [0]
    },
    {
        'code': 'extra_heavy',
        'value': 8,
        'genders': [0]
    }

]


export const ethnicities =  [
    {
        'code': 'white',
        'value': 0
    },
    {
        'code': 'black',
        'value': 1
    },
    {
        'code': 'asian',
        'value': 2
    },
    {
        'code': 'hispanic',
        'value': 3
    },
    {
        'code': 'indian',
        'value': 4
    },
    {
        'code': 'eastern',
        'value': 5
    },
    {
        'code': 'other',
        'value': 6
    },
]


export const interests = [
    {
        'code': 'rinsing',
        'value': 0
    },
    {
        'code': 'selling_pics',
        'value': 1
    },
    {
        'code': 'meetings',
        'value': 2
    },
    {
        'code': 'travel',
        'value': 3
    },
]


export const hairColor =  [
    {
        'code': 'black',
        'value': 0
    },
    {
        'code': 'blond',
        'value': 1
    },
    {
        'code': 'brown',
        'value': 2
    },
    {
        'code': 'gray',
        'value': 3
    },
    {
        'code': 'red',
        'value': 4
    },
    {
        'code': 'other',
        'value': 5
    }
]


export const eyeColor = [
    {
        'code': 'black',
        'value': 0
    },
    {
        'code': 'blue',
        'value': 1
    },
    {
        'code': 'light_brown',
        'value': 2
    },
    {
        'code': 'dark_brown',
        'value': 3
    },
    {
        'code': 'green',
        'value': 4
    },
    {
        'code': 'hazel',
        'value': 5
    },
    {
        'code': 'other',
        'value': 6
    }
]


export const piercings = [
    {
        'code': 'none',
        'value': 0
    },
    {
        'code': 'ears',
        'value': 1
    },
    {
        'code': 'few',
        'value': 2
    },
    {
        'code': 'many',
        'value': 3
    },
    {
        'code': 'covered',
        'value': 4
    }
]


export const tattoos = [
    {
        'code': 'none',
        'value': 0
    },
    {
        'code': 'few',
        'value': 1
    },
    {
        'code': 'many',
        'value': 2
    },
    {
        'code': 'covered',
        'value': 3
    }
]


export const smoking = [
    {
        'code': 'no',
        'value': 0
    },
    {
        'code': 'occasionally',
        'value': 1
    },
    {
        'code': 'regularly',
        'value': 2
    }
]


export const drinking = [
    {
        'code': 'no',
        'value': 0
    },
    {
        'code': 'socially',
        'value': 1
    },
    {
        'code': 'regularly',
        'value': 2
    }
]


export const yearlyIncome = [
    {
        'code': 'under_100',
        'value': 0
    },
    {
        'code': '100_to_250',
        'value': 1
    },
    {
        'code': '250_to_500',
        'value': 2
    },
    {
        'code': '500_to_1',
        'value': 3
    },
    {
        'code': 'over_1',
        'value': 4
    }
]


export const netWorth = [
    {
        'code': 'under_500',
        'value': 0
    },
    {
        'code': '500_to_1',
        'value': 1
    },
    {
        'code': '1_to_10',
        'value': 2
    },
    {
        'code': 'over_10',
        'value': 3
    }
]


export const education = [
    {
        'code': 'high_school',
        'value': 0
    },
    {
        'code': 'college',
        'value': 1
    },
    {
        'code': 'associate',
        'value': 2
    },
    {
        'code': 'bachelor',
        'value': 3
    },
    {
        'code': 'graduate',
        'value': 4
    },
    {
        'code': 'phd',
        'value': 5
    }
]


export const timezones = [
    { value: 'Pacific/Pago_Pago', text: '(GMT-11:00) Pacific/Pago_Pago' },
    { value: 'Pacific/Honolulu', text: '(GMT-10:00) Pacific/Honolulu' },
    { value: 'America/Los_Angeles', text: '(GMT-08:00) America/Los_Angeles' },
    { value: 'America/Tijuana', text: '(GMT-08:00) America/Tijuana' },
    { value: 'America/Denver', text: '(GMT-07:00) America/Denver' },
    { value: 'America/Phoenix', text: '(GMT-07:00) America/Phoenix' },
    { value: 'America/Mazatlan', text: '(GMT-07:00) America/Mazatlan' },
    { value: 'America/Chicago', text: '(GMT-06:00) America/Chicago' },
    { value: 'America/Mexico_City', text: '(GMT-06:00) America/Mexico_City' },
    { value: 'America/Regina', text: '(GMT-06:00) America/Regina' },
    { value: 'America/Guatemala', text: '(GMT-06:00) America/Guatemala' },
    { value: 'America/Bogota', text: '(GMT-05:00) America/Bogota' },
    { value: 'America/New_York', text: '(GMT-05:00) America/New_York' },
    { value: 'America/Lima', text: '(GMT-05:00) America/Lima' },
    { value: 'America/Caracas', text: '(GMT-04:30) America/Caracas' },
    { value: 'America/Halifax', text: '(GMT-04:00) America/Halifax' },
    { value: 'America/Guyana', text: '(GMT-04:00) America/Guyana' },
    { value: 'America/La_Paz', text: '(GMT-04:00) America/La_Paz' },
    { value: 'America/Argentina', text: '(GMT-03:00) America/Argentina/Buenos_Aires' },
    { value: 'America/Godthab', text: '(GMT-03:00) America/Godthab' },
    { value: 'America/Montevideo', text: '(GMT-03:00) America/Montevideo' },
    { value: 'America/St_Johns', text: '(GMT-03:30) America/St_Johns' },
    { value: 'America/Santiago', text: '(GMT-03:00) America/Santiago' },
    { value: 'America/Sao_Paulo', text: '(GMT-02:00) America/Sao_Paulo' },
    { value: 'Atlantic/South_Georgia', text: '(GMT-02:00) Atlantic/South_Georgia' },
    { value: 'Atlantic/Azores', text: '(GMT-01:00) Atlantic/Azores' },
    { value: 'Atlantic/Cape_Verde', text: '(GMT-01:00) Atlantic/Cape_Verde' },
    { value: 'Africa/Casablanca', text: '(GMT+00:00) Africa/Casablanca' },
    { value: 'Europe/Dublin', text: '(GMT+00:00) Europe/Dublin' },
    { value: 'Europe/Lisbon', text: '(GMT+00:00) Europe/Lisbon' },
    { value: 'Europe/London', text: '(GMT+00:00) Europe/London' },
    { value: 'Africa/Monrovia', text: '(GMT+00:00) Africa/Monrovia' },
    { value: 'Africa/Algiers', text: '(GMT+01:00) Africa/Algiers' },
    { value: 'Europe/Amsterdam', text: '(GMT+01:00) Europe/Amsterdam' },
    { value: 'Europe/Berlin', text: '(GMT+01:00) Europe/Berlin' },
    { value: 'Europe/Brussels', text: '(GMT+01:00) Europe/Brussels' },
    { value: 'Europe/Budapest', text: '(GMT+01:00) Europe/Budapest' },
    { value: 'Europe/Belgrade', text: '(GMT+01:00) Europe/Belgrade' },
    { value: 'Europe/Prague', text: '(GMT+01:00) Europe/Prague' },
    { value: 'Europe/Copenhagen', text: '(GMT+01:00) Europe/Copenhagen' },
    { value: 'Europe/Madrid', text: '(GMT+01:00) Europe/Madrid' },
    { value: 'Europe/Paris', text: '(GMT+01:00) Europe/Paris' },
    { value: 'Europe/Rome', text: '(GMT+01:00) Europe/Rome' },
    { value: 'Europe/Stockholm', text: '(GMT+01:00) Europe/Stockholm' },
    { value: 'Europe/Vienna', text: '(GMT+01:00) Europe/Vienna' },
    { value: 'Europe/Warsaw', text: '(GMT+01:00) Europe/Warsaw' },
    { value: 'Europe/Athens', text: '(GMT+02:00) Europe/Athens' },
    { value: 'Europe/Bucharest', text: '(GMT+02:00) Europe/Bucharest' },
    { value: 'Africa/Cairo', text: '(GMT+02:00) Africa/Cairo' },
    { value: 'Asia/Jerusalem', text: '(GMT+02:00) Asia/Jerusalem' },
    { value: 'Africa/Johannesburg', text: '(GMT+02:00) Africa/Johannesburg' },
    { value: 'Europe/Helsinki', text: '(GMT+02:00) Europe/Helsinki' },
    { value: 'Europe/Kiev', text: '(GMT+02:00) Europe/Kiev' },
    { value: 'Europe/Kaliningrad', text: '(GMT+02:00) Europe/Kaliningrad' },
    { value: 'Europe/Riga', text: '(GMT+02:00) Europe/Riga' },
    { value: 'Europe/Sofia', text: '(GMT+02:00) Europe/Sofia' },
    { value: 'Europe/Tallinn', text: '(GMT+02:00) Europe/Tallinn' },
    { value: 'Europe/Vilnius', text: '(GMT+02:00) Europe/Vilnius' },
    { value: 'Europe/Istanbul', text: '(GMT+03:00) Europe/Istanbul' },
    { value: 'Asia/Baghdad', text: '(GMT+03:00) Asia/Baghdad' },
    { value: 'Africa/Nairobi', text: '(GMT+03:00) Africa/Nairobi' },
    { value: 'Europe/Minsk', text: '(GMT+03:00) Europe/Minsk' },
    { value: 'Asia/Riyadh', text: '(GMT+03:00) Asia/Riyadh' },
    { value: 'Europe/Moscow', text: '(GMT+03:00) Europe/Moscow' },
    { value: 'Asia/Tehran', text: '(GMT+03:30) Asia/Tehran' },
    { value: 'Asia/Baku', text: '(GMT+04:00) Asia/Baku' },
    { value: 'Europe/Samara', text: '(GMT+04:00) Europe/Samara' },
    { value: 'Asia/Tbilisi', text: '(GMT+04:00) Asia/Tbilisi' },
    { value: 'Asia/Yerevan', text: '(GMT+04:00) Asia/Yerevan' },
    { value: 'Asia/Kabul', text: '(GMT+04:30) Asia/Kabul' },
    { value: 'Asia/Karachi', text: '(GMT+05:00) Asia/Karachi' },
    { value: 'Asia/Yekaterinburg', text: '(GMT+05:00) Asia/Yekaterinburg' },
    { value: 'Asia/Tashkent', text: '(GMT+05:00) Asia/Tashkent' },
    { value: 'Asia/Colombo', text: '(GMT+05:30) Asia/Colombo' },
    { value: 'Asia/Almaty', text: '(GMT+06:00) Asia/Almaty' },
    { value: 'Asia/Dhaka', text: '(GMT+06:00) Asia/Dhaka' },
    { value: 'Asia/Rangoon', text: '(GMT+06:30) Asia/Rangoon' },
    { value: 'Asia/Bangkok', text: '(GMT+07:00) Asia/Bangkok' },
    { value: 'Asia/Jakarta', text: '(GMT+07:00) Asia/Jakarta' },
    { value: 'Asia/Krasnoyarsk', text: '(GMT+07:00) Asia/Krasnoyarsk' },
    { value: 'Asia/Shanghai', text: '(GMT+08:00) Asia/Shanghai' },
    { value: 'Asia/Hong_Kong', text: '(GMT+08:00) Asia/Hong_Kong' },
    { value: 'Asia/Kuala_Lumpur', text: '(GMT+08:00) Asia/Kuala_Lumpur' },
    { value: 'Asia/Irkutsk', text: '(GMT+08:00) Asia/Irkutsk' },
    { value: 'Asia/Singapore', text: '(GMT+08:00) Asia/Singapore' },
    { value: 'Asia/Taipei', text: '(GMT+08:00) Asia/Taipei' },
    { value: 'Asia/Ulaanbaatar', text: '(GMT+08:00) Asia/Ulaanbaatar' },
    { value: 'Australia/Perth', text: '(GMT+08:00) Australia/Perth' },
    { value: 'Asia/Yakutsk', text: '(GMT+09:00) Asia/Yakutsk' },
    { value: 'Asia/Seoul', text: '(GMT+09:00) Asia/Seoul' },
    { value: 'Asia/Tokyo', text: '(GMT+09:00) Asia/Tokyo' },
    { value: 'Australia/Darwin', text: '(GMT+09:30) Australia/Darwin' },
    { value: 'Australia/Brisbane', text: '(GMT+10:00) Australia/Brisbane' },
    { value: 'Pacific/Guam', text: '(GMT+10:00) Pacific/Guam' },
    { value: 'Asia/Magadan', text: '(GMT+10:00) Asia/Magadan' },
    { value: 'Asia/Vladivostok', text: '(GMT+10:00) Asia/Vladivostok' },
    { value: 'Pacific/Port_Moresby', text: '(GMT+10:00) Pacific/Port_Moresby' },
    { value: 'Australia/Adelaide', text: '(GMT+10:30) Australia/Adelaide' },
    { value: 'Australia/Hobart', text: '(GMT+11:00) Australia/Hobart' },
    { value: 'Australia/Sydney', text: '(GMT+11:00) Australia/Sydney' },
    { value: 'Pacific/Guadalcanal', text: '(GMT+11:00) Pacific/Guadalcanal' },
    { value: 'Pacific/Noumea', text: '(GMT+11:00) Pacific/Noumea' },
    { value: 'Pacific/Majuro', text: '(GMT+12:00) Pacific/Majuro' },
    { value: 'Asia/Kamchatka', text: '(GMT+12:00) Asia/Kamchatka' },
    { value: 'Pacific/Auckland', text: '(GMT+13:00) Pacific/Auckland' },
    { value: 'Pacific/Fakaofo', text: '(GMT+13:00) Pacific/Fakaofo' },
    { value: 'Pacific/Fiji', text: '(GMT+13:00) Pacific/Fiji' },
    { value: 'Pacific/Tongatapu', text: '(GMT+13:00) Pacific/Tongatapu' },
    { value: 'Pacific/Apia', text: '(GMT+14:00) Pacific/Apia' },
];

export const genderString = (gender) => {
    return gender ? 'female' : 'male'
}

export const searchResultsOrderBy = [
    {
        value: 'last_activity',
        code: 'search.order_by_recently_active',
        genders: [TYPE_GENDER_MALE, TYPE_GENDER_FEMALE]
    },
    {
        value: 'id',
        code: 'search.order_by_newest',
        genders: [TYPE_GENDER_MALE, TYPE_GENDER_FEMALE]
    },
    {
        value: 'premium',
        code: `search.premium.${process.env.VUE_APP_THEME}`,
        genders: [TYPE_GENDER_FEMALE]
    }
]

export const defaultHeight = [
    130,
    230
]

export const defaultAge = [
    18,
    100
]

export const defaultDistance = 200

