import store from "@/store";
import {checkIsInstalled, isMobile} from "@/service";
import { Loader } from "@googlemaps/js-api-loader";
import platform from "@/service/platform"

export default () => {

    return new Promise(resolve => {

        if(localStorage.getItem('auth_token')) {
            store.commit('application/setBootstrapping', true)
        }

        if(isMobile()) {
            store.commit('application/setMobile', true)
            console.log('Mobile device detected')
        }

        const loader = new Loader({
            apiKey: process.env.VUE_APP_GOOGLE_MAPS_KEY,
            version: "weekly",
            libraries: ["places"],
            language: "en"
        });

        store.commit('application/setBrowser', {
            name: platform.name ?? '',
            version: platform.version ?? '',
        })

        store.commit('application/setOS', {
            name: platform.os?.family ?? '',
            version: platform.os?.version ?? ''
        })

        store.commit('application/setAgent', platform.description ?? '')

        store.commit('application/setInstalled', checkIsInstalled())

        loader.load().then(() => resolve())

    })

}