import { createRouter, createWebHistory } from 'vue-router'
import RegisterPage from "@/views/auth/RegisterPage";
import LoginPage from "@/views/auth/LoginPage";
import SearchPage from "@/views/member/SearchPage";
import ProfilePage from "@/views/member/ProfilePage";
import Profile from "@/components/auth/profile/Profile";
import { bootUser } from "@/service";
import Gallery from "@/components/photos/Gallery";
import VideoGallery from "@/components/videos/VideoGallery";
import VerificationPage from "@/views/member/VerificationPage";
import MatchesPage from "@/views/member/MatchesPage";
import Admirers from "@/components/matches/Admirers";
import Favorites from "@/components/matches/Favorites";
import Visitors from "@/components/matches/Visitors";
import Viewed from "@/components/matches/Viewed";
import AccessPage from "@/views/member/AccessPage";
import MyAccess from "@/components/access/MyAccess";
import Pending from "@/components/access/Pending";
import Approved from "@/components/access/Approved";
import Declined from "@/components/access/Declined";
import UserPage from "@/views/UserPage";
import { clientLogout } from "@/service/auth";
import VerifyEmailPage from "@/views/auth/VerifyEmailPage";
import { autoLoginRouteHandler } from "@/router/helpers";
import SettingsPage from "@/views/member/SettingsPage";
import Email from "@/components/settings/Email";
import Password from "@/components/settings/Password";
import SecretBrowsing from "@/components/settings/SecretBrowsing";
import EmailSubscription from "@/components/settings/EmailSubscription";
import Credits from "@/views/Credits";
import Help from "@/views/Help";
import Safety from "@/views/Safety";
import Contact from "@/views/Contact";
import Privacy from "@/views/Privacy";
import Terms from "@/views/Terms";
import Imprint from "@/views/Imprint";
import Messages from "@/views/member/MessagesPage";
import ForgotPasswordPage from "@/views/auth/ForgotPasswordPage";
import ResetPasswordPage from "@/views/auth/ResetPasswordPage";
import PaymentStatusPage from "@/views/PaymentStatusPage";
import BlockedUsersPage from "@/views/BlockedUsersPage";
import PageNotFound from "@/views/PageNotFound";
import CreditsHistory from "@/views/CreditsHistory"
import { TYPE_GENDER_MALE } from "@/types/user";
import { collectMarketingParams, storeMarketingParams } from "@/service/marketing";
import DeleteProfile from "@/components/settings/DeleteProfile.vue";
import DeletedProfilePage from "@/views/DeletedProfilePage.vue";
import Debug from "@/views/Debug.vue";
import {SONI_FLOW_1} from "@/types/flows";
import store from "@/store";

const ALLOWED_ROUTES = [
    'verify-email', 'credits', 'status', 'terms', 'contact', 'dating-safety', 'privacy', 'imprint',
    'settings-email', 'settings-password', 'settings-secret-browsing', 'settings-email-notifications',
    'settings', 'settings-delete-profile'
]

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: {
            name: 'search'
        }
    },
    {
        path: '/goodbye',
        name: 'goodbye',
        component: DeletedProfilePage,
        meta: {
            headerRemoveSearchbar: false,
            guest: true
        }

    },
    {
        path: '/register/:gender?',
        name: 'register',
        component: RegisterPage,
        meta: {
            guest: true,
            // footerHidden: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        meta: {
            guest: true
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPasswordPage,
        meta: {
            guest: true
        }
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: ResetPasswordPage,
        meta: {
            guest: true
        }
    },
    {
        path: '/search',
        name: 'search',
        component: SearchPage,
        meta: {
            auth: true,
            footerHidden: true,
            scrollDisabled: true,
        }

    },
    {
        path: '/profile',
        name: 'profile',
        component: ProfilePage,
        children: [
            {
                path: '',
                component: Profile,
                name: 'general',
                meta: {
                    auth: true,
                    navbarHidden: true,
                    footerHidden: true
                }
            },
            {
                path: 'photos',
                component: Gallery,
                name: 'photos',
                meta: {
                    auth: true,
                    navbarHidden: true,
                    footerHidden: true
                }
            },
            {
                path: 'videos',
                component: VideoGallery,
                name: 'videos',
                meta: {
                    auth: true,
                    navbarHidden: true,
                    footerHidden: true
                }
            },
            {
                path: 'verification',
                component: VerificationPage,
                name: 'verification',
                meta: {
                    auth: true
                }
            },
        ],
    },
    {
        path: "/matches",
        name: "matches",
        component: MatchesPage,
        redirect: '/matches/admirers',
        meta: {
            headerRemoveSearchbar: true,
        },
        children: [
            {
                path: "admirers",
                component: Admirers,
                name: "admirers",
                meta: {
                    auth: true,
                    footerHidden: true
                }
            },
            {
                path: "favorites",
                component: Favorites,
                name: "favorites",
                meta: {
                    auth: true,
                    footerHidden: true
                }
            },
            {
                path: "visitors",
                component: Visitors,
                name: "visitors",
                meta: {
                    auth: true,
                    footerHidden: true
                }
            },
            {
                path: "viewed",
                component: Viewed,
                name: "viewed",
                meta: {
                    auth: true,
                    footerHidden: true
                }
            }
        ]
    },
    {
        path: '/access',
        component: AccessPage,
        name: 'access',
        redirect: "/access/my-access",
        meta: {
            headerRemoveSearchbar: true,
        },
        children: [
            {
                path: "my-access",
                name: "my-access",
                component: MyAccess,
                meta: {
                    auth: true,
                    footerHidden: true
                }
            },
            {
                path: "pending",
                name: "pending",
                component: Pending,
                meta: {
                    auth: true,
                    footerHidden: true
                }
            },
            {
                path: "approved",
                name: "approved",
                component: Approved,
                meta: {
                    auth: true,
                    footerHidden: true
                }
            },
            {
                path: "declined",
                name: "declined",
                component: Declined,
                meta: {
                    auth: true,
                    footerHidden: true
                }
            }
        ]
    },
    {
        path: "/users/:id",
        name: "user",
        component: UserPage,
        meta: {
            auth: true,
            navbarHidden: true,
            footerHidden: true
        }

    },
    {
        path: "/settings",
        name: "settings",
        component: SettingsPage,
        children: [
            {
                path: "email",
                name: "settings-email",
                component: Email,
                meta: {
                    auth: true,
                    navbarHidden: true,
                    footerHidden: true,
                    restrictFlowRedirect: true
                }

            },
            {
                path: "password",
                name: "settings-password",
                component: Password,
                meta: {
                    auth: true,
                    navbarHidden: true,
                    footerHidden: true
                }

            },
            {
                path: "secret-browsing",
                name: "settings-secret-browsing",
                component: SecretBrowsing,
                meta: {
                    auth: true,
                    navbarHidden: true,
                    footerHidden: true,
                    gender: TYPE_GENDER_MALE
                }

            },
            {
                path: "email-notifications",
                name: "settings-email-notifications",
                component: EmailSubscription,
                meta: {
                    auth: true,
                    navbarHidden: true,
                    footerHidden: true
                }

            },
            {
                path: "blocked-users",
                name: "settings-blocked-users",
                component: BlockedUsersPage,
                meta: {
                    auth: true,
                    navbarHidden: true,
                    footerHidden: true
                }

            },
            {
                path: "delete-profile",
                name: "settings-delete-profile",
                component: DeleteProfile,
                meta: {
                    auth: true,
                    navbarHidden: true,
                    footerHidden: true
                }

            },
        ],
        meta: {
            auth: true,
            navbarHidden: true,
            footerHidden: true
        }
    },
    {
        path: '/credits/history',
        name: 'credits_log',
        component: CreditsHistory,
        meta: {
            auth: true,
            footerHidden: true,
            gender: TYPE_GENDER_MALE,
            navbarHidden: true
        }
    },
    {
        path: '/credits',
        name: 'credits',
        component: Credits,
        meta: {
            auth: true,
            gender: TYPE_GENDER_MALE,
            navbarHidden: true
        }
    },
    {
        path: '/credits/mmg_status',
        name: 'status',
        component: PaymentStatusPage,
        meta: {
            auth: true,
            gender: TYPE_GENDER_MALE,
            footerHidden: true,
            restrictFlowRedirect: true
        }
    },
    {
        path: '/help',
        name: 'help',
        component: Help,
        meta: {
            auth: true,
            navbarHidden: true,
            footerHidden: true,
            restrictFlowRedirect: true
        }
    },
    {
        path: '/dating-safety',
        name: 'safety',
        component: Safety,
        meta: {
            auth: true,
            navbarHidden: true,
            footerHidden: true
        }

    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: {
            auth: true,
            navbarHidden: true,
            footerHidden: true
        }
    },
    {
        path: '/messages/:conversation?',
        name: 'messages',
        component: Messages,
        meta: {
            auth: true,
            navbarHidden: true,
            footerHidden: true
        }
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: Privacy,
        meta: {
            footerHidden: true,
            restrictFlowRedirect: true
        }
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms,
        meta: {
            footerHidden: true,
            restrictFlowRedirect: true
        }
    },
    {
        path: '/imprint',
        name: 'imprint',
        component: Imprint,
        meta: {
            footerHidden: true,
            restrictFlowRedirect: true
        }
    },
    {
        path: "/verify",
        name: "verify-email",
        component: VerifyEmailPage
    },
    {
        path: "/debug",
        name: "debug",
        component: Debug,
        meta: {
            guest: true
        }
    },
    {
        path: '/:catchAll(.*)',
        name: 'not-found',
        component: PageNotFound
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to) {

        if(to.meta?.scrollDisabled) {
            return
        }

        let scroll = {
            top: 0,
        }


        if(to.meta?.behavior) {
            scroll.behavior = to.meta.behavior
        }

        return scroll
    }
})


router.beforeEach(async(to, from, next) => {

    const payload = await bootUser()

    if( ! payload) {
        await clientLogout()
    }

    if(to.query && ! payload) {
        storeMarketingParams(collectMarketingParams(to.query))
    }

    if(to.query.auto && ! payload) {

        return await autoLoginRouteHandler(to, from, next)

    }

    if(to.meta?.auth && ! payload) {

        return next({name: "login"})

    }

    if(to.meta?.guest && payload) {

        return next({name: "search"})

    }

    if(payload && to.meta && to.meta.gender !== undefined) {

        if(payload.user.gender !== to.meta.gender) {
            return next({name: "search"})
        }

    }

    if(payload && payload.user?.gender === TYPE_GENDER_MALE
        && payload.user?.flow?.includes(SONI_FLOW_1)
        && ! store.state.credits.payment_history
        && ! ALLOWED_ROUTES.includes(to.name)
    )
    {
        return next({name: "credits"})
    }

    to.previousRouteName = from.name

    next()


})

export default router
