<template>
    <Modal class="modal modal--mob modal-radio-buttons">
        <template #header>
            <h2>{{$t('form_fields.education_label')}}</h2>
        </template>
        <template #body>
            <div v-for="e in educationOptions" :key="e.code" class="radiobox">
                <input class="form-check-input" type="radio" :id="e.code" :checked="value === e.value">
                <label @click="update(e.value)" class="radiobox__label" :for="e.code">
                    {{$t(`form_fields.education_options_${e.value}`)}}
                </label>
            </div>
        </template>
        <template #footer>
            <a @click="$emit('success')" class="skip">{{$t('credits.skip_for_now')}}</a>
        </template>
    </Modal>
</template>

<script>

import Modal from "@/components/generic/Modal";
import { education } from "@/service/forms"
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";

export default {
    name: "OnDemandEducation",
    mixins: [AuthenticatedMixin],
    components: { Modal },
    props: {
        value: {
            type: [Number, null],
            required: true
        }
    },

    methods: {

        update(value) {

            this.$emit('set', {
                education: value
            })

        }

    },

    created() {
        this.educationOptions = education
    },
    mounted() {
        this.education = this.auth.user.education
    },
    beforeUnmount() {
        clearTimeout(this.timeout)
    }
}
</script>