<template>
    <main>
        <section class="safety">
            <div class="o-container">
                <article>
                    <div class="header-mob">
                        <router-link :to="{name: 'search'}" class="back-mob"><svg xmlns="http://www.w3.org/2000/svg" width="14.004" height="14.001"><path data-name="Union 6" d="M6.289 13.708.262 7.669a1 1 0 0 1 .03-1.379l6-6a1 1 0 0 1 1.417 0 1 1 0 0 1 0 1.413l-4.3 4.295H13a1 1 0 0 1 1 1 1 1 0 0 1-1 1H3.41l4.292 4.3a1 1 0 0 1 0 1.412A1 1 0 0 1 7 14a1 1 0 0 1-.711-.292Z"/></svg></router-link>
                        <h1>Keep it Safe!</h1>
                    </div>
                    <h1 class="heading-mob"><strong>Keep it Safe!</strong></h1>
                    <p>We highly value your safety and privacy, that’s why we encourage you to stay safe and take some precautions when interacting with other members.</p>
                    <p>You get to decide what your own level of comfort is, when it comes to disclosing personal details. That means that you have the keys to your own protection. Check out our safety tips when getting in touch with another member, in order to avoid an awkward situation.</p>
                    <h2><strong>{{VUE_APP_NAME}} Dating Tips</strong></h2>
                    <ul>
                        <li>Keep your finances safe. Do not send any money to someone that you haven't met in person.</li>
                        <li>Your bank account information is private so make sure to keep it that way. DO not give those details to anyone.</li>
                        <li>This website is not designed for product sale, so do not buy videos or photos from anyone offering to give them to you.</li>
                        <li>Try to keep all conversation on this website, so that you can have minimum control over things. If something inappropriate happens during a conversation with a member, you can always report it to the support team, but only if the conversation takes place on our platform.</li>
                        <li>If at any moment you don't feel safe or you spot a suspicious behavior, please report it immediately to our support team so we can take action.</li>
                        <li>Your log in information to this website is private so make sure no one can access it. This way you make sure you protect your intimacy.</li>
                        <li>When you disclose personal information, like the place you live or other contact details, do it only when you feel you can trust that person, and always after you've met that person in real life. The same advice applies to documents like passport, ID card and so on.</li>
                        <li>If anyone attempts to sell you escort services or massage services, please report it to us as soon as possible so we can ban that person. Escort and massage services are not allowed on our website.</li>
                        <li>Your first dates should always be in public places. It is safer that way. Also, we feel the need to repeat ourselves, do not share private information with people you've just met!</li>
                    </ul>

                    <p>You are now officially ready to enter the {{VUE_APP_NAME}} and become the next top {{VUE_APP_NAME}} member! We hope you've found the above info useful and that you'll take all precautions to be safe and enjoy this new amazing journey. Please do not hesitate to ask for our help if you encounter any difficulties or you have any concerns regarding the Sugar lifestyle.</p>
                    <p>Also, for more info check out our <router-link :to="{name: 'help'}">FAQs</router-link>.</p>
                </article>
            </div>
        </section>
    </main>
</template>

<script>
export default {
    name: 'Safety',
    created() {
        this.VUE_APP_NAME = process.env.VUE_APP_NAME
    }
}
</script>

<style scoped lang="scss">

    main {
        @media (max-width: 991px) {
            background-color: #fff;
        }
    }

    .header-mob {
        display: none;

        @media(max-width: 500px) {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            h1 {
                margin-bottom: 0!important;
                font-size: 22px!important;
                font-weight: 700!important;
            }

            .back-mob {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                margin-right: 10px;
            }
        }
    }

    .safety {
        padding: 70px 0;

        article {
            width: 100%;
            margin: 0 auto;
            max-width:680px;
            padding: 0 15px;

            @media(max-width: 500px) {
                padding: 0;
            }
        }

        .heading-mob {
            @media(max-width: 500px) {
                display: none;
            }
        }

        h1 {
            font-size: 26px;
            font-weight: 800;
            margin-bottom: 47px;
        }

        h2 {
            font-size: 17px;
            margin-top: 62px;
            margin-bottom: 15px;
        }

        p,
        li {
            line-height: 1.5;
        }

        ul {
            padding-left: 30px;
            list-style-type: disc;
            margin-bottom: 0;
        }

        li {
            padding: 10px 10px;
        }

        p {
            a {
                color: #bfa46a;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        @media(max-width: 991px) {
            padding: 20px 0 60px;

            h1 {
                font-size: 22px;
                margin-bottom: 20px;
            }

            h2 {
                margin-top: 20px;
            }

            p,li {
                font-size: 14px;
            }

            ul {
                padding-left: 15px;
            }

            li {
                padding: 5px 10px;
            }
        }
    }
</style>
