<template>
    <Modal class="modal modal--mob modal--pwa" :closable="true" @close="$emit('success')">
        <template #body>
            <div class="icon-wrapper-circle">
                <svg v-if="isOS(iOS)" width="54px" viewBox="0 0 45.594 56">
                    <path d="M44.138 19.092a12.684 12.684 0 0 0-6.06 10.669 12.347 12.347 0 0 0 7.516 11.324 29.37 29.37 0 0 1-3.848 7.947c-2.4 3.45-4.9 6.894-8.709 6.894s-4.788-2.212-9.185-2.212c-4.284 0-5.808 2.285-9.291 2.285s-5.914-3.192-8.709-7.113A34.3 34.3 0 0 1 0 30.344c0-10.888 7.079-16.662 14.046-16.662 3.7 0 6.788 2.431 9.112 2.431 2.212 0 5.662-2.576 9.874-2.576a13.2 13.2 0 0 1 11.106 5.556ZM31.033 8.927a12.5 12.5 0 0 0 2.974-7.8A5.376 5.376 0 0 0 33.9 0a12.526 12.526 0 0 0-8.238 4.245 12.175 12.175 0 0 0-3.086 7.589 5.142 5.142 0 0 0 .106 1.014 4.383 4.383 0 0 0 .762.073 10.854 10.854 0 0 0 7.589-3.993Z"/>
                </svg>
                <svg v-if="isOS(Android)" width="54px" viewBox="0 0 50.751 56.002">
                    <g data-name="Group 1242">
                        <path fill="#ea4335" d="M23.701 26.748.21 51.359a6.372 6.372 0 0 0 9.33 3.781l26.431-15.054Z" data-name="Path 11688"/>
                        <path fill="#fbbc04" d="m47.454 22.566-11.426-6.55-12.87 11.29 12.918 12.743 11.343-6.477a6.214 6.214 0 0 0 0-11.01Z" data-name="Path 11689"/>
                        <path fill="#4285f4" d="M.21 4.655A6.057 6.057 0 0 0 0 6.265v43.481a6.407 6.407 0 0 0 .21 1.61l24.3-23.981Z" data-name="Path 11690"/>
                        <path fill="#34a853" d="m23.876 28.007 12.148-11.99L9.627.893A6.512 6.512 0 0 0 6.354 0 6.372 6.372 0 0 0 .21 4.639Z" data-name="Path 11691"/>
                    </g>
                </svg>
            </div>
            <h1>{{$t('pwa.install.banner.title')}}</h1>
            <p>{{$t('pwa.install.banner.description')}}</p>

            <ul class="list-action">
                <template v-if="isBrowser(Safari)">
                    <li>
                        <a href="javascript:;">
                            <div class="o-flex o-flex--center">
                                <div class="numb-wrap">1</div>
                                <p>{{$t('pwa.install.modal.ios.safari.step_1')}}</p>
                            </div>
                            <div class="icon-wrap">
                                <svg width="38px" viewBox="0 0 38 38">
                                    <g fill="none" data-name="Group 1233">
                                        <path d="M0 0h38v38H0z" data-name="Rectangle 625"/>
                                        <g stroke="#B89450" stroke-linecap="round" stroke-width="3" data-name="Group 1232">
                                            <path stroke-linejoin="round" d="M19 21.35V2.5" data-name="Path 11676"/>
                                            <path stroke-linejoin="round" d="M12.46 9.038 18.998 2.5l6.538 6.538" data-name="Path 11677"/>
                                            <path d="M24.199 14.328h2.49a3.831 3.831 0 0 1 3.831 3.831v13.51a3.831 3.831 0 0 1-3.831 3.831H11.311a3.831 3.831 0 0 1-3.831-3.831v-13.51a3.831 3.831 0 0 1 3.831-3.831h2.595" data-name="Path 11679"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <div class="o-flex o-flex--center">
                                <div class="numb-wrap">2</div>
                                <p>{{$t('pwa.install.modal.ios.safari.step_2')}}</p>
                            </div>
                            <div class="icon-wrap">
                                <svg width="38px" viewBox="0 0 38 38">
                                    <g fill="none" data-name="Group 1235">
                                        <path d="M0 0h38v38H0z" data-name="Rectangle 626"/>
                                        <g stroke="#B89450" stroke-width="3" data-name="Group 1234" transform="translate(-282 -636.5)">
                                            <rect width="28" height="28" data-name="Rectangle 624" rx="6" transform="translate(287 641.5)"/>
                                            <path stroke-linecap="round" d="M301 649.5v12" data-name="Path 11680"/>
                                            <path stroke-linecap="round" d="M295 655.5h12" data-name="Path 11681"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <div class="o-flex o-flex--center">
                                <div class="numb-wrap">3</div>
                                <p>{{$t('pwa.install.modal.ios.safari.step_3')}}</p>
                            </div>
                            <div class="icon-wrap">

                            </div>
                        </a>
                    </li>
                </template>
                <template v-if="isBrowser(Chrome)">
                    <li>
                        <a href="javascript:;">
                            <div class="o-flex o-flex--center">
                                <div class="numb-wrap">1</div>
                                <p>
                                    {{$t(`pwa.install.modal.${isOS(iOS) ? 'ios' : 'android'}.chrome.step_1`)}}
                                </p>
                            </div>
                            <div class="icon-wrap">
                                <svg v-if="isOS(Android)" width="38px" viewBox="0 0 38 38">
                                    <g data-name="Group 1233" transform="translate(-282 -521)">
                                        <path fill="none" d="M282 521h38v38h-38z" data-name="Rectangle 625"/>
                                        <circle cx="3" cy="3" r="3" fill="#B89450" data-name="Ellipse 131" transform="translate(298 526)"/>
                                        <circle cx="3" cy="3" r="3" fill="#B89450" data-name="Ellipse 132" transform="translate(298 536)"/>
                                        <circle cx="3" cy="3" r="3" fill="#B89450" data-name="Ellipse 133" transform="translate(298 546)"/>
                                    </g>
                                </svg>
                                <svg v-if="isOS(iOS)" width="38px" viewBox="0 0 38 38">
                                    <g fill="none" data-name="Group 1233">
                                        <path d="M0 0h38v38H0z" data-name="Rectangle 625"/>
                                        <g stroke="#B89450" stroke-linecap="round" stroke-width="3" data-name="Group 1232">
                                            <path stroke-linejoin="round" d="M19 21.35V2.5" data-name="Path 11676"/>
                                            <path stroke-linejoin="round" d="M12.46 9.038 18.998 2.5l6.538 6.538" data-name="Path 11677"/>
                                            <path d="M24.199 14.328h2.49a3.831 3.831 0 0 1 3.831 3.831v13.51a3.831 3.831 0 0 1-3.831 3.831H11.311a3.831 3.831 0 0 1-3.831-3.831v-13.51a3.831 3.831 0 0 1 3.831-3.831h2.595" data-name="Path 11679"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </a>
                    </li>

                    <li>
                        <a href="javascript:;">
                            <div class="o-flex o-flex--center">
                                <div class="numb-wrap">2</div>
                                <p>{{$t(`pwa.install.modal.${isOS(iOS) ? 'ios' : 'android'}.chrome.step_2`)}}</p>
                            </div>
                            <div class="icon-wrap">
                                <svg v-if="isOS(Android)" width="38px" viewBox="0 0 38.854 38">
                                    <g data-name="Group 1235">
                                        <path fill="none" d="M.854 0h38v38h-38z" data-name="Rectangle 626"/>
                                        <g fill="#B89450" data-name="Group 1236">
                                            <path d="M29.241 35.25h-14.7a2.928 2.928 0 0 1-2.937-2.91V28.5h2.574v1.031h15.428V8.961H14.184v1.537H11.61v-4.34a2.928 2.928 0 0 1 2.938-2.91h14.7a2.928 2.928 0 0 1 2.938 2.91V32.34a2.928 2.928 0 0 1-2.945 2.91Z" data-name="Subtraction 19"/>
                                            <path stroke="#B89450" d="M8.834 12.27v2.46l7.745.01L5.962 25.357 7.703 27.1 18.32 16.482l.01 7.745h2.46V12.268Z" data-name="Icon material-arrow-forward"/>
                                        </g>
                                    </g>
                                </svg>
                                <svg v-if="isOS(iOS)" width="38px" viewBox="0 0 38 38">
                                    <g fill="none" data-name="Group 1235">
                                        <path d="M0 0h38v38H0z" data-name="Rectangle 626"/>
                                        <g stroke="#B89450" stroke-width="3" data-name="Group 1234" transform="translate(-282 -636.5)">
                                            <rect width="28" height="28" data-name="Rectangle 624" rx="6" transform="translate(287 641.5)"/>
                                            <path stroke-linecap="round" d="M301 649.5v12" data-name="Path 11680"/>
                                            <path stroke-linecap="round" d="M295 655.5h12" data-name="Path 11681"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </a>
                    </li>

                    <li>
                        <a href="javascript:;">
                            <div class="o-flex o-flex--center">
                                <div class="numb-wrap">3</div>
                                <p>{{$t(`pwa.install.modal.${isOS(iOS) ? 'ios' : 'android'}.chrome.step_3`)}}</p>
                            </div>
                            <div class="icon-wrap">
                                <svg v-if="isOS(Android)" width="38px" viewBox="0 0 38 38">
                                    <g data-name="Group 1237" transform="translate(-282 -683)">
                                        <rect width="38" height="38" data-name="Rectangle 629" rx="6" transform="translate(282 683)"/>
                                        <path fill="#b98a56" d="m301 714-3.82-3.109a.172.172 0 0 1 .014-.273l.658-.406a4.08 4.08 0 0 1 2.06-.619l1.905-.049a4.07 4.07 0 0 1 2.263.621l.731.453a.17.17 0 0 1 .015.273L301 714Zm4.773-4.25-3.57-1.719a1.093 1.093 0 0 1-.643-1v-.361l1.625-1.422a1.91 1.91 0 0 0 .613-.937l.8-2.836a1.772 1.772 0 0 1 1.562-1.187l2.831-.182v7.385l-2.68 2.18a.53.53 0 0 1-.336.121.511.511 0 0 1-.202-.044Zm-10.09-.078-2.684-2.18v-7.385l2.832.176a1.789 1.789 0 0 1 1.564 1.193l.8 2.836a1.854 1.854 0 0 0 .613.934l1.646 1.426v.361a1.076 1.076 0 0 1-.643.992l-3.592 1.721a.506.506 0 0 1-.211.045.521.521 0 0 1-.324-.121Zm7.34-6.852-.594-.066a13.23 13.23 0 0 0-2.862 0l-.594.066a.194.194 0 0 1-.2-.143l-.562-1.98a3.943 3.943 0 0 0-2.584-2.76l-2.447-.559a.23.23 0 0 1-.178-.225v-1.758h16v1.758a.229.229 0 0 1-.176.225l-2.445.559a3.948 3.948 0 0 0-2.588 2.76l-.561 1.98a.192.192 0 0 1-.187.143ZM293 693.963V690h2.24a.386.386 0 0 1 .366.256l.417 1.145a1.42 1.42 0 0 0 1.329.938h1a.253.253 0 0 0 .25-.285l-.221-2.053h5.234l-.221 2.053a.25.25 0 0 0 .246.285h1.006a1.414 1.414 0 0 0 1.324-.937l.422-1.145a.38.38 0 0 1 .361-.256H309v3.961Z" data-name="Union 29"/>
                                    </g>
                                </svg>
                            </div>
                        </a>
                    </li>
                </template>
            </ul>
        </template>
    </Modal>
</template>
<script>
import Modal from "@/components/generic/Modal.vue";
import { isBrowser, isOS } from "@/service/general";
import { Safari, Chrome, iOS, Android } from "@/types/device";

export default {
    name: 'OnDemandInstallPWA',
    components: {Modal},
    methods: {
        isBrowser,
        isOS
    },
    created() {
        this.Safari = Safari
        this.Chrome = Chrome
        this.iOS = iOS
        this.Android = Android
    }
}
</script>

<style lang="scss" scoped>
    .icon-wrapper-circle {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: $color-type3;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px auto 20px;
    }

    h1 {
        font-weight: 700;
        margin-bottom: 10px;
        text-align: center;
        letter-spacing: -0.9px;
    }

    p {
        line-height: 1.3;
        margin-bottom: 30px;
        text-align: center;
        color: #000;
    }

    .list-action {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;

        li {

            a {
                background-color: $color-type3;
                padding: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 10px;
                gap: 16px;

                >.o-flex {
                    gap: 10px;
                }
            }

            p {
                margin-bottom: 0;
                text-align: left;
                font-size: 18px;
            }
        }

        .numb-wrap {
            border-radius: 50%;
            width: 32px;
            min-width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-type3;
            background-color: $color-type4;
            font-size: 18px;
            font-weight: 700;
        }
    }
</style>

<style lang="scss">
    .modal--pwa {
        .modal-footer {
            padding: 0;
        }
    }
</style>