export default {

    namespaced: true,

    state: {
        amount: 0,
        card: null,
        payment_history: false
    },

    mutations: {
        setAmount(state, value) {
            state.amount = value
        },
        setCard(state, value) {
            state.card = value
        },
        setPaymentHistory(state, value) {
            state.payment_history = value
        }
    },

    actions: {
        setAmount({commit}, value) {
            commit('setAmount', value)
        },
        setCard({commit}, value) {
            commit('setCard', value)
        },
        setPaymentHistory({commit}, value) {
            commit('setPaymentHistory', value)
        }
    },

    getters: {}

}