<template>
    <Modal class="modal modal--mob" :closable="false">
        <template #header>
            <div class="u-text-center" v-if="!showUploadPhoto">
                <h1>{{$i18n.t(`signup.select_required_photos.title`)}}</h1>
                <p>{{$t('profile.working_hard')}}</p>
            </div>
        </template>
        <template #body>
            <div v-show="showUploadPhoto">
                <UploadItem v-if="image" @progress="working = true; showUploadPhoto = false" @close="reset" @error="uploadError" @finished="uploadFinishHandler" :file="image">
                    <template #switch>
                        {{ucFirst($t(`profile.secret_photo`))}}
                    </template>
                    <template #description>
                        {{$t('profile.secret_photo_visible')}}
                    </template>
                </UploadItem>
            </div>
            <div v-if="!showUploadPhoto" class="public_photos">
                <div class="image-wrapper">
                    <transition-group name="flip-list">
                        <div v-for="(i, k) in GIRL_MIN_PHOTOS" :key="i">
                            <div v-if="photos.length >= i" class="img-upload" style="cursor: pointer;">
                                <Image class="img-main" :src="photos[k].source.medium" />
                                <a v-if="photos[k].id !== auth.user.photo_id && deleting === null" href="javascript:;" @click="deletePhoto(photos[k].id)" class="remove-img"><svg xmlns="http://www.w3.org/2000/svg" width="10.003" height="10"><path fill="#fff" d="M6.187 5 9.76 1.427A.837.837 0 0 0 8.576.243L5.003 3.811 1.43.238A.837.837 0 1 0 .246 1.422L3.815 5 .242 8.573a.837.837 0 1 0 1.184 1.184l3.573-3.573 3.573 3.573a.837.837 0 1 0 1.184-1.184Z" data-name="Icon ionic-ios-close"/></svg></a>
                                <div class="pending" v-if="working && deleting === k">
                                    <svg class="spinner" viewBox="0 0 50 50">
                                        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                                    </svg>
                                </div>
                                <div class="status-info" v-else>
                                    <span v-if="photos[k].private"><strong>{{$t('file_control.secret')}}</strong></span>
                                    <span v-if="!photos[k].private"><strong>{{$t('file_control.public')}}</strong></span>
                                    <span v-if="photos[k].id === auth.user.photo_id"><strong>{{$t('quick_start.profile_photo')}}</strong></span>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="image && working && photos.length + 1 === i && deleting === null" class="img-upload">
                                    <div class="pending">
                                        <svg class="spinner" viewBox="0 0 50 50">
                                            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                                        </svg>
                                    </div>
                                    <Image class="img-main" :src="image.path" />
                                </div>
                                <div v-else class="img-upload img-upload--icon" style="cursor: pointer;" @click="!working ? $refs.photo.click() : null">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="49.314" height="49.314"><g fill="none" stroke="#4b4b4b" stroke-width="2" data-name="Group 237" transform="translate(1 1)"><circle cx="23.657" cy="23.657" r="23.657" data-name="Ellipse 56"/><path stroke-linecap="round" d="M23.658 13.041v21.232" data-name="Path 81"/><path stroke-linecap="round" d="M13.042 23.657h21.232" data-name="Path 82"/></g></svg>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                </div>
            </div>
            <input @change="set" ref="photo" type="file" style="display: none" :accept="ALLOWED_IMAGE_FORMATS.map(e => `image/${e}`).join(', ')" />
        </template>
        <template #footer>

            <div v-if="!showUploadPhoto" class="u-text-center mt">
                <button :disabled="photos.length < GIRL_MIN_PHOTOS" @click="proceed($event)" class="button button--primary">{{$i18n.t('signup.select_required_photos.continue')}}</button>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal";
import ThemeMixin from "@/mixins/ThemeMixin";
import { GIRL_MIN_PHOTOS  } from "@/utilities/user";
import { mapState, mapActions } from "vuex";
import toastr from "toastr";
import {gallery} from "@/api/profile";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import {setAuthenticatedUser, setAuthenticatedUserItemCount} from "@/service/auth";
import { user } from "@/api/auth";
import { pushDataLayer } from "@/service/gtm";
import { GTM_EVENT_ADD_PHOTOS_MODAL, GTM_EVENT_ADD_PHOTOS_MODAL_CONTINUE } from "@/service/gtm/types";
import {
    getExtensionFromFilename,
    hashFile, createSelectedFile,
} from "@/service/files";
import { ALLOWED_IMAGE_FORMATS } from "@/utilities/files";
import { ucFirst } from "@/utilities/general";
import { ITEM_TYPE_PHOTO } from "@/types/item";
import { deletePhoto } from "@/service/photo";
import Image from "@/components/generic/Image.vue";
import UploadItem from "@/components/photos/UploadItem.vue";

export default {
    name: "PreActionPhotos",
    mixins: [ThemeMixin, AuthenticatedMixin],
    components: {UploadItem, Modal, Image },
    data() {
        return {
            image: null,
            showUploadPhoto: false,
            working: false,
            photos: [],
            deleting: null
        }
    },
    computed: {
        ...mapState({
            deleted: state => state.items.deleted,
            uploaded: state => state.items.uploaded,
            ready: state => state.items.ready
        })
    },
    watch: {
        async deleted(value) {

            const [id] = value.slice(-1)

            if(this.auth.user.photo_id === id) {
                const { data } = await user()
                await setAuthenticatedUser(data)
                if( ! this.auth.user.photo) {
                    await this.queuePrepend('profile_photo')
                }
            }

            const hash = this.uploaded.find(e => e.id === id)

            if(hash) {
                await this.unTrackDeletedPhoto(hash)
            }

            gallery({perPage: 50, orderDirection: 'asc'})
                .then(({data}) => this.photos = data.data)

            await setAuthenticatedUserItemCount()

        },
        async ready(value) {

            const item = {
                ...value[value.length - 1]
            }

            if( ! item.ready || item.type !== ITEM_TYPE_PHOTO)
                return

            this.photos = [...this.photos, item]

            this.reset()

            this.working = false
            this.showUploadPhoto = false

            await setAuthenticatedUserItemCount()

        }
    },
    methods: {
        ucFirst,
        async deletePhoto(id) {
            const key = this.photos.findIndex(e => e.id === id)
            this.working = true
            this.deleting = key
            await deletePhoto(id)
            this.working = false
            this.deleting = null
        },
        ...mapActions({
            setPublicPhotosTotal: 'items/setPublicPhotosTotal',
            setSecretPhotosTotal: 'items/setSecretPhotosTotal',
            add: 'profile/add',
            remove: 'profile/remove',
            queuePrepend: 'profile/prepend',
            trackUploadedPhoto: 'items/trackUploadedFile',
            unTrackDeletedPhoto: 'items/removeTrackedFile'
        }),
        async proceed($event) {

            $event.target.disabled = true

            if(this.photos.length >= GIRL_MIN_PHOTOS) {

                this.$emit('success')
            }

            pushDataLayer(GTM_EVENT_ADD_PHOTOS_MODAL_CONTINUE, this.auth.user)

        },
        uploadFinishHandler(e) {

            this.trackUploadedPhoto({id: e.id, hash: e.hash})

        },
        reset() {
            this.$refs.photo.value = null
            this.showUploadPhoto = false
            this.image = null
            this.working = false
        },

        uploadError(e) {
            console.log(e)
            toastr.error(this.$i18n.t('credits.error_processing'))
            this.$refs.photo.value = ''
        },

        async set(e) {

            const [file] = e.target.files || e.dataTransfer.files

            if( ! file)
                return

            const extension = getExtensionFromFilename(this.$refs.photo.value).toLowerCase()

            if( ! ALLOWED_IMAGE_FORMATS.includes(extension)) {
                return toastr.warning(this.$i18n.t('form_fields.validation.file_type', [null, extension]))
            }

            const hash = await hashFile(file)

            if(this.uploaded.find(i => i.hash === hash)) {
                this.$refs.photo.value = null
                return toastr.warning(this.$i18n.t('file_control.photo_uploaded_already'))
            }


            this.image = await createSelectedFile(file, hash)
            this.showUploadPhoto = true

        }
    },

    created() {
        this.ALLOWED_IMAGE_FORMATS = ALLOWED_IMAGE_FORMATS
        this.GIRL_MIN_PHOTOS = GIRL_MIN_PHOTOS
    },


    async mounted() {

        pushDataLayer(GTM_EVENT_ADD_PHOTOS_MODAL, this.auth.user)

        gallery({perPage: 50, orderDirection: 'asc'})
            .then(({data}) => this.photos = data.data)

    }
}
</script>

<style scoped lang="scss">

    h1 {
        font-size: 22px;
        margin-bottom: 20px;
        font-weight: 700;
        letter-spacing: -0.9px;
    }

    p {
        color: $color-type18;
        font-size: 16px;
        line-height: 1.5;
        margin: 0 auto 30px;
        max-width: 460px;
    }

    .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 16px;
        background-color: #f1f1f1;
        padding: 10px 0 10px 10px;
        border-radius: 2px;

        strong {
            color: #1DAB5F;
            margin-left: 5px;
            font-family: "Open Sans", sans-serif;
        }
    }

    .pending {
        position: absolute;
        z-index: 3;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($color-type18, .7);

        .spinner {
            animation: rotate 2s linear infinite;
            z-index: 2;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -25px 0 0 -25px;
            width: 50px;
            height: 50px;

            & .path {
                stroke: $color-type1;
                stroke-linecap: round;
                animation: dash 1.5s ease-in-out infinite;
            }

        }

        @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
        }

        @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
        }
    }

    .image-wrapper {
        display: flex;
        margin: 0 -2px;
        flex-wrap: wrap;

        >div {
            margin: 0 2px 4px;
            width: calc(50% - 4px);
            border-radius: 6px;
        }

        .img-wrap {
            // border: 1px solid $color-type6;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 25%;
            position: relative;
            background-color: #e6e6e6;
            cursor: pointer;
            transition: all .3s ease-in-out;

            span {
                position: absolute;
                background-color: $color-type22;
                font-size: 13px;
                padding: 5px 10px;
                border-radius: 10px;
                text-align: center;
                bottom: 0;
                left: 50%;
                transform: translate(-50%,0);
                color: $color-type1;
                font-weight: 600;
                white-space: nowrap;
                visibility: hidden;
                opacity: 0;
                transition: all .3s ease-in-out;
            }

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
            }

            &.is-active,
            &:hover {
                box-shadow: 0 6px 20px $color-type22;

                span {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        .img-upload {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            aspect-ratio: 1/1;
            position: relative;
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            transition: all .3s ease;
            border-radius: 10px;
            padding: 6px;


            &.img-upload--icon {
                border: 2px dashed $color-type8;
                align-items: center;
                justify-content: center;
            }

            .remove-img {
                position: absolute;
                right: 6px;
                top: 6px;
                width: 26px;
                height: 26px;
                background-color: $color-type18;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &.disabled {
                opacity: .5;
                pointer-events: none;
            }

            .status-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;
                position: relative;
                z-index: 2;

                span {
                    height: 23px;
                    display: inline-flex;
                    align-items: center;
                    padding: 0 10px;
                    margin-top: 4px;
                    background-color: rgba($color-type18, .7);
                    border-radius: 12px;
                    color: $color-type1;
                    font-size: 14px;
                }
            }

            .img {
                aspect-ratio: 1 / 1;
                width: 100%;
                background-position: 50% 50%;
                background-size: cover;
                display: flex;
                align-items: flex-end;
            }
        }

        .img-main {
            position: absolute;
            left: 0;
            top: 0;
            min-width: 100%;
            min-height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
</style>