<template>
    <main>
        <section class="no-page">
            <article>
                <svg width="341" height="137" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M187.813 127.101c-25.047 3.441-45.742 3.39-46.223-.114-.481-3.503 19.433-9.133 44.48-12.573 25.048-3.441 45.742-3.388 46.223.115.481 3.503-19.432 9.132-44.48 12.572Z" fill="#E4E4E4"/><path fill-rule="evenodd" clip-rule="evenodd" d="M213.285 5.172c-29.226-5.731-37.59 13.6-39.748 28.955l6.505 9.724-8.239 30.018 16.942 21.12L177.063 120c4.766 1.02 9.004 1.183 12.363.298 28.417-7.492 70.703-105.943 23.859-115.126Z" fill="#B20012"/><path fill-rule="evenodd" clip-rule="evenodd" d="M224.732 36.263a2.309 2.309 0 0 1-.407.02 2.508 2.508 0 0 1-2.445-2.571c.141-5.41-1.281-9.609-4.22-12.478-5.038-4.915-13.057-4.576-13.14-4.572a2.515 2.515 0 0 1-2.633-2.375c-.073-1.382.99-2.571 2.362-2.636.415-.023 10.239-.487 16.878 5.958 4.004 3.887 5.947 9.345 5.773 16.23a2.514 2.514 0 0 1-2.168 2.424ZM226.803 41.545a2.548 2.548 0 0 1-2.179 2.868 2.553 2.553 0 0 1-2.875-2.174 2.55 2.55 0 1 1 5.054-.694Z" fill="#FFFFFE"/><path fill-rule="evenodd" clip-rule="evenodd" d="m163.529 75.577 8.062-29.362a89.932 89.932 0 0 0-1.322-3.439c-2.74-6.726-7.516-15.963-14.659-21.911-7.986-6.645-18.927-9.173-33.352.608-45.47 30.835 10.425 83.443 47.478 96.436l10.213-21.866-16.42-20.466Z" fill="#B20012"/><path d="M87.371 98.514H74.968V120H44.919V98.514H0V76.496L46.845 9h28.122v67.116H87.37v22.398ZM44.92 76.116V63.21c0-1.164.025-2.632.077-4.403.05-1.772.102-3.543.154-5.315.102-1.822.18-3.34.23-4.555.103-1.266.18-1.949.232-2.05h-.848c-.976 2.277-1.874 4.176-2.696 5.694-.822 1.468-1.9 3.214-3.236 5.239L26.35 76.116H44.92ZM341 98.514h-12.405V120h-30.048V98.514h-44.918V76.496L300.473 9h28.122v67.116H341v22.398Zm-42.453-22.398V63.21c0-1.164.026-2.632.077-4.403l.154-5.315c.103-1.822.18-3.34.231-4.555.103-1.266.18-1.949.231-2.05h-.847c-.976 2.277-1.875 4.176-2.697 5.694-.822 1.468-1.9 3.214-3.236 5.239l-12.481 18.297h18.568Z" fill="#ECECEC"/></svg>
                <h1>Oooops! Seems something went wrong! </h1>
                <router-link :to="{name: 'search'}" class="button button--primary">Continue browsing</router-link>
            </article>
        </section>
    </main>
</template>

<script>
export default {
    name: 'PageNotFound',
    emits: ['requestRegister']
}
</script>

<style scoped lang="scss">
    .no-page {
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 150px 0;

        @media(max-width: 991px) {
            padding: 50px 0;
        }

        article {
            width: 100%;
            max-width: 365px;
            text-align: center;
        }

        h1 {
            font-weight: 700;
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 24px;
        }

        svg {
            margin-bottom: 32px;
        }
    }
</style>