<template>
    <article class="u-text-center" >
        <h1><strong>{{$t('file_control.crop_profile_photo')}}</strong></h1>
        <div :style="{opacity: opacity,}" :class="{'private-image': privateItem}"  class="cropper-wrapper">
            <img ref="editor" v-show="source" :src="source" @load="setOpacity" alt="edit">
            <transition name="fade">
                <div v-if="privateItem" class="private-overlay">
                    <svg xmlns="http://www.w3.org/2000/svg" width="33.337" height="32" data-name="Component 7 – 19"><g data-name="Group 35"><path fill="#fff" d="M9.819 32a9.887 9.887 0 0 1-6.944-16.858 9.782 9.782 0 0 1 12.791-.954L29.347.426a1.439 1.439 0 0 1 2.045 0 1.459 1.459 0 0 1 0 2.057l-2.02 2.032 3.543 3.567a1.462 1.462 0 0 1 0 2.057L27.587 15.5a1.482 1.482 0 0 1-2.045 0L22 11.933l-4.286 4.312A9.871 9.871 0 0 1 9.819 32m0-16.841a6.861 6.861 0 0 0-4.9 2.041 6.927 6.927 0 1 0 9.8 0 6.861 6.861 0 0 0-4.9-2.041m14.223-5.283 2.522 2.537 3.283-3.3-2.521-2.54Z" data-name="Path 26"/></g></svg>
                </div>
            </transition>
            <a href="javascript:;" class="minus">
                <svg xmlns="http://www.w3.org/2000/svg" width="22.414" height="22.414"><g data-name="Icon feather-zoom-out" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path data-name="Path 74" d="M18.778 9.889A8.889 8.889 0 1 1 9.889 1a8.889 8.889 0 0 1 8.889 8.889Z"/><path data-name="Path 75" d="m21 21-4.833-4.833"/><path data-name="Path 76" d="M6.556 9.889h6.667"/></g></svg>
            </a>
            <a href="javascript:;" class="plus">
                <svg xmlns="http://www.w3.org/2000/svg" width="22.414" height="22.414"><g data-name="Icon feather-zoom-in" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path data-name="Path 70" d="M18.778 9.889A8.889 8.889 0 1 1 9.889 1a8.889 8.889 0 0 1 8.889 8.889Z"/><path data-name="Path 71" d="m21 21-4.833-4.833"/><path data-name="Path 72" d="M9.889 6.556v6.667"/><path data-name="Path 73" d="M6.556 9.889h6.667"/></g></svg>
            </a>
        </div>
        <slot name="content"></slot>
        <div class="btn-wrapper o-flex o-flex--column">
            <button class="button button--primary" @click="save">{{ucFirst($t('profile.edit.save'))}}</button>
            <button class="button button--fourth" @click="$emit('clear')">{{$t('file_control.cancel')}}</button>
        </div>
    </article>
</template>

<script>

import Croppie from "croppie"
import { ucFirst } from "@/utilities/general";

export default {
    name: "ImageEditor",
    emits: ['edit', 'clear'],
    props: {
        source: {
            type: String,
            required: true,
        },
        enableResize: {
            type: Boolean,
            default: false
        },
        privateItem: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            imageEditor: null,
            rotation: 0,
            opacity: 0
        }
    },
    watch: {
        rotation(value) {
            this.imageEditor.rotate(value)
        }
    },
    methods: {
        ucFirst,
        rotate() {

            this.rotation += this.rotation + 90 > 270 ?  0 : 90

        },
        setOpacity() {
            setTimeout(() => this.opacity = 1, 200)
        },
        save() {
            this.imageEditor.result({type: 'base64', size: 'original'}).then(result => this.$emit('edit', result))
        }
    },

    mounted() {
        this.imageEditor = new Croppie(this.$refs.editor, {
            enableExif: true,
            enableResize: this.enableResize,
            viewport: {
                width: 250,
                height: 250
            }
        })
    }
}
</script>


<style src="croppie/croppie.css"></style>

<style lang="scss">
    .private-image {

        .cr-image {
            filter: blur(25px) brightness(70%);
        }
    }
</style>

<style lang="scss" scoped>
    h1 {
        margin-bottom: 30px;
        font-size: 22px;
    }

    img {
        max-height: 250px;
        transition: all .3s ease;
    }

    // .secret-profile-photo {
    //     margin-top: 10px;
    //     margin-bottom: 10px;

    //     p {
    //         margin-top: 12px;
    //         margin-bottom: 0;
    //         font-size: 14px;
    //         line-height: 21px;
    //         text-align: left;
    //     }
    // }

    .cropper-wrapper {
        height: 370px;
        padding-bottom: 60px;
        position: relative;

        .minus,
        .plus {
            position: absolute;
            bottom: 0;
            transform: translate(0, -50%);
            pointer-events: none;
            cursor: none;
        }

        .minus {
            left: 0;
        }

        .plus {
            right: 0;
        }


    }


    .private-overlay {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9;
        width: 100%;
        aspect-ratio: 1 / 0.91;
    }

    .button--black {
        height: 30px;
        width: calc(50% - 10px);
        font-size: 16px;
        letter-spacing: -0.66px;
        font-weight: 600;
    }

    .button--primary {
        font-size: 18px;
        letter-spacing: -0.74px;
        font-weight: 600;
        text-transform: initial;
    }

    .btn-wrapper {

        .button {
            width: 100%;
            margin-top: 10px;
        }
    }
</style>