<template>
    <main>
        <div class="o-container h-100">
            <div class="credits-history">
                <div class="credits-history__header">
                    <BackButton />
                    <h2>{{$t('credits.log.credits_history')}}</h2>
                </div>
                <ContentLoader v-if="!pagination" />
                <article v-if="pagination && log?.data?.length">
                    <p class="text">{{$t('credits.log.find')}}</p>
                    <table class="table">
                        <tr v-for="i in log.data" :key="i.id">
                            <td style="width: 20%;">
                                <div class="o-flex o-flex--center">
                                    <svg v-if="i.description.toLowerCase().includes('refund')" xmlns="http://www.w3.org/2000/svg" class="mr--" width="16.754" height="16.754"><path fill="#2c7ddb" d="M5.432 12.592h-.84a.64.64 0 0 1-.643-.642l-.005-7.359a.64.64 0 0 1 .642-.642h7.354a.64.64 0 0 1 .642.642v.842a.644.644 0 0 1-.657.642L7.48 5.963l5.445 5.445a.641.641 0 0 1 0 .91l-.606.605a.641.641 0 0 1-.91 0L5.966 7.48l.109 4.456a.639.639 0 0 1-.642.657Z" data-name="Icon awesome-arrow-up"/></svg>
                                    <svg v-else-if="parseInt(i.amount) < 0" xmlns="http://www.w3.org/2000/svg" class="mr--" width="16.754" height="16.754"><path fill="#f1476f" d="M12.592 11.322v.84a.64.64 0 0 1-.642.643l-7.359.005a.64.64 0 0 1-.642-.642V4.814a.64.64 0 0 1 .642-.642h.842a.644.644 0 0 1 .642.657l-.112 4.444 5.445-5.445a.641.641 0 0 1 .91 0l.605.606a.641.641 0 0 1 0 .91L7.48 10.788l4.456-.109a.639.639 0 0 1 .657.642Z" data-name="Icon awesome-arrow-up"/></svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="mr--" width="16.754" height="16.754"><path fill="#3cbf6c" d="M4.162 5.432v-.84a.64.64 0 0 1 .642-.643l7.359-.005a.64.64 0 0 1 .642.642v7.354a.64.64 0 0 1-.642.642h-.842a.644.644 0 0 1-.642-.657l.112-4.444-5.445 5.445a.641.641 0 0 1-.91 0l-.605-.606a.641.641 0 0 1 0-.91l5.444-5.444-4.456.109a.639.639 0 0 1-.657-.642Z" data-name="Icon awesome-arrow-up"/></svg>
                                    <span>{{i.amount > 0 ? '+' : ''}}{{i.amount}}</span>
                                </div>
                            </td>
                            <td>
                                {{i.translation}}
                                <p>{{moment.utc(i.created_at, 'YYYY-MM-DD HH:mm:ss').local().format('ll h:mm a')}}</p>
                            </td>
                        </tr>
                    </table>
                    <Pagination @page-click="load" :info="pagination" />
                </article>
                <article class="no-credits-history" v-if="pagination && !log?.data?.length">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="mb+" width="65" height="65"><defs><linearGradient id="a" x1="1" x2="0" y1=".5" y2=".5" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#e1b685"/><stop offset="1" stop-color="#b1784c"/></linearGradient></defs><g data-name="Group 93" transform="translate(-95.5 -233.5)"><circle cx="32" cy="32" r="32" fill="url(#a)" stroke="rgba(0,0,0,0)" data-name="Ellipse 26" transform="translate(96 234)"/><path fill="#fff" d="m133.191 264.444-8.137-2.385a2.222 2.222 0 0 1 .625-4.35h5a4.6 4.6 0 0 1 2.577.791 1.175 1.175 0 0 0 1.469-.151l2.622-2.562a1.221 1.221 0 0 0-.136-1.846 10.654 10.654 0 0 0-6.517-2.26v-3.616a1.209 1.209 0 0 0-1.21-1.206h-2.411a1.209 1.209 0 0 0-1.206 1.206v3.616h-.188a8.255 8.255 0 0 0-8.213 9.011 8.625 8.625 0 0 0 6.314 7.278l7.723 2.26a2.222 2.222 0 0 1-.625 4.355h-5a4.6 4.6 0 0 1-2.577-.791 1.175 1.175 0 0 0-1.469.151l-2.618 2.562a1.221 1.221 0 0 0 .136 1.846 10.654 10.654 0 0 0 6.517 2.26v3.617a1.209 1.209 0 0 0 1.206 1.206h2.411a1.209 1.209 0 0 0 1.206-1.206v-3.632a8.511 8.511 0 0 0 7.964-5.478 8.273 8.273 0 0 0-5.462-10.676Z" data-name="Icon awesome-dollar-sign"/></g></svg>
                        <h2 class="mb-"><strong>{{$t('credits.log.no_history')}}</strong></h2>
                        <p>{{$t('credits.log.no_credits_buy')}}</p>
                        <button @click="requestBuyCreditsRouteHandler" type="button" class="button button--primary">{{$t('general.buy_credits')}}</button>
                    </div>
                </article>
            </div>
        </div>
    </main>
</template>
<script>

import BackButton from "@/components/generic/BackButton";
import { creditLog } from "@/api/credits";
import Pagination from "@/components/generic/Pagination.vue";
import { getPaginationPayload } from "@/service/general";
import moment from "moment";
import ContentLoader from "@/components/ContentLoader.vue";
import { requestBuyCreditsRouteHandler } from "@/service/credits";

export default {
    name: "CreditsHistory",
    components: { BackButton, Pagination, ContentLoader },
    data() {
        return {
            log: [],
            pagination: null
        }
    },
    methods: {
        requestBuyCreditsRouteHandler,
        async load(page) {
            const { data } = await creditLog({page: page})
            this.log = data
            this.pagination = getPaginationPayload(data)
        }
    },
    created() {
        this.moment = moment
    },
    mounted() {
        this.load()
    }
}
</script>
<style lang="scss" scoped>
    .credits-history {
        padding: 60px 100px;
        border-radius: 10px;
        background-color: #fff;
        min-height: 100%;
        height: auto;
        // overflow: auto;
        display: flex;
        flex-direction: column;

        @media(max-width: 991px) {
            padding: 20px 10px 100px;
            margin-top: 0;
        }
    }

    .credits-history__header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding: 0;

        .back-mob {
            margin-right: 10px;
            display: none;
            margin-top: 2px;
            padding: 10px;
        }

        h2 {
            margin-bottom: 0;
            font-size: 22px;
            font-weight: 700;
            letter-spacing: -0.9px;
        }

        @media(max-width: 991px) {
            margin-bottom: 30px;

            .back-mob {
                display: block;
            }
        }
    }

    .text {
        letter-spacing: -0.66px;
        font-size: 16px;
        line-height: 22px;
    }

    .table {
        width: 100%;
        display: table;

        td {
            padding: 10px 10px 10px 0;
            border-bottom: 1px solid #DEDEDE;
            font-weight: 600;
            color: $color-type18;
            vertical-align: top;
            line-height: 19px;
            font-size: 16px;

            p {
                margin-bottom: 0;
                margin-top: 5px;
                font-weight: 400;
            }
        }
    }

    .no-credits-history {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        text-align: center;

        p {
            margin-bottom: 30px;
            font-size: 16px;
            line-height: 20px;
            color: $color-type12;
        }
    }

    @media (max-width: 991px) {

        main {
            background-color: #fff;
            height: 100vh;
            overflow: auto;
        }
    }
</style>