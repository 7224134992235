<template>
    <div class="tab-section">
        <ContentLoader v-if="loading"/>
        <div v-if="!loading">
            <article class="info-box">
                <h1>{{$t('access.pending.title')}}</h1>
                <p>
                    {{$t('access.pending.text')}}
                </p>
            </article>
            <article class="list">
                <div v-if="pending.length">
                    <transition-group appear name="fade-in" tag="ul">
                        <li v-for="(a, index) in pending" :key="a.id"  :style="'--animate:' + index">
                            <router-link :to="{name: 'user', params: {id: a.user.id}}">
                                <div class="user-info">
                                    <div class="img-wrapper" :class="notifications.social?.access?.pending?.includes(a.id) ? 'is-active' : ''">
                                        <Image alt="Profile photo" :src="getUserPhotoURL(a.user, 'small')" :blurred="!!a.user.profile_photo?.private && ! access.includes(a.user.id)" />
                                    </div>
                                    <OnlineStatus class="active-user" v-if="isOnline(a.user)" />
                                    <p class="mb0">
                                        <span class="o-flex o-flex--center">
                                            <Premium v-if="a.user.premium" />
                                            <Verified v-if="a.user.profile_verified_at" />
                                            <strong>{{a.user.username}}</strong>
                                        </span>
                                        <span>
                                            {{a.user.address}}
                                        </span>
                                    </p>
                                </div>
                            </router-link>
                            <div class="o-flex o-flex--center">
                                <button @click="update(a.id, TYPE_ACCESS_APPROVED, $event)" class="button button--primary">{{$t('access.accept')}}</button>
                                <button @click="update(a.id, TYPE_ACCESS_DECLINED, $event)" class="button button--primary">{{$t('access.decline')}}</button>
                            </div>

                        </li>
                    </transition-group>
                </div>
            </article>
            <Pagination @page-click="load" :info="pagination" />
        </div>
    </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { getPending } from "@/api/access";
import { getUserPhotoURL, isOnline } from "@/service/user/profile";
import Pagination from "@/components/generic/Pagination";
import { respond } from "@/api/access";
import { getPaginationPayload } from "@/service/general";
import { mapState } from "vuex";
import { read } from "@/service/notifications";
import ContentLoader from "@/components/ContentLoader";
import OnlineStatus from "@/components/user/OnlineStatus";
import { TYPE_ACCESS_APPROVED, TYPE_ACCESS_DECLINED } from "@/types/access";
import Premium from "@/components/generic/Premium.vue";
import Verified from "@/components/generic/Verified.vue";
import Image from "@/components/generic/Image.vue";

export default {
    name: "MyAccess",
    mixins: [AuthenticatedMixin],
    components: {Image, Verified, Premium, Pagination, ContentLoader, OnlineStatus},
    data() {
        return {
            loading: false,
            pending: [],
            pagination: null,
            timeout: null
        }
    },
    computed: {
        ...mapState({
            notifications: state => state.notifications,
            access: state => state.access.available
        })
    },
    watch: {
        '$store.state.notifications.social.access.pending': {
            handler: async function(value) {

                if( ! value.length)
                    return

                this.pending = []

                await this.load(this.pagination?.current_page || 1)

                clearTimeout(this.timeout)

                this.timeout = setTimeout(() => {
                    read('pending', this.notifications.social.access.pending)
                }, 3000)
            }
        }
    },
    methods: {
        getUserPhotoURL,
        isOnline,
        async load(page = 1) {
            this.loading = true
            this.pending = []
            const { data } = await getPending({page: page})
            this.pending = data.data
            this.pagination = getPaginationPayload(data)
            this.loading = false
        },
        async update(id, status, $event) {
            $event.target.disabled = true

            try {
                await respond(id, status)
            } catch (e) {
                console.log('Error updating access status from pending page', e)
            }
            await this.load(this.pagination.current_page)
        },
    },
    created() {
        this.TYPE_ACCESS_APPROVED = TYPE_ACCESS_APPROVED
        this.TYPE_ACCESS_DECLINED = TYPE_ACCESS_DECLINED
    },
    mounted() {
        this.load()
        if(this.notifications.social.access.pending.length) {
            this.timeout = setTimeout(() => {
                read('pending', this.notifications.social.access.pending)
            }, 3000)
        }
    },
    beforeUnmount() {
        if(this.notifications.social.access.pending.length) {
            clearTimeout(this.timeout)
            read('pending', this.notifications.social.access.pending)
        }
    }
}
</script>

<style scoped lang="scss">
    main {
        height: auto;
        min-height: 100%!important;

    }
    // @import "../../assets/styles/components/_components.page-tab-content.scss";
</style>