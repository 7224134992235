<template>

    <div class="active-users-list" v-if="mobile && profiles.length">
        <ul class="o-flex">
            <li :key="k" v-for="(p, k) in profiles">
                <a @click="selectSuggestedProfileClickHandler(p.id)" class="active-user-item">
                    <div class="img-wrapper">
                        <img alt="user photo" :src="getUserPhotoURL(p, 'small')">
                    </div>
                    <OnlineStatus class="active-user"/>
                </a>
            </li>
        </ul>
    </div>
    <div v-if="!singleChat" class="sidebar" :class="activeConversation ? 'is-active' : ''">
        <h3>{{$t('messages.recent_chats')}}</h3>
        <div class="chat-list">
            <transition-group name="flip-list" tag="ul" class="conversation-items mb0">
                <li @click="setURLConversationID(con.id); activeChat = !activeChat" v-for="con in formattedConversations" :key="con.name"
                    :class="`${activeConversation?.id === con.id ? 'is-active' : ''} ${ ! con.system_approved_at && (auth.user.gender === TYPE_GENDER_MALE) ? 'locked' : ''}`">
                    <ConversationItem :sending="sendingQueue.some(e => e.conversation_id === con.id) && con.id !== activeConversation?.id" :unread="getUnread(con.id)" :conversation="con" :key="con.name" :is-typing="isTyping(con)" />
                </li>
            </transition-group>
        </div>

        <slot></slot>
    </div>
    <div class="chat-content-wrapper" :class="activeConversation ? 'is-active' : ''">
        <ContentLoader v-if="!activeConversation"/>
        <ChatBox v-if="activeConversation"
                 :single-chat="singleChat"
                 :typing-timeout="this.typingTimeout"
                 :conversation="activeConversation"
                 :is-typing="typing"
                 :messages="messages"
                 :scroll-direction="scrollDirection"
                 :last-message="lastMessage"
                 @uploading="handleUploadingState"
                 @remove="removeActiveConversation"
                 @load="loadMessages"
                 @unlock-started="startUnlockingAnimation"
                 @unlocked="unlock"
                 @seen="handleReadEvent"
                 @unlock-failed="unlockFailedHandler"
                 @refunded="handleRefundedConversation"
        />
    </div>
    <transition name="fade">
        <Modal class="modal" size="small" v-if="unlockingModal">
            <template #body>
                <UnlockAnimation @animated="finishUnlockingAnimation" :status="unlockingStatus" />
            </template>
        </Modal>
    </transition>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import ChatBox from "@/components/messages/ChatBox";
import ConversationItem from "@/components/messages/ConversationItem";
import moment from "moment";
import {conversation, messages} from "@/api/messages";
import {getUserPhotoURL, isOnline} from "@/service/user/profile";
import {connect} from "@/connection/websocket/connection";
import { mapGetters, mapActions, mapState } from "vuex";
import { readConversation as readConversationService } from "@/service/messages";
import { TYPE_GENDER_MALE } from "@/types/user";
import ContentLoader from "@/components/ContentLoader";
import { TYPE_TEMPORARY} from "@/types/message";
import Modal from "@/components/generic/Modal";
import UnlockAnimation from "@/components/generic/UnlockAnimation";
import { wait } from "@/utilities/general";
import { sortMessages, goToConversationWithUser } from "@/service/messages";
import { ANIMATION_INIT, ANIMATION_IN_PROGRESS, ANIMATION_COMPLETED} from "@/utilities/unlockAnimation";
import OnlineStatus from "@/components/user/OnlineStatus.vue";

export default {
    name: "Messages",
    mixins: [AuthenticatedMixin],
    components: {ChatBox, ConversationItem, ContentLoader, Modal, UnlockAnimation, OnlineStatus},
    emits: ['new', 'error', 'update'],
    props: {
        conversations: {
            type: Object,
            required: true
        },
        mobile: {
            type: Boolean,
            default: false
        },
        singleChat: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            moment,
            loading: true,
            list: [],
            activeConversation: null,
            text: '',
            messages: null,
            connection: null,
            typingBroadcaster: false,
            typingReceiver: [],
            typingTimeout: 3000,
            participantConnected: false,
            participantSeen: null,
            activeChat:false,
            unlockingStatus: 0,
            unlockingModal: false,
            scrollDirection: 'end',
            lastMessage: null,
            goingToConversation: false
        }
    },
    computed: {
        ...mapState({
            sendingQueue: state => state.messages.sendQueue,
            animations: state => state.animations.profiles
        }),
        ...mapGetters({
            getUnread: 'notifications/countMessages'
        }),
        typing() {
            return this.isTyping(this.activeConversation)
        },
        profiles() {
            let out = []
            for(let i in this.animations) {
                out.push(...this.animations[i])
            }
            return out.filter(e => !! e.profile_photo).filter(e => this.participants?.findIndex(i => i.id === e.id) === -1).sort((a, b) => moment(b.last_activity, 'YYYY-MM-DD HH:mm:ss').diff(moment(a.last_activity, 'YYYY-MM-DD HH:mm:ss')))
        },
        formattedConversations() {
            if( ! this.list.data)
                return []

            return [...this.list.data].filter(e => e.members.length > 1).sort((a, b) => {

                return moment(b?.last_message?.created_at ?? b.updated_at, 'YYYY-MM-DD HH:mm:ss').diff(moment(a?.last_message?.created_at ?? a.updated_at, 'YYYY-MM-DD HH:mm:ss'))

            })

        },
        participants() {
            return this.formattedConversations.map(e => {
                return e.members?.find(i => i.id !== this.auth.user.id)
            })
        }
    },
    watch: {
        "$route": {

            handler: async function($to) {

                if( ! $to.params.conversation) {
                    return this.activeConversation = null
                }

                if( ! this.list.data.find(e => parseInt(e.id) === parseInt($to.params.conversation))) {

                    try {

                        const  { data } = await conversation($to.params.conversation)
                        this.list = {
                            ...this.list,
                            data: [
                                ...this.list.data,
                                data
                            ]
                        }

                        await this.setActiveConversation(data)

                    } catch(e) {
                        console.log('error fetching new conversation')
                        console.log(e)
                        return this.$emit('error', {conversation: $to.params.conversation})
                    }

                } else {

                    await this.setActiveConversation(this.list.data.find(e => parseInt(e.id) === parseInt($to.params.conversation)))

                }

            }

        },
        conversations: {
            handler: async function(value) {

                this.list = {...value}

                this.typingListener()

            }

        },
        activeConversation: {
            handler: async function(current) {

                this.lastMessage = null
                this.scrollDirection = 'end'

                this.messages = null

                if(current) {

                    try {
                        const { data } = await messages(current.id, {perPage: 30})
                        this.messages = data
                        this.lastMessage = data.data[0]
                    } catch {
                        return this.$emit('error', {conversation: current.id})
                    }



                    if( ! this.logged)
                        return

                    this.participantSeen = this.activeConversation?.members.find(e => e.id !== this.auth.user.id)?.pivot?.last_message_seen

                    const whisperPayload = {
                        status: true,
                        user_id: this.auth.user.id,
                        message_id: null,
                        unlocked: !! this.activeConversation?.system_approved_at
                    }
                    const lastMessage = this.lastParticipantMessage()
                    if(lastMessage) {
                        whisperPayload.message_id = lastMessage.id
                    }

                }

            },
            immediate: true
        },
        '$store.state.messages.messages': async function (value) {

            this.scrollDirection = 'end'

            const message = value[value.length - 1]

            if(message.conversation_id === this.activeConversation?.id) {
                this.lastMessage = message
            }

            if(message.author_id !== this.auth.user.id) {
                if( ! this.participantConnected)
                    this.participantConnected = true
            }

            if( ! this.list.data.find(e => e.id === message.conversation_id)) {

                try {

                    const  { data } = await conversation(message.conversation_id)
                    return this.$emit('new', data)

                } catch(e) {
                    console.log('error fetching new conversation')
                    console.log(e)
                }

            }

            this.list.data = this.list.data.map(e => {
                if(e.id === message.conversation_id) {
                    let payload = {
                        ...e,
                        last_message: message,
                        refundable: e.refundable ? (message.author_id === this.auth.user.id) : e.refundable
                    }
                    // TODO Refactor this, MessagesPage load more cause problem, since list is updated here
                    this.$emit('update', payload)
                    return payload
                }
                return e
            })

            if(this.activeConversation?.id === message.conversation_id) {

                if(this.activeConversation.refundable) {

                    if(message.author_id !== this.auth.user.id) {
                        this.activeConversation.refundable = false
                    }

                }

                this.messages = {
                    ...this.messages,
                    data: [
                        message,
                        ...(this.messages?.data ?? [])
                    ]
                }
            }

            const conv = this.typingReceiver.find(e => e.conversation_id === message.conversation_id)

            if(conv !== undefined) {

                clearTimeout(conv.timeout)

                await this.$nextTick(() => {
                    this.typingReceiver = this.typingReceiver.filter(e => e.conversation_id !== conv.conversation_id)
                })

            }

        }
    },

    methods: {
        handleRefundedConversation(conversation) {
            this.list = {
                ...this.list,
                data: this.list.data.map(i => {
                    if(i.id === conversation.id) {
                        return {
                            ...i,
                            system_approved_at: conversation.system_approved_at,
                            refundable: conversation.refundable
                        }
                    }
                    return i
                })
            }

            if(this.activeConversation.id === conversation.id) {
                this.activeConversation.system_approved_at = conversation.system_approved_at
                this.activeConversation.refundable = conversation.refundable
            }
        },
        async selectSuggestedProfileClickHandler(id) {

            if(this.goingToConversation)
                return

            this.goingToConversation = true

            await goToConversationWithUser(id)

            this.goingToConversation = false

        },
        ...mapActions({
            tempReadNotification: 'notifications/readConversation'
        }),

        async handleReadEvent(payload) {

            this.list = {
                ...this.list,
                data: this.list.data.map(e => {
                    if(e.id === payload.conversation_id) {
                        return {
                            ...e,
                            members: e.members.map(i => {
                                if(i.id === this.auth.user.id) {

                                    return {
                                        ...i,
                                        pivot: {
                                            ...i.pivot,
                                            last_message_seen: payload.id
                                        }
                                    }

                                }
                                return i
                            })
                        }
                    }
                    return e
                })
            }

        },

        unlockFailedHandler() {
            this.unlockingModal = false
            this.unlockingStatus = ANIMATION_INIT
        },

        async startUnlockingAnimation() {

            this.unlockingModal = true

            await this.$nextTick(() => {
                this.unlockingStatus = ANIMATION_INIT
                this.unlockingStatus = ANIMATION_IN_PROGRESS
            })

        },

        async finishUnlockingAnimation() {

            await wait(1)

            this.unlockingModal = false

            this.unlockingStatus = ANIMATION_INIT
        },

        handleUploadingState(payload) {

            if(payload.conversation_id !== this.activeConversation.id)
                return

            if( ! this.messages.data) {
                return this.messages.data = [
                    payload
                ]
            }

            if(payload.error && this.messages.data?.length) {
                return this.messages.data = [
                    ...this.messages.data.filter(e => e.hash !== payload.hash)
                ]
            }

            if(this.messages.data?.find(e => e.type === TYPE_TEMPORARY && e.hash === payload.hash)) {
                return this.messages.data = this.messages.data.map(e => e.hash === payload.hash ? payload : e)
            }

            return this.messages.data = [
                ...this.messages.data,
                {...payload}
            ]

        },

        async removeActiveConversation(payload) {

            this.list = {
                ...this.list,
                data: this.list.data.filter(e => e.id !== payload.id)
            }

            if(this.list?.data.length) {
                return await this.setActiveConversation({...this.list.data[0]})
            }

            await this.$router.push({name: 'search'})

        },
        async readConversation(message) {

            await readConversationService(message)

            await this.handleReadEvent({message_id: message.id})


        },
        getUserPhotoURL,
        isOnline,
        lastParticipantMessage() {

            if( ! this.messages.data?.length)
                return false

            const messages = sortMessages(this.messages.data.filter(e => e.author_id !== this.auth.user.id))

            return messages.length ? messages[messages.length - 1] : false


        },
        isTyping(conversation) {
            return !! this.typingReceiver.find(e => e.conversation_id === conversation.id && e.typing)
        },

        async setURLConversationID(id) {
            await this.$router.push({
                name: 'messages',
                params: {
                    conversation: id
                }
            })
        },

        async setActiveConversation(payload) {

            if(this.activeConversation?.id === payload.id)
                return

            this.activeConversation = {...payload}

            if(this.$route.name === 'messages') {

                await this.setURLConversationID(payload.id)

            }


        },
        async unlock(conversation) {

            if(this.activeConversation.id === conversation.id) {
                this.activeConversation = {
                    ...conversation
                }
            }

            this.list.data = this.list.data.map(e => {
                if(e.id === conversation.id) {
                    return {
                        ...e,
                        ...conversation
                    }
                }
                return e
            })

            const lastMessage = this.lastParticipantMessage()

            await this.$nextTick(() => {
                this.unlockingStatus = ANIMATION_COMPLETED
            })

            this.$emit('update', conversation)

            connect().then(connection => connection.private(`conversation_events.${conversation.id}`)
                .whisper('connected', {
                    status: true,
                    user_id: this.auth.user.id,
                    message_id: lastMessage.id,
                    unlocked: true
                }))

        },

        async loadMessages(query = null) {
            try {
                this.scrollDirection = 'start'
                const { data } = await messages(this.activeConversation.id, query)
                this.messages = {
                    ...data,
                    data: [
                        ...data.data,
                        ...this.messages?.data?.filter(e => ! data?.data?.find(i => e.id === i.id))
                    ]
                }
            } catch (e) {
                console.log('error fetching older messages', e)
            }
        },

        updateTypingReceiver(target) {
            return {
                conversation_id: target.conversation_id,
                typing: true,
                timeout: setTimeout(() => {
                    this.typingReceiver = this.typingReceiver.map(i => {
                        if(i.conversation_id === target.conversation_id) {
                            return {
                                ...i,
                                typing: false
                            }
                        }
                        return i
                    })
                }, this.typingTimeout + 500)
            }
        },

        typingListener() {

            this.list.data.forEach(conversation => {

                if(this.typingReceiver.find(e => e.conversation_id === conversation.id))
                    return

                if( ! this.logged)
                    return

                connect().then(connection => {

                    connection.private(`conversation_events.${conversation.id}`)

                        .listenForWhisper('typing', (e) => {

                            if(e?.user_id === this.auth.user.id)
                                return

                            let target = this.typingReceiver.find(i => i.conversation_id === e.conversation_id)

                            if(target === undefined) {

                                target = this.updateTypingReceiver(e)

                                this.typingReceiver = [...this.typingReceiver, target]

                            } else {

                                clearTimeout(target.timeout)

                                this.typingReceiver = this.typingReceiver.map(i => {

                                    if(i.conversation_id === e.conversation_id) {

                                        clearTimeout(target.timeout)

                                        return this.updateTypingReceiver(i)

                                    }

                                    return i

                                })

                            }

                        })

                })

            })

        }
    },
    created() {
        this.TYPE_GENDER_MALE = TYPE_GENDER_MALE
    },
    mounted() {

        this.list = {...this.conversations}

        this.typingListener()

        if(this.$route.params.conversation) {

            return this.setActiveConversation(this.list.data.find(e => parseInt(e.id) === parseInt(this.$route.params.conversation)))

        }

        if(this.singleChat) {
            return this.setActiveConversation({...this.list.data[0]})
        }

    }
}
</script>

<style scoped lang="scss">

.flip-list-enter-active,
.flip-list-leave-active {
    transition: transform 0.8s ease;
}
.flip-list-enter-from,
.flip-list-leave-to {
    transition: transform 0.8s ease;
}

.flip-list-move {
    transition: all 0.8s ease;
}

.active-users-list {
    padding: 0 10px;
    margin-bottom: 20px;

    .img-wrapper {
        overflow: hidden;
        border-radius: 50%;
        min-width: 60px;
        width: 60px;
        height: 60px;
        display: block;

        img {
            object-fit:cover;
            min-width: 100%;
            min-height: 100%;
        }
    }

    ul {
        margin-bottom: 0;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    li {
        margin-right: 6px;
    }
}

.active-user-item {
    position: relative;
    display: block;
    .active-user {
        border: 2px solid $color-type1;
        position: absolute;
        z-index: 2;
        left: 44px;
        bottom: 0;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $color-type25;

        @media(max-width: 991px) {
            left: 45px;
            bottom: 0;
            margin-left: 0;
        }
    }
}
.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
        display: none;
    }

    >h2 {
        margin-bottom: 30px;
    }

    @media(max-width: 991px) {
        width: 100%;
        // height: 100vh;
        position: relative;
        transition: all .3s ease;
        padding: 0;
        background-color: $color-type1;
        left: 0;
        top: 0;
        transform: translate(0,0);
        height: 100%;
        overflow: hidden;

        h3 {
            padding: 0 10px;
            color: #4B4B4B;
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: 400;
            display: block;
        }

        &.is-active {
            transform: translate(-100%,0);
        }
    }
}

.chat-content-wrapper {
    background-color: #fff;
    border-radius: 10px;
    padding-bottom: 20px;
    width: calc(100% - 14px);
    margin-left: 14px;

    @media(max-width: 991px) {
        position: fixed;
        z-index: 3;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: translate(100%,0);
        background-color: $color-type1;
        transition: all .3s ease;
        padding-bottom: 0;
        margin-left: 0;

        margin-bottom: env(safe-area-inset-bottom);

        &.is-active {
            transform: translate(0,0);
        }
    }
}

.chat-list {
    max-height: 80vh;
    overflow: auto;
    margin-bottom: 0;

    li {
        display: flex;
        align-items: center;
        border-radius: 30px 0 0 30px;
        padding-right: 10px;
        transition: all .3s ease-in-out;
        cursor: pointer;
        position: relative;
        height: 60px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        &.is-active {
            background-color: $color-type1;
        }
    }

    @media(max-width: 991px) {
        max-height: initial;
        height: 100%;
        padding: 0 10px;


        li {
            position: relative;
            padding: 6px 0;
            height: auto;

            &:not(:last-child) {
                margin-bottom: 0;
            }

            &:before {
                position: absolute;
                right: 0;
                bottom: 0;
                width: calc(100% - 75px);
                height: 1px;
                background-color: $color-type3;
                content: "";
            }
        }
    }
}
</style>
