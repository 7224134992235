export default {

    namespaced: true,

    state: {
        queue: []
    },

    mutations: {
        add(state, item) {

            if(state.queue.includes(item))
                return

            state.queue = [
                ...state.queue,
                item
            ]
        },

        set(state, items) {

            items = items.filter(e => ! state.queue.includes(e))

            state.queue = [
                ...state.queue,
                ...items
            ]

        },

        remove(state, item) {
            if( ! state.queue.includes(item))
                return

            state.queue = state.queue.filter(e => e !== item)
        },
    },

    actions: {
        add({commit}, item) {
            commit('add', item)
        },
        remove({commit}, item) {
            commit('remove', item)
        },
        set({commit}, items) {
            commit('set', items)
        },
        prepend(context, item) {
            context.commit('set', [
                item,
                ...context.state.queue
            ])
        }
    },

    getters: {

    }

}