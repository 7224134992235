<template>
    <header class="header-main">
        <div class="o-container">
            <router-link :to="{name: 'home'}" class="header-logo">
                <img :src="require(`@/assets/img/${theme}.svg`)" alt="main logo"/>
            </router-link>

            <div class="header-main__right">
                <nav class="header-user-nav">
                    <LanguageSelection class="type" :dropdownAbsolute="'type1'"/>

                    <ul class="action-buttons">
                        <li>
                            <router-link :to="{name: 'login'}" class="button button--third">{{ucFirst($t('navigation.public.login'))}}</router-link>
                        </li>
                        <li>
                            <a href="javascript:void(0);" @click="$emit('request-register')" class="button button--primary">{{capitalize($t('navigation.public.signup'))}}</a>
                        </li>
                    </ul>
                </nav>
            </div>

        </div>
    </header>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import LanguageSelection from "@/components/language/LanguageSelection";
import { ucFirst, capitalize } from "@/utilities/general";
import ThemeMixin from "@/mixins/ThemeMixin";

export default {
    name: "Header",
    components: {LanguageSelection},
    mixins: [AuthenticatedMixin, ThemeMixin],
    methods: {
        ucFirst,
        capitalize
    }
}
</script>

<style scoped lang="scss">

    .header-main {
        padding: 10px 0;
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        background-color: $color-type2;

        .o-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .header-logo {
        display: block;
        margin-right: 40px;

        @media(max-width: 991px) {
            margin-right: 15px;
        }
    }

    .header-main__right {
        display: flex;
        align-items: center;
        position: relative;
    }


    .header-user-nav {
        display: flex;
        align-items: center;

        ul {
            display: flex;
            align-items: center;
            margin-bottom: 0;
        }

        li {
            margin-left: 20px;
        }

        .button {
            padding: 0 35px;
        }

        @media(max-width: 991px) {
            flex-direction: row-reverse;

            .navbar-nav {
                position: absolute;
                right: -10px;
                padding-right: 10px;
                top: 100%;
                background-color: $color-type2;
                width: 100vw;
                display: flex;
                justify-content: flex-end;
            }

            li {
                margin-left: 10px;
            }

            .button {
                padding: 0 15px;
                text-align: center;
                text-transform: capitalize;
            }
        }
    }

    .bars {
        display: none;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        position: relative;
        transform: rotate(0deg);
        cursor: pointer;

        &:hover,
        &:focus {

            svg path {
                fill: $color-type19;
            }
        }


        @media (max-width: 991px) {
            display: flex;
        }
    }

</style>