<template>
    <div class="bubble-notification-modal">
        <div class="bubble-notification-modal__header bubble-notification-modal__header--double">
            <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"><g data-name="Group 246" transform="translate(-150 -326)"><circle data-name="Ellipse 59" cx="30" cy="30" r="30" transform="translate(150 326)" fill="#f5f5f5"/><path data-name="Shape 2" d="M179.999 374a1.6 1.6 0 0 1-.871-.258c-1.5-.969-14.709-9.694-16.411-17.448a9.618 9.618 0 0 1-.5-3.2 11.068 11.068 0 0 1 5.579-9.719 10.055 10.055 0 0 1 5.193-1.38 10.583 10.583 0 0 1 7.005 2.675 10.786 10.786 0 0 1 12.225-1.281 11.069 11.069 0 0 1 5.552 9.7 10.156 10.156 0 0 1-.559 3.4c-1.639 7.547-14.848 16.271-16.348 17.24a1.6 1.6 0 0 1-.865.271Z" fill="#e32b6a"/></g></svg>
            </div>
            <div class="double-image-wrapper">
                <div class="img-wrapper">
                    <Image :src="getUserPhotoURL(bubble.payload.user)" alt="User Photo" />
                </div>
                <div class="img-wrapper">
                    <Image :src="getUserPhotoURL(auth.user)" alt="User Photo" />
                </div>
            </div>
        </div>
        <div class="text">
            {{$t('notifications.match.title')}}
        </div>
        <h2>{{$t(`notifications.match.subtitle.${bubble.payload.user.gender === TYPE_GENDER_FEMALE ? 'female' : 'male'}`)}}</h2>
        <button :disabled="working" @click="messageUser" class="button button--primary">{{$t(`notifications.actions.send_message.${bubble.payload.user.gender === TYPE_GENDER_FEMALE ? 'female' : 'male'}`)}}</button>
        <router-link class="button button--fourth" @click="$emit('close'); pushDataLayer(GTM_EVENT_MATCH_CLICK_2, auth.user)" :to="{name: 'user', params: {id: bubble.payload.user.id}}">{{$t(`notifications.actions.check.${bubble.payload.user.gender === TYPE_GENDER_FEMALE ? 'female' : 'male'}`)}}</router-link>
    </div>
</template>

<script>

import { TYPE_GENDER_FEMALE } from "@/types/user";
import { getUserPhotoURL } from "@/service/user/profile";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { getOrCreate } from "@/api/messages";
import { GTM_EVENT_MATCH_OPEN, GTM_EVENT_MATCH_CLICK_1, GTM_EVENT_MATCH_CLICK_2} from "@/service/gtm/types";
import { pushDataLayer } from "@/service/gtm";
import Image from "@/components/generic/Image";
import { mapActions } from "vuex";
import toastr from "toastr";

export default {
    name: "Match",
    mixins: [AuthenticatedMixin],
    components: { Image },
    props: {
        bubble: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            working: false
        }
    },
    methods: {
        pushDataLayer,
        getUserPhotoURL,
        ...mapActions({
            addPreAction: "profile/add"
        }),
        async messageUser() {
            this.working = true

            try {
                const { data } = await getOrCreate(this.bubble.payload.user.id)
                pushDataLayer(GTM_EVENT_MATCH_CLICK_1, this.auth.user)
                await this.$router.push({name: 'messages', params: {conversation: data.id}})
            } catch(e) {
                if(e?.response?.status === 406) {
                    await this.addPreAction('daily_limit')
                } else {
                    toastr.error(this.$i18n.t('credits.error_processing'))
                }
            }

            this.working = false

            this.$emit('close')
        }
    },
    created() {
        this.TYPE_GENDER_FEMALE         = TYPE_GENDER_FEMALE
        this.GTM_EVENT_MATCH_CLICK_2    = GTM_EVENT_MATCH_CLICK_2
    },
    mounted() {
        pushDataLayer(GTM_EVENT_MATCH_OPEN, this.auth.user)
    }
}
</script>