<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" viewBox="0 0 12.5 10"><path fill="#c59264" d="M10.312 8.75H2.187a.313.313 0 0 0-.312.313v.625a.313.313 0 0 0 .312.312h8.125a.313.313 0 0 0 .313-.312v-.625a.313.313 0 0 0-.313-.313Zm1.25-6.25a.938.938 0 0 0-.937.937.918.918 0 0 0 .086.387L9.3 4.672a.624.624 0 0 1-.863-.227L6.842 1.66a.938.938 0 1 0-1.184 0L4.066 4.445a.625.625 0 0 1-.863.227l-1.412-.848a.937.937 0 1 0-.854.551.957.957 0 0 0 .15-.016L2.5 8.125H10l1.412-3.766a.957.957 0 0 0 .15.016.937.937 0 1 0 0-1.875Z" data-name="Icon awesome-crown"/></svg>
</template>
<script>
export default {
    name: "Premium",
    props: {
        width: {
            type: Number,
            default: 12
        }
    }
}
</script>
<style lang="scss" scoped>

</style>