<template>
        <Modal class="modal modal--mob modal--registration" :closable="false">
            <template #header>
                <h1>{{$t('signup.update_email.title')}}</h1>
                <p class="text">{{$t('signup.update_email.description')}}</p>
            </template>
            <template #body>

                <div class="input-box">
                    <p>Email Address</p>
                    <input type="text" v-model="email" class="input input--primary">
                </div>
            </template>
            <template #footer>
                <button :disabled="!email.length" @click="resendEmail($event)" class="button button--primary w-100">{{ucFirst($t('verify.resendBtn'))}}</button>
            </template>
        </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal";
import {resendEmail} from "@/service/user";
import toastr from "toastr";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { ucFirst } from "@/utilities/general";
import { pushDataLayer } from "@/service/gtm";
import { GTM_EVENT_VERIFY_EMAIL_SEND, GTM_EVENT_VERIFY_EMAIL_SEND_CLICK } from "@/service/gtm/types";
import { mapActions } from "vuex";

export default {
    name: "PreActionResend",
    mixins: [AuthenticatedMixin],
    components: {Modal},
    data() {
        return {
            email: '',
            successMessage: '',
            errorMessage: ''
        }
    },
    methods: {
        ucFirst,
        ...mapActions({
            setUserNewEmail: "authenticate/setUser"
        }),
        async resendEmail($event) {
            pushDataLayer(GTM_EVENT_VERIFY_EMAIL_SEND_CLICK, this.auth.user)
            $event.target.disabled = true
            try {
                await resendEmail(this.email)
                await this.setUserNewEmail({...this.auth.user, email: this.email})
                toastr.success(this.successMessage)
                this.$emit('success')
            } catch (e) {

                if(e.response?.data?.errors?.email) {
                    toastr.error(e.response.data.errors.email[0])
                } else {

                    toastr.error(this.errorMessage)
                }
            }
            $event.target.disabled = false
        },
    },
    mounted() {
        this.email = this.auth.user.email
        pushDataLayer(GTM_EVENT_VERIFY_EMAIL_SEND, this.auth.user)
    },
    created() {
        this.successMessage  = this.$i18n.t('verify.sent')
        this.errorMessage = this.$i18n.t('credits.error_processing')
    }
}
</script>

<style scoped lang="scss">
    .close {
        position: absolute;
        right: -65px;
        top: -45px;
    }

    h1 {
        font-size: 22px;
        margin-bottom: 30px;
        font-weight: 700;
        letter-spacing: -0.9px;
    }

    .text {
        color: $color-type18;
        font-size: 16px;
        line-height: 1.2;
        margin: 0 auto 30px;
        max-width: 350px;
        text-align: center;
    }

    .button--fourth {
        background: #e6e6e6;
        margin-bottom: 30px;
        height: 45px;
        text-transform: uppercase;
        margin-top: 10px;
    }

    @media(max-width: 991px) {

        h1 {
            margin-bottom: 20px;
        }
    }
</style>