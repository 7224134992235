<template>
    <main>
        <div class="o-container">
            <section class="faq">
                <div class="header-mob">
                    <router-link :to="{name: 'search'}" class="back-mob"><svg xmlns="http://www.w3.org/2000/svg" width="14.004" height="14.001"><path data-name="Union 6" d="M6.289 13.708.262 7.669a1 1 0 0 1 .03-1.379l6-6a1 1 0 0 1 1.417 0 1 1 0 0 1 0 1.413l-4.3 4.295H13a1 1 0 0 1 1 1 1 1 0 0 1-1 1H3.41l4.292 4.3a1 1 0 0 1 0 1.412A1 1 0 0 1 7 14a1 1 0 0 1-.711-.292Z"/></svg></router-link>
                    <h2>{{$t('other.faq')}}</h2>
                </div>
                <article>

                    <h1>{{$t('faq.title')}}</h1>
                    <Accordion :bigAccordion="'big-accordion'">
                        <template v-slot:asideTitle>
                            {{$t('faq.q_payment_method')}}
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                        </template>
                        <template v-slot:asideOptions>
                            <p>{{$t('faq.a_payment_method')}}</p>
                            <p>{{$t('faq.support', [VUE_APP_SUPPORT_EMAIL])}}</p>
                        </template>
                    </Accordion>
                    <Accordion :bigAccordion="'big-accordion'">
                        <template v-slot:asideTitle>
                            {{$t('faq.q_send_message')}}
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                        </template>
                        <template v-slot:asideOptions>
                            <p>{{$t('faq.a_send_message')}}</p>
                        </template>
                    </Accordion>
                    <Accordion :bigAccordion="'big-accordion'">
                        <template v-slot:asideTitle>
                            {{$t('faq.q_write_profile')}}
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                        </template>
                        <template v-slot:asideOptions>
                            <p>{{$t('faq.a_write_profile_title')}}</p>
                            <ul>
                                <li v-for="i in 6" :key="i">
                                    {{$t(`faq.a_write_profile_${i - 1}`)}}
                                </li>
                            </ul>
                        </template>
                    </Accordion>
                    <Accordion :bigAccordion="'big-accordion'">
                        <template v-slot:asideTitle>
                            {{$t('faq.q_photo_type')}}
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                        </template>
                        <template v-slot:asideOptions>
                            <p>{{$t('faq.a_photo_type_title')}}</p>
                            <ul>
                                <li v-for="i in 7" :key="i">
                                    {{$t(`faq.a_photo_type_${i - 1}`)}}
                                </li>
                            </ul>
                        </template>
                    </Accordion>
                    <Accordion :bigAccordion="'big-accordion'">
                        <template v-slot:asideTitle>
                            {{$t('faq.q_report_user')}}
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                        </template>
                        <template v-slot:asideOptions>
                            <p>{{$t('faq.a_report_user')}}</p>
                        </template>
                    </Accordion>
                    <Accordion :bigAccordion="'big-accordion'">
                        <template v-slot:asideTitle>
                            {{$t('faq.q_delete_account')}}
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                        </template>
                        <template v-slot:asideOptions>
                            <p>{{$t('faq.a_delete_account')}}</p>
                        </template>
                    </Accordion>
                    <Accordion :bigAccordion="'big-accordion'">
                        <template v-slot:asideTitle>
                            {{$t('faq.q_free_trial')}}
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                        </template>
                        <template v-slot:asideOptions>
                            <p>{{$t('faq.a_free_trial', [VUE_APP_NAME])}}</p>
                        </template>
                    </Accordion>
                    <Accordion :bigAccordion="'big-accordion'">
                        <template v-slot:asideTitle>
                            {{$t('faq.q_female_real')}}
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                        </template>
                        <template v-slot:asideOptions>
                            {{$t('faq.a_female_real')}}
                        </template>
                    </Accordion>
                    <Accordion :bigAccordion="'big-accordion'">
                        <template v-slot:asideTitle>
                            {{$t('faq.q_new_message')}}
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                        </template>
                        <template v-slot:asideOptions>
                            <p>{{$t('faq.a_new_message')}}</p>
                        </template>
                    </Accordion>
                    <Accordion :bigAccordion="'big-accordion'">
                        <template v-slot:asideTitle>
                            {{$t('faq.q_account_details')}}
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                        </template>
                        <template v-slot:asideOptions>
                            <p v-for="i in 3" :key="i">{{$t(`faq.a_account_details_${i - 1}`)}}</p>
                        </template>
                    </Accordion>
                    <Accordion :bigAccordion="'big-accordion'">
                        <template v-slot:asideTitle>
                            {{$t('faq.q_change_email')}}
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                        </template>
                        <template v-slot:asideOptions>
                            <p>{{$t('faq.a_change_email')}}</p>
                        </template>
                    </Accordion>
                    <Accordion :bigAccordion="'big-accordion'">
                        <template v-slot:asideTitle>
                            {{$t('faq.q_unsubscribe')}}
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                        </template>
                        <template v-slot:asideOptions>
                            <p>{{$t('faq.a_unsubscribe_title')}}</p>
                            <ul>
                                <li v-for="i in 3" :key="i">
                                    {{$t(`faq.a_unsubscribe_${i - 1}`)}}
                                </li>
                            </ul>
                            <p>{{$t('faq.a_unsubscribe_end')}}</p>
                        </template>
                    </Accordion>
                    <Accordion :bigAccordion="'big-accordion'">
                        <template v-slot:asideTitle>
                            {{$t('faq.q_delete_credits')}}
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9.29"><g data-name="Group 55"><path data-name="Icon awesome-angle-down" d="m5.795 5.136-3.95 3.953a.694.694 0 0 1-.985 0l-.655-.66a.694.694 0 0 1 0-.985l2.8-2.8-2.8-2.8a.694.694 0 0 1 0-.985L.861.203a.694.694 0 0 1 .985 0l3.95 3.95a.691.691 0 0 1 0 .982Z"/></g></svg>
                        </template>
                        <template v-slot:asideOptions>
                            <p>{{$t('faq.a_delete_credits')}}</p>
                        </template>
                    </Accordion>
                </article>
            </section>
        </div>
    </main>
</template>

<script>
import Accordion from "@/components/generic/Accordion";
export default {
    name: 'Help',
    components: {Accordion},
    created() {
        this.VUE_APP_SUPPORT_EMAIL = process.env.VUE_APP_SUPPORT_EMAIL
        this.VUE_APP_NAME = process.env.VUE_APP_NAME
    }
}
</script>

<style scoped lang="scss">

    main {
        @media (max-width: 991px) {
            background-color: #fff;
            height: auto;
            min-height: 0;
        }
    }

    .faq {
        padding: 60px 100px;
        border-radius: 10px;
        background-color: #fff;
        height: calc(100% - 40px);
        overflow: hidden;


        article {
            width: 100%;
            margin-bottom: 30px;

            h1 {
                font-weight: 800;
                margin-bottom: 30px;
            }
        }

        ul {
            padding-left: 30px;
            list-style-type: disc;
        }

        li {
            padding: 10px 10px;
            line-height: 1.5;

        }

        p {
            line-height: 1.5;

            a {
                color: #bfa46a;
            }
        }

        .aside-title {

            svg {
                transition: all .3s ease-in-out;
                min-width: 9px;
                transform: rotate(0deg);
            }

            &.is-active {
                svg {
                    transform: rotate(90deg);
                }
            }
        }


        @media(max-width: 991px) {
            padding: 30px 0 70px;
            margin-top: 0;
            margin-bottom: 0;

            h1 {
                margin-bottom: 20px;
                display: none;
            }

            p, li {
                font-size: 14px;
            }

            p {
                margin-bottom: 10px;

                a {
                    font-size: 14px;
                }
            }

            ul {
                padding-left: 15px;
            }
        }
    }

    .header-mob {
        display: none;
        margin-bottom: 10px;

        @media(max-width: 500px) {
            display: flex;
            align-items: center;

            h2 {
                margin-bottom: 0;
                font-size: 22px;
                font-weight: 700;
            }

            .back-mob {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                margin-right: 10px;
            }
        }
    }

</style>

<style lang="scss">
    .big-accordion {


        .aside-title.is-active {
            svg {
                transform: rotate(0deg);

            }
        }

        .aside-option__wrapper {
            padding: 0!important;
        }

        @media(max-width: 991px) {
            .aside-title {
                svg {
                    margin-left: 20px!important;
                }
            }
        }
    }
</style>
