<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
export default {
    name: 'PreActionFlowBasedRedirect',
    mixins: [AuthenticatedMixin],
    render() {
        return null
    },
    async mounted() {

        if(this.$route.meta?.restrictFlowRedirect) {
            return this.$emit('success')
        }

        this.$emit('success')

    }
}
</script>