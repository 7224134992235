<template>
    <Modal class="modal modal--mob modal-radio-buttons">
        <template #header>
            <div class="u-text-center">
                <div class="push-notification-box">
                    <div class="bell">
                        <div class="bell-border"></div>
                        <svg class="btn-bell" width="25" viewBox="0 0 448 512">
                        <path fill="#fff" d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"/>
                        </svg>
                    </div>
                </div>
            </div>
        </template>
        <template #body>
            <p class="u-text-center mb0" style="line-height: 1.3;">
                {{$t('push.modal.description')}}
            </p>
            <p class="u-text-center mb0 mt+ u-font-300">
                <small>{{$t('push.modal.choose')}}</small>
            </p>
        </template>
        <template #footer>
            <div class="u-display-block w-100">
                <button :disabled="working" @click="clickHandler" class="button button--primary w-100 mb">Ok</button>
                <div class="u-text-center">
                    <a @click="delayHandler" class="skip ">{{$t('credits.skip_for_now')}}</a>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>

import Modal from "@/components/generic/Modal";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import {getUserToken,
    reject,
    requestPushNotification, subscribe} from "@/service/push_notifications/push";
import {
    PUSH_NOTIFICATION_BROWSER_ALLOW,
    PUSH_NOTIFICATION_BROWSER_REJECT,
    PUSH_NOTIFICATION_PROMPT_SHOW,
    PUSH_NOTIFICATION_PROMPT_SKIP,
    PUSH_NOTIFICATION_BROWSER_REQUEST,
    PUSH_NOTIFICATION_PROMPT_ACCEPT,
    PUSH_NOTIFICATION_ACCEPT_FLOW_SHOW, PUSH_NOTIFICATION_ACCEPT_FLOW_CLOSE
} from "@/service/gtm/types";
import { pushDataLayer } from "@/service/gtm";
import {mapActions} from "vuex";

export default {
    name: "OnDemandPushPermission",
    mixins: [AuthenticatedMixin],
    components: {Modal},
    emits: ['success'],
    data() {
        return {
            working: false
        }
    },

    methods: {
        ...mapActions({
            pushEnabled: "application/setPushEnabled"
        }),
        async closeHandler() {

            pushDataLayer(PUSH_NOTIFICATION_ACCEPT_FLOW_CLOSE, this.auth.user)

            this.$emit('success')
        },

        async clickHandler() {

            pushDataLayer(PUSH_NOTIFICATION_PROMPT_ACCEPT, this.auth.user)

            pushDataLayer(PUSH_NOTIFICATION_ACCEPT_FLOW_SHOW, this.auth.user)

            try {

                await navigator.serviceWorker.getRegistration()

                let request = await requestPushNotification()

                this.working = true

                pushDataLayer(PUSH_NOTIFICATION_BROWSER_REQUEST, this.auth.user)

                if( ! request) {
                    return this.delayHandler()
                }

                const token = await getUserToken()

                await subscribe(token)

                await this.pushEnabled(true)

                pushDataLayer(PUSH_NOTIFICATION_BROWSER_ALLOW, this.auth.user)

                this.$emit('success', true)

            } catch(e)  {

                reject()

                pushDataLayer(PUSH_NOTIFICATION_BROWSER_REJECT, this.auth.user)

                this.$emit('success', false)

            }

        },

        delayHandler() {
            if(this.working)
                return

            pushDataLayer(PUSH_NOTIFICATION_PROMPT_SKIP, this.auth.user)
            this.$emit('success', null)
        }

    },
    mounted() {
        pushDataLayer(PUSH_NOTIFICATION_PROMPT_SHOW, this.auth.user)
    }
}
</script>

<style lang="scss" scoped>

h1 {
    margin-bottom: 60px;
}
.push-notification-box {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px;
}

.bell-border, .btn-bell {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.bell {
    height: 60px;
    width: 60px;
    box-shadow: -1px 2px 10px #999;
    background: $color-type19;
    animation-name: col;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    border-radius: 50%;
    position: relative;
}
.banner-pwa {
    background-color: #fff;
    padding: 30px;
    display: flex;
    border-radius: 10px;
    gap: 30px;
    flex: 1 1 100%;


    .icon-wrapper-circle {
        width: 100px;
        min-width: 100px;
        height: 100px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .banner-pwa__content {
        h1 {
            font-weight: 700;
            margin-bottom: 10px;
            line-height: 1.3;
            font-size: 22px;
        }

        p {
            line-height: 1.3;
        }
    }

    @media(max-width: 768px) {
        flex-direction: column;
        text-align: center;
        padding: 30px 20px 40px;

        .icon-wrapper-circle {
            margin: 0 auto;
        }
    }
}

.bell-border {
    height: 59px;
    width: 59px;
    border: 1px solid $color-type19!important;
    animation-name: bord-pop;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    box-shadow: 2px 2px 5px #ccc, -2px -2px 5px #ccc;
}

.btn-bell {
    color: white;
    font-size: 20px;
    animation-name: bell-ring;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes bord-pop {
    0% {
        transform: translate(-50%, -50%);
    }
    50% {
        transform: translate(-50%, -50%) scale(1.9);
        opacity: 0.1;
    }
    100% {
        transform: translate(-50%, -50%) scale(1.9);
        opacity: 0;
    }
}

@keyframes col {
    0% {
        transform: scale(1) translate(0,0);
    }
    10% {
        transform: scale(1.1) translate(0,0);
    }
    75% {
        transform: scale(1) translate(0,0);
    }
    100% {
        transform: scale(1) translate(0,0);
    }
}

@keyframes bell-ring {
    0% {
        transform: translate(-50%, -50%);
    }
    5%, 15% {
        transform: translate(-50%, -50%) rotate(25deg);
    }
    10%, 20% {
        transform: translate(-50%, -50%) rotate(-25deg);
    }
    25%  {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
}
</style>