<template>
    <div class="bubble-notification-modal">

        <div class="bubble-notification-modal__header">
            <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"><g data-name="Group 249" transform="translate(-150 -366)"><circle data-name="Ellipse 59" cx="30" cy="30" r="30" transform="translate(150 366)" fill="#f5f5f5"/><path data-name="Icon awesome-eye" d="M199.764 395.011A21.717 21.717 0 0 0 180.5 383a21.72 21.72 0 0 0-19.264 12.012 2.19 2.19 0 0 0 0 1.976A21.717 21.717 0 0 0 180.5 409a21.72 21.72 0 0 0 19.264-12.012 2.19 2.19 0 0 0 0-1.976ZM180.5 405.75a9.75 9.75 0 1 1 9.75-9.75 9.75 9.75 0 0 1-9.75 9.75Zm0-16.25a6.453 6.453 0 0 0-1.714.257 3.24 3.24 0 0 1-4.53 4.53 6.485 6.485 0 1 0 6.244-4.787Z" fill="#c59264"/></g></svg>
            </div>

            <div class="img-wrapper">
                <img :src="getUserPhotoURL(bubble.payload.user)" alt="User Photo">
            </div>
        </div>
        <div class="text">
            {{$t('notifications.visit.title')}}
        </div>
        <h2>{{$t(`notifications.visit.subtitle.${bubble.payload.user.gender === TYPE_GENDER_FEMALE ? 'female' : 'male'}`)}}</h2>
        <button @click="messageUser" :disabled="working" class="button button--primary">{{$t(`notifications.actions.send_message.${bubble.payload.user.gender === TYPE_GENDER_FEMALE ? 'female' : 'male'}`)}}</button>
        <router-link class="button button--fourth" @click="$emit('close'); pushDataLayer(GTM_EVENT_ONLINE_PROFILE_CLICK_2, auth.user)" :to="{name: 'user', params: {id: bubble.payload.user.id}}">{{$t(`notifications.actions.check.${bubble.payload.user.gender === TYPE_GENDER_FEMALE ? 'female' : 'male'}`)}}</router-link>
    </div>
</template>

<script>

import { getUserPhotoURL } from "@/service/user/profile";
import { TYPE_GENDER_FEMALE } from "@/types/user";
import { getOrCreate } from "@/api/messages";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { GTM_EVENT_PROFILE_VISIT_OPEN, GTM_EVENT_PROFILE_VISIT_CLICK_1, GTM_EVENT_ONLINE_PROFILE_CLICK_2 } from "@/service/gtm/types";
import { pushDataLayer } from "@/service/gtm";
import toastr from "toastr";
import { mapActions } from "vuex";

export default {
    name: "ProfileVisit",
    mixins: [AuthenticatedMixin],
    props: {
        bubble: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            working: false
        }
    },
    methods: {
        getUserPhotoURL,
        pushDataLayer,
        ...mapActions({
            addPreAction: "profile/add"
        }),
        async messageUser() {
            this.working = true

            try {
                const { data } = await getOrCreate(this.bubble.payload.user.id)
                pushDataLayer(GTM_EVENT_PROFILE_VISIT_CLICK_1, this.auth.user)
                await this.$router.push({name: 'messages', params: {conversation: data.id}})
            } catch(e) {
                if(e?.response?.status === 406) {
                    await this.addPreAction('daily_limit')
                } else {
                    toastr.error(this.$i18n.t('credits.error_processing'))
                }
            }

            this.working = false

            this.$emit('close')
        }
    },
    created() {
        this.TYPE_GENDER_FEMALE                 = TYPE_GENDER_FEMALE
        this.GTM_EVENT_ONLINE_PROFILE_CLICK_2   = GTM_EVENT_ONLINE_PROFILE_CLICK_2
    },
    mounted() {
        pushDataLayer(GTM_EVENT_PROFILE_VISIT_OPEN, this.auth.user)
    }
}
</script>

<style lang="scss" scoped>

</style>