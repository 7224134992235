<template>
    <div class="upload-photo">
        <ContentLoader v-if="working" />
        <div v-if="source && ! working">
            <ImageEditor ref="editor" :source="source" :private-item="secret" @edit="save" @clear="clear">
                <template #content>
                    <div class="secret-profile-photo">
                        <SwitchButton :checked="secret" @switch="value => secret = value">
                            <p class="mb0 switch-box-paragraph">{{$t('profile.secret_profile_photo')}}</p>
                        </SwitchButton>
                        <transition name="slidedown">
                            <p v-if="secret" class="u-text-left p0">{{$t('profile.secret_photo_visible')}}</p>
                        </transition>
                    </div>
                </template>
            </ImageEditor>
        </div>
        <div v-if=" ! source && ! working" class="u-text-center">
            <article>
                <h1>
                    <strong v-if=" ! requestSecret">{{$t('signup.male.upload_photo.upload_profile_picture')}}</strong>
                    <strong v-else>{{$t('profile.add_secret_profile_photo')}}</strong>
                </h1>
                <label for="upload-photo" class="upload-photo-label">
                    <svg v-if="requestSecret" xmlns="http://www.w3.org/2000/svg" width="142.069" height="160"><g data-name="Group 374"><path fill="#4b4b4b" d="M58.924 74.294a37.147 37.147 0 1 1 37.293-37.147 37.263 37.263 0 0 1-37.293 37.147Zm0-66.721a29.574 29.574 0 1 0 29.69 29.574 29.665 29.665 0 0 0-29.69-29.573Z" data-name="Path 78"/><path fill="#4b4b4b" d="M50.406 134.677a3.854 3.854 0 0 1-.437-.025A79.367 79.367 0 0 1 1.42 110.444l-1.42-1.5.491-2C3.556 94.463 6.748 86.318 11.51 78.831a15.372 15.372 0 0 1 12.9-6.985 14.439 14.439 0 0 1 7.082 1.833 56.779 56.779 0 0 0 54.872 0 14.445 14.445 0 0 1 7.082-1.833 15.365 15.365 0 0 1 12.9 6.985c1.049 1.649 5.295 8.924 6.207 10.665a3.8 3.8 0 0 1-6.74 3.5c-.866-1.652-4.895-8.552-5.89-10.117a7.693 7.693 0 0 0-6.472-3.465 6.806 6.806 0 0 0-3.338.851 64.407 64.407 0 0 1-62.36 0 6.8 6.8 0 0 0-3.337-.851 7.7 7.7 0 0 0-6.474 3.466c-4.033 6.341-6.851 13.347-9.547 23.832a71.757 71.757 0 0 0 42.447 20.41 3.786 3.786 0 0 1-.428 7.549Z" data-name="Path 79"/><g data-name="Group 35"><path fill="#2c7ddb" d="M83.414 160a24.445 24.445 0 1 1 14.582-44.09l34.12-34.064a3.603 3.603 0 0 1 5.1 5.092l-5.039 5.03 8.837 8.829a3.6 3.6 0 0 1 0 5.092l-13.29 13.266a3.717 3.717 0 0 1-5.1 0l-8.84-8.826-10.688 10.673a24.444 24.444 0 0 1-19.682 39m0-41.688a17.263 17.263 0 1 0 12.216 5.052 17.178 17.178 0 0 0-12.216-5.054m35.473-13.075 6.29 6.28 8.187-8.174-6.286-6.283Z" data-name="Path 26"/></g></g></svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="132.48" height="160.001"><g data-name="Group 373"><path fill="#4b4b4b" d="M51.426 134.683a3.916 3.916 0 0 1-.446-.026 80.86 80.86 0 0 1-49.532-24.8L0 108.323l.5-2.05c3.128-12.781 6.385-21.123 11.243-28.79a15.668 15.668 0 0 1 13.158-7.154 14.687 14.687 0 0 1 7.225 1.878 57.732 57.732 0 0 0 55.984 0 14.693 14.693 0 0 1 7.225-1.878 15.661 15.661 0 0 1 13.157 7.155c.188.324.6.885.912 1.475a3.879 3.879 0 1 1-6.877 3.59c-.211-.316-.423-.695-.588-.913a7.841 7.841 0 0 0-6.6-3.549 6.922 6.922 0 0 0-3.406.872 65.487 65.487 0 0 1-63.623 0 6.917 6.917 0 0 0-3.4-.872 7.847 7.847 0 0 0-6.605 3.55c-4.115 6.5-6.989 13.671-9.74 24.41a73.1 73.1 0 0 0 43.298 20.902 3.879 3.879 0 0 1-.436 7.733Z" data-name="Path 79"/><path fill="#2c7ddb" d="M59.894 123.707a36.293 36.293 0 1 1 36.293 36.294 36.335 36.335 0 0 1-36.293-36.294Zm7.757 0a28.535 28.535 0 1 0 28.536-28.533 28.57 28.57 0 0 0-28.536 28.533Zm24.658 14.547v-10.668H81.642a3.878 3.878 0 1 1 0-7.756h10.667v-10.668a3.878 3.878 0 0 1 7.756 0v10.668h10.667a3.878 3.878 0 1 1 0 7.756h-10.667v10.668a3.878 3.878 0 0 1-7.756 0Z" data-name="Union 20"/><path fill="#4b4b4b" d="M59.895 74.3a37.148 37.148 0 1 1 37.294-37.153A37.264 37.264 0 0 1 59.895 74.3Zm0-66.722a29.574 29.574 0 1 0 29.69 29.573 29.666 29.666 0 0 0-29.69-29.577Z" data-name="Union 19"/></g></svg>
                </label>
                <p v-if="!requestSecret">{{$t('file_control.profile_photo_advice')}}</p>
                <p v-else>{{$t('profile.private_profile_photo_visible')}}</p>
                <input ref="photo" @change="setPhoto" :accept="ALLOWED_IMAGE_FORMATS.map(e => `image/${e}`).join(', ')" type="file" id="upload-photo">
                <label for="upload-photo" class="button button--primary w-100">{{requestSecret ? $t('profile.upload_secret_photo') : ucFirst($t('quick_start.upload'))}}</label>
                <p v-if="auth.user.gender === TYPE_GENDER_MALE" class="mb0 mt"><a @click="$emit('finish', {uploaded: false})">{{$t('credits.skip_for_now')}}</a></p>
            </article>
        </div>
    </div>
</template>

<script>
import ImageEditor from "@/components/generic/ImageEditor";
import { base64ToBlob }  from "@/service/files";
import toastr from "toastr";
import ContentLoader from "@/components/ContentLoader";
import { ucFirst } from "@/utilities/general";
import { getExtensionFromFilename, uploadToBucket, getExtensionFromBase64 } from "@/service/files";
import { ALLOWED_IMAGE_FORMATS } from "@/utilities/files";
import { TYPE_GENDER_MALE } from "@/types/user";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { createPreSignedURL, createItem } from "@/api/files";
import { ITEM_TYPE_PHOTO } from "@/types/item";
import { setPhotoAsProfile} from "@/service/photo";
import { mapState } from "vuex";
import SwitchButton from "@/components/generic/SwitchButton.vue";


export default {
    name: "UploadProfilePhoto",
    mixins: [AuthenticatedMixin],
    components: {SwitchButton, ImageEditor, ContentLoader },
    emits: ['finish'],
    props: {
        requestSecret: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            source: null,
            type: null,
            working: false,
            finishedItem: {},
            secret: false
        }
    },
    computed: {
        ...mapState({
            ready: state => state.items.ready
        })
    },
    watch: {
        async ready(value) {

            const item = {
                ...value[value.length - 1]
            }

            if( ! item.ready || item.type !== ITEM_TYPE_PHOTO)
                return

            this.finishedItem = {
                ...item
            }
            await setPhotoAsProfile(this.finishedItem)
            this.finishHandler()
        }
    },
    methods: {
        ucFirst,
        async setPhoto($event) {

            if( ! $event.target.files.length)
                return

            const [file] = $event.target.files
            const extension = getExtensionFromFilename(file.name).toLowerCase()

            if( ! ALLOWED_IMAGE_FORMATS.includes(extension)) {
                this.$refs.photo.value = ''
                return toastr.error(this.$i18n.t('form_fields.validation.file_type', [null, extension]))
            }

            this.type = file.type
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = () => this.$nextTick(() => this.source = reader.result)

        },

        clear() {
            this.source = null
            this.type = null
        },

        finishHandler() {
            toastr.success(this.$i18n.t('quick_start.profile_photo_uploaded'))
            this.$emit('finish', {uploaded: true})
        },

        async save(data) {

            try {
                this.working = true

                const extension     = getExtensionFromBase64(data.slice(0, 20))
                const preSignedUrl  = await createPreSignedURL(extension)

                await uploadToBucket(preSignedUrl.data, base64ToBlob(data))

                await createItem({file: preSignedUrl.data.filename, type: ITEM_TYPE_PHOTO, private: this.secret ? 1 : 0})


            } catch (e) {
                console.log(e)
                toastr.error(this.$i18n.t('credits.error_processing'))
            } finally {
                this.source = null
            }
        }
    },
    created() {
        this.ALLOWED_IMAGE_FORMATS = ALLOWED_IMAGE_FORMATS
        this.TYPE_GENDER_MALE = TYPE_GENDER_MALE
    },
    mounted() {
        this.secret = this.requestSecret
    }
}
</script>

<style lang="scss" scoped>

    .upload-photo {
        min-height: 400px;
    }

    input {
        position: absolute;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
    }

    .upload-photo-label {
        background-color: #F5F5F5;
        width: 230px;
        height: 230px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 30px;
    }

    article {
        max-width: 400px;
        margin: 0 auto;

        h1 {
            margin-bottom: 60px;
        }

        p {
            margin-top: 10px;
            margin-bottom: 40px;
            font-size: 16px;
            line-height: 21px;
            padding: 0 12px;
        }
    }
</style>