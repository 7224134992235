<template>
    <div class="bubble-notification-modal">

        <div class="bubble-notification-modal__header">
            <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"><g data-name="Group 248" transform="translate(-150 -366)"><circle data-name="Ellipse 59" cx="30" cy="30" r="30" transform="translate(150 366)" fill="#f5f5f5"/><path data-name="Shape 6" d="m196.955 387.798-9.33 2.716v-4.7a1.557 1.557 0 0 0-1.548-1.567h-22.512a1.566 1.566 0 0 0-1.565 1.567v20.376a1.566 1.566 0 0 0 1.565 1.567h22.512a1.557 1.557 0 0 0 1.548-1.567v-4.7l9.33 2.716a.762.762 0 0 0 1.044-.708v-14.992a.761.761 0 0 0-1.044-.708Z" fill="#c59264"/></g></svg>
            </div>

            <div class="img-wrapper">
                <img :src="getUserPhotoURL(bubble.payload.user)" alt="User Photo">
            </div>
        </div>
        <div class="text">
            {{$t('notifications.new_video.title')}}
        </div>
        <h2>{{$t(`notifications.new_video.subtitle.${bubble.payload.user.gender === TYPE_GENDER_MALE ? 'male' : 'female'}`)}}</h2>
        <router-link @click="$emit('close'); pushDataLayer(GTM_EVENT_ADDED_NEW_VIDEO_CLICK_1, auth.user)" :to="{name: 'user', params: {id: bubble.payload.user.id}, query: {load: 'video'}}" class="button button--primary">
            {{$t('notifications.actions.watch_video')}}
        </router-link>
        <router-link class="button button--fourth" @click="$emit('close'); pushDataLayer(GTM_EVENT_ADDED_NEW_VIDEO_CLICK_2, auth.user)" :to="{name: 'user', params: {id: bubble.payload.user.id}}">
            {{$t(`notifications.actions.check.${bubble.payload.user.gender === TYPE_GENDER_MALE ? 'male' : 'female'}`)}}
        </router-link>
    </div>
</template>

<script>
import { TYPE_GENDER_MALE } from "@/types/user";
import { getUserPhotoURL } from "@/service/user/profile";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { GTM_EVENT_ADDED_NEW_VIDEO_OPEN, GTM_EVENT_ADDED_NEW_VIDEO_CLICK_1, GTM_EVENT_ADDED_NEW_VIDEO_CLICK_2 } from "@/service/gtm/types";
import { pushDataLayer } from "@/service/gtm";

export default {
    name: "NewVideo",
    mixins: [AuthenticatedMixin],
    props: {
        bubble: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            working: false
        }
    },
    methods: {
        pushDataLayer,
        getUserPhotoURL,
    },
    created() {
        this.TYPE_GENDER_MALE                   = TYPE_GENDER_MALE
        this.GTM_EVENT_ADDED_NEW_VIDEO_CLICK_1  = GTM_EVENT_ADDED_NEW_VIDEO_CLICK_1
        this.GTM_EVENT_ADDED_NEW_VIDEO_CLICK_2  = GTM_EVENT_ADDED_NEW_VIDEO_CLICK_2
    },
    mounted() {
        pushDataLayer(GTM_EVENT_ADDED_NEW_VIDEO_OPEN, this.auth.user)
    }
}
</script>

<style lang="scss" scoped>

</style>