<template>
    <Modal class="modal modal--mob modal--block-user" :size="'small'">
        <template #body>
            <h2>{{$t('block_report.block')}} {{user.username}}</h2>
            <p>{{$t('block_report.are_you_sure')}} {{$t('block_report.permanently_block')}} {{user.username}}? {{$t('block_report.if_so')}} {{user.username}}.</p>
            <ul>
                <li @click="reason = $t('block_report.not_my_type')"><a :class="reason === $t('block_report.not_my_type') ? 'active' : ''">{{$t('block_report.not_my_type')}}</a></li>
                <li @click="reason = $t('block_report.profile_content')"><a :class="reason === $t('block_report.profile_content') ? 'active' : ''">{{$t('block_report.profile_content')}}</a></li>
                <li @click="reason = $t('block_report.inperson_behavior')"><a :class="reason === $t('block_report.inperson_behavior') ? 'active' : ''">{{$t('block_report.inperson_behavior')}}</a></li>
                <li @click="reason = $t('block_report.too_far_away')"><a :class="reason === $t('block_report.too_far_away') ? 'active' : ''">{{$t('block_report.too_far_away')}}</a></li>
                <li @click="reason = $t('block_report.other')"><a :class="reason === $t('block_report.other') ? 'active' : ''">{{$t('block_report.other')}}</a></li>
            </ul>
            <button @click="blockUser" class="button button--primary w-100" :disabled="!reason.length || working">{{$t('block_report.block')}}</button>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal";
import { blockUser } from "@/service/user";
import toastr from "toastr";

export default {
    name: "BlockedUserDialog",
    components: {Modal},
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            reason: '',
            working: false
        }
    },
    methods: {
        async blockUser() {

            this.working = true

            try {
                await blockUser(this.user.id, this.reason)
                this.working = false
                this.$emit('close')
                this.$emit('success')
                toastr.success(this.$i18n.t('block_report.user_blocked'))
            } catch (e) {
                console.log('Error blocking user', e)
                toastr.error(this.$i18n.t('credits.error_processing'))
            }

        }
    }
}
</script>

<style scoped lang="scss">
    .modal {
        h2 {
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 30px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 20px;
        }

        li {

            &:not(:last-child) {
                margin-bottom: 15px;
            }

            a {
                font-size: 18px;
                line-height: 24px;
                color: $color-type18;

                &:hover,
                &.active {
                    color: $color-type19;
                }
            }
        }
    }
</style>