<template>
    <button :disabled="disabled" @click="$emit('click')" :class="className">
        <slot name="content"></slot>
    </button>
</template>

<script>
export default {
    name: "Button",
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        className: {
            type: Array,
            default: () => ['btn', 'btn-default']
        }
    }
}
</script>

<style scoped>

</style>