<template>
    <article v-if="loading" class="email-notifications-box"></article>
    <article v-else class="email-notifications-box">
        <div class="header-mob">
            <BackButton />
            <h1>{{$t('settings.email_notifications')}}</h1>
        </div>
        <p class="mb--">{{$t('settings.notifications.immediate_label')}}</p>
        <div class="checkbox">
            <input v-model="settings.message" class="form-check-input" type="checkbox" id="checkbox1">
            <label class="checkbox__label" for="checkbox1">
                <div class="checkbox__icon">
                    <svg width="12" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                </div>
                {{$t('settings.notifications.immediate_message')}}
            </label>
        </div>
        <div class="checkbox">
            <input v-model="settings.favorite" class="form-check-input" type="checkbox" id="checkbox2">
            <label class="checkbox__label" for="checkbox2">
                <div class="checkbox__icon">
                    <svg width="12" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                </div>
                {{$t('settings.notifications.immediate_favorite')}}
            </label>
        </div>
        <div class="checkbox">
            <input v-model="settings.request_access" class="form-check-input" type="checkbox" id="checkbox3">
            <label class="checkbox__label" for="checkbox3">
                <div class="checkbox__icon">
                    <svg width="12" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                </div>
                {{$t('settings.notifications.immediate_request_access')}}
            </label>
        </div>
        <div class="checkbox">
            <input v-model="settings.give_access" class="form-check-input" type="checkbox" id="checkbox4">
            <label class="checkbox__label" for="checkbox4">
                <div class="checkbox__icon">
                    <svg width="12" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                </div>
                {{$t('settings.notifications.immediate_give_access')}}
            </label>
        </div>
        <div class="checkbox">
            <input v-model="settings.visit" class="form-check-input" type="checkbox" id="checkbox5">
            <label class="checkbox__label" for="checkbox5">
                <div class="checkbox__icon">
                    <svg width="12" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                </div>
                {{$t('settings.notifications.immediate_visit')}}
            </label>
        </div>
        <p class="mb--">{{$t('settings.notifications.occasional_label')}}</p>
        <div class="checkbox">
            <input v-model="settings.activity_notifications" class="form-check-input" type="checkbox" id="checkbox6">
            <label class="checkbox__label" for="checkbox6">
                <div class="checkbox__icon">
                    <svg width="12" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                </div>
                {{$t('settings.notifications.occasional_activity')}}
            </label>
        </div>
        <div class="checkbox">
            <input v-model="settings.new_member" class="form-check-input" type="checkbox" id="checkbox7">
            <label class="checkbox__label" for="checkbox7">
                <div class="checkbox__icon">
                    <svg width="12" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                </div>
                {{$t('settings.notifications.occasional_new_member')}}
            </label>
        </div>
        <p class="mb--">{{$t('settings.notifications.newsletters_label')}}</p>
        <div class="checkbox">
            <input v-model="settings.tips" class="form-check-input" type="checkbox" id="checkbox8">
            <label class="checkbox__label" for="checkbox8">
                <div class="checkbox__icon">
                    <svg width="12" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                </div>
                {{$t('settings.notifications.newsletters_tips')}}
            </label>
        </div>
        <div class="checkbox">
            <input v-model="settings.announcements" class="form-check-input" type="checkbox" id="checkbox9">
            <label class="checkbox__label" for="checkbox9">
                <div class="checkbox__icon">
                    <svg width="12" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                </div>
                {{$t('settings.notifications.newsletters_announcements')}}
            </label>
        </div>
        <br>
        <a @click="reset" class="unsubscribe-link">{{$t('settings.notifications.unsubscribe_all')}}</a>

        <div class="btn-wrapper">
            <button @click="update($event)" class="w-100 button button--primary">{{$t('settings.save')}}</button>
        </div>
    </article>
</template>

<script>
import {getUserEmailSettings, setUserEmailSettings} from "@/api/profile";
import toastr from "toastr";
import BackButton from "@/components/generic/BackButton";

export default {
    name: "EmailSubscription",
    components: {BackButton},
    data() {
        return {
            settings: {
                message: false,
                favorite: false,
                request_access: false,
                give_access: false,
                visit: false,
                new_member: false,
                announcements: false,
                activity_notifications: false,
                tips: false
            },
            loading: false
        }
    },
    methods: {
        reset() {
            for(const i in this.settings) {
                this.settings[i] = false
            }
        },
        async update($event) {

            $event.target.disabled = true

            try {
                const payload = {}
                for(const i in this.settings) {
                    payload[i] = this.settings[i] ? 1 : 0
                }
                await setUserEmailSettings(payload)
                toastr.success(this.$i18n.t('settings.notifications.set_successfully'))
            } catch {
                toastr.success(this.$i18n.t('credits.error_processing'))
            }

            $event.target.disabled = false

        }
    },
    async mounted() {

        this.loading = true
        const { data } = await getUserEmailSettings()
        for(const i in this.settings) {
            this.settings[i] = !! data[i]
        }
        this.loading = false

    }

}
</script>

<style scoped lang="scss">
@import "../../assets/styles/components/_components.settings.scss";

.email-notifications-box {

    p {
        margin-top: 30px;
        font-size: 16px;
        line-height: 20px;
        color: $color-type10;
    }

    .unsubscribe-link {
        color: $color-type18;
        font-size: 16px;
        line-height: 1.4;
        margin-top: 30px;
        font-weight: 400;
    }

    .btn-wrapper {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    @media(max-width: 991px) {
        overflow: auto;

        .checkbox {
            display: flex;

            .checkbox__label {
                margin-top: 5px;
                margin-bottom: 5px;
                display: flex;
                height: auto!important;
                line-height: 1.3;
            }
        }

        .btn-wrapper {
            justify-content: center;

            @media(max-width: 500px) {
                position: fixed;
                left: 0;
                bottom: 0;
                width: 100%;
                background-color: $color-type3;
                padding: 10px 20px;
                border-radius: 10px 10px 0 0;
                margin: 0;
                .button {
                    width: 100%;
                    margin-left: 0!important;
                }
            }
        }
    }

    @media(max-width: 991px) {
        position: fixed;
        left: 0;
        top: 0;
        padding: 20px 20px 70px;
        width: 100%;
        height: 100%;
        background-color: #fff;
    }
}

</style>