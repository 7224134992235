<template>

    <div v-if="!paymentModal" class="price__heading">
        <div class="price__heading-box">
            <BackButton class="back-button"/>
            <h2 class="mb0 h1">{{$t('promotions.verification.profile')}}</h2>
        </div>

        <h4 class="mb">{{$t('promotions.verification.one_more_step')}}</h4>
        <h2 class="mb-"><svg class="verified-icon mr--" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32"><g data-name="Group 121"><path data-name="Polygon 2" d="M14.51 1.665a2 2 0 0 1 2.98 0l1.268 1.417a2 2 0 0 0 2.018.6l1.821-.5a2 2 0 0 1 2.5 1.606l.315 1.924a2 2 0 0 0 1.366 1.578l1.834.587a2 2 0 0 1 1.23 2.69l-.776 1.819a2 2 0 0 0 .294 2.055l1.251 1.523a2 2 0 0 1-.421 2.923l-1.614 1.1a2 2 0 0 0-.861 1.894l.236 1.956a2 2 0 0 1-1.943 2.24l-1.9.041a2 2 0 0 0-1.762 1.137l-.83 1.737a2 2 0 0 1-2.858.838l-1.6-.994a2 2 0 0 0-2.106 0l-1.6.994a2 2 0 0 1-2.858-.838l-.83-1.737a2 2 0 0 0-1.762-1.137l-1.9-.041a2 2 0 0 1-1.943-2.24l.236-1.956a2 2 0 0 0-.861-1.894l-1.614-1.1a2 2 0 0 1-.421-2.923l1.242-1.524a2 2 0 0 0 .294-2.055l-.776-1.819a2 2 0 0 1 1.23-2.69l1.833-.586a2 2 0 0 0 1.364-1.582L6.9 4.784a2 2 0 0 1 2.5-1.606l1.821.5a2 2 0 0 0 2.018-.6Z" fill="#2c7ddb"/><path data-name="Icon metro-checkmark" d="m21.668 9.461-8.165 8.163-3.81-3.81-2.719 2.722 6.529 6.531 10.886-10.885Z" fill="#fff"/></g></svg><strong>{{$t('promotions.verification.verify_buy')}}</strong></h2>
        <p class="mb--"><i>{{$t('promotions.verification.no_hidden')}}</i></p>

    </div>

    <div v-else>
        <div class="amount-box">
            <img src="../../assets/img/dollar-icon.svg" alt="Dollar icon"> {{credits}}
        </div>
        <h2 class="h4">{{$t('credits.not_enough_credits')}}? {{$t('credits.recharge')}}:</h2>
    </div>
</template>
<script>
import BackButton from "@/components/generic/BackButton.vue";
import ThemeMixin from "@/mixins/ThemeMixin";
import { mapState } from "vuex";
export default {
    name: "SFlowOneHeader",
    components: { BackButton },
    mixins: [ThemeMixin],
    props: {
        promotion: {
            type: Object
        },
        paymentModal: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            credits: state => state.credits.amount
        })
    },
    created() {
        this.conversation_price = process.env.VUE_APP_UNLOCK_CONVERSATION
    }
}

</script>
<style scoped lang="scss">
.price__heading {
    text-align: left;

    h2 {
        margin-bottom: 10px;
        font-weight: 700;
        line-height: 1.3;
    }

    h4 {
        font-weight: 400;
        line-height: 1.3;
    }

    .verified-icon {
        transform: translate(0, 2px);
    }

    p {
        font-size: 18px;
        line-height: 24px;

        i {
            text-decoration: underline;
        }
    }

    @media(max-width: 991px) {
        padding: 0 20px;

        p {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.06px;
        }
    }
}

.price__heading-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .back-button {
        margin-right: 20px;
        display: none;
    }

    @media(max-width: 991px) {
        margin-bottom: 15px;

        .back-button {
            display: flex;
        }
    }
}

.amount-box {
    border-radius: 15px;
    background-color: #fff;
    padding-left: 40px;
    position: relative;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    height: 30px;
    width: auto;
    padding-right: 20px;
    margin-bottom: 10px;
    font-size: 18px;
    font-family: "Open Sans", sans-serif!important;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media(max-width: 350px) {
        min-width: 105px;
    }
}
</style>