export default {
    namespaced: true,
    state: {
        location: null,
        query: null,
        historyLocations: [],
        myFavorite: [],
        data: {},
        scroll_position: null
    },
    mutations: {
        setLocation(state, value) {
            state.location = value
        },
        setHistoryLocations(state, value) {
            state.historyLocations = value
        },
        setQuery(state, value) {
            state.query = value
        },
        setMyFavorite(state, value) {
            state.myFavorite = value
        },
        setData(state, value) {
            state.data = value
        },
        storeScrollPosition(state, value) {
            state.scroll_position = value
        }
    },
    actions: {
        setLocation(context, payload) {

            if(context.state.location) {

                context.commit('setHistoryLocations', [
                    context.state.location,
                    ...context.state.historyLocations
                        .slice(0, 4)
                        .filter(e => e.location !== context.state.location)
                        .filter(e => e.location !== payload.location)
                ])
            }

            context.commit('setLocation', payload)

            localStorage.setItem('search_location', JSON.stringify(payload))
            localStorage.setItem('search_history_locations', JSON.stringify(context.state.historyLocations))

        },
        setQuery({commit}, value) {
            commit('setQuery', value)
        },
        addMyFavorite(context, id) {

            if(context.state.myFavorite.includes(id))
                return

            context.commit('setMyFavorite', [
                ...context.state.myFavorite,
                id
            ])
        },
        removeMyFavorite(context, id) {
            context.commit('setMyFavorite', [
                ...context.state.myFavorite.filter(e => e !== id)
            ])
        },
        setData({commit}, value) {
            commit('setData', value)
        },
        storeScrollPosition({commit}, value) {
            commit('storeScrollPosition', value)
        }
    },
    getters: {}
}