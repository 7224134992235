<template>
    <router-link v-if="queue.length" :to="{name: 'messages', params: {conversation: queue[0].conversation_id}}">
        <div class="sending-info">
            <SendingAnimation />
            <span>{{queue.length}}</span>
        </div>
    </router-link>
</template>

<script>
import SendingAnimation from "@/components/messages/SendingAnimation";

export default {
    name: "SendingPanel",
    components: { SendingAnimation },
    props: {
        queue: {
            type: Array,
            default: () => []
        }
    }
}
</script>

<style scoped lang="scss">
    .sending-info {
        position: fixed;
        bottom: 30px;
        right: 30px;
        z-index: 9999;
        background-color: #fff;
        padding: 10px 15px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        color: #000;
        box-shadow: 0 0 8px -1px #000;

        span {
            background-color: #B89450;
            padding: 0 6px;
            height: 20px;
            border-radius: 20px;
            color: #fff;
            margin-left: 10px;
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 600;
        }
    }
</style>