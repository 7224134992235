import {createStore} from 'vuex'
import authenticate from "@/store/modules/authenticate";
import search from "@/store/modules/search";
import items from "@/store/modules/items";
import profile from "@/store/modules/profile";
import credits from "@/store/modules/credits";
import promotions from "@/store/modules/promotions";
import application from "@/store/modules/application";
import messages from "@/store/modules/messages";
import notifications from "@/store/modules/notifications";
import bubble from "@/store/modules/bubble";
import animations from "@/store/modules/animations";
import access from "@/store/modules/access";

export default createStore({
    state: {},
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        authenticate, search, items, profile, credits, promotions, application, messages, notifications, bubble,
        animations, access
    }
})
