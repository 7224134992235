<template>
    <div class="o-container">
        <main>
            <hr />
            <div>
                <h2>
                    Application
                </h2>
                <code>{{application}}</code>
            </div>
            <hr />
            <div>
                <h2>
                    Push notifications
                </h2>
                <code>
                    {{notifications}}
                </code>
            </div>
            <hr />
        </main>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: 'Debug',
    computed: {
        ...mapState({
            application: state => state.application,
        }),
        notifications() {
            return (Notification && Notification.permission === 'granted') ? 'Yes' : 'No'
        },
    }
}
</script>