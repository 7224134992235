<template>
    <div class="tab-section">
        <ContentLoader v-if="loading" />
        <template v-else>
            <article class="no-data" v-if="!loading && !admirers.length">
                <div class="img-wrapper">
                    <img alt="Image" width="100" height="100" src="@/assets/img/no_admirers.svg">
                </div>
                <h1>{{$t('matches.admirers.title')}}</h1><br>
                <p class="u-text-center">{{$t('matches.admirers.text')}}</p>
                <router-link class="button button--primary" :to="{name: 'search'}">{{$t('matches.search')}}</router-link>
            </article>
            <article class="list">
                <div v-if="admirers.length">
                    <transition-group appear name="fade-in" tag="ul">
                        <li v-for="(a, index) in admirers" :key="a.id" :style="'--animate:' + index">
                            <router-link :to="{name: 'user', params: {id: a.id}}">
                                <div class="user-info">
                                    <div class="img-wrapper" :class="notifications.social?.matches?.admirers?.includes(a.id) ? 'is-active' : ''">
                                        <Image alt="Profile photo" :src="getUserPhotoURL(a, 'small')" :blurred="!! a.profile_photo?.private && ! access.includes(a.id)" />
                                    </div>
                                    <OnlineStatus class="active-user" v-if="isOnline(a)" />
                                    <p class="mb0">
                                        <span class="o-flex o-flex--center">
                                            <Premium v-if="a.premium" />
                                            <Verified v-if="a.profile_verified_at" />
                                            <strong>{{a.username}}</strong>
                                        </span>
                                        <span>{{a.address}}</span>
                                    </p>
                                </div>
                            </router-link>
                            <Favorite :profile="a" />
                        </li>
                    </transition-group>
                </div>
            </article>
            <Pagination @page-click="load" :info="pagination" />
        </template>
    </div>
</template>

<script>
import ContentLoader from "@/components/ContentLoader";
import { getAdmirers } from "@/api/matches/admirers";
import { getUserPhotoURL, isOnline } from "@/service/user/profile";
import Pagination from "@/components/generic/Pagination";
import Favorite from "@/components/search/Favorite";
import { getPaginationPayload } from "@/service/general";
import { mapState } from "vuex";
import { read } from "@/service/notifications";
import OnlineStatus from "@/components/user/OnlineStatus";
import Premium from "@/components/generic/Premium.vue";
import Verified from "@/components/generic/Verified.vue";
import Image from "@/components/generic/Image.vue";


export default {
    name: "Admirers",
    components: {Image, Verified, Premium, Pagination, Favorite,ContentLoader, OnlineStatus},
    data() {
        return {
            loading: false,
            page: 1,
            admirers: [],
            pagination: null,
            timeout: null
        }
    },
    computed: {
        ...mapState({
            notifications: state => state.notifications,
            access: state => state.access.available
        })
    },
    watch: {
        '$store.state.notifications.social.matches.admirers': {
            handler: function(value) {

                if( ! value.length)
                    return

                this.load()
            }
        }
    },
    methods: {
        getUserPhotoURL,
        isOnline,
        async load(page = 1) {
            this.loading = true
            this.admirers = []
            const { data } = await getAdmirers({page: page})
            this.pagination = getPaginationPayload(data)
            this.admirers = data.data
            this.loading = false
        },
    },
    async mounted() {
        await this.load()
        if(this.notifications.social.matches.admirers.length) {
            this.timeout = setTimeout(() => {
                read('admirers', this.notifications.social.matches.admirers)
            }, 3000)
        }
    },
    beforeUnmount() {
        if(this.notifications.social.matches.admirers.length) {
            clearTimeout(this.timeout)
            read('admirers', this.notifications.social.matches.admirers)
        }
    }
}
</script>

<style scoped lang="scss">
    main {
        height: auto;
        min-height: 100%!important;

    }
    // @import "../../assets/styles/components/_components.page-tab-content.scss";
</style>