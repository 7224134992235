<template>
    <div>
        <div class="bubble-wrapper">
            <Bubble @click="open(bubble)" v-for="bubble in bubbles" :key="bubble.id" :type="bubble.type" :user="bubble.payload.user" />
        </div>
        <Modal @close="closeHandler" class="modal modal-notifications modal--mob" v-if="component">
            <template #body>
                <component @close="closeHandler" :is="component" :bubble="payload" />
            </template>
        </Modal>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getUserPhotoURL } from "@/service/user/profile";
import Modal from "@/components/generic/Modal";
import {
    TYPE_ACCESS_REQUESTED, TYPE_FAVORITE, TYPE_MESSAGE, TYPE_ACCESS_APPROVED,
    TYPE_PROFILE_VISIT, TYPE_ANIMATION_SEARCH_MATCH, TYPE_ANIMATION_ONLINE, TYPE_ANIMATION_NEW_VIDEO,
    TYPE_ANIMATION_PREMIUM, TYPE_ANIMATION_OFFER
} from "@/types/bubble";
import Favorite from "@/components/bubble/Favorite";
import Message from "@/components/bubble/Message"
import AccessRequested from "@/components/bubble/AccessRequested";
import Match from "@/components/bubble/Match";
import AccessGranted from "@/components/bubble/AccessGranted";
import ProfileVisit from "@/components/bubble/ProfileVisit";
import SearchMatch from "@/components/bubble/SearchMatch";
import OnlineNow from "@/components/bubble/OnlineNow";
import NewVideo from "@/components/bubble/NewVideo";
import SuggestedSugarDaddy from "@/components/bubble/SuggestedSugarDaddy";
import PremiumSugarDaddy from "@/components/bubble/PremiumSugarDaddy";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { TYPE_GENDER_MALE } from "@/types/user";
import Image from "@/components/generic/Image";
import Bubble from "@/components/generic/Bubble";
import Offer from "@/components/bubble/Offer";

export default {
    name: "BubbleNotification",
    components: { Modal, Favorite, Message, AccessRequested, Match, AccessGranted, ProfileVisit, SearchMatch,
        OnlineNow, NewVideo, PremiumSugarDaddy, SuggestedSugarDaddy, Image, Bubble, Offer },
    mixins: [AuthenticatedMixin],
    data() {
        return {
            component: null,
            payload: null
        }
    },
    computed: {
        ...mapState({
            bubbles: state => state.bubble.data
        })
    },
    methods: {
        ...mapActions({
            removeBubble: 'bubble/remove'
        }),
        getUserPhotoURL,
        async closeHandler() {
            this.component = null;
            await this.removeBubble(this.payload.id);
            this.payload = null
        },
        open(bubble) {

            switch (bubble.type) {

                case TYPE_FAVORITE:
                    this.payload = bubble
                    this.component = bubble.payload?.user?.mutual ? 'Match' : 'Favorite'
                    break;

                case TYPE_MESSAGE:
                    this.payload = bubble
                    this.component = 'Message'
                    break;

                case TYPE_ACCESS_REQUESTED:
                    this.payload = bubble
                    this.component = 'AccessRequested'
                    break;

                case TYPE_ACCESS_APPROVED:
                    this.payload = bubble
                    this.component = 'AccessGranted'
                    break;

                case TYPE_PROFILE_VISIT:
                    this.payload = bubble
                    this.component = 'ProfileVisit'
                    break;

                case TYPE_ANIMATION_SEARCH_MATCH:
                    this.payload = bubble
                    this.component = this.auth.user.gender === TYPE_GENDER_MALE ? 'SearchMatch' : 'SuggestedSugarDaddy'
                    break;

                case TYPE_ANIMATION_ONLINE:
                    this.payload = bubble
                    this.component = 'OnlineNow'
                    break;

                case TYPE_ANIMATION_NEW_VIDEO:
                    this.payload = bubble
                    this.component = 'NewVideo'
                    break;

                case TYPE_ANIMATION_PREMIUM:
                    this.payload = bubble
                    this.component = 'PremiumSugarDaddy'
                    break;

                case TYPE_ANIMATION_OFFER:
                    this.payload = bubble
                    this.component = 'Offer'
                    break;

            }
        }
    }
}
</script>

<style scoped lang="scss">
</style>

<style lang="scss">
    .modal {

        .close {
            display: none;
        }
        &.modal-notifications {
            .modal-container {
                padding: 30px;
            }
        }
    }

    .bubble-notification-modal {
        text-align: center;
        .category-img {
            max-width: 50%;
        }

        .text {
            display: block;
            margin-bottom: 10px;
            font-family: "Relation-bold";
            font-weight: 500;
            font-size: 40px;
            color: $color-type18;
            text-align: center;
        }

        .img-wrapper {
            width: 180px;
            height: 180px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            margin: 0 auto 40px;
            overflow: hidden;
            position: relative;
        }

        .button {
            width: 100%;
            font-size: 16px;
            text-transform: uppercase;
            margin-top: 15px;
        }

        h2 {
            margin-top: 10px;
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.3;
        }
    }

    .bubble-notification-modal__header {
        position: relative;

        .icon-wrapper {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #F5F5F5;
            position: absolute;
            left: 50%;
            top: 100%;
            transform: translate(-50%, -50%);
            z-index: 5;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.bubble-notification-modal__header--double {
            .icon-wrapper {
                top: 50%;
            }
        }
    }

    .double-image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

       .img-wrapper {
           width: 140px;
           height: 140px;
           margin: 20px 5px;

           img {
               border-radius: 50%;
           }
       }
    }

    .bubble-wrapper {
        position: fixed;
        right: -10px;
        top: 150px;
        display: flex;
        flex-direction: column;
        z-index: 9999;

        @media (max-width: 991px) {
            top: 100px;
        }
    }

</style>