<template>
    <nav v-if="info && (info.next_page_url || info.prev_page_url)" class="pagination-wrapper" aria-label="...">
        <button type="button" class="pagination-nav prev button" :disabled="!pages.includes(this.info.current_page - 1)" @click="$emit('page-click', this.info.current_page - 1)">
            {{$t('pagination.previous')}}
        </button>
        <ul class="pagination pagination-lg">
            <li @click="$emit('page-click', page)" v-for="(page, key) in pages" :key="key" class="page-item" :class="page === this.info.current_page ? 'active' : ''">
                <span class="page-link">{{page}}</span>
            </li>
        </ul>
        <button type="button" class="pagination-nav next button" :disabled="!pages.includes(this.info.current_page + 1)" @click="$emit('page-click', this.info.current_page + 1)">
            {{$t('pagination.next')}}
        </button>
    </nav>
</template>

<script>
export default {
    name: "Pagination",
    computed: {
        pages() {

            if( ! this.info)
                return []

            let out = [this.info.current_page]

            if(this.info.prev_page_url) {
                out = [this.info.current_page - 1, ...out]
                if(this.fake && this.info.current_page - 2 > 0) {
                    out = [this.info.current_page - 2, ...out]
                }
            }

            if(this.info.next_page_url) {
                out = [...out, this.info.current_page + 1]
                if(this.fake) {
                    out = [...out, this.info.current_page + 2]
                }
            }

            return out.filter(e => !this.ignore.includes(e))
        }
    },
    props: {
        fake: {
            type: Boolean,
            default: false
        },
        ignore: {
            type: Array,
            default: () => []
        },
        info: {
            type: Object,
            default() {
                return {
                    current_page: 1,
                    last_page: 1,
                    next_page_url: null,
                    prev_page_url: null

                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .pagination-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 60px;

        @media(max-width: 991px) {
            margin-top: 35px;
        }
    }

    .pagination {
        display: flex;
        justify-content: center;
        position: relative;
        margin-bottom: 0;

        .list-item-prev,
        .list-item-next {
            position: absolute;
            top: 0;
        }

        .list-item-prev {
            left: 0;
        }

        .list-item-next {
            right: 0;
        }
    }

    .page-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        color: $color-type17;
        font-size: 18px;
        border-radius: 12px;
        margin: 0 10px;
        cursor: pointer;
        transition: all .3s ease;

        &:hover,
        &.active {
            color: $color-type19;
        }

        @media (hover: hover) {
            color: $color-type17;
        }

        @media(max-width: 991px) {
            margin: 0 5px;
            font-size: 18px;
        }
    }

    .pagination-nav {
        width:140px;
        height:40px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        background-color: $color-type19;
        color: #fff;

        &:disabled {
            background-color: #D6D6D6;
            color: #fff;
            opacity: 1;
        }

        &:hover:not(:disabled) {
            background-color: darken($color-type19, 10%);
        }

        @media(max-width: 991px) {
            width:110px;
            height:40px;
            letter-spacing: -0.74px;
            border-radius: 20px;
            font-size: 18px;
            font-weight: 600;
        }
    }
</style>
