export const ALLOWED_VIDEO_FORMATS = ['mp4', 'mov']

// bytes (500 Megabytes)
export const MAX_VIDEO_FILE_SIZE = 500000000

// bytes (20 Megabytes)
export const MAX_IMAGE_FILE_SIZE = 20000000

export const ALLOWED_IMAGE_FORMATS = ['jpg', 'jpeg', 'png']

export const isVideo = extension => ALLOWED_VIDEO_FORMATS.includes(extension)